import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
} from '@angular/forms';

import { IPreviousAttachmentEvent } from '../../models/previous-attachment-event';
import { ICertificateAttachmentEvent } from '../../models/certificate-attachment-event';
import { IremboFileUploadComponent } from '../irembo-file-upload/irembo-file-upload.component';
import { formatFileSizeBytesUtil } from '../../../utils/utils/base-64-uri-to-file-converter.util';
import { getCertificateNameByLocale } from '../../../utils/utils/application-form.util';

@Component({
  selector: 'irembogov-file-upload-selector',
  templateUrl: './irembo-file-upload-selector.component.html',
  styleUrls: ['./irembo-file-upload-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: IremboFileUploadSelectorComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IremboFileUploadSelectorComponent,
      multi: true,
    },
  ],
})
export class IremboFileUploadSelectorComponent
  extends IremboFileUploadComponent
  implements ControlValueAccessor, Validator, OnChanges
{
  formatFileSizeBytesUtil = formatFileSizeBytesUtil;
  @Input() allowUploadPreviousFiles!: boolean;
  @Input() noFileActionLabel = 'Click to upload';
  @Input() loadFileContentAsBase64Data = false;
  @Input() noFileSubLabelHtml = `or drag and drop <br /> ${this.allowedFormats
    .join(', ')
    .toUpperCase()} (max. ${this.formatFileSizeBytesUtil(
    this.maximumUploadSize * 1024,
    0
  ).toLocaleUpperCase()})`;

  selectAllCertificateAttachments = false;
  selectAllPreviousAttachments = false;
  selectedPreviousAttachments: IPreviousAttachmentEvent[] = [];
  selectedCertificateAttachments: ICertificateAttachmentEvent[] = [];
  readyToUpload = false;
  isUploading = false;

  selectedTab = 'document';

  selectAttchmentFile(
    type: 'document' | 'certificate',
    index: number,
    event: Event
  ) {
    if (type === 'document') {
      const checked = event.target as HTMLInputElement;
      this.previousAttachments[index].selected = checked.checked;
      this.previousAttachments[index].type = type;
      this.updateSelectedFiles(this.previousAttachments[index]);
    }

    if (type === 'certificate') {
      const checked = event.target as HTMLInputElement;
      this.certificateAttachments[index].selected = checked.checked;
      this.certificateAttachments[index].type = type;
      this.updateSelectedFiles(this.certificateAttachments[index]);
    }

    this.readyToUpload =
      this.selectedPreviousAttachments.length > 0 ||
      this.selectedCertificateAttachments.length > 0;
  }

  toggleSelectAllAttachments(type: 'document' | 'certificate') {
    if (type === 'document') {
      this.previousAttachments.forEach(
        item => (item.selected = this.selectAllPreviousAttachments)
      );
      this.selectedPreviousAttachments = this.selectAllPreviousAttachments
        ? [...this.previousAttachments]
        : [];
    } else if (type === 'certificate') {
      this.certificateAttachments.forEach(
        item => (item.selected = this.selectAllCertificateAttachments)
      );
      this.selectedCertificateAttachments = this.selectAllCertificateAttachments
        ? [...this.certificateAttachments]
        : [];
    }
    this.readyToUpload =
      this.selectedPreviousAttachments.length > 0 ||
      this.selectedCertificateAttachments.length > 0;
  }

  updateSelectedFiles(
    file: IPreviousAttachmentEvent | ICertificateAttachmentEvent
  ) {
    switch (file.type) {
      case 'document':
        this.updateSelectedAttachmentList(
          this.selectedPreviousAttachments,
          file
        );
        break;
      case 'certificate':
        this.updateSelectedAttachmentList(
          this.selectedCertificateAttachments,
          file
        );
        break;
    }
  }

  private updateSelectedAttachmentList(list: any[], file: any) {
    if (file.selected) {
      list.push(file);
    } else {
      const index = list.indexOf(file);
      if (index !== -1) {
        list.splice(index, 1);
      }
    }
  }

  uploadSelectedFiles() {
    this.isUploading = true;
    const selectedList =
      this.selectedTab === 'document'
        ? this.selectedPreviousAttachments
        : this.selectedCertificateAttachments;
    const primaryList =
      this.selectedTab === 'document'
        ? this.previousAttachments
        : this.certificateAttachments;

    if (selectedList.length > 0) {
      const file = selectedList[0];
      const event =
        this.selectedTab === 'document'
          ? this.selectPreviousAttachmentEvent
          : this.selectCertificateAttachmentEvent;

      event.emit(file as any);
      this.isUploading = false;

      const itemIndex = primaryList.indexOf(file as any);
      if (itemIndex !== -1) {
        primaryList[itemIndex].selected = false;
      }
    }
  }

  resetState(selectedTab: string) {
    this.selectAllCertificateAttachments = false;
    this.selectAllPreviousAttachments = false;
    this.selectedPreviousAttachments = [];
    this.selectedCertificateAttachments = [];
    this.certificateAttachments.forEach(item => (item.selected = false));
    this.previousAttachments.forEach(item => (item.selected = false));
    this.readyToUpload = false;
    this.isUploading = false;
    this.selectedTab = selectedTab;
  }

  override selectFile(content: TemplateRef<unknown>) {
    this.selectFileEvent.emit('select-file');
    this.selectedTab = 'document';
    this.modalService
      .open(content, {
        ariaLabelledBy: 'preview-document',
        size: 'xl',
      })
      .result.then(
        () => {
          this.resetState(this.selectedTab);
        },
        () => {
          this.resetState(this.selectedTab);
        }
      );
  }

  getCertificateName(certificateNames: Record<string, string>) {
    return getCertificateNameByLocale(certificateNames);
  }
}
