<irembogov-application-number-input
  [formControl]="formControl"
  [id]="field.key"
  [placeholder]="field.props['placeholder']"
  [appNoLength]="field.props['appNoLength']"
  [statusClass]="statusClass"
  (fetchData)="onFetchData($event)"
  [compareKeyValue]="compareKeyValue"
  [compareValue]="field.props['compareValue']"
  [compareOperator]="
    field.props['compareOperator']
  "></irembogov-application-number-input>
