<div class="container activation-success mt-5 col-3 p-3">
  <div *ngIf="showLogo" class="text-center logo-container">
    <div class="logo text-center">
      <img class="img logo" src="assets/images/logo.png" alt="irembo logo" />
    </div>
  </div>
  <div class="text-center mb-3 check text-center mt-3 image-contianer">
    <img
      class="icon-img"
      src="assets/images/irembo_password_reset_sucess.svg"
      alt="" />
  </div>
  <div class="text-center align-self-center">
    <h2 class="mb-4">{{ title }}</h2>
    <p class="m-0" [innerHTML]="description"></p>
    <button (click)="nav(continue)" class="btn btn-primary mt-3 w-100">
      {{ buttonActionLabel }}
    </button>
  </div>
  <div class="login-button text-center my-3" *ngIf="showResendButton">
    <button class="back-btn btn btn-default w-auto" (click)="nav(continue)">
      <i aria-hidden="true" class="fa fa-angle-left pe-2"></i>
      Back to log in
    </button>
  </div>
</div>
