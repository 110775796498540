import { Component, Input } from '@angular/core';
import {
  IApplicationFormData,
  IFieldArrayData,
  IFieldArrayDataFields,
  IFieldData,
  IFieldGroupNameData,
} from '../../models/application-form-data.model';

@Component({
  selector: 'irembogov-application-form-preview-card',
  templateUrl: './application-form-preview-card.component.html',
  styleUrls: ['./application-form-preview-card.component.scss'],
})
export class ApplicationFormPreviewCardComponent {
  @Input() data: IApplicationFormData | undefined;
  @Input() columnSize = 'col-md-4';
  @Input() applicationId: string | undefined;
  @Input() applicationBaseUrl?: string;
  @Input() applicationPath: string | undefined;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackByFn(index: number, item: IFieldGroupNameData) {
    return index;
  }

  columnSizeClass(numberOfBlocks?: number): string {
    return numberOfBlocks && numberOfBlocks > 1 ? this.columnSize : 'col mb-3';
  }

  isNotEmpty(obj: IFieldGroupNameData) {
    const hasFieldGroup =
      obj.fieldGroupName !== undefined &&
      this.fieldGroupHasValues(obj.fieldGroup);
    const hasFieldArray =
      obj.fieldArray !== undefined && this.fieldArrayHasItems(obj.fieldArray);
    return hasFieldGroup || hasFieldArray;
  }

  fieldGroupHasValues(fieldGroup?: IFieldData[]) {
    if (!fieldGroup) return false;
    return fieldGroup.some(ele => ele.fieldValue);
  }

  nonEmptyArrayItemFieldGroupItem(fieldGroup: IFieldData[]): boolean {
    if (!fieldGroup) return false;
    return fieldGroup.some(ele => {
      if (ele.fieldValue) {
        return true;
      }
      return false;
    });
  }

  nonEmptyArrayItemFormGroupItem(formGroup: IFieldGroupNameData[]): boolean {
    if (!formGroup) return false;
    return formGroup.some(ele => {
      if (ele.fieldGroup) {
        return this.nonEmptyArrayItemFieldGroupItem(ele.fieldGroup);
      }
      return false;
    });
  }

  fieldArrayHasItems(fieldArray?: IFieldArrayData[]) {
    if (!fieldArray) return false;
    return fieldArray.some(fieldArrayItem => {
      if (fieldArrayItem.arrayItems.length == 0) {
        return false;
      }
      return fieldArrayItem.arrayItems.some(
        arrayItem =>
          this.nonEmptyArrayItemFieldGroupItem(arrayItem.fieldGroup) ||
          this.nonEmptyArrayItemFormGroupItem(arrayItem.formGroup)
      );
    });
  }

  isOdd(count?: number): boolean {
    if (!count) return false;
    return count % 2 !== 0;
  }
}
