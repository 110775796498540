<div
  class="custom-date-picker"
  [ngClass]="{ 'available-date': remainingSlots > 0 }">
  <input
    (click)="d.toggle()"
    [formControl]="customformControl"
    (keyup)="change($event)"
    (blur)="_onTouch($event)"
    [maxDate]="maxDate"
    [minDate]="minDate"
    class="form-control"
    [placeholder]="placeholder"
    [footerTemplate]="footerTemplate"
    name="dp"
    ngbDatepicker
    #d="ngbDatepicker"
    (dateSelect)="dateSelect($event)"
    [markDisabled]="markDisabled"
    container="body" />
  <button
    class="btn btn-calendar btn-outline-default fa-regular fa-calendar"
    (click)="d.toggle()"
    type="button"
    [disabled]="disabled"></button>
</div>
<small *ngIf="remainingSlots > 0" class="available-date-text">
  <ng-template [ngIf]="remainingSlots === 1" [ngIfElse]="multipleRemainingSlots"
    >There is 1 slot available for the selected date</ng-template
  >
  <ng-template #multipleRemainingSlots
    >There are {{ remainingSlots }} slots available for the selected
    date</ng-template
  >
</small>
<ng-template #footerTemplate>
  <div class="calendar-footer-buttons">
    <button
      class="btn btn-secondary btn-sm m-2"
      (click)="cancelDate(); d.close()">
      Cancel
    </button>
  </div>
</ng-template>
