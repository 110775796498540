import { INavbarItem } from '@irembo-andela/irembogov3-common';

export const MainNavBarLinkItems: Record<string, INavbarItem> = {
  dashboard: {
    name: 'Home',
    icon: 'icon-home-smile',
    routerLink: ['/'],
  },
  users: {
    name: 'Track applications',
    icon: 'icon-file-search-02',
    routerLink: ['/track-application'],
  },
  lambdaUsers: {
    name: 'Verify certificates',
    icon: 'icon-file-check-03',
    routerLink: ['/verify-certificate'],
  },
};
