import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Subscription, fromEvent, map, debounceTime } from 'rxjs';
import { getValidCrvsIdDataFetchInputValue } from '../../../utils/utils/data-fetch-widget-utils';
import { BaseControlValueAccessor } from '../../models/BaseControlValueAccessor';

@Component({
  selector: 'irembogov-crvs-id-input',
  templateUrl: './irembogov-crvs-id-input-fetch-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IrembogovCrvsIdInputFetchWidgetComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IrembogovCrvsIdInputFetchWidgetComponent,
      multi: true,
    },
  ],
})
export class IrembogovCrvsIdInputFetchWidgetComponent
  extends BaseControlValueAccessor<string>
  implements Validator, OnChanges, AfterViewInit, OnDestroy
{
  @ViewChild('IdInput') IdInputEl: ElementRef | undefined;
  @Input() placeholder: unknown;
  @Input() id: unknown;
  @Input() regExp: string | undefined;
  @Output() dataFetch = new EventEmitter<string>();
  @Input() statusClass: string | undefined;
  @Input() documentTypeValue: string | undefined;

  isInvalidIdInput = false;
  IdInputSub: Subscription | undefined;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit(): void {
    this.IdInputSub = fromEvent(this.IdInputEl?.nativeElement, 'keyup')
      .pipe(
        map((event: unknown) => {
          let IdInput = '';
          if (event instanceof Event) {
            IdInput = (event.target as HTMLInputElement).value;
          }
          return IdInput;
        }),
        debounceTime(500)
      )
      .subscribe({
        next: (value: string) => {
          this.statusClass = '';
          this.isInvalidIdInput = false;
          this.onChange(value);
          this.onTouched(value);
          const validCrvsInput = getValidCrvsIdDataFetchInputValue(
            value,
            this.regExp
          );
          if (validCrvsInput) {
            this.dataFetch.emit(validCrvsInput);
          }
          this.cd.detectChanges();
        },
      });
  }

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onValidationChange = () => {};
  /* eslint-enable */

  validate(formControl: AbstractControl<any, any>): ValidationErrors | null {
    const value = formControl.value;
    const validationErrors: ValidationErrors = {};
    if (!value) {
      return null;
    }

    const validInput = getValidCrvsIdDataFetchInputValue(value, this.regExp);
    if (!validInput) {
      validationErrors['pattern'] = true;
    }

    if (this.isInvalidIdInput) {
      validationErrors['invalidId'] = true;
    }

    return Object.keys(validationErrors).length ? validationErrors : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['statusClass']) {
      this.isInvalidIdInput = changes['statusClass'].currentValue === 'danger';
      this._onValidationChange();
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }

  ngOnDestroy(): void {
    this.IdInputSub?.unsubscribe();
  }
}
