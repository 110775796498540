import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'irembogov-resend-auth-otp',
  templateUrl: './resend-auth-otp.component.html',
  styleUrls: ['./resend-auth-otp.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResendAuthOtpComponent {
  @Input() inputMode: 'email' | 'phone' = 'email';
  @Input() title = `Resend Activation`;
  @Input() descriptionForEmail = 'Kindly enter your email address.';
  @Input() descriptionForPhone = 'Kindly enter your phone number';
  @Input() ResendButtonLabel = `Resend activation`;
  @Input() showGoToActionButton = true;
  @Input() goToActionQuestion = `Already have a token?`;
  @Input() goToActionLabel = 'Activate account';
  @Input() showCancelButton = true;
  @Input() cancelLinkLabel = 'Back To Login';
  @Input() mainErrorMessage = '';
  @Input() emailInputValidationMessage = `Enter a valid Email`;
  @Input() phoneInputValidationMessage = `Enter a valid phone number`;
  @Input() isLoading = false;

  @Output() OnResendToEmailClicked: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() OnResendToPhoneClicked: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() OnGoToActionClicked: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() OnCancelClicked: EventEmitter<string> = new EventEmitter<string>();

  email: FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  phone: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(13),
  ]);

  onSubmit() {
    if (this.inputMode === 'email') {
      this.email.valid
        ? this.emitResentinput(this.OnResendToEmailClicked, this.email.value)
        : this.setErrorMessage(this.emailInputValidationMessage);
      return;
    }

    this.phone.valid
      ? this.emitResentinput(this.OnResendToPhoneClicked, this.phone.value)
      : this.setErrorMessage(this.phoneInputValidationMessage);
  }

  emitResentinput(emmitter: EventEmitter<string>, value: string) {
    this.isLoading = true;
    emmitter.emit(value);
  }

  goToActionClicked() {
    if (this.inputMode === 'email') return;
    this.OnGoToActionClicked.emit();
  }

  getToggleSwitchLabel() {
    return this.inputMode === 'email'
      ? 'Send to phone number instead'
      : 'Send to email instead.';
  }

  toggleInputMode() {
    this.mainErrorMessage = '';
    this.inputMode = this.inputMode === 'email' ? 'phone' : 'email';
  }

  setErrorMessage(message: string) {
    this.mainErrorMessage = message;
  }
}
