import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { apis } from '../app-apis';
import { Observable } from 'rxjs';
import {
  AddBusinesResponseDTO,
  Business,
  VerifyBusinesResponseDTO,
} from '../models/business.model';
import { IResponse } from '../models/response.model';
import { IHttpPagedResponse } from '../models/http/paginated-response.model';

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  private baseUrl = environment.apiGatewayBaseUrl + '/application';
  private businessAPIs = apis.business;
  constructor(private http: HttpClient) {}

  getBusinesses(): Observable<IHttpPagedResponse<Business>> {
    return this.http.get<IHttpPagedResponse<Business>>(
      `${this.baseUrl}/${this.businessAPIs.listBusinesses}`
    );
  }

  addBusinessByTin(
    tinNumber: string
  ): Observable<IResponse<AddBusinesResponseDTO>> {
    return this.http.post<IResponse<AddBusinesResponseDTO>>(
      `${this.baseUrl}/${this.businessAPIs.linkBusiness}`,
      { tinNumber: tinNumber }
    );
  }

  verifyBusinessToken(
    tinNumber: string,
    token: string
  ): Observable<IResponse<VerifyBusinesResponseDTO>> {
    return this.http.post<IResponse<VerifyBusinesResponseDTO>>(
      `${this.baseUrl}/${this.businessAPIs.verifyBusinessToken}`,
      { tinNumber: tinNumber, token: token }
    );
  }
}
