import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'irembogov-generic-container',
  templateUrl: './generic-container.component.html',
  styleUrls: ['./generic-container.component.scss'],
})
export class GenericContainerComponent {
  constructor(public translate: TranslateService) {}
}
