<div class="toggle-container d-none d-lg-flex d-md-flex">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ active: activeTab === 'personal' }"
        (click)="setTab('personal')"
        >{{ 'Personal' | translate }}</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ active: activeTab === 'business' }"
        (click)="setTab('business')"
        >{{ 'Business' | translate }}</a
      >
    </li>
  </ul>
</div>

<!-- Mobile -->
<div class="hstack w-100 d-lg-none d-md-none show-items">
  <button
    class="show-item btn btn-outline-none w-50"
    [ngClass]="{ active: activeTab === 'personal' }"
    (click)="setTab('personal')">
    {{ 'Personal' | translate }}
  </button>
  <button
    class="show-item btn btn-outline-none w-50"
    [ngClass]="{ active: activeTab === 'business' }"
    (click)="setTab('business')">
    {{ 'Business' | translate }}
  </button>
</div>
