<div class="preview-card">
  <div class="text-start display-list px-3 col-12">
    <p>{{ group?.fieldGroupName }}</p>

    <ng-container
      *ngFor="
        let field of group?.fieldGroup;
        let i = index;
        trackBy: trackByFn
      ">
      <div *ngIf="field" [style.order]="field?.order">
        <ng-container
          *ngTemplateOutlet="
            fieldItemDataValues;
            context: { fieldItemData: field, content: content, index: i }
          ">
        </ng-container>
      </div>
    </ng-container>

    <div
      [style.order]="fieldArray?.order"
      class="d-flex flex-column"
      *ngFor="
        let fieldArray of group?.fieldArray;
        let i = index;
        trackBy: trackByFn
      ">
      <ng-container *ngIf="fieldArray?.arrayItems">
        <ng-container *ngFor="let arrayItem of fieldArray?.arrayItems">
          <ng-container *ngFor="let fieldGroupItem of arrayItem.formGroup">
            <div [style.order]="fieldGroupItem?.order">
              <ng-container
                *ngTemplateOutlet="
                  nestedBlock;
                  context: {
                    fieldGroupNameData: fieldGroupItem
                  }
                ">
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngFor="let fieldGroupItem of arrayItem.fieldGroup">
            <div [style.order]="fieldGroupItem?.order">
              <ng-container
                *ngTemplateOutlet="
                  fieldItemDataValues;
                  context: { fieldItemData: fieldGroupItem }
                ">
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #content let-modal let-content>
  <div class="modal-body">
    <div class="modal-header-preview">
      <h1 class="fw-normal">
        <i class="fa fa-file-text"></i> {{ selectedAttachment?.fileName }}
      </h1>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div *ngIf="!this.filePath" class="d-flex justify-content-center mt-5">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <ng-container
      *ngIf="
        selectedAttachment?.fileType === 'application/pdf' && base64Data;
        else imagePreview
      ">
      <pdf-viewer
        *ngIf="base64Data"
        [src]="base64Data"
        [render-text]="true"
        [original-size]="true"
        style="width: 100%; height: 80vh; margin-top: 15px"></pdf-viewer>
    </ng-container>
  </div>
</ng-template>

<ng-template #imagePreview>
  <img
    *ngIf="this.filePath"
    [src]="this.filePath"
    alt="Image preview"
    class="w-100 h-100 mt-3" />
</ng-template>

<ng-template
  let-fieldItemData="fieldItemData"
  let-content="content"
  let-index="index"
  #fieldItemDataValues>
  <ng-container
    *ngIf="
      fieldItemData?.fieldValue &&
      fieldItemData?.fieldType !== 'customfileupload'
    ">
    <ng-container
      *ngIf="multiLineValueFieldTypes.includes(fieldItemData.fieldType)">
      <div *ngFor="let value of getMultiLineValues(fieldItemData)">
        <span class="field-name">{{ value?.label }}</span>
        <p class="field-value">
          {{ value.key }}
        </p>
      </div>
    </ng-container>
    <div *ngIf="!multiLineValueFieldTypes.includes(fieldItemData.fieldType)">
      <span class="field-name">{{ fieldItemData?.fieldLabel }}</span>
      <p class="field-value">
        {{ getSingleLineValue(fieldItemData) }}
      </p>
    </div>
  </ng-container>
  <div
    class="custom-uploads row py-3 px-2 mx-0 rounded-3 mb-2"
    *ngIf="fieldItemData?.fieldType === 'customfileupload'">
    <div
      class="col-1 float-start d-flex justify-content-center align-items-center text-center">
      <img src="../../assets/images/file-icon.svg" alt="" />
    </div>
    <div class="col-11 row d-flex flex-grow-1">
      <div class="d-flex justify-content-between pt-2">
        <div
          class="text-start text-dark fs-6 fw-medium text-capitalize text-break">
          {{ getAttachmentValue(fieldItemData?.fieldValue).fileName }}
        </div>
        <div
          class="text-end bg-primary rounded-circle p-1 d-flex justify-content-center align-items-center checker">
          <i class="fa-solid fa-check text-white checker-icon"></i>
        </div>
      </div>
      <div class="text-start text-dark fs-6 fw-normal m-0">
        {{ getAttachmentValue(fieldItemData?.fieldValue).fileSize | fileSize }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-fieldGroupNameData="fieldGroupNameData" #nestedBlock>
  <div class="my-3">
    <irembogov-application-form-field-card
      [group]="fieldGroupNameData"
      [applicationId]="applicationId"
      [baseUrl]="baseUrl"
      [applicationPath]="
        applicationPath
      "></irembogov-application-form-field-card>
  </div>
</ng-template>
