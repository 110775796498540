import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceTypeEnumToLabel } from './pipes/serice-type-to-label.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [ServiceTypeEnumToLabel],
  exports: [ServiceTypeEnumToLabel],
})
export class UtilsModule {}
