import { FormArray, FormGroup } from '@angular/forms';
import {
  FieldTypeConfig,
  FormlyFieldProps,
  FormlyFieldConfig,
} from '@ngx-formly/core';
import { distinctUntilChanged, filter } from 'rxjs';
import { IEnvironment } from '../../ui/models/environment.model';
import { flatten } from 'flat';
import { EIremboFormlyFormStateKeys } from '../../ui/models/irembo-formly-formstate-keys.enum';
import { FormlyValueChangeEvent } from '@ngx-formly/core/lib/models';

export function updateUrlWithApiGatewayBaseUrl(
  url: string,
  environment: IEnvironment
): string {
  const portalApiGatewayBaseUrl: string | undefined =
    environment.apiGatewayBaseUrl;
  if (!portalApiGatewayBaseUrl) {
    throw new Error(
      'useBaseUrl: portal environment ApiGatewayBaseUrl property is required'
    );
  } else {
    url = `${portalApiGatewayBaseUrl}${url}`;
  }

  return url;
}

export function populateFormFields(
  data: unknown,
  field: FieldTypeConfig<
    | (FormlyFieldProps & {
        [additionalProperties: string]: any;
      })
    | undefined
  >,
  form: FormGroup<any> | FormArray<any>
) {
  const fieldsToPopulates: {
    valueKey: string | string[];
    targetKey: string;
    separator: string;
  }[] = field.props?.['populates'] ?? [];
  let jsonData: Record<string, unknown> = {};

  try {
    if (data && typeof data === 'string') {
      jsonData = JSON.parse(data);
    }

    if (data && typeof data === 'object') {
      jsonData = data as Record<string, unknown>;
    }

    const flattenData = flatten(jsonData) as Record<string, unknown>;
    for (const fieldToPopulate of fieldsToPopulates) {
      const formControl = form.get(fieldToPopulate['targetKey']);
      let value = '';

      if (Array.isArray(fieldToPopulate['valueKey'])) {
        const separator = fieldToPopulate['separator'] ?? ' ';
        const dataValue = fieldToPopulate['valueKey']
          .map(key => flattenData[key])
          .filter(value => value !== undefined)
          .join(separator);
        value = getValueAsParsedData(dataValue);
      } else {
        value = getValueAsParsedData(flattenData[fieldToPopulate['valueKey']]);
      }

      if (formControl) {
        formControl.reset();
        formControl.patchValue(value ?? null);
      }
    }
  } catch (error) {
    throw new Error('An error occurred while parsing response data');
  }
}

export function resetFieldsToPopulate(
  fieldsToPopulate: Record<string, string>[],
  form: FormGroup<any> | FormArray<any>
) {
  for (const fieldToPopulate of fieldsToPopulate) {
    const formControl = form.get(fieldToPopulate['targetKey']);
    if (formControl) {
      formControl.setValue(null);
    }
  }
}

export function checkForValidFields(
  field: FieldTypeConfig<
    | (FormlyFieldProps & {
        [additionalProperties: string]: any;
      })
    | undefined
  >
) {
  if (field.props?.['populates']) {
    if (!Array.isArray(field.props?.['populates'])) {
      throw new Error('populates should be an array');
    }
  }
}

export function configureFields(
  field: FieldTypeConfig<
    | (FormlyFieldProps & {
        [additionalProperties: string]: any;
      })
    | undefined
  >,
  fieldsToPopulate: Record<string, string>[],
  form: FormGroup<any> | FormArray<any>
) {
  return field.formControl.valueChanges
    .pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    )
    .subscribe({
      next: () => {
        const resetFields = field.props['resetFields'] ?? true;
        if (field.formControl.invalid && resetFields) {
          resetFieldsToPopulate(fieldsToPopulate, form);
        }
      },
    });
}

const getValueAsParsedData = (value: unknown) => {
  try {
    return JSON.parse(<string>value);
  } catch (_err) {
    return value;
  }
};

export const getPopulateReferenceForm = (
  field: FormlyFieldConfig,
  form: FormGroup<any> | FormArray<any>
): FormGroup<any> | FormArray<any> => {
  if (field.props?.['populateRelativeToRootForm']) {
    return form.parent?.parent ?? form;
  }

  return form;
};

export const subscribeToResetFieldFetchData = (
  field: FormlyFieldConfig,
  formState: any
) => {
  field.options?.fieldChanges
    ?.pipe(
      filter((e: FormlyValueChangeEvent) => {
        return e.field.key === field.key;
      })
    )
    .subscribe((data: FormlyValueChangeEvent) => {
      if (!data.value || data.field?.formControl?.invalid) {
        delete formState?.[EIremboFormlyFormStateKeys.FETCHED_DATA]?.[
          <string>field.key
        ];
      }
    });
};

export const getValidDataFetchInputValue = (
  value: string | null,
  genericInputLength: number,
  inputLenghtAsMinLength = false
): string | null => {
  const formattedValue = value ? value.trim() : null;

  if (!formattedValue) {
    return null;
  }

  if (
    genericInputLength === formattedValue.length ||
    (inputLenghtAsMinLength && formattedValue.length > genericInputLength)
  ) {
    return formattedValue;
  }

  return null;
};

export const getValidCrvsIdDataFetchInputValue = (
  value: string | null,
  regExp?: string
): string | null => {
  if (value && regExp && value.match(new RegExp(regExp))) {
    return value;
  }

  return null;
};
