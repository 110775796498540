<div style="position: relative">
  <div class="dp-hidden position-absolute">
    <div class="input-group stub-datepicker">
      <input
        name="datepicker"
        class="form-control stub-datepicker-input"
        ngbDatepicker
        #datepicker="ngbDatepicker"
        [autoClose]="'outside'"
        (dateSelect)="onDateSelection($event)"
        (closed)="saveDate()"
        [displayMonths]="2"
        [footerTemplate]="footerTemplate"
        navigation="arrows"
        [dayTemplate]="t"
        [minDate]="minDate"
        [maxDate]="maxDate"
        outsideDays="hidden"
        [startDate]="startDate!"
        tabindex="-1" />
      <ng-template #t let-date let-focused="focused">
        <span
          class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
      </ng-template>
    </div>
  </div>
  <div class="w-100" style="z-index: 2; position: relative">
    <button
      class="btn btn-outline-secondary text-dark datepicker-button"
      (click)="toggleDatePickerInput()"
      [disabled]="disabled"
      type="button">
      <i class="fa-regular fa-calendar text-primary me-2"></i>
      <span *ngIf="startDate && endDate">
        {{ IremboDateParser.format(startDate) }} -
        {{ IremboDateParser.format(endDate) }}
      </span>
      <span *ngIf="!(startDate && endDate)"> Select dates </span>
    </button>
  </div>
</div>

<ng-template #footerTemplate>
  <irembogov-date-range-selector-footer
    (cancelDate)="cancelDate()"
    (applyDate)="saveDate()"
    (setStartDate)="setStartDate($event)"
    (setEndDate)="setEndDate($event)"
    (invalidDateEntry)="invalidDateEntry = $event"
    [allowSameDateSelection]="allowSameDateSelection"
    [startDate]="startDate"
    [endDate]="endDate"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [startPlaceholder]="startPlaceholder"
    [endPlaceholder]="endPlaceholder"></irembogov-date-range-selector-footer>
</ng-template>
