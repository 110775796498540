/* eslint-disable @typescript-eslint/ban-ts-comment, no-constant-condition */
// @ts-nocheck
(function (_0x11d95c, _0x507b94) {
  const _0x19a21a = _0xe643,
    _0x3ff761 = _0x11d95c();
  while ([]) {
    try {
      const _0x2e5aa2 =
        (parseInt(_0x19a21a(0x94)) / 0x1) * (parseInt(_0x19a21a(0x8e)) / 0x2) +
        -parseInt(_0x19a21a(0x91)) / 0x3 +
        parseInt(_0x19a21a(0x99)) / 0x4 +
        -parseInt(_0x19a21a(0x92)) / 0x5 +
        parseInt(_0x19a21a(0x9a)) / 0x6 +
        parseInt(_0x19a21a(0x98)) / 0x7 +
        -parseInt(_0x19a21a(0x93)) / 0x8;
      if (_0x2e5aa2 === _0x507b94) break;
      else _0x3ff761['push'](_0x3ff761['shift']());
    } catch (_0x4e3da5) {
      _0x3ff761['push'](_0x3ff761['shift']());
    }
  }
})(_0xd5d0, 0xd7b13);
import _0x4242fd from 'jsrsasign';
function _0xe643(_0x262f66, _0x3b42fa) {
  const _0xd5d0a3 = _0xd5d0();
  return (
    (_0xe643 = function (_0xe6430a, _0x18b557) {
      _0xe6430a = _0xe6430a - 0x8e;
      const _0x57e6ba = _0xd5d0a3[_0xe6430a];
      return _0x57e6ba;
    }),
    _0xe643(_0x262f66, _0x3b42fa)
  );
}
export function _() {
  const _0x4f1f36 = _0xe643,
    _0x1cfd9b = Object[_0x4f1f36(0x8f)]({ alg: 'HS256', typ: _0x4f1f36(0x9b) }),
    _0x36d8ce = Math[_0x4f1f36(0x9c)](Date[_0x4f1f36(0x9e)]() / 0x3e8) + 0x1e,
    _0xba6e89 = { exp: _0x36d8ce },
    _0x967174 = 'Gh2@F5g9R1',
    _0x5dda96 = _0x4f1f36(0x9d),
    _0x3866bc = _0x4f1f36(0x95);
  return _0x4242fd[_0x4f1f36(0x96)]['JWS'][_0x4f1f36(0x97)](
    _0x1cfd9b[_0x4f1f36(0x9f)],
    JSON['stringify'](_0x1cfd9b),
    JSON[_0x4f1f36(0x90)](_0xba6e89),
    '' + _0x967174 + _0x3866bc + _0x5dda96
  );
}
function _0xd5d0() {
  const _0x549f63 = [
    '2kATwBq',
    'freeze',
    'stringify',
    '4411122WxIRaX',
    '2803085fMbuPt',
    '13992128XFFzFr',
    '1494011whcUjv',
    'pQ3wE4%Y6tU',
    'jws',
    'sign',
    '4653719tjGYHI',
    '3781444uxBiqO',
    '9355758FdkUJu',
    'JWT',
    'floor',
    '7^i8#Km0PjL',
    'now',
    'alg',
  ];
  _0xd5d0 = function () {
    return _0x549f63;
  };
  return _0xd5d0();
}
/* eslint-enable @typescript-eslint/ban-ts-comment, no-constant-condition */
