<div class="nav-bar-placeholder d-none d-sm-block"></div>
<div class="register-container">
  <img
    src="assets/img/O.svg"
    alt="irembo logo"
    class="irembo-logo d-none d-sm-block" />
  <div class="message-container d-none d-sm-block">
    <h1 class="welcome-h1">{{ 'Welcome' | translate }}</h1>
    <p class="welcome-p">
      {{
        'We have been working to bring you a new IremboGov experience'
          | translate
      }}, <br />
      {{
        'easier to use, has more & more e-certificates and less attachments'
          | translate
      }}. <br /><br />
      {{
        'We have been working to bring you a new IremboGov experience'
          | translate
      }},<br />
      {{ 'easier to use, has more & mo' | translate }}
    </p>
  </div>
  <div class="authentication-container">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-5">
          <div
            *ngIf="userFeedback.length > 0"
            class="text-center alert alert-success">
            {{ userFeedback | translate }}
          </div>

          <div
            *ngIf="errorMessage.length > 0"
            class="text-center alert alert-danger">
            {{ errorMessage | translate }}
          </div>

          <div class="logo text-center px-5">
            <img class="img" src="assets/images/logo.svg" alt="" />
          </div>
        </div>

        <div class="text-center px-5">
          <img src="assets/img/email.png" class="irembo-responsive" alt="" />
        </div>

        <div class="email-check-accent">
          <h4 class="mt-3 tt_norms500">{{ 'Check your email' | translate }}</h4>
          <p class="email-check-body tt_norms400">
            {{ message | translate }} <b>{{ email }}</b>
          </p>

          <p class="email-check-secondary tt_norms400">
            {{ 'Didn’t receive the email' | translate }}?
            <span class="resendLink" (click)="resendLink()">{{
              'Click to resend' | translate
            }}</span>
          </p>
        </div>

        <div class="go-back mt-3">
          <button
            name="login"
            id="login"
            class="btn btn-block text-dark-grey col-12"
            type="button"
            [routerLink]="['/auth/register']">
            <i aria-hidden="true" class="fa fa-arrow-left pe-2"></i>
            {{ 'Back to register' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
