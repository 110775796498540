import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'irembogov-preview-certificate',
  templateUrl: './preview-certificate.component.html',
  styleUrls: ['./preview-certificate.component.scss'],
})
export class PreviewCertificateComponent {
  @Input() base64Data: string | undefined;
  constructor(public activeModal: NgbActiveModal) {}
}
