<div class="row">
  <div class="col">
    <div class="track">
      <h2><i class="fa fa-list-check"></i> {{ 'Track' | translate }}</h2>
      <ul class="horizontal-timeline">
        <li
          class="completed"
          *ngFor="let tracker of applicationDetails?.applicationStateTrackers">
          <span class="timestamp">{{
            tracker.stateTimestamp | date : 'dd/MM/yy | h:mm a'
          }}</span>
          <span
            class="irembo-badge"
            [ngClass]="getApplicationStatusToColor(tracker.applicationState)"
            >{{
              getApplicationStatusToLabel(tracker.applicationState) | translate
            }}</span
          >
        </li>
      </ul>
    </div>
  </div>
</div>
