import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'irembogov-applicant-type-toggle',
  templateUrl: './applicant-type-toggle.component.html',
  styleUrls: ['./applicant-type-toggle.component.scss'],
})
export class ApplicantTypeToggleComponent implements OnInit {
  view!: string;
  @Output() viewChange = new EventEmitter<string>();

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.view = params['view'] || 'individual';
      this.viewChange.emit(this.view);
    });
  }

  setView(view: string): void {
    this.router.navigate([], {
      queryParams: { view },
      queryParamsHandling: 'merge',
    });
    this.view = view;
    this.viewChange.emit(view);
  }
}
