export type TIdType = 'NID' | 'CHILD_ID' | 'NIN';

export const CustomIdInputTypesApiUrls = (): ICustomIdInputTypeApiUrls => {
  return {
    nid: {
      basic: '/identity/v1/nid-info',
      verify: '/identity/v1/nid-info/verify',
      privacy: '/identity/v1/nid-info/privacy',
      prefetchUrl: '/application/v1/user-profile',
      prefetchFieldKey: 'NID',
    },
    childId: {
      basic: '/identity/v1/child-id-info',
      verify: '/identity/v1/child-id-info/verify',
      privacy: '/identity/v1/child-id-info/privacy',
    },
    nin: {
      basic: '/identity/v1/nin-info',
    },
  };
};

export interface ICustomIdInputTypeApiUrls {
  nid: IIdInputTypeApiUrls;
  childId: IIdInputTypeApiUrls;
  nin: IIdInputTypeApiUrls;
}

export interface IIdInputTypeApiUrls {
  basic?: string;
  verify?: string;
  privacy?: string;
  prefetchUrl?: string;
  prefetchFieldKey?: string;
}

export enum CustomIdInputTypesIdLength {
  NID_ID_LENGTH = 16,
  CHILD_ID_LENGTH = 8,
  NIN_ID_LENGTH = 10,
}

export enum ECustomIdInputErrorKeys {
  INVALID_INPUT = 'invalidInput',
  INVALID_ID_INPUT = 'invalidId',
  VERIFICATON_FAILED = 'verificationFailed',
  VERIFICATON_INVALID = 'verificationInvalid',
  VERIFICATION_REQUIRED = 'verificationRequired',
  ENDPOINT_CODE_NOT_CONFIGURED = 'endpointCodeNotConfigured',
}
