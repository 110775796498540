import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../models/response.model';
import { apis } from '../app-apis';
import { Observable, map } from 'rxjs';
import { ICertificateDownload } from '../models/certificate-download.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  private baseUrl = environment.apiGatewayBaseUrl + '/document-generation';
  private documentAPIs = apis.documentGeneration;

  constructor(private http: HttpClient) {}

  downloadCertificate(
    certificateName: string
  ): Observable<ICertificateDownload> {
    return this.http
      .get<IResponse<ICertificateDownload>>(
        `${this.baseUrl}${this.documentAPIs.downloadCertificate}`,
        {
          headers: { certificateName: certificateName },
        }
      )
      .pipe(map(res => res.data));
  }
}
