<footer class="footer bg-white">
  <div class="footer-links">
    <div
      class="footer-top d-flex justify-content-between align-items-center d-none d-xxl-inline-flex d-xl-inline-flex d-lg-inline-flex d-md-inline-flex w-100">
      <img
        src="../../../assets/img/irembogov-logo-new.svg"
        alt="IremboGov logo"
        class="img-fluid" />
      <ul class="list-unstyled list-group list-group-horizontal">
        <li>
          <a
            [routerLink]="['/legal-information']"
            class="text-decoration-none"
            >{{ 'Terms of Use' | translate }}</a
          >
        </li>
        <li>
          <a
            [href]="env.knowledgeBaseUrl"
            target="_blank"
            class="text-decoration-none"
            >{{ 'Support Center' | translate }}</a
          >
        </li>
      </ul>
    </div>

    <!-- Mobile Footer top -->
    <div
      class="footer-top d-flex flex-column justify-content-between align-items-start d-block d-xxl-none d-xl-none d-lg-none d-md-none gap-2 py-3">
      <div class="ps-4 mb-4">
        <img
          src="../../../assets/img/irembogov-logo-new.svg"
          alt="IremboGov logo"
          class="img-fluid" />
      </div>
      <div
        class="text-start fw-semibold d-flex flex-row justify-content-between gap-2">
        <ul
          class="list-unstyled list-group list-group-horizontal-lg gap-2 ps-2">
          <li>
            <a href="#" class="text-decoration-none">{{
              'Terms of Use' | translate
            }}</a>
          </li>
          <li>
            <a href="#" class="text-decoration-none">{{
              'Privacy Policy' | translate
            }}</a>
          </li>
          <li>
            <a href="#" class="text-decoration-none">{{
              'Disclaimer' | translate
            }}</a>
          </li>
        </ul>
        <ul
          class="list-unstyled list-group list-group-horizontal-lg gap-2 ps-2">
          <li>
            <a [href]="env.knowledgeBaseUrl" class="text-decoration-none">{{
              'Support Center' | translate
            }}</a>
          </li>
          <li>
            <a href="#" class="text-decoration-none">{{
              'Company' | translate
            }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="footer-bottom w-100 d-flex justify-content-between align-items-center d-none d-xxl-inline-flex d-xl-inline-flex d-lg-inline-flex d-md-inline-flex w-100">
      <div class="ms-0">
        &copy; {{ currentYear }}
        {{ 'Irembo. All rights reserved' | translate }}
      </div>
      <div class="text-end social-icons">
        <a
          href="https://x.com/IremboGov"
          target="_blank"
          class="text-decoration-none"
          ><i class="fab fa-twitter"></i
        ></a>
        <a
          href="https://www.facebook.com/irembogov"
          target="_blank"
          class="text-decoration-none"
          ><i class="fab fa-facebook-f"></i
        ></a>
        <a
          href="https://www.linkedin.com/company/irembogov/mycompany/"
          target="_blank"
          class="text-decoration-none"
          ><i class="fab fa-linkedin-in"></i
        ></a>
        <a
          href="https://www.instagram.com/irembo_gov/"
          target="_blank"
          class="text-decoration-none me-0"
          ><i class="fab fa-instagram" width="24" height="24"></i
        ></a>
      </div>
    </div>

    <!-- Mobile footer bottom -->
    <div
      class="footer-bottom w-100 d-flex flex-column-reverse justify-content-between align-items-start d-block d-xxl-none d-xl-none d-lg-none d-md-none p-3 gap-3">
      <p class="mb-3">
        &copy; {{ currentYear }}
        {{ 'Irembo. All rights reserved' | translate }}
      </p>
      <div class="text-start social-icons m-0">
        <a href="#" class="text-decoration-none" style="margin-left: -8px"
          ><i class="fab fa-twitter"></i
        ></a>
        <a href="#" class="text-decoration-none"
          ><i class="fab fa-facebook-f"></i
        ></a>
        <a href="#" class="text-decoration-none"
          ><i class="fab fa-linkedin-in"></i
        ></a>
        <a href="#" class="text-decoration-none"
          ><i class="fab fa-instagram"></i
        ></a>
      </div>
    </div>
  </div>
</footer>
