<irembogov-select-paginated
  [loading]="loading"
  [items]="items"
  [customFormControl]="formControl"
  [bindLabel]="field.props['bindLabel']"
  ngDefaultControl
  [bindValue]="field.props['bindValue']"
  [placeholder]="field.props.placeholder ? field.props.placeholder : ''"
  [labelForId]="field.key"
  [multiple]="field.props['multiple']"
  [virtualScroll]="field.props['virtualScroll']"
  [pagination]="field.props['dataset']?.pagination ?? false"
  [searching]="field.props['dataset']?.searching ?? false"
  (loadMoreItems)="getMoreDataMethod($event)"
  (searchItem)="searchData($event)">
</irembogov-select-paginated>
