<div class="container">
  <div class="btn-group d-none d-lg-flex d-md-flex" role="group">
    <a
      [routerLink]="[]"
      [queryParams]="{ view: 'individual' }"
      [class.active]="view === 'individual'"
      class="btn btn-primary"
      (click)="setView('individual')">
      {{ 'For Individuals' | translate }}
    </a>
    <a
      [routerLink]="[]"
      [queryParams]="{ view: 'business' }"
      [class.active]="view === 'business'"
      class="btn btn-primary"
      (click)="setView('business')">
      {{ 'For Businesses' | translate }}
    </a>
  </div>

  <!-- Mobile -->
  <div class="hstack w-100 d-lg-none d-md-none">
    <button
      class="btn btn-toggle text-center w-50"
      [ngClass]="{ active: view === 'individual' }"
      (click)="setView('individual')">
      {{ 'For individuals' | translate }}
    </button>
    <button
      class="btn btn-toggle w-50"
      [ngClass]="{ active: view === 'business' }"
      (click)="setView('business')">
      {{ 'For businesses' | translate }}
    </button>
  </div>
</div>
