import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgOtpInputComponent } from 'ng-otp-input';
import { finalize } from 'rxjs';
import { BusinessService } from '../../services/business.service';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'irembogov-add-business',
  templateUrl: './add-business.component.html',
  styleUrls: ['./add-business.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddBusinessComponent implements OnInit {
  @Input() buttonStyling: 'profile' | 'modal' = 'profile';

  addBusinessForm!: FormGroup;
  businessToVerify?: any;
  isProcessingAddBusiness = false;
  token: string | undefined;

  invalidToken = false;
  invalidTin = false;
  tinAlreadyExist = false;

  routeKeyword: string | null = null;
  routeServiceId: string | null = null;

  @ViewChild('addBusinessModal')
  addBusinessModal!: TemplateRef<unknown>;

  @ViewChild('token')
  verifyTokenModalContent!: TemplateRef<unknown>;

  constructor(
    private businessService: BusinessService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) {
    config.animation = false;
    config.centered = true;
  }

  ngOnInit(): void {
    this.addBusinessForm = new FormGroup({
      tinNumber: new FormControl('', [Validators.required]),
    });
    this.routeKeyword =
      this.route.snapshot.queryParamMap.get('keyword') ?? null;
    this.routeServiceId = this.route.snapshot.queryParamMap.get('id') ?? null;
  }

  openModal(
    content: unknown,
    windowClass: string,
    container?: string,
    backdrop?: boolean | 'static',
    size?: string
  ) {
    let options: Record<string, unknown> = {
      ariaLabelledBy: 'verify-token',
      windowClass,
      container,
      centered: true,
      size,
    };
    if (backdrop) {
      options = { ...options, backdrop };
    }
    return this.modalService.open(content, options);
  }

  openVerifyModal() {
    this.openModal(this.verifyTokenModalContent, 'verify-token-modal');
  }

  initiateAddBusiness() {
    this.modalService.dismissAll();
    this.openModal(this.addBusinessModal, 'init-add-business-modal');
  }

  onAddBusiness(isRetry = false) {
    this.isProcessingAddBusiness = true;
    if (!this.addBusinessForm.valid) {
      this.addBusinessForm.markAllAsTouched();
      return;
    }

    const tinNumber = this.addBusinessForm.controls['tinNumber'].value;
    this.businessService
      .addBusinessByTin(tinNumber)
      .pipe(finalize(() => (this.isProcessingAddBusiness = false)))
      .subscribe({
        next: res => {
          this.businessToVerify = res.data;
          if (!isRetry) {
            this.modalService.dismissAll();
            this.openVerifyModal();
          } else {
            this.toastService.show({
              body: 'OTP sent successfully.',
              type: 'success',
            });
          }
        },
        error: (error: HttpErrorResponse) => {
          this.invalidTin =
            error.error.message === 'TIN_NUMBER_NOT_FOUND' ||
            error.error.message === 'TIN_DETAILS_FETCH_ERROR';
          this.tinAlreadyExist =
            error.error.message === 'BUSINESS_ALREADY_EXISTS';
        },
      });
  }

  resendAddBusinessOTP() {
    this.onAddBusiness(true);
  }

  cancelAddBusiness() {
    this.addBusinessForm.reset();
    this.invalidTin = false;
    this.tinAlreadyExist = false;

    this.modalService.dismissAll('Cancel');
  }

  cancelVerifyAddBusiness() {
    this.addBusinessForm.reset();
    this.invalidTin = false;
    this.tinAlreadyExist = false;
    this.invalidToken = false;

    this.modalService.dismissAll('Cancel');
  }

  onOtpChange(event: string, ngOtpInputRef: NgOtpInputComponent) {
    this.token = event;

    if (ngOtpInputRef) {
      const controls = ngOtpInputRef.otpForm.controls;
      for (const key in controls) {
        if (Object.prototype.hasOwnProperty.call(controls, key)) {
          const element = controls[key];
          if (!element.value) {
            element.reset();
          }
        }
      }
    }
  }

  confirmAddBusiness(ngOtpInputRef: NgOtpInputComponent) {
    if (!this.token) {
      return;
    }
    if (!this.businessToVerify) {
      return;
    }
    this.businessService
      .verifyBusinessToken(this.businessToVerify.tinNumber, this.token)
      .pipe(finalize(() => (this.isProcessingAddBusiness = false)))
      .subscribe({
        next: res => {
          const business = res.data;

          this.addBusinessForm.reset();
          this.modalService.dismissAll();

          const navigationExtras: NavigationExtras = {
            queryParams: {
              businessId: business.guid,
              businessName: business.name,
            },
          };

          if (this.routeKeyword && this.routeServiceId) {
            navigationExtras.state = {
              initiatedApplyService: {
                keyword: this.routeKeyword,
                id: this.routeServiceId,
              },
            };
          }
          this.router.navigate(['business', 'success'], navigationExtras);
        },
        error: () => {
          this.markControlsAsInValid(ngOtpInputRef);
        },
      });
  }

  private markControlsAsInValid(ngOtpInputRef: NgOtpInputComponent) {
    if (ngOtpInputRef) {
      this.invalidToken = true;
      const controls = ngOtpInputRef.otpForm.controls;
      for (const key in controls) {
        if (Object.prototype.hasOwnProperty.call(controls, key)) {
          const element = controls[key];
          element.setErrors({ invalidToken: true });
        }
      }
    }
  }
}
