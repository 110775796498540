import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ResendTokenOtpDto } from '../../../models/common.model';
import { AuthService } from '../service/auth.service';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { UserType } from '../../../models/citizenCreationRequest.model';

@Component({
  selector: 'irembogov-citizen-link',
  templateUrl: './citizen-link.component.html',
  styleUrls: ['./citizen-link.component.scss'],
})
export class CitizenLinkComponent {
  @Input()
  token = '';
  @Input()
  username = '';
  userFeedback = '';
  errorMessage = '';

  constructor(
    private authService: AuthService,
    private toastService: ToastService
  ) {}

  resendLink() {
    this.userFeedback = '';
    this.errorMessage = '';

    const resendTokenOtpDto: ResendTokenOtpDto = {
      token: this.token,
      clientId: environment.authClientId,
      userType: UserType.CITIZEN,
      username: this.username,
    };

    this.authService.resendTokenLink(resendTokenOtpDto).subscribe({
      next: () => {
        this.toastService.show({
          type: 'success',
          body: `Email sent, check your email`,
        });
      },
      error: (err: HttpErrorResponse) => {
        this.errorMessage = err.error.message || 'Fail to resend link';
        console.log(err);
      },
    });
  }
}
