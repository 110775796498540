import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ICategoryService } from 'src/app/models/service/category-services-response.model';

@Component({
  selector: 'irembogov-service-view',
  templateUrl: './service-view.component.html',
  styleUrls: ['./service-view.component.scss'],
})
export class ServiceViewComponent implements OnChanges {
  @Input() categoryServices: ICategoryService[] = [];
  @Output() serviceClicked: EventEmitter<ICategoryService> =
    new EventEmitter<ICategoryService>();

  services: ICategoryService[] | undefined = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['categoryServices']?.currentValue) {
      this.categoryServices = changes['categoryServices']?.currentValue;
      this.services = this.categoryServices;
    }
  }

  redirectToService(service: ICategoryService): void {
    this.serviceClicked.emit(service);
  }
}
