import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Subscription, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { getValidDataFetchInputValue } from '../../../utils/utils/data-fetch-widget-utils';
import { ECustomIdInputErrorKeys } from '../../config/custom-id-input-api-urls.config';

@Component({
  selector: 'irembogov-id-input',
  templateUrl: './irembo-id-input.component.html',
  styleUrls: ['./irembo-id-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboIdInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IremboIdInputComponent,
      multi: true,
    },
  ],
})
export class IremboIdInputComponent
  implements
    ControlValueAccessor,
    Validator,
    OnChanges,
    AfterViewInit,
    OnDestroy
{
  @ViewChild('citizenId') citizenIdEl: ElementRef | undefined;
  @Input() placeholder: unknown;
  @Input() id: unknown;
  @Input() idLength = 0;
  @Output() verifyId = new EventEmitter<string>();
  @Input() statusClass: string | undefined;
  idInputSub: Subscription | undefined;
  customFormControl = new FormControl();

  constructor(private cd: ChangeDetectorRef) {}

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onChange = (value: unknown) => {};
  private _onTouch = (value: unknown) => {};
  private _onValidationChange = () => {};
  /* eslint-enable */

  ngAfterViewInit(): void {
    this.idInputSub = fromEvent(this.citizenIdEl?.nativeElement, 'keyup')
      .pipe(
        map((event: unknown) => {
          let citizenId = '';
          if (event instanceof Event) {
            citizenId = (event.target as HTMLInputElement).value;
          }
          return citizenId;
        }),
        distinctUntilChanged()
      )
      .subscribe({
        next: (value: string) => {
          this.statusClass = '';
          this._onChange(value);
          this._onTouch(value);
          const validId = getValidDataFetchInputValue(value, this.idLength);
          if (validId) {
            this.verifyId.emit(validId);
          }
          this.cd.detectChanges();
        },
      });

    this.idInputSub.add(
      fromEvent(this.citizenIdEl?.nativeElement, 'blur').subscribe({
        next: () => {
          const validIdValue = getValidDataFetchInputValue(
            this.customFormControl.value,
            this.idLength
          );
          this._onTouch(validIdValue);
          this._onChange(validIdValue);
          this.cd.detectChanges();
        },
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['statusClass']) {
      const validId = getValidDataFetchInputValue(
        this.customFormControl.value,
        this.idLength
      );
      this._onTouch(validId);
      this._onChange(validId);
      this.cd.detectChanges();
    }
  }

  writeValue(obj: unknown): void {
    this.customFormControl.setValue(obj);
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.customFormControl.disable();
    } else {
      this.customFormControl.enable();
    }
  }

  validate(formControl: FormControl): ValidationErrors | null {
    const value = formControl.value?.replace(/ /g, '').split('-').join('');
    const validationErrors: ValidationErrors = {};

    if (
      formControl.errors?.[ECustomIdInputErrorKeys.ENDPOINT_CODE_NOT_CONFIGURED]
    ) {
      validationErrors[ECustomIdInputErrorKeys.ENDPOINT_CODE_NOT_CONFIGURED] =
        true;
      return validationErrors;
    }

    if (!value) {
      return null;
    }

    const validInput = getValidDataFetchInputValue(value, this.idLength);
    if (!validInput) {
      validationErrors[ECustomIdInputErrorKeys.INVALID_INPUT] = true;
    }

    if (formControl.errors?.[ECustomIdInputErrorKeys.VERIFICATION_REQUIRED]) {
      validationErrors[ECustomIdInputErrorKeys.VERIFICATION_REQUIRED] = true;
    }

    if (formControl.errors?.[ECustomIdInputErrorKeys.VERIFICATON_FAILED]) {
      validationErrors[ECustomIdInputErrorKeys.VERIFICATON_FAILED] = true;
    }

    if (formControl.errors?.[ECustomIdInputErrorKeys.VERIFICATON_INVALID]) {
      validationErrors[ECustomIdInputErrorKeys.VERIFICATON_INVALID] = true;
    }

    if (formControl.errors?.[ECustomIdInputErrorKeys.INVALID_ID_INPUT]) {
      validationErrors[ECustomIdInputErrorKeys.INVALID_ID_INPUT] = true;
    }
    return Object.keys(validationErrors).length ? validationErrors : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }

  ngOnDestroy(): void {
    this.idInputSub?.unsubscribe();
  }
}
