import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { TUiAlertTypes } from '../../../models/IUiAlertTypes';

@Component({
  selector: 'irembogov-custom-section-message-with-link',
  templateUrl: './custom-section-message-with-link.component.html',
  styleUrls: ['./custom-section-message-with-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomSectionMessageWithLinkComponent extends FieldType<FieldTypeConfig> {
  defaultAlertType: TUiAlertTypes = 'warning';

  getSectionMessageType(): TUiAlertTypes {
    return (this.field.props?.['messageType'] as TUiAlertTypes) || 'warning';
  }
}
