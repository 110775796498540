import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'irembogov-irembo-loading-circle-spinner',
  templateUrl: './irembo-loading-circle-spinner.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LoadingCircleSpinnerComponent {
  @Input() marginTop = 'initial';
}
