import { Component, Input } from '@angular/core';
import { IFeedbackResponseDetails } from '@irembo-andela/irembogov3-common';

@Component({
  selector: 'irembogov-application-feedback',
  templateUrl: './application-feedback.component.html',
  styleUrls: ['./application-feedback.component.scss'],
})
export class ApplicationFeedbackComponent {
  @Input() applicationFeedBack: IFeedbackResponseDetails[] = [];
}
