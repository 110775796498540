import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { environment } from '../../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { ResendTokenOtpDto } from '../../../models/common.model';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { UserType } from '../../../models/citizenCreationRequest.model';

@Component({
  selector: 'irembogov-link',
  templateUrl: './link.component.html',
})
export class LinkComponent implements OnInit {
  accountStatus = '';
  token = '';
  username = '';
  successTitle!: string;
  successQuestion!: string;
  recipient!: string;
  defaultActivatedErrorTitle = 'Account already Activated';
  defaultActivatedErrorMessage =
    'The activation link you are trying to access is from an account that is already activated. Click the button below to login';
  activatedErrorMessage = this.defaultActivatedErrorMessage;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get('token') ?? '';
    this.username = this.route.snapshot.queryParamMap.get('username') ?? '';

    this.verifyLink(this.token, this.username);
  }

  verifyLink(token: string, username: string) {
    this.authService.verifyLink(token, username).subscribe({
      next: () => {
        this.router.navigate(['auth/password'], {
          queryParams: { token: this.token, username: this.username },
        });
      },
      error: err => {
        if (
          err.error &&
          err.error.responseCode === 'ACCOUNT_ALREADY_ACTIVATED'
        ) {
          this.accountStatus = 'account-activated';
          this.activatedErrorMessage = err.error.message;
        } else {
          this.accountStatus = 'expired-account';
          console.log(err, token);
        }
      },
    });
  }

  resendLinkClicked() {
    const resendTokenOtpDto: ResendTokenOtpDto = {
      token: this.token,
      clientId: environment.authClientId,
      userType: UserType.CITIZEN,
      username: this.username,
    };

    this.authService.resendTokenLink(resendTokenOtpDto).subscribe({
      next: () => {
        this.toastService.show({
          type: 'success',
          body: `Email sent, check your email`,
        });
      },
      error: (err: HttpErrorResponse) => {
        const errorMsg = err?.error?.message ?? 'Fail to resend link';
        this.toastService.show({ type: 'error', body: errorMsg });
        console.log(err);
      },
    });
  }

  onGoToLogin() {
    this.router.navigate(['dashboard']);
  }

  goToOtp() {
    this.router.navigate(['auth/otp']);
  }

  getDecodedToken(oneTimePasswordToken: string): string {
    let decoded = '';
    try {
      decoded = atob(oneTimePasswordToken ?? '');
    } catch (error) {
      this.accountStatus = 'expired-account';
      console.log(error);
    }

    return decoded;
  }
}
