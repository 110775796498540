import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'irembogov-application-form-section',
  templateUrl: './application-form-section.component.html',
  styleUrls: ['./application-form-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationFormSectionComponent extends FieldType {
  @Input() showCount = false;
}
