<div
  class="d-flex flex-column justify-content-lg-center justify-content-md-center justify-content-sm-start align-items-lg-center align-items-md-center align-items-sm-start">
  <div
    class="form w-100 d-flex flex-row justify-content-between flex-nowrap gap-2 mb-3 pe-sm-3">
    <ng-container
      *ngFor="let control of form.controls; let i = index; last as isLast">
      <input
        type="text"
        maxlength="1"
        (keydown)="onChange($event, i)"
        (input)="onChange($event, i)"
        #code
        (paste)="paste($event)"
        (focus)="boxFocus($event)"
        [formControl]="$any(control)"
        class="bg-white otp-input" />
    </ng-container>
  </div>
  <div
    *ngIf="errorMessage"
    class="d-flex justify-content-start align-items-start align-content-center text-start error-text mb-3 w-100">
    {{ errorMessage | translate }}
  </div>

  <p *ngIf="showCountdown" class="mb-3">
    {{ "Didn't receive the code? Resend in" | translate }}
    <span class="text-dark-blue fw-bold">
      <countdown
        id="cd"
        #cd
        [config]="config"
        (event)="notify($event)"></countdown>
    </span>
  </p>
  <p *ngIf="!showCountdown" class="mb-3">
    {{ "Didn't receive the code? " | translate }}
    <span role="button" class="text-dark-blue fw-bold" (click)="resend()">{{
      resendText | translate
    }}</span>
  </p>
  <button
    class="btn btn-primary tex-white w-100 fw-semibold"
    (click)="onSubmit()"
    [disabled]="form.invalid || isLoading">
    <div
      *ngIf="isLoading"
      class="spinner-border spinner-border-sm mr-2"
      role="status"></div>
    {{ buttonText | translate }}
  </button>

  <a
    class="text-lg-center text-md-center text-sm-start mt-3 fw-bold"
    (click)="onRedirection()"
    role="button"
    ><i class="fa-solid fa-angle-left me-2"></i
    >{{ navigationText | translate }}</a
  >
</div>
