<div class="row">
  <div
    class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4 position-relative"
    *ngFor="let service of featuredServices">
    <div class="flex-fill h-100">
      <div class="card text-start">
        <div class="card-body" (click)="cardClick.emit(service)">
          <div class="row">
            <div class="col-10 mb-1">
              <h5 class="card-title text-irembo-blue p-0 m-0">
                {{ service?.service?.serviceName }}
              </h5>
            </div>
            <div
              class="col-2 px-0 d-flex align-items-center justify-content-end">
              <i aria-hidden="true" class="fa fa-arrow-right"></i>
            </div>
            <small class="col-12 text-uppercase mb-3 org-name text-dark">
              {{ service?.service?.organization?.acronym }}
            </small>
          </div>

          <small class="card-text text-dark">
            {{ service?.reasonForBeingFeatured }}
          </small>
        </div>
      </div>
    </div>
  </div>
</div>
