<div class="mb-3 irembogov-custom-repeater">
  <legend *ngIf="props.label">{{ props.label }}</legend>
  <p *ngIf="props.description">{{ props.description }}</p>

  <div
    *ngFor="let field of field.fieldGroup; let i = index"
    class="d-flex align-items-baseline custom-repeater-extra-fields">
    <formly-field
      class="repeater-group repeater-extra-fields"
      [field]="field"></formly-field>
    <button
      class="btn btn-danger ms-2 repeater-add-button"
      type="button"
      (click)="remove(i)">
      <i class="fa-solid fa-trash-can"></i>
    </button>
  </div>
  <div class="my-1">
    <button class="btn btn-primary btn-sm" type="button" (click)="add()">
      <i class="fa-solid fa-plus"></i> {{ props['addText'] ?? 'Add' }}
    </button>
  </div>
</div>
