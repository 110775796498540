import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  forwardRef,
  EventEmitter,
  Output,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import intlTelInput, { Iti, SomeOptions } from 'intl-tel-input';
import { Subscription, fromEvent, finalize } from 'rxjs';
import { phoneNUmberRegexValidation } from '../../../../lib/utils/utils/phone-number-regex-validation.util';

@Component({
  selector: 'irembogov-phone-number',
  templateUrl: './irembo-phone-number.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboPhoneNumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IremboPhoneNumberComponent),
      multi: true,
    },
  ],
})
export class IremboPhoneNumberComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy, Validator
{
  @ViewChild('phone') input_tel: ElementRef | undefined;
  @Input() id = '';
  @Input() placeholder = '';
  @Input() preferredCountries: string[] = ['rw'];
  @Input() initialCountry = 'rw';
  @Output() inputChange = new EventEmitter<string>();
  @Output() isValidPhoneNo = new EventEmitter<boolean>();
  @Output() intelInputInitialized = new EventEmitter<'success' | 'failed'>();
  iti: Iti | undefined;
  countryChangeSub: Subscription | undefined;
  onBlurSubscription: Subscription | undefined;
  customFormControl = new FormControl();
  @Input() countryNoValidation = true;
  @Input() onlyCountries: string[] | undefined;
  @Input() allowDropdown: boolean | undefined;
  isValidPhoneNumber = false;
  isValidNumberFormat = false;
  initialFieldValue?: string = undefined;

  constructor(private cdRef: ChangeDetectorRef) {}

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onChange = (value: unknown) => {};
  private _onTouch = (value: unknown) => {};
  private _onValidationChange = () => {};
  /* eslint-enable */

  writeValue(value: string): void {
    if (!this.input_tel) {
      this.initialFieldValue = value;
    }
    if (value) {
      this.setValueOnInputField(value);
    }
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  change(event: unknown): void {
    if (this.iti) {
      if (event instanceof Event) {
        const rawValue = (event.target as HTMLInputElement).value;
        this.checkIsValidNumber(rawValue);
        this._onValidationChange();
        this._onChange(this.iti.getNumber());
        this._onTouch(this.iti.getNumber());
        this.inputChange.emit(this.iti.getNumber());
        this.isValidPhoneNo.emit(
          this.isValidPhoneNumber && this.isValidNumberFormat
        );
      }
    }
  }

  private checkIsValidNumber(value?: string): void {
    this.isValidPhoneNumber = this.iti?.isValidNumber() ?? false;
    if (value) {
      this.isValidNumberFormat = phoneNUmberRegexValidation(
        value.replace(/[ \s-()]+/g, '')
      );
    }
  }

  ngAfterViewInit(): void {
    this.iti = this.initializeIntTelInputPlugin();

    if (this.initialFieldValue) {
      this.setValueOnInputField(this.initialFieldValue);
    }
    this.initialFieldValue = undefined;

    this.onBlurSubscription = fromEvent(this.input_tel?.nativeElement, 'blur')
      .pipe(finalize(() => this.cdRef.detectChanges()))
      .subscribe({
        next: () => {
          this._onChange(this.iti?.getNumber());
          this._onTouch(this.iti?.getNumber());
        },
        error: () => {
          this._onChange(null);
          this._onTouch(null);
        },
      });

    this.countryChangeSub = fromEvent(
      this.input_tel?.nativeElement,
      'countrychange'
    ).subscribe({
      next: () => {
        this.customFormControl.setValue(null);
        this._onChange(null);
        this._onTouch(null);
        this.inputChange.emit('');
        this.isValidPhoneNo.emit(false);
      },
    });
  }

  private initializeIntTelInputPlugin(): Iti {
    let defaultOptions: SomeOptions = {
      validationNumberType: null,
      separateDialCode: true,
      nationalMode: true,
      initialCountry: this.initialCountry ?? 'rw',
      formatOnDisplay: false,
      utilsScript: '/assets/intl-tel-input/utils.js',
    };

    if (this.preferredCountries) {
      defaultOptions = {
        ...defaultOptions,
        countryOrder: this.preferredCountries,
      };
    }

    if (this.onlyCountries && Array.isArray(this.onlyCountries)) {
      defaultOptions = {
        ...defaultOptions,
        onlyCountries: this.onlyCountries,
      };
    }

    if (this.allowDropdown !== undefined && this.allowDropdown !== null) {
      defaultOptions = {
        ...defaultOptions,
        allowDropdown: this.allowDropdown,
      };
    }

    return intlTelInput(this.input_tel?.nativeElement, defaultOptions);
  }

  private setValueOnInputField(value?: string): void {
    if (!this.input_tel) {
      return;
    }

    if (!this.iti) {
      this.iti = this.initializeIntTelInputPlugin();
    }

    this.iti.promise
      .then(() => {
        if (value) {
          const intlTelInputInstance: Iti | null = intlTelInput.getInstance(
            this.input_tel?.nativeElement
          );
          intlTelInputInstance?.setNumber(value);
          this.iti?.setNumber(value);
          this.checkIsValidNumber(value);
          this._onTouch(this.iti?.getNumber(0));
          this._onChange(this.iti?.getNumber(0));
          this.inputChange.emit(this.iti?.getNumber());
          this.isValidPhoneNo.emit(
            this.isValidNumberFormat && this.isValidPhoneNumber
          );
        }
        this.iti?.isValidNumber();
        this._onValidationChange();
        this.cdRef.markForCheck();
        this.intelInputInitialized.emit('success');
      })
      .catch(() => {
        this.customFormControl.setValue(value);
        this.intelInputInitialized.emit('failed');
      });
  }

  validate(formControl: FormControl): ValidationErrors | null {
    const validationErrors: ValidationErrors = {};
    if (formControl.value && !this.isValidNumberFormat) {
      validationErrors['invalidNumberFormat'] = true;
    }

    if (
      this.countryNoValidation &&
      formControl.value &&
      !this.isValidPhoneNumber
    ) {
      validationErrors['invalidNumber'] = true;
    }

    return Object.keys(validationErrors).length ? validationErrors : null;
  }

  ngOnDestroy(): void {
    if (this.iti) {
      this.iti.destroy();
    }
    this.countryChangeSub?.unsubscribe();
    this.onBlurSubscription?.unsubscribe();
  }
}
