<div class="row section-content" style="line-height: 28px">
  <div class="col">
    <p class="text-color" style="font-size: 18px">
      {{
        'Thank you for visiting Irembo e-gov portal (the "Portal"). As\n' +
          '                  is true for many other Web sites, this Portal has rules that\n' +
          '                  apply to your use and any services available through this\n' +
          '                  Portal. Those rules, referred to as "terms of use", are set\n' +
          '                  forth in this Terms of Use. By using this Portal, you are\n' +
          '                  agreeing to comply with and be bound by the following terms of\n' +
          '                  use. Please review them carefully. If you do not agree with\n' +
          '                  any of these terms of use, please do not use this Portal.'
          | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">
      {{ 'Who owns this portal and how it operates' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'This Portal functions as a real time electronic processing\n' +
          '                  system for services application process between Government\n' +
          '                  Authorities and End-Users and such transactional interface is\n' +
          '                  designed and maintained by the Irembo Ltd., a private company\n' +
          '                  under a concession agreement of 25 year with the Government of\n' +
          '                  Rwanda.' | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'It is under the sole responsibility of the Government and each\n' +
          '                  participating Government Authority to develop and maintain the\n' +
          '                  information web pages, backends and supply Irembo with any\n' +
          '                  initial and updated information required during the provision\n' +
          '                  of the services to the End-Users.' | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">{{ 'Trademarks' | translate }}</h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'Irembo and its logo are trademarked by Irembo Ltd. and shall\n' +
          '                  be transferred to the Government at the end of the Agreement.'
          | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">{{ 'Registration' | translate }}</h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'You may be required to register on Irembo Portal account to\n' +
          '                  access some of the online services. However, it is not a\n' +
          '                  requirement when using our USSD *909#.' | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">
      {{ 'User responsibility' | translate }}
    </h4>
    <p
      class="text-color"
      style="font-size: 18px"
      [innerHTML]="
        'You must use <a href=&quot;https://www.irembo.gov.rw&quot;>www.irembo.gov.rw</a> and your account only for lawful purposes and in a manner that does not infringe the rights of or restrict or inhibit the use and enjoyment of the website by any third party.'
          | translate
      "></p>
    <h4 style="padding: 32px 0 16px">
      {{ 'Right to make changes' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'Irembo may revise this Privacy Policy from time to time and\n' +
          '                  when such a change is made; we will post a revised version on\n' +
          '                  this Website. Please note that changes are effective when they\n' +
          '                  are posted and it is your responsibility to read the Privacy\n' +
          '                  Policy from time to time in order that you are aware of any\n' +
          '                  such change. In our sole discretion, and if you are a\n' +
          '                  registered user, we may notify you via email associated with\n' +
          '                  your account or by SMS.' | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'By continuing to access or use the platform after those\n' +
          '                  changes become effective, you agree to be bound by the revised\n' +
          '                  Privacy Policy.' | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">
      {{ 'Compliance to the regulations' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px; padding-bottom: 8rem">
      {{
        'Irembo regularly reviews compliance with its Privacy Policy.\n' +
          '                  It also adheres to several self-regulatory frameworks. It\n' +
          '                  works with the appropriate regulatory authorities to resolve\n' +
          '                  any complaints on personal data that it cannot resolve with\n' +
          '                  our users directly.' | translate
      }}
    </p>
  </div>
</div>
