import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { BaseControlValueAccessor } from './../../models/BaseControlValueAccessor';
import {
  Subscription,
  distinctUntilChanged,
  fromEvent,
  map,
  debounceTime,
} from 'rxjs';
import { getValidDataFetchInputValue } from '../../../utils/utils/data-fetch-widget-utils';

@Component({
  selector: 'irembogov-generic-data-fetch',
  templateUrl: './irembogov-generic-data-fetch.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IrembogovGenericDataFetchComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IrembogovGenericDataFetchComponent,
      multi: true,
    },
  ],
})
export class IrembogovGenericDataFetchComponent
  extends BaseControlValueAccessor<string>
  implements Validator, OnChanges, AfterViewInit, OnDestroy
{
  @ViewChild('genericInput') genericInputEl: ElementRef | undefined;
  @Input() placeholder: unknown;
  @Input() id: unknown;
  @Input() genericInputLength = 0;
  @Output() dataFetch = new EventEmitter<string>();
  @Input() statusClass: string | undefined;
  @Input() inputLenghtAsMinLength = false;

  isInvalidGenericInput = false;
  genericInputSub: Subscription | undefined;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onValidationChange = () => {};
  /* eslint-enable */

  ngAfterViewInit(): void {
    this.genericInputSub = fromEvent(
      this.genericInputEl?.nativeElement,
      'keyup'
    )
      .pipe(
        map((event: unknown) => {
          let genericInput = '';
          if (event instanceof Event) {
            genericInput = (event.target as HTMLInputElement).value;
          }
          return genericInput;
        }),
        distinctUntilChanged(),
        debounceTime(300)
      )
      .subscribe({
        next: (value: string) => {
          this.statusClass = '';
          this.onChange(value);
          this.onTouched(value);
          const validValue = getValidDataFetchInputValue(
            value,
            this.genericInputLength,
            this.inputLenghtAsMinLength
          );
          if (validValue) {
            this.dataFetch.emit(validValue);
          }
          this.cd.detectChanges();
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['statusClass']) {
      this.isInvalidGenericInput =
        changes['statusClass'].currentValue === 'danger';
      this._onValidationChange();
    }
  }

  validate(formControl: FormControl): ValidationErrors | null {
    const value = formControl.value;
    const validationErrors: ValidationErrors = {};
    if (!value) {
      return null;
    }
    const validInput = getValidDataFetchInputValue(
      value,
      this.genericInputLength,
      this.inputLenghtAsMinLength
    );
    if (!validInput) {
      validationErrors['invalidInput'] = true;
    }
    if (this.isInvalidGenericInput) {
      validationErrors['invalidGenericInput'] = true;
    }

    return Object.keys(validationErrors).length ? validationErrors : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }

  ngOnDestroy(): void {
    this.genericInputSub?.unsubscribe();
  }
}
