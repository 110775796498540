import { Component } from '@angular/core';

@Component({
  selector: 'irembogov-legal-pages',
  templateUrl: './irembogov-legal-pages.component.html',
  styleUrls: ['./irembogov-legal-pages.component.scss'],
})
export class IrembogovLegalPagesComponent {
  selectedTab = 'privacy';
}
