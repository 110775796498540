import { Component, Input } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'irembogov-stepper',
  templateUrl: './irembogov-stepper.component.html',
  styleUrls: ['./irembogov-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: IrembogovStepperComponent }],
})
export class IrembogovStepperComponent extends CdkStepper {
  @Input() enableButtonNavigation = true;
}
