import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'irembogov-pagination',
  templateUrl: './irembo-pagination.component.html',
  styleUrls: ['./irembo-pagination.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IremboPaginationComponent implements OnInit, OnChanges {
  @Input() collectionSize!: number;
  @Input() pageSizes: number[] = [10, 20, 30];
  @Input() pageSize: number = this.pageSizes[0] || 10;
  @Input() maxSize = 7;
  @Input() showDropdown = true;
  @Input() showDirectionText = true;
  @Input() paginationDisplaySize: 'sm' | 'lg' | string | null = null;
  @Input() minimalPagination = false;
  @Output() paginatedValues = new EventEmitter();
  @Input() page = 1;
  pagination = { pageNumber: 1, pageSize: this.pageSizes[0] || 10 };

  ngOnInit(): void {
    this.pagination.pageSize = this.pageSizes[0] || 10;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['pageSize'] && !!changes['pageSize']?.currentValue) {
      this.pagination = {
        ...this.pagination,
        pageSize: changes['pageSize']?.currentValue,
      };
    }
  }

  getPageSymbol(current: number) {
    let pages: number[] = [];
    pages = Array.from({ length: this.collectionSize }, (_, i) => i + 1);
    return pages[current - 1];
  }

  pageChange(pageNumber: number): void {
    this.pagination.pageNumber = pageNumber;
    this.paginate();
  }

  pageSizeChange(pageSize: number): void {
    this.pagination.pageSize = pageSize;
    this.paginate();
  }

  paginate(): void {
    this.paginatedValues.emit(this.pagination);
  }

  getTotalPages(): number {
    return Math.ceil(this.collectionSize / this.pageSize);
  }
}
