<div
  class="forgot-pass container forgot-pass d-flex flex-column justify-content-start min-vh-100">
  <div class="text-center mb-3 px-5 auth-components-margin-top">
    <img class="img logo" src="assets/images/logo.svg" alt="irembo logo" />
  </div>
  <div
    class="d-flex justify-content-center bg-body align-self-center flex-column w-100 reset-form-container">
    <div class="text-center mb-3 check text-center">
      <img class="icon-img" src="assets/images/irembo_img-key.svg" alt="" />
      <div class="mt-3">
        <h2 class="text-center">{{ title }}</h2>
        <p class="text-center mt-3 desc">{{ description }}</p>
      </div>
    </div>
    <form
      [formGroup]="resetPasswordForm"
      (submit)="onFormSubmit()"
      class="reset-form d-flex justify-content-center w-100 bg-body align-self-center flex-column">
      <div class="my-1" *ngIf="errorMessage">
        <div class="alert alert-{{ errorTheme }} fade show" role="alert">
          <h6 class="alert-heading" *ngIf="showErrorTitle && errorTitle">
            <span>
              <i
                class="fa-solid fa-{{
                  errorTheme === 'danger' ? 'circle' : 'triangle'
                }}-exclamation me-2"></i>
              {{ errorTitle | translate }}
            </span>

            <button
              *ngIf="showErrorTitle"
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </h6>

          <p>{{ errorMessage | translate }}</p>
        </div>
      </div>
      <div class="form-group">
        <label
          for="login-pass"
          class="form-label"
          style="font-weight: 500; line-height: 20px; font-size: 14px"
          >{{ 'Email' | translate }}</label
        >
        <div class="mb-3">
          <input
            formControlName="email"
            #email
            type="email"
            class="form-control"
            id="email"
            placeholder="Enter your email"
            autocomplete="off" />
          <small
            *ngIf="
              !this.resetPasswordForm.controls['email'].dirty ||
              !this.resetPasswordForm.controls['email'].invalid
            "
            class="small text-muted w-100">
          </small>
          <small
            *ngIf="
              (this.resetPasswordForm.controls['email'].dirty ||
                this.resetPasswordForm.controls['email'].touched) &&
              this.resetPasswordForm.controls['email'].invalid &&
              this.resetPasswordForm.controls['email'].value
            "
            class="small text-danger w-100"
            role="alert">
            {{ 'Invalid email address' | translate }}
          </small>
        </div>
      </div>
      <button
        class="btn btn-primary mt-1 mx-auto w-100 font-weight-normal"
        type="submit"
        [disabled]="isLoading || resetPasswordForm.invalid">
        <div
          *ngIf="isLoading"
          class="spinner-border spinner-border-sm mr-2"
          role="status"></div>
        {{ buttonActionLabel }}
      </button>
      <div class="mt-3 text-center" *ngIf="resendEmailUrl">
        <p class="">
          {{ "Didn't receive email" | translate }}?
          <a (click)="resend.emit()" class="no-dec">
            {{ 'Click to resend' | translate }}
          </a>
        </p>
      </div>
    </form>
    <div *ngIf="showBackToLogin" class="login-button text-center my-3">
      <button class="back-btn btn btn-default w-auto" (click)="goBack.emit()">
        <i aria-hidden="true" class="fa fa-arrow-left pe-2"></i>
        {{ 'Back to log in' | translate }}
      </button>
    </div>
  </div>
</div>
