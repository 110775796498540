<div class="modal-header">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 text-center mb-3 px-5">
            <img class="img" src="assets/images/logo.png" alt="" />
          </div>
          <div class="col-12 text-center mb-3 check">
            <img
              class="icon-img"
              src="assets/images/irembo_img-key.svg"
              alt="" />
          </div>

          <div class="col-12">
            <h2 class="text-center">{{ 'Change Password' | translate }}</h2>
            <p class="text-center mt-4 desc">
              {{
                'Your new password must be 8 characters and, contain a number, an uppercase letter, a lowercase letter and a special character'
                  | translate
              }}
            </p>
          </div>

          <div class="col-12 justify-content-center">
            <div class="mt-2 forgot-pass-form">
              <form [formGroup]="changePassForm" (submit)="onFormSubmit()">
                <div class="mx-1 py-3 text-center">
                  <small
                    class="text-danger"
                    *ngIf="errorMessage && !this.changePassForm.errors?.['mismatch']"
                    >{{ errorMessage | translate }}</small
                  >
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label for="password" class="align-left">{{
                      'Password' | translate
                    }}</label>
                    <div class="input-container">
                      <input
                        name="newPassword"
                        [(ngModel)]="password"
                        type="{{ showPassword ? 'text' : 'password' }}"
                        class="align-left form-control password-input"
                        id="newPassword"
                        placeholder="{{ 'Password' | translate }}"
                        formControlName="newPassword"
                        (input)="calculatePasswordStrength($event)" />
                      <a (click)="togglePassword()" class="eye-b">
                        <i
                          class="{{
                            showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'
                          }}"></i>
                      </a>
                    </div>
                  </div>

                  <small
                    *ngIf="
                      this.changePassForm.controls['newPassword'].dirty &&
                      this.changePassForm.controls['newPassword'].invalid
                    "
                    class="wrap small text-danger w-100"
                    role="alert">
                    {{ 'Invalid password' | translate }}
                  </small>
                  <div class="strength">
                    <ul class="strengthBar">
                      <li
                        class="point"
                        [style.background-color]="barColors[0]"></li>
                      <li
                        class="point"
                        [style.background-color]="barColors[1]"></li>
                      <li
                        class="point"
                        [style.background-color]="barColors[2]"></li>
                      <li
                        class="point"
                        [style.background-color]="barColors[3]"></li>
                    </ul>
                  </div>

                  <div class="mb-3">
                    <label for="cpassword" class="align-left">{{
                      'Confirm Password' | translate
                    }}</label>
                    <div class="input-container">
                      <input
                        input
                        name="confirmPassword"
                        [(ngModel)]="cpassword"
                        type="{{ cShowPassword ? 'text' : 'password' }}"
                        class="align-left form-control password-input"
                        id="confirmPassword"
                        placeholder="{{ 'Password' | translate }}"
                        formControlName="confirmPassword" />
                      <a (click)="cTogglePassword()" class="eye-b">
                        <i
                          class="{{
                            cShowPassword ? 'fas fa-eye-slash' : 'fas fa-eye'
                          }}"></i>
                      </a>
                    </div>

                    <small
                      class="small text-danger w-100"
                      *ngIf="
                    this.changePassForm.controls['confirmPassword'].dirty && this.changePassForm.errors?.['mismatch']"
                      role="alert">
                      {{ 'Passwords do not match' | translate }}
                    </small>
                  </div>
                </div>

                <button
                  class="col-12 btn btn-primary mt-1"
                  type="submit"
                  [disabled]="changePassForm.invalid">
                  <div
                    *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-2"
                    role="status"></div>
                  <b>{{ 'Set Password' | translate }}</b>
                </button>
                <div class="align-center text-center mt-3">
                  <button
                    class="align-center back-btn btn btn-default w-auto"
                    (click)="activeModal.dismiss('Cross click')">
                    <i aria-hidden="true" class="fa fa-arrow-left pe-2"></i>
                    {{ 'Cancel' | translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
