import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Subscription, distinctUntilChanged, filter } from 'rxjs';

@Component({
  selector: 'irembogov-readonly-wrapper',
  templateUrl: './formly-readonly-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadonlyWrapperComponent
  extends FieldWrapper
  implements AfterViewInit, OnDestroy
{
  @ViewChild('parentContainer') parentContainer!: ElementRef;

  private fieldChangesSub: Subscription | undefined;

  ngAfterViewInit(): void {
    // iterate through all inputs with `readonly` attribute changes
    this.fieldChangesSub = this.field.options?.fieldChanges
      ?.pipe(
        filter(e => {
          return (
            e.type === 'expressionChanges' &&
            e['property'] === 'props.readonly' &&
            e.field.key === this.field.key
          );
        }),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe({
        next: () => {
          this.changeInputReadOnlyBehavior();
        },
      });
  }

  /**
   * Changes the input read only
   * behavior based on the readonly attribute
   */
  changeInputReadOnlyBehavior(): void {
    const container = this.parentContainer.nativeElement;
    const inputs = container.getElementsByTagName('input');

    // iterate through all inputs under current div selected
    for (const input of inputs) {
      // determine `event` function to call based on readonly attribute
      const functionCallName = this.field.props?.['readonly']
        ? 'addEventListener'
        : 'removeEventListener';

      // disable/enable input based on readonly attribute
      // process to disable/enable inputs based on input type
      switch (input.type) {
        case 'radio':
        case 'checkbox':
          input?.[functionCallName]('click', this.preventDefault);
          break;
        default:
          input?.[functionCallName]('input', this.preventDefault);
          break;
      }
    }
  }

  preventDefault(event: Event) {
    event.preventDefault();
  }

  ngOnDestroy(): void {
    this.fieldChangesSub?.unsubscribe();
  }
}
