<div class="nav-bar-placeholder d-none d-sm-block"></div>
<div class="register-container">
  <img
    src="assets/img/O.svg"
    alt="irembo logo"
    class="irembo-logo d-none d-sm-block" />
  <div class="message-container d-none d-sm-block">
    <h1 class="welcome-h1">{{ 'Welcome' | translate }}</h1>
    <p class="welcome-p">
      {{
        'We have been working to bring you a new IremboGov experience'
          | translate
      }}, <br />
      {{
        'easier to use, has more & more e-certificates and less attachments'
          | translate
      }}. <br /><br />
      {{
        'We have been working to bring you a new IremboGov experience'
          | translate
      }},<br />
      {{ 'easier to use, has more & mo' | translate }}
    </p>
  </div>
  <div class="authentication-container">
    <div class="row">
      <div class="col-12 mb-5">
        <div
          *ngIf="errorMessage.length > 0"
          class="text-center mt-4 alert alert-danger">
          {{ errorMessage | translate }}
        </div>

        <div class="logo text-center px-5">
          <img class="img" src="assets/images/logo.svg" alt="" />
        </div>
      </div>

      <div class="text-center px-5">
        <img src="assets/img/link.svg" class="irembo-responsive" alt="" />
      </div>

      <div class="text-center justify-content-center align-items-center p-3">
        <h4 class="email-check-heading tt_norms500">
          {{ 'Link expired' | translate }}
        </h4>
        <p
          class="congratulations-body justify-content-center text-center fw-normal">
          {{ 'The activation link you are trying to' | translate }} <br />
          {{ 'access has expired' | translate }}.
        </p>
      </div>

      <p class="email-check-secondary fw-normal">
        {{ 'Do you want another link' | translate }}?
        <span class="resendLink" (click)="resendLink()">{{
          'Click to resend' | translate
        }}</span>
      </p>
    </div>
  </div>
</div>
