<router-outlet *ngIf="!isLoadingTranslations"></router-outlet>
<irembogov-citizen-toast
  aria-live="polite"
  aria-atomic="true"></irembogov-citizen-toast>
<div class="centered-div" *ngIf="isLoadingTranslations">
  <div
    class="w-99 d-flex align-items-center justify-content-center text-center top-margin-spinner">
    <div
      class="spinner-grow text-primary"
      style="width: 3rem; height: 3rem"
      role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>
