<div class="container account-already-active">
  <div class="row">
    <div class="col">
      <div *ngIf="showLogo" class="col-12 logo-container">
        <div class="logo text-center">
          <img class="img" src="assets/images/logo.svg" alt="" />
        </div>
      </div>
      <div class="col-12 text-center mb-3 check image-contianer">
        <img class="icon-img" src="assets/images/link_broken_blue.svg" alt="" />
      </div>
      <div
        class="text-center d-flex justify-content-center flex-column align-items-center">
        <h2 class="mb-3">{{ title }}</h2>
        <p [innerHTML]="description"></p>
        <button class="btn btn-primary mt-3" (click)="goToLogin.emit()">
          <b> {{ buttonActionLabel | translate }} </b>
        </button>
      </div>
    </div>
  </div>
</div>
