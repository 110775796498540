<div class="d-flex flex-column feedback">
  <h2 class="title">{{ 'Officer comment' | translate }}</h2>
  <div *ngFor="let feedback of applicationFeedBack">
    <p class="fw-semibold">{{ feedback.feedbackReason }}</p>
    <div class="w-100 d-flex flex-row align-items-center">
      <div class="pulse"></div>
      <div class="comments">{{ feedback.comment | translate }}</div>
    </div>
  </div>
</div>
