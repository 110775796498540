<div *ngIf="service" class="service">
  <span class="orgcode instituion-badge">{{
    service.organization.acronym
  }}</span>
  <h1>{{ service.serviceName }}</h1>

  <div class="summary">
    <p class="service-paragraph">
      {{ service.serviceSummary }}
    </p>

    <div class="price tt_norms600" *ngIf="!showMore">
      {{ service.price | number }} {{ service.currency }}
    </div>
  </div>
  <button
    class="btn btn-link apply-link text-decoration-none"
    (click)="openDetails(service.id)">
    {{ showMoreText }} <i class="fa fa-angle-down"></i>
  </button>

  <ng-container *ngIf="showMore">
    <ul class="service-header-meta-box">
      <li>
        <p class="tt_norms400">
          <i aria-hidden="true" class="fa fa-tag me-2"></i>Price:
          <span class="tt_norms500"
            >{{ service.price }}{{ service.currency }}</span
          >
        </p>
      </li>

      <li>
        <p class="tt_norms400">
          <i aria-hidden="true" class="fa fa-building-flag me-2"></i
          >Institution:
          <span class="tt_norms500 instituion-badge">{{
            service.organization.acronym | uppercase
          }}</span>
        </p>
      </li>

      <li>
        <p class="tt_norms400">
          <i aria-hidden="true" class="fa-regular fa-clock me-2"></i>Expected
          Processing Time:
          <span class="tt_norms500"
            >{{ service.expectedProcessingTime }} Day(s)</span
          >
        </p>
      </li>
    </ul>
  </ng-container>

  <div class="apply-btn mt-2">
    <a
      class="btn btn-outline-primary mr-15 me-3"
      href="{{ knowledgeBaseUrl }}"
      rel="noopener"
      target="_blank"
      >How to Apply?</a
    >
    <button (click)="navigateToApply.emit(service)" class="btn btn-primary">
      Apply <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>
