export interface CitizenCreationRequestDto {
  usernameType: RecipientType;
  password: string;
  username: string;
  locale: string;
}

export interface UserRole {
  role: string;
  scopeType?: string;
  scopeId?: string;
}

export enum RoleType {
  CITIZEN = 'ROLE_CITIZEN',
}

export enum RecipientType {
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
}

export enum UserType {
  CITIZEN = 'CITIZEN',
}

export interface IKeycloakTokenResponse {
  keycloakId: string;
  tokenDto: TokenDto;
}

export interface IProfileResponse {
  status: boolean;
  message: string;
  responseCode: string;
  timestamp: string;
  data: IProfileData;
}

export interface IProfileData {
  self: string;
  id: string;
  origin: string;
  createdTimestamp: 0;
  username: string;
  enabled: true;
  emailVerified: true;
  firstName: string;
  lastName: string;
  email: string;
  federationLink: string;
  serviceAccountClientId: string;
  attributes: IProfileAttribute<string>;
  credentials: [IProfileCredential];
  disableableCredentialTypes: [string];
  requiredActions: [string];
  federatedIdentities: [IProfileFederatedIndentities];
  realmRoles: [string];
  clientRoles: IProfileAttribute<string>;
  clientConsents: [IProfileClientConsents];
  notBefore: 0;
  groups: [string];
  access: IProfileAttribute<boolean>;
}

export interface IProfileAttribute<T> {
  additionalProp1: [T];
  additionalProp2: [T];
  additionalProp3: [T];
}

export interface IProfileCredential {
  id: string;
  type: string;
  userLabel: string;
  createdDate: number;
  secretData: string;
  credentialData: string;
  priority: number;
  value: string;
  temporary: boolean;
}

export interface TokenDto {
  accessToken: string;
  expiresIn: number;
  refreshExpiresIn: number;
  refreshToken: string;
  tokenType: string;
}

export interface IProfileFederatedIndentities {
  identityProvider: string;
  userId: string;
  userName: string;
}

export interface IProfileClientConsents {
  clientId: string;
  grantedClientScopes: [string];
  createdDate: number;
  lastUpdatedDate: number;
}

export interface ICheckTokenResponse {
  status: boolean;
  message: string;
  responseCode: string;
  timestamp: string;
  data: boolean;
}
