import { IOrganisation } from './../../models/organisations-list.model';
import { map, Subscription } from 'rxjs';
import { Component, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PublicServicesService } from '../../services/public-services.service';
import { ICategory } from '../../models/categories-list.model';
import { IService } from '../../models/services-list.model';

@Component({
  selector: 'irembogov-search-by-type',
  templateUrl: './search-by-type.component.html',
  styleUrls: ['./search-by-type.component.scss'],
})
export class SearchByTypeComponent implements OnInit, OnDestroy {
  keyword = '';
  searchby = '';
  browseby = '';
  totalItems = 0;
  institutions: IOrganisation[] | undefined;
  categories: ICategory[] | undefined;
  selectedApplication: IService;
  private subscriptions = new Subscription();
  showMore = false;
  modalID!: ElementRef;
  searchResults: IService[] | undefined;
  activeFilter = '';
  categoryId: string | undefined;
  organizationId: string | undefined;
  filterByFirstItem: Record<string, string> = {
    category: '',
    institution: '',
  };
  page = 0;
  size = 50;
  isSearching = false;

  constructor(
    private route: ActivatedRoute,
    private publicServicesService: PublicServicesService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.selectedApplication = {
      id: '',
      serviceName: '',
      serviceSummary: '',
      category: {
        id: '',
        name: '',
      },
      organization: {
        id: '',
        acronym: '',
        name: '',
      },
      priceType: '',
      price: 0,
      currency: '',
      expectedProcessingTime: 0,
      applicantType: '',
    };
  }

  ngOnInit(): void {
    const allowedFilters = ['category', 'institution'];
    let runGet = true;
    this.route.queryParams.subscribe(res => {
      this.browseby = res['type'];
      if (!this.browseby || allowedFilters.indexOf(this.browseby) == -1) {
        this.browseby = 'category';
      }
      // Check for additional parameters and call handleFilterBy
      const filterId = res['id'];
      const type = res['type'];
      if (filterId && type) {
        runGet = false;
        this.handleFilterBy(type, filterId);
      }
    });
    if (runGet) {
      this.getCategories();
      this.getInstitutions();
    }
  }

  openModal(modal: ElementRef) {
    this.modalService.open(modal, {
      size: 'lg',
      centered: true,
      animation: true,
    });
  }

  handleApply(modal: ElementRef, service: IService) {
    this.selectedApplication = service;
    this.openModal(modal);
  }

  handleFilterBy(type: string, id: string) {
    this.activeFilter = id;
    this.browseby = type;
    this.searchByType(type, id);
  }

  handleSearchItemSelect(queryParams: IService) {
    const navigationExtras: NavigationExtras = {
      queryParams: { keyword: queryParams.serviceName, id: queryParams.id },
    };
    this.router.navigate(['/service/details'], navigationExtras);
  }
  handleSearchItemPressed(searchTerm: string) {
    this.router.navigate(['/advance-search'], {
      queryParams: { keyword: searchTerm },
    });
  }

  handlePrimaryFilterBy(type: string) {
    this.browseby = type;
    this.activeFilter = this.filterByFirstItem[type];
    this.searchByType(type, this.filterByFirstItem[type]);
  }

  getInstitutions() {
    this.subscriptions.add(
      this.publicServicesService
        .getOrganisations({ page: 0, size: this.size })
        .pipe(map(res => res.data))
        .subscribe({
          next: res => {
            this.institutions = res.content;
            this.filterByFirstItem['institution'] = res.content[0].id;
            if (this.browseby === 'institution') {
              this.activeFilter = res.content[0].id;
              this.searchByType('institution', res.content[0].id);
            }
          },
        })
    );
  }

  getCategories() {
    this.subscriptions.add(
      this.publicServicesService
        .getCategories()
        .pipe(map(res => res.data))
        .subscribe({
          next: res => {
            this.categories = res.content;
            this.filterByFirstItem['category'] = res.content[0].id;
            if (this.browseby === 'category') {
              this.activeFilter = res.content[0].id;
              this.searchByType('category', res.content[0].id);
            }
          },
        })
    );
  }

  handlePaginate(event: { pageNumber: number; pageSize: number }) {
    this.page = event.pageNumber - 1;
    this.searchByType(this.browseby, this.activeFilter);
  }

  searchByType(type: string, id: string) {
    const filters: Record<string, unknown> = {};
    this.isSearching = true;
    if (type === 'category') {
      filters['categoryId'] = id;
    }

    if (type === 'institution') {
      filters['organizationId'] = id;
    }

    this.subscriptions.add(
      this.publicServicesService
        .getServices({ page: this.page, size: this.size, ...filters })
        .subscribe({
          next: res => {
            this.isSearching = false;
            this.totalItems = res.data.totalElements;
            this.searchResults = res.data.content;
            this.size = res.data.size;
          },
          error: () => {
            this.isSearching = false;
          },
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  searchByQuery(query: string) {
    this.publicServicesService
      .getServices({ page: 0, size: this.size, query })
      .subscribe({
        next: res => {
          this.totalItems = res.data.totalElements;
          this.searchResults = res.data.content;
          this.size = res.data.size;
        },
      });
  }
}
