<!-- Body-->
<body>
  <!-- Google Tag Manager (noscript)-->

  <!-- Page loading spinner-->
  <div class="page-loading active">
    <div class="page-loading-inner">
      <div class="page-spinner"></div>
      <span>Loading...</span>
    </div>
  </div>

  <!-- Page wrapper-->
  <main class="page-wrapper">
    <!-- Page content-->
    <div
      class="container d-flex flex-column justify-content-center min-vh-100 py-5">
      <div class="text-center pt-4 mt-lg-2">
        <h1 class="display-5">{{ 'Page not found' | translate }}</h1>
        <p class="fs-lg pb-2 pb-md-0 mb-4 mb-md-5">
          {{
            'The page you are looking for was moved, removed or might never existed'
              | translate
          }}.
        </p>
        <a class="btn btn-lg btn-primary" routerLink="">{{
          'Go to homepage' | translate
        }}</a>
      </div>
    </div>
  </main>
</body>
