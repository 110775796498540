<section class="irembo-pagination-wrapper">
  <div
    class="irembo-pagination d-flex align-items-center {{
      minimalPagination
        ? 'irembogov-minimal-pagination border-0 shadow-none'
        : ''
    }}">
    <ngb-pagination
      [collectionSize]="collectionSize"
      [(page)]="page"
      (pageChange)="pageChange($event)"
      [maxSize]="maxSize"
      [size]="paginationDisplaySize"
      [rotate]="false"
      [pageSize]="pagination.pageSize"
      class="d-flex justify-content-end"
      aria-label="Custom pagination">
      <ng-container *ngIf="!minimalPagination">
        <ng-template ngbPaginationPrevious
          ><span class="d-flex align-items-center">
            <i aria-hidden="true" class="fas fa-chevron-left me-3"></i>
            <span *ngIf="showDirectionText">{{ 'Previous' | translate }}</span>
          </span>
        </ng-template>
        <ng-template ngbPaginationNext>
          <span class="d-flex align-items-center">
            <span *ngIf="showDirectionText">{{ 'Next' | translate }}</span>
            <i aria-hidden="true" class="fas fa-chevron-right ms-3"></i> </span
        ></ng-template>
        <ng-template ngbPaginationNumber let-p>{{
          getPageSymbol(p)
        }}</ng-template>
      </ng-container>

      <ng-container *ngIf="minimalPagination">
        <ng-template ngbPaginationPrevious>
          <button class="btn btn-sm p-1">
            <i aria-hidden="true" class="fas fa-chevron-left"></i>
          </button>
        </ng-template>
        <ng-template ngbPaginationNext>
          <button class="btn btn-sm p-1">
            <i aria-hidden="true" class="fas fa-chevron-right"></i>
          </button>
        </ng-template>

        <ng-template ngbPaginationPages let-page>
          <div class="small d-flex flex-nowrap px-2">
            <span class="col-form-label text-nowrap px-2">
              {{ 'Page' | translate }} {{ page }} of {{ getTotalPages() }}</span
            >
          </div>
        </ng-template>
      </ng-container>
    </ngb-pagination>
  </div>

  <div class="dropdown-wrapper" *ngIf="showDropdown">
    <div ngbDropdown class="d-inline-block">
      <button type="button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
        {{ pagination.pageSize }} {{ 'per page' | translate }}
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button
          *ngFor="let pageSize of pageSizes"
          ngbDropdownItem
          (click)="pageSizeChange(pageSize)">
          {{ pageSize }}
        </button>
      </div>
    </div>
  </div>
</section>
