import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormlyUtilsService {
  phoneNumberFieldInitialized$: Subject<{ key: string; success: boolean }> =
    new Subject<any>();

  onPhoneNumberFieldInitialized(keyAndInitSuccess: {
    key: string;
    success: boolean;
  }): void {
    return this.phoneNumberFieldInitialized$.next(keyAndInitSuccess);
  }
}
