<div class="irembogov-custom-section-message-with-link">
  <irembogov-irembo-section-message
    [openAlert]="true"
    [dismissible]="false"
    [type]="getSectionMessageType()"
    [title]="field.props?.['title']"
    [message]="field.props?.['message']"
    [extraMessage]="field.props?.['extraMessage']"
    [extraMessageAsHtml]="field.props?.['extraMessageAsHtml'] ?? false"></irembogov-irembo-section-message>

  <ng-container *ngIf="field.props?.['links']?.length > 0">
    <div class="link-item" *ngFor="let linkItem of field.props?.['links']">
      <a
        [href]="linkItem?.url"
        [target]="linkItem?.selfTarget ? '_self' : '_blank'"
        >{{ linkItem?.name }}</a
      >
    </div>
  </ng-container>
</div>
