import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FormlyValueChangeEvent } from '@ngx-formly/core/lib/models';
import { filter, Subject, takeUntil } from 'rxjs';

enum EHintPositions {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

@Component({
  selector: 'irembogov-hint-field-wrapper',
  templateUrl: './hint-field-wrapper.component.html',
  styleUrls: ['./hint-field-wrapper.component.scss'],
})
export class HintFieldWrapperComponent
  extends FieldWrapper
  implements OnInit, OnDestroy
{
  EHintPositions = EHintPositions;
  hintPosition: EHintPositions = EHintPositions.BOTTOM;
  subscribeSubject: Subject<void> = new Subject();

  ngOnInit() {
    this.field.options?.fieldChanges
      ?.pipe(
        filter((e: FormlyValueChangeEvent) => {
          return (
            e.field.key === this.field.key && e.type === 'expressionChanges'
          );
        }),
        takeUntil(this.subscribeSubject)
      )
      .subscribe(() => {
        this.setHintPosition();
      });
    this.setHintPosition();
  }

  setHintPosition() {
    if (
      this.field.props?.['hintPosition'] &&
      this.field.props?.['hintPosition'] === EHintPositions.TOP
    ) {
      this.hintPosition = EHintPositions.TOP;
    } else {
      this.hintPosition = EHintPositions.BOTTOM;
    }
  }

  ngOnDestroy() {
    this.subscribeSubject.next();
    this.subscribeSubject.complete();
  }
}
