import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { IService, IServicesList } from '../../models/services-list.model';
import { PublicServicesService } from '../../services/public-services.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  of,
  Subscription,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'irembogov-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss'],
})
export class SearchboxComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('search') searchEl: ElementRef | undefined;
  focused = false;
  keyword = '';

  isSearching = false;
  searchResults: IService[] = [];
  @Input() value = '';
  @Output() resultSelected = new EventEmitter<IService>();
  @Output() keyEnterEvent = new EventEmitter<string>();
  predicitiveSearchSub: Subscription | undefined;
  searchByKeyWordSub: Subscription | undefined;
  @Input() smShadow = false;
  @Input() size = 'sm';

  constructor(
    private publicServicesService: PublicServicesService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(res => {
      this.value = res['keyword'] || '';
    });
  }

  ngAfterViewInit(): void {
    this.predicitiveSearchSub = fromEvent(this.searchEl?.nativeElement, 'keyup')
      .pipe(
        map((event: unknown) => {
          this.keyword = '';
          if (event instanceof Event) {
            this.keyword = (event.target as HTMLInputElement).value;
          }
          return this.keyword;
        }),
        filter(res => res.length > 2),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe({
        next: (keyword: string) => {
          this.isSearching = true;
          this.searchByKeyWordSub = this.searchByKeyWord(keyword).subscribe({
            next: (res: IService[]) => {
              this.isSearching = false;
              this.searchResults = res;
            },
            error: (err: Error) => {
              this.isSearching = false;
              console.log('error', err);
            },
          });
        },
      });
  }

  onKeyUp() {
    this.focused = true;
  }

  setFocus(num: number) {
    this.focused = !!num;
  }

  handleSelect(selectedItem: IService) {
    this.value = selectedItem.serviceName;
    this.resultSelected.emit(selectedItem);

    this.searchResults = [];
  }

  searchByKeyWord(keyword: string) {
    if (keyword === '') {
      return of([]);
    }
    const page = 0;
    const size = 10;
    const formattedKeyword = keyword.toLowerCase().trim();
    return this.publicServicesService
      .getServices({
        page,
        size,
        query: formattedKeyword,
      })
      .pipe(map((res: IServicesList) => res.data.content));
  }

  onEnter() {
    const inputField = this.searchEl?.nativeElement as HTMLInputElement;
    this.keyEnterEvent.emit(inputField.value);
  }

  ngOnDestroy(): void {
    this.predicitiveSearchSub?.unsubscribe();
    this.searchByKeyWordSub?.unsubscribe();
  }

  goAdvanceSearch() {
    this.router.navigate(['/advance-search'], {
      queryParams: { keyword: this.keyword },
    });
  }
}
