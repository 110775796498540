import { Component, Input } from '@angular/core';
import { IremboToastComponent } from '@irembo-andela/irembogov3-common';

@Component({
  selector: 'irembogov-citizen-toast',
  templateUrl: './citizen-toast.component.html',
  styleUrls: ['./citizen-toast.component.scss'],
})
export class CitizenToastComponent extends IremboToastComponent {
  @Input() title!: string;
}
