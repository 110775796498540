export const base64UriToFileConverter = (
  uri: string | undefined,
  fileType: string,
  fileName: string
): File => {
  const splitDataURI = (<string>uri).split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new File([ia], fileName, {
    type: fileType,
  });
};

export const formatFileSizeBytesUtil = (
  bytes: number | null | undefined,
  decimals = 2
) => {
  if (bytes === undefined || bytes === null) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
