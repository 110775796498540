<div
  class="d-flex flex-column justify-content-lg-center justify-content-md-center justify-content-sm-start align-items-lg-center align-items-md-center align-items-sm-center min-vh-100 bg-white">
  <div
    class="d-flex flex-column justify-content-lg-center justify-content-md-center justify-content-sm-start align-items-lg-center align-items-md-center align-items-sm-start col-xl-3 col-lg-3 col-md-3 col-sm-12 px-3">
    <div class="mb-4 mb-sm-4 mt-4">
      <img
        src="../../../../assets/img/logo.png"
        alt="Irembo logo"
        height="20"
        width="109" />
    </div>
    <div class="text-lg-center text-md-center text-sm-start">
      <h1>
        {{ 'Forgot your password?' | translate }}
      </h1>
      <p class="sub-heading">
        {{ subHeading | translate }}
      </p>
    </div>
    <div *ngIf="resetType === 'email'; else phone" class="w-100">
      <ng-container *ngTemplateOutlet="email"></ng-container>
    </div>
    <button
      class="btn btn-primary tex-white w-100 fw-semibold mt-3"
      [disabled]="isLoading || resetForm.invalid"
      (click)="onFormSubmit()">
      <div
        *ngIf="isLoading"
        class="spinner-border spinner-border-sm mr-2"
        role="status"></div>
      {{ 'Reset Password' | translate }}
    </button>
    <a
      class="text-lg-center text-md-center text-sm-start mt-3 fw-semibold"
      routerLink="/profile"
      ><i class="fa-solid fa-angle-left me-2"></i
      >{{ 'Return to log in' | translate }}</a
    >
  </div>
</div>

<ng-template #email>
  <form [formGroup]="resetForm">
    <div class="form-group mb-3">
      <label for="email" class="form-label">{{ 'Email' | translate }}</label>
      <input
        formControlName="username"
        type="email"
        class="form-control text-input"
        [ngClass]="hasError ? 'has-error' : ''"
        id="email"
        aria-describedby="email"
        placeholder="{{ 'Enter your email' | translate }}" />
      <small
        *ngIf="
          resetType === 'email' &&
          resetForm.get('username')?.invalid &&
          resetForm.get('username')?.touched
        "
        class="form-text text-danger">
        {{ 'Invalid email provided' | translate }}</small
      >
      <small *ngIf="hasError" class="form-text text-danger">
        {{ errorMessage | translate }}</small
      >
    </div>
  </form>
</ng-template>

<ng-template #phone>
  <form [formGroup]="resetForm" class="w-100">
    <div class="form-group mb-3">
      <label for="phone" class="form-label">{{
        'Phone number' | translate
      }}</label>
      <input
        formControlName="username"
        type="tel"
        class="form-control text-input"
        id="phone"
        aria-describedby="phone"
        [ngClass]="hasError ? 'has-error' : ''"
        placeholder="{{ 'Enter your phone number' | translate }}" />
      <small
        *ngIf="!hasError"
        class="form-text"
        [ngClass]="
          resetType === 'phone' &&
          resetForm.get('username')?.invalid &&
          resetForm.get('username')?.touched
            ? 'text-danger'
            : 'text-muted '
        ">
        {{
          'Must be a 10 digit Rwandan phone number. Please double-check and try again.'
            | translate
        }}</small
      >
      <small *ngIf="hasError" class="form-text text-danger">
        {{ errorMessage | translate }}</small
      >
    </div>
  </form>
</ng-template>
