<div class="bg-white min-vh-100 w-100">
  <div
    class="container-fluid d-flex flex-column justify-content-lg-center justify-content-md-center justify-content-sm-start align-items-lg-center align-items-sm-center align-content-lg-center align-content-md-center align-content-sm-start min-vh-100 col-lg-3 col-sm-12">
    <div class="mb-5">
      <img
        src="../../../../assets/img/logo.png"
        alt="Irembo logo"
        height="20"
        width="109" />
    </div>
    <div class="mb-3">
      <img
        src="../../../../assets/img/success.png"
        alt="Success"
        height="56"
        width="56" />
    </div>
    <div class="text-lg-center text-md-center text-sm-start">
      <h3 class="mb-3">
        {{ 'Success!' | translate }}{{ ' ' }} {{ 'Your business' | translate }}
        {{ " '" }}{{ businessName }}{{ "' " }}
        {{ 'is now linked to your account' | translate }}.
      </h3>

      <ng-container
        *ngIf="!(initiatedApplyServiceKeyword && initiatedApplyServiceId)"
        [ngTemplateOutlet]="redirectTextAndButton"
        [ngTemplateOutletContext]="{
          subHeading:
            'You can now manage your business interactions and apply for services on it\'s behalf',
          redirectText: 'Go to homepage',
          redirectTo: 'home'
        }">
      </ng-container>

      <ng-container
        *ngIf="initiatedApplyServiceKeyword && initiatedApplyServiceId"
        [ngTemplateOutlet]="redirectTextAndButton"
        [ngTemplateOutletContext]="{
          subHeading: 'Click the button below to continue',
          redirectText: 'Continue to application',
          redirectTo: 'application'
        }">
      </ng-container>

      <ng-template
        #redirectTextAndButton
        let-subHeading="subHeading"
        let-redirectText="redirectText"
        let-redirectTo="redirectTo">
        <p class="sub-heading">
          {{ subHeading | translate }}
        </p>

        <button
          class="btn btn-primary text-white w-100"
          (click)="redirectPage(redirectTo)"
          type="button">
          {{ redirectText | translate }}
        </button>
      </ng-template>
    </div>
  </div>
</div>
