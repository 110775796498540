import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivationComponent } from './activation/activation.component';
import { PasswordComponent } from './password/password.component';
import { SuccessComponent } from './success/success.component';
import { OtpComponent } from './otp/otp.component';
import { ResetComponent } from './reset/reset.component';
import { LinkComponent } from './link/link.component';
import { ResetLinkComponent } from './reset-link/reset-link.component';
import { ResetActivationComponent } from './reset-activation/reset-activation.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordOtpComponent } from './reset-password-otp/reset-password-otp.component';
import { GenericContainerComponent } from './generic-container/generic-container.component';
import { VerifyComponent } from './verify/verify.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { GenericContainerResolver } from './generic-container/generic-container.resolver';

const routes: Routes = [
  {
    path: '',
    component: GenericContainerComponent,
    resolve: {
      setup: GenericContainerResolver,
    },
    children: [
      { path: 'register', component: CreateAccountComponent },
      { path: 'activate', component: ActivationComponent },
      { path: 'activate-account', component: LinkComponent },
      {
        path: 'password-reset',
        component: ResetLinkComponent,
      },
      {
        path: 'password',
        component: PasswordComponent,
      },
      {
        path: 'success',
        component: SuccessComponent,
      },
      {
        path: 'otp',
        component: OtpComponent,
      },
      {
        path: 'reset',
        component: ResetComponent,
      },
      {
        path: 'reset-activation',
        component: ResetActivationComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'reset-password-otp',
        component: ResetPasswordOtpComponent,
      },
      {
        path: 'verify',
        component: VerifyComponent,
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
