import { Pipe, PipeTransform } from '@angular/core';
import { IService } from '../../models/services-list.model';
import { PriceType } from '../../models/price-type.enum';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'servicePrice',
})
export class ServicePricePipe implements PipeTransform {
  transform(service: IService): string {
    const decimalPipe = new DecimalPipe(`en-US`);
    switch (service?.priceType) {
      case PriceType.FREE:
        return 'Free';
      case PriceType.DYNAMIC:
        return 'It depends';
      case PriceType.FIXED:
        return `${decimalPipe.transform(service.price)} ${service.currency}`;
      default:
        return '';
    }
  }
}
