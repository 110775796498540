import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IApplication,
  applicationStatusEnumToColorUtil,
  applicationStatusEnumToLabelUtil,
  applicationStatusValueOf,
} from '@irembo-andela/irembogov3-common';

@Component({
  selector: 'irembogov-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss'],
})
export class ApplicationListComponent {
  @Input() applicationsList: IApplication[] = [];
  @Input() totalApplications = 0;
  @Input() isLoadingApplications = false;
  @Input() showMobileApplicationList = false;
  @Input() viewType?: 'business' | 'individual' = 'individual';

  viewSummary = false;
  viewSummaryText = 'View application summary';
  searchTerm = '';

  @Output() getApplications = new EventEmitter<{
    pageNumber: number;
    pageSize: number;
    sort: string;
    searchTerm: string;
  }>();

  @Output() viewApplicationDetails = new EventEmitter<IApplication>();

  viewSummaryToggle() {
    this.viewSummary = !this.viewSummary;
    if (this.viewSummary) {
      this.viewSummaryText = 'Hide application summary';
    } else {
      this.viewSummaryText = 'View application summary';
    }
  }

  onEnterInSearchInput() {
    this.getApplications.emit({
      pageNumber: 0,
      pageSize: 10,
      sort: 'dateCreated,desc',
      searchTerm: this.searchTerm,
    });
  }

  getApplicationStatusToLabel(applicationStatus: string): string {
    return applicationStatusEnumToLabelUtil(
      applicationStatusValueOf(applicationStatus)
    );
  }

  handlePaginate(event: { pageNumber: number; pageSize: number }) {
    this.getApplications.emit({
      pageNumber: event.pageNumber - 1,
      pageSize: event.pageSize,
      sort: 'dateCreated,desc',
      searchTerm: this.searchTerm,
    });
  }

  getApplicationStatusToColor(applicationStatus: string): string {
    return applicationStatusEnumToColorUtil(
      applicationStatusValueOf(applicationStatus)
    );
  }

  getApplicationStatusPulseToBackgroundColor(applicationStatus: string) {
    const color = applicationStatusEnumToColorUtil(
      applicationStatusValueOf(applicationStatus)
    );
    return `bg-${color}`;
  }

  viewApliactionDetails(application: IApplication) {
    this.viewApplicationDetails.emit(application);
  }

  convertDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/');
    return new Date(+year, +month - 1, +day);
  }
}
