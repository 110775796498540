export function getValueFromNestedObject(
  obj: Record<string, unknown>,
  keyPath: string
) {
  const keys = keyPath.split('.');
  let value = obj;
  for (const key of keys) {
    value = value[key] as Record<string, unknown>;
    if (value === undefined) {
      throw new Error(`Invalid key path string: ${keyPath}`);
    }
  }
  return value as unknown as Record<string, unknown>[];
}
