<div class="left-panel">
  <div class="left-panel-container">
    <h3>Browse service by:</h3>
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a
          [routerLink]="['/services/browse-by']"
          (click)="browseByChanged.emit('category')"
          [queryParams]="{ type: 'category' }"
          [ngClass]="{ active: browseby === 'category' }"
          class="nav-link tt_norms500"
          id="nav-cat-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-cat"
          type="button"
          role="tab"
          aria-controls="nav-cat"
          aria-selected="true"
          >Categories</a
        >
        <a
          [routerLink]="['/services/browse-by']"
          (click)="browseByChanged.emit('institution')"
          [queryParams]="{ type: 'institution' }"
          [ngClass]="{ active: browseby === 'institution' }"
          class="nav-link tt_norms500"
          id="nav-inst-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-inst"
          type="button"
          role="tab"
          aria-controls="nav-inst"
          aria-selected="false"
          >Institutions</a
        >
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div
        [ngClass]="{ 'show active': browseby === 'category' }"
        class="tab-pane fade"
        id="nav-cat"
        role="tabpanel"
        aria-labelledby="nav-cat-tab">
        <ul class="list-group borderless list-group-content">
          <li
            class="list-group-item borderless"
            *ngFor="let category of categories">
            <button
              class="btn btn-outline-dark btn-tab active"
              [ngClass]="{ active: activeFilter === category.id }"
              (click)="filterByCategory.emit(category.id)">
              {{ category.name }}
            </button>
          </li>
        </ul>
      </div>
      <div
        [ngClass]="{ 'show active': browseby === 'institution' }"
        class="tab-pane fade"
        id="nav-inst"
        role="tabpanel"
        aria-labelledby="nav-inst-tab">
        <ul class="list-group">
          <li
            class="list-group-item borderless"
            *ngFor="let institution of institutions">
            <button
              class="btn btn-outline-dark btn-tab active"
              [ngClass]="{ active: activeFilter === institution.id }"
              (click)="filterByInstitution.emit(institution.id)">
              {{ institution.acronym }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
