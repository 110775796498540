<div class="nav-bar-placeholder d-none d-sm-block"></div>
<div class="register-container">
  <img
    src="assets/img/O.svg"
    alt="irembo logo"
    class="irembo-logo d-none d-sm-block" />
  <div class="message-container d-none d-sm-block">
    <h1 class="welcome-h1">{{ 'Welcome' | translate }}</h1>
    <p class="welcome-p">
      {{
        'We have been working to bring you a new IremboGov experience'
          | translate
      }}, <br />
      {{
        'easier to use, has more & more e-certificates and less attachments'
          | translate
      }}. <br /><br />
      {{
        'We have been working to bring you a new IremboGov experience'
          | translate
      }},<br />
      {{ 'easier to use, has more & mo' | translate }}
    </p>
  </div>
  <div class="authentication-container">
    <div class="container p-0">
      <div class="row">
        <div class="col-12">
          <div
            *ngIf="userFeedback.length > 0"
            class="text-center mt-4 alert alert-success">
            {{ userFeedback | translate }}
          </div>
          <div
            *ngIf="errorMessage.length > 0"
            class="text-center px-5 mt-4 alert alert-danger">
            {{ errorMessage | translate }}
          </div>

          <div class="logo text-center px-5">
            <img class="img" src="assets/images/logo.svg" alt="" />
          </div>

          <div class="text-center px-5 mt-3">
            <img
              class="img"
              src="assets/img/key.png"
              class="irembo-responsive"
              alt="" />
          </div>
        </div>

        <h2 class="mb-4 text-center tt_norms500">
          {{ 'Enter code' | translate }}
        </h2>
        <p class="otp-frame-body text-center tt_norms400">
          {{ 'We sent you a code through your phone number' | translate }}
          {{ maskNumber }}
        </p>

        <irembogov-otp-input
          (formError)="onError($event)"
          (formSubmit)="onFormSubmit($event)"
          [restartTimer]="restartTimer"
          [isLoading]="isLoading">
        </irembogov-otp-input>

        <div class="text-center p-1">
          <p class="mt-2 otp-code-secondary">
            {{ 'Didn’t receive the code' | translate }}?
            <span (click)="resendLink()" class="resendLink">{{
              'Click to resend' | translate
            }}</span>
          </p>
        </div>

        <div class="text-center">
          <button
            (click)="goBack()"
            class="dark-grey back-btn btn btn-default w-auto">
            <i aria-hidden="true" class="fa fa-arrow-left pe-2"></i>
            {{ 'Back to login' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
