import { Component, Input, OnInit } from '@angular/core';
import { IApplicationDetails } from '../../models/application-details.model';
import { IApplication } from '../../models/application.model';
import { IApplicationFormData } from '../../models/application-form-data.model';
import { applicationFormBuildPreviewData } from '../../../utils/utils/application-form.util';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'irembogov-application-summary',
  templateUrl: './application-summary.component.html',
  styleUrls: ['./application-summary.component.scss'],
})
export class ApplicationSummaryComponent implements OnInit {
  @Input() application?: IApplication;
  @Input() applicationBaseUrl?: string;
  @Input() applicationPath: string | undefined;
  @Input() isCollapsed = false;
  @Input() showTitle = true;
  @Input() applicationSummary: IApplicationFormData[] = [];

  ngOnInit(): void {
    this.applicationSummary = this.applicationSummary.filter(
      x => x.formGroup.length > 0
    );
  }

  trackByFn(index: number) {
    return index;
  }
}
