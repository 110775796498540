<div class="search-header">
  <div class="left-panel"></div>
  <div class="right-panel">
    <div class="search-container">
      <irembogov-searchbox
        [smShadow]="true"
        (resultSelected)="handleSearchItemSelect($event)"
        (keyEnterEvent)="handleSearchItemPressed($event)"
        [value]="keyword"></irembogov-searchbox>
    </div>
  </div>
</div>

<div class="searchByTypeContainer">
  <div class="left-panel">
    <div class="left-panel-container">
      <h3>{{ 'Browse service by' | translate }}:</h3>
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a
            [routerLink]="['/services/browse-by']"
            (click)="handlePrimaryFilterBy('category')"
            [queryParams]="{ type: 'category' }"
            [ngClass]="{ active: browseby === 'category' || browseby === '' }"
            class="nav-link tt_norms500"
            id="nav-cat-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-cat"
            type="button"
            role="tab"
            aria-controls="nav-cat"
            aria-selected="true"
            >{{ 'Categories' | translate }}</a
          >
          <a
            [routerLink]="['/services/browse-by']"
            (click)="handlePrimaryFilterBy('institution')"
            [queryParams]="{ type: 'institution' }"
            [ngClass]="{ active: browseby === 'institution' }"
            class="nav-link tt_norms500"
            id="nav-inst-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-inst"
            type="button"
            role="tab"
            aria-controls="nav-inst"
            aria-selected="false"
            >{{ 'Institutions' | translate }}</a
          >
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div
          [ngClass]="{
            'show active': browseby === 'category' || browseby === ''
          }"
          class="tab-pane fade"
          id="nav-cat"
          role="tabpanel"
          aria-labelledby="nav-cat-tab">
          <ul class="list-group borderless">
            <li
              class="list-group-item borderless"
              *ngFor="let category of categories">
              <button
                class="btn btn-outline-dark btn-tab active"
                [ngClass]="{ active: activeFilter === category.id }"
                (click)="handleFilterBy('category', category.id)">
                {{ category.name | translate }}
              </button>
            </li>
          </ul>
        </div>
        <div
          [ngClass]="{ 'show active': browseby === 'institution' }"
          class="tab-pane fade"
          id="nav-inst"
          role="tabpanel"
          aria-labelledby="nav-inst-tab">
          <ul class="list-group">
            <li
              class="list-group-item borderless"
              *ngFor="let institution of institutions">
              <button
                class="btn btn-outline-dark btn-tab active"
                [ngClass]="{ active: activeFilter === institution.id }"
                (click)="handleFilterBy('institution', institution.id)">
                {{ institution.acronym ?? '' | translate }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="right-panel bg-white">
    <div class="list-container">
      <h1 class="title tt_norms500">{{ 'Services' | translate }}</h1>
      <div *ngIf="!isSearching" class="row">
        <div class="col-md-10">
          <ng-container *ngFor="let result of searchResults">
            <irembogov-service-search-result
              [service]="result"
              (applyClicked)="
                handleApply(modalID, result)
              "></irembogov-service-search-result>
          </ng-container>

          <div
            *ngIf="totalItems"
            class="d-flex align-items-start flex-column mt-4">
            <irembogov-citizen-pagination
              [collectionSize]="totalItems"
              [pageSize]="size"
              (paginatedValues)="
                handlePaginate($event)
              "></irembogov-citizen-pagination>
          </div>
        </div>
      </div>

      <div
        *ngIf="isSearching"
        class="w-99 d-flex align-items-center justify-content-center text-center mt-5">
        <div
          class="spinner-grow text-primary"
          style="width: 3rem; height: 3rem"
          role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</div>

<irembogov-service-modal
  *ngIf="selectedApplication"
  (modalID)="modalID = $event"
  [data]="selectedApplication"></irembogov-service-modal>
