<ng-template #content let-offcanvas>
  <div class="{{ wrapClass }} irembo-off-canvas-wrapper">
    <div class="offcanvas-header flex-column px-4 mt-3 py-1">
      <div class="close-content align-self-end mb-3">
        <span class="close-x small" (click)="offcanvas.dismiss()">
          Close
          <i class="fa fa-times ms-2"></i>
        </span>
      </div>
      <div class="header-content flow-column w-100">
        <div class="headline d-flex justify-content-between align-items-center">
          <h4 class="offcanvas-title" id="offcanvas-basic-title">
            {{ title }}
          </h4>
          <div class="title-action">
            <ng-content select="[titleAction]"></ng-content>
          </div>
        </div>
        <small class="w-100 text-muted">{{ description }}</small>
      </div>
    </div>
    <span
      (click)="offcanvas.dismiss()"
      class="close-arrow shadow text-center align-middle d-block">
      <i class="fa-solid fa-chevron-left"></i>
    </span>
    <div class="offcanvas-body p-4 pt-0">
      <div [ngClass]="roundedWrapContent ? 'rounded bg-white p-3' : ''">
        <ng-content
          *ngIf="!contentNgTemplate"
          select=":not([titleAction])"></ng-content>
        <ng-container
          *ngIf="contentNgTemplate"
          [ngTemplateOutlet]="contentNgTemplate"></ng-container>
      </div>
    </div>
  </div>
</ng-template>
