import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';

@Component({
  selector: 'irembogov-new-otp-input',
  templateUrl: './new-otp-input.component.html',
  styleUrls: ['./new-otp-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NewOtpInputComponent implements OnInit {
  isCompleted = false;
  @Input() isLoading = false;
  @Input() size = 5;
  @Input() isSuccess = false;
  @Input() showCountdown = true;
  @Input() countdownTime = 300;
  @Input() navigationText = 'Return to login';
  @Input() navigationLink = '/auth/login';
  @Input() buttonText = 'Verify';
  @Input() errorMessage = '';
  @Input() resendText = 'Resend code';
  @Input() redirectionStyle: 'step' | 'redirect' = 'redirect';
  @Input() restartTimer = false;

  @Output() OnFinish = new EventEmitter<string>();
  @Output() OnResend = new EventEmitter<void>();
  @Output() goBack = new EventEmitter<void>();

  @ViewChildren('code') inputs: QueryList<HTMLInputElement> | undefined;
  @ViewChild('cd', { static: false }) private countdown:
    | CountdownComponent
    | undefined;
  protected form!: FormArray;

  config: CountdownConfig = {};
  keydownJustOccurred = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    const controls = [];
    for (let i = 0; i < this.size; i++) {
      const element = new FormControl('', [
        Validators.maxLength(1),
        Validators.pattern('[a-zA-Z0-9]{1}'),
        Validators.required,
      ]);
      controls.push(element);
    }
    this.form = new FormArray(controls);

    this.config = {
      leftTime: this.countdownTime ?? 300,
      notify: [60],
      format: 'mm:ss',
    };
  }

  onChange(event: any, i: number) {
    this.keydownJustOccurred = true;
    const commonKeyCodes = ['KeyV', 'Delete', 'Tab', 'ShiftLeft', 'ShiftRight'];
    const e = <KeyboardEvent>event;
    const eventValue = (event.target as HTMLInputElement).value;

    if (e.key !== 'Backspace') {
      if (/^[0-9a-zA-Z]$/.test(e.key) || /^[0-9a-zA-Z]$/.test(eventValue)) {
        if (
          (event.type === 'input' || event.type === 'keydown') &&
          i < this.size - 1
        ) {
          this.focus(i + 1);
        }
      } else if (
        (commonKeyCodes.includes(e.code) && (e.metaKey || e.ctrlKey)) ||
        commonKeyCodes.includes(e.code)
      ) {
        event.preventDefault();
        return;
      } else {
        this.form.controls[i].setValue('');
        event.preventDefault();
      }
    } else {
      if (i >= 0) {
        if (i === 0) {
          this.form.controls[i].setValue('');
        } else {
          this.form.controls[i].setValue('');
          this.focus(i - 1);
        }
      }
    }
  }

  focus(i: number) {
    if (this.inputs) {
      const el: any = this.inputs.get(i);
      if (el) {
        // Timeout to let the change event go through
        setTimeout(() => el.nativeElement.focus(), 50);
      }
    }
  }

  boxFocus(event: FocusEvent) {
    const input = event.target as HTMLInputElement;
    if (this.keydownJustOccurred) {
      input.select();
    }
    this.keydownJustOccurred = false;
  }

  paste(event: any) {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    const sanitizedText = pastedText.replace(/[^a-zA-Z0-9]/g, '');
    for (let i = 0; i < Math.min(sanitizedText.length, this.size); i++) {
      this.form.controls[i].setValue(sanitizedText.charAt(i));
      this.focus(i + 1);
    }
    if (this.inputs) {
      const nextIndex = Math.min(sanitizedText.length, this.size);
      this.focus(nextIndex);
    }
  }

  onSubmit() {
    setTimeout(() => this.blur(), 50);
    if (this.form.invalid) {
      return;
    }
    this.isCompleted = true;
    this.OnFinish.emit(this.form.value.join(''));
  }

  blur() {
    const focusedEl: any = document.activeElement;
    focusedEl.blur();
  }

  resend() {
    this.OnResend.emit();
    this.isCompleted = false;
    this.isLoading = false;
    this.form.reset();
    this.countdown?.restart();
    this.showCountdown = true;
  }

  getTimeLeft() {
    return this.countdown?.left;
  }

  notify(event: any) {
    if (event.action === 'done' && event.left <= 0) {
      this.showCountdown = false;
    }
  }

  onRedirection() {
    if (this.redirectionStyle === 'redirect') {
      this.router.navigate([this.navigationLink]);
    } else {
      this.goBack.emit();
    }
  }
}
