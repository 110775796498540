<div class="track-application">
  <div [ngClass]="{ 'hide-search': foundApplication }" class="inner-container">
    <div
      class="text-start text-lg-center text-md-center col-12 col-lg-auto"
      [style.gap.px]="32">
      <h3 class="title text-start text-lg-center text-md-center">
        {{ 'Track applications' | translate }}
      </h3>
      <p class="sub-title">
        {{ 'Search applications by' | translate }}
      </p>
      <form
        [formGroup]="findApplicationForm"
        class="d-flex flex-column justify-content-between align-items-start align-items-lg-center"
        [style.gap.px]="32">
        <div
          class="apply-by w-100 d-flex flex-column flex-lg-row justify-content-between justify-content-lg-center justify-content-md-center gap-lg-0"
          [style.gap.px]="16">
          <div class="form-check form-check-inline">
            <input
              formControlName="searchBy"
              class="form-check-input"
              type="radio"
              id="app_no"
              value="APP_NO" />
            <label class="form-check-label" for="app_no">{{
              'Application number' | translate
            }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              formControlName="searchBy"
              class="form-check-input"
              type="radio"
              id="bill_id"
              value="BILL_ID" />
            <label class="form-check-label" for="bill_id">{{
              'Bill ID' | translate
            }}</label>
          </div>
        </div>
        <div
          class="d-flex flex-column flex-lg-row justify-content-between align-items-start align-content-center w-100"
          [style.gap.px]="32">
          <div class="w-100">
            <div class="input-group col-12" [style.height.px]="48">
              <span
                class="input-group-text bg-white border border-1 border-secondary border-end-0 d-flex justify-content-center align-items-center">
                <i class="fas fa-search text-dark"></i>
              </span>
              <input
                type="text"
                class="form-control border border-1 border-secondary border-start-0"
                placeholder="{{
                  findApplicationForm.get('searchBy')?.value === 'APP_NO'
                    ? ('Enter application number' | translate)
                    : ('Enter bill ID' | translate)
                }}"
                formControlName="searchKeyword" />
            </div>
            <div class="d-flex justify-content-start mt-3">
              <small
                class="text-start"
                *ngIf="findApplicationForm.get('searchBy')?.value !== ''"
                >{{
                  findApplicationForm.get('searchBy')?.value === 'APP_NO'
                    ? ('This should be 19 characters' | translate)
                    : ('This should be 12 characters' | translate)
                }}</small
              >
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-primary col-12 col-lg-auto col-md-auto text-center text-lg-start text-md-start"
            [disabled]="findApplicationForm.invalid"
            (click)="findApplication()">
            <div
              *ngIf="isApplicationLoading"
              class="spinner-border spinner-border-sm text-light text-center mt-1 me-1"
              role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            {{ 'Search' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <button
    *ngIf="foundApplication"
    class="d-block d-sm-none btn btn-link btn-back-to-search"
    (click)="showSearch()">
    <i class="fa fa-chevron-left me-2"></i> {{ 'Back to search' | translate }}
  </button>
  <div
    *ngIf="foundApplication || applicationNotFound"
    class="d-flex flex-column justify-content-center align-items-center col-12 col-lg-auto results"
    #applicationResults>
    <div
      class="application text-start col-12 application-result"
      *ngIf="foundApplication && !viewMoreDetails">
      <div class="row">
        <div class="col-12">
          <div class="header">
            <h1 class="title">{{ 'Application details' | translate }}</h1>
          </div>
        </div>
        <div class="col-12">
          <div class="content">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="application-item">
                  <span class="name">{{
                    'Application number' | translate
                  }}</span>
                  <p class="value">
                    {{ foundApplication.applicationNumber }}
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="application-item mb-2">
                  <span class="name">{{ 'Status' | translate }}</span>
                  <div
                    class="irembo-badge mb-3 text-uppercase"
                    [ngClass]="
                      getApplicationStatusToColor(
                        foundApplication.applicationStatus
                      )
                    ">
                    <i class="fa fa-circle" aria-hidden="true"></i>
                    <span>
                      {{
                        changeApplicationStatusToLabel(
                          foundApplication.applicationStatus
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="application-item">
                  <span class="name">{{ 'Service name' | translate }}</span>
                  <p class="value">
                    {{ foundApplication.serviceName | translate }}
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="application-item">
                  <span class="name">{{ 'Service fee' | translate }}</span>
                  <p class="value">
                    {{ foundApplication.currency }}
                    {{
                      foundApplication.price === 0
                        ? 'FREE'
                        : foundApplication.price
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="application-item">
                  <span class="name">{{ 'Submission date' | translate }}</span>
                  <p class="value">{{ foundApplication.dateCreated }}</p>
                </div>
              </div>
              <div class="col-md-6 col-sm-12"></div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="footer">
            <button
              (click)="handleViewMoreDetails(token)"
              class="btn btn-primary text-center w-100">
              <div
                *ngIf="requestingAccess"
                class="spinner-border spinner-border-sm"
                role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              View more
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row not-found" *ngIf="applicationNotFound">
      <div class="col-auto mx-auto text-center">
        <img
          src="../../../assets/images/irembo-search.svg"
          alt=""
          height="48"
          width="48" />
        <div class="mt-3">
          <h5>{{ 'Application not found' | translate }}</h5>
          <p class="mb-3">
            {{
              'We were unable to find the application, please double-check the application number or call our support on 9099'
                | translate
            }}
          </p>
          <button
            class="btn btn-outline-secondary text-dark"
            (click)="clearSearch()">
            {{ 'Clear search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="foundApplication && viewMoreDetails">
    <div class="col">
      <div class="application text-start col-12 mb-4">
        <div class="row">
          <div class="col-12">
            <div class="header">
              <h1 class="title">{{ 'Application details' | translate }}</h1>
            </div>
          </div>
          <div class="col-12">
            <div class="content">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <div class="application-item">
                    <span class="name">{{
                      'Application number' | translate
                    }}</span>
                    <p class="value">
                      {{ foundApplication.applicationNumber }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="application-item mb-2">
                    <span class="name">{{ 'Status' | translate }}</span>
                    <div
                      class="irembo-badge mb-3 text-uppercase"
                      [ngClass]="
                        getApplicationStatusToColor(
                          foundApplication.applicationStatus
                        )
                      ">
                      <i class="fa fa-circle" aria-hidden="true"></i>
                      <span>
                        {{
                          changeApplicationStatusToLabel(
                            foundApplication.applicationStatus
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <div class="application-item">
                    <span class="name">{{ 'Service name' | translate }}</span>
                    <p class="value">
                      {{ foundApplication.serviceName | translate }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="application-item">
                    <span class="name">{{ 'Service fee' | translate }}</span>
                    <p class="value">
                      {{ foundApplication.currency }}
                      {{
                        foundApplication.price === 0
                          ? 'FREE'
                          : foundApplication.price
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <div class="application-item">
                    <span class="name">{{
                      'Submission date' | translate
                    }}</span>
                    <p class="value">{{ foundApplication.dateCreated }}</p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12"></div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div
              class="footer"
              *ngIf="
                (applicationDetails &&
                  applicationDetails['priceType'] !== 'FREE' &&
                  applicationDetails['applicationStatus'] !==
                    'PAYMENT_PENDING') ||
                (applicationDetails &&
                  applicationDetails['applicationCertificate'])
              ">
              <button
                (click)="downloadReciept()"
                [disabled]="downloadingReciept"
                *ngIf="
                  applicationDetails &&
                  applicationDetails['priceType'] !== 'FREE' &&
                  applicationDetails['applicationStatus'] !== 'PAYMENT_PENDING'
                "
                class="btn btn-primary text-center w-100 btn-primary-alt">
                <div
                  class="spinner-border spinner-border-sm"
                  role="status"
                  *ngIf="downloadingReciept">
                  <span class="visually-hidden"
                    >{{ 'Loading' | translate }}...</span
                  >
                </div>
                {{ 'Download receipt' | translate }}
              </button>
              <button
                [disabled]="downloadingCert"
                (click)="opendownloadCertificateModal(locale)"
                *ngIf="
                  applicationDetails &&
                  applicationDetails['applicationCertificate']
                "
                class="btn btn-primary text-center w-100">
                {{ 'Download certificate' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <ng-container
        *ngFor="
          let data of applicationSummary;
          let index = index;
          trackBy: trackByFn
        ">
        <div
          class="mb-3 app-details-container"
          [ngClass]="{ 'panel-closed': index !== selectedPanel }">
          <div
            class="details-header d-flex flex-row justify-content-between align-items-center">
            <h4 class="section-title">
              {{ index + 1 }}.
              <span class="camel-case">{{ data.formGroupName }}</span>
            </h4>
            <button class="mb-collapse-btn" (click)="expandPanel(index)">
              <span class="icon-chevron-down"></span>
            </button>
          </div>
          <div class="app-details">
            <irembogov-application-form-preview-card
              [applicationId]="foundApplication.applicationId"
              [data]="data"
              [columnSize]="'col-md-12 mb-3'"
              [applicationBaseUrl]="applicationBaseUrl"
              [applicationPath]="
                applicationPath
              "></irembogov-application-form-preview-card>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #token let-modal>
  <div class="modal-header pb-0">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'Enter the One-Time Password (OTP)' | translate }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body" [ngClass]="{ 'invalid-token': invalidToken }">
    <p class="supporting-text">
      {{
        "We've sent a code to the registered notification channels for this application."
          | translate
      }}
    </p>
    <div
      class="d-flex mb-3"
      *ngIf="
        requestAccessResponse &&
        requestAccessResponse['smsNotificationRecipient']
      ">
      <div class="flex-shrink-0">
        <span class="icon-phone modal-icon"></span>
      </div>
      <div class="flex-grow-1 ms-3 modal-icon-text">
        {{ requestAccessResponse['smsNotificationRecipient'] }}
      </div>
    </div>
    <div
      class="d-flex mb-3"
      *ngIf="
        requestAccessResponse &&
        requestAccessResponse['emailNotificationRecipient']
      ">
      <div class="flex-shrink-0">
        <span class="icon-mail-01 modal-icon"></span>
      </div>
      <div class="flex-grow-1 ms-3 modal-icon-text">
        {{ requestAccessResponse['emailNotificationRecipient'] }}
      </div>
    </div>

    <ng-otp-input
      #ngOtpInputRef
      (onInputChange)="onOtpChange($event, ngOtpInputRef)"
      [config]="{
        length: 5,
        placeholder: '0',
        inputClass: 'formControl',
        containerClass: 'custom-token-input'
      }"></ng-otp-input>
    <p *ngIf="invalidToken" class="invalidTokenMsg mt-2">
      {{
        'The code you entered is not valid. Please request a new code to continue'
          | translate
      }}.
    </p>
    <div class="resend-token mt-3">
      <p *ngIf="(countdown$ | async) !== 0">
        {{ "Didn't receive the code? Resend in" | translate }}
        <span class="count-down">{{ countdownTime }}</span>
      </p>
      <p *ngIf="(countdown$ | async) === 0">
        {{ "Didn't receive the code? " | translate }}
        <button
          type="button"
          [disabled]="resendingToken"
          (click)="resendOTP()"
          class="btn btn-link resend-btn">
          <div
            class="spinner-border spinner-border-sm"
            role="status"
            *ngIf="resendingToken">
            <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
          </div>
          {{ 'Click to resend OTP' | translate }}
        </button>
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn w-100 btn-outline-dark cancel-btn"
      (click)="modal.dismiss('Cancel')">
      {{ 'Cancel' | translate }}
    </button>
    <button
      type="button"
      [disabled]="otp.length < 5"
      (click)="confirm(ngOtpInputRef)"
      class="btn w-100 btn-primary">
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        *ngIf="isConfirming">
        <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
      </div>
      {{ 'Confirm' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #locale let-modal>
  <div class="modal-header pb-3">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'Select certificate language' | translate }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body pb-2">
    <div
      (click)="selectLocale(item.locale)"
      [ngClass]="{ 'locale-selected': selectedCertLocale === item.locale }"
      class="locale d-flex justify-content-between align-items-center gap-2"
      *ngFor="let item of supportedLocales">
      <div class="d-flex gap-2">
        <span class="fi fi-{{ getShortLocale(item.locale) }} fis fir"></span>
        <div class="nativeName">
          {{ item.nativeName }}
        </div>
      </div>

      <label class="custom-radio-container">
        <input
          [value]="item.locale"
          [(ngModel)]="selectedCertLocale"
          type="radio"
          name="locale"
          id="locale" />
        <span class="custom-radio"></span>
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn w-100 btn-outline-dark cancel-btn"
      (click)="modal.dismiss('Cancel')">
      {{ 'Cancel' | translate }}
    </button>
    <button
      type="button"
      [disabled]="!selectedCertLocale || downloadingCert"
      (click)="downloadCertificate(selectedCertLocale)"
      class="btn w-100 btn-primary d-flex gap-2 align-items-center justify-content-center">
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        *ngIf="downloadingCert">
        <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
      </div>
      {{ 'Download' | translate }}
    </button>
  </div>
</ng-template>
