import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  forwardRef,
  OnInit,
  Inject,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { stringify } from 'flatted';
import { Subscription, distinctUntilChanged, merge } from 'rxjs';
import { getValueFromNestedObject } from '../../../utils/utils/nested-object-extractor.util';
import {
  TDoubleIdentityFormControlValue,
  IIdentityFieldValue,
  DOUBLE_ID_DROPDOWN_INPUT_KEY,
  COUNTRY_DROPDOWN_INPUT_KEY,
  IDocumentTypeConfig,
} from '../../models/citizen-document-types.enum';
import { IEnvironment } from '../../models/environment.model';
import { IdInputStatusEnum } from '../../models/irembo-id-input-status.enum';

@Component({
  selector: 'irembogov-double-id-input',
  templateUrl: './irembo-double-id-input.component.html',
  styleUrls: ['./irembo-double-id-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboDoubleIdInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IremboDoubleIdInputComponent,
      multi: true,
    },
  ],
})
export class IremboDoubleIdInputComponent
  implements ControlValueAccessor, Validator, OnInit, OnChanges, OnDestroy
{
  @Output() fetchDataFromApi =
    new EventEmitter<TDoubleIdentityFormControlValue | null>();

  @Input() firstIdentityLabel!: string;
  @Input() firstIdentityPlaceholder!: string;
  @Input() firstIdentityDocumentTypeKey!: string;
  @Input() firstIdentityValueKey!: string;

  @Input() secondIdentityLabel!: string;
  @Input() secondIdentityPlaceholder!: string;
  @Input() secondIdentityDocumentTypeKey!: string;
  @Input() secondIdentityValueKey!: string;
  @Input() allowedDocumentTypes: IDocumentTypeConfig[] = [];
  @Input() statusClass: IdInputStatusEnum | undefined;

  @Input() isRequired!: boolean | undefined;

  firstIdentityPresetCountry: string | undefined = undefined;
  secondIdentityPresetCountry: string | undefined = undefined;

  countryDataSetLoading = false;
  items: Record<string, unknown>[] = [];

  private environment: IEnvironment;

  DOUBLE_ID_DROPDOWN_INPUT_KEY = DOUBLE_ID_DROPDOWN_INPUT_KEY;
  COUNTRY_DROPDOWN_INPUT_KEY = COUNTRY_DROPDOWN_INPUT_KEY;

  invalidInput = false;
  idInputSub: Subscription = new Subscription();

  firstIdentityFormGroup: FormGroup = new FormGroup({});
  secondIdentityFormGroup: FormGroup = new FormGroup({});

  doubleIdControlValue!: TDoubleIdentityFormControlValue | null;

  documentTypes: IDocumentTypeConfig[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    @Inject('environment') environment: IEnvironment
  ) {
    this.environment = environment;
    this.firstIdentityFormGroup = this.formBuilder.group({});
    this.secondIdentityFormGroup = this.formBuilder.group({});
  }

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onChange = (value: unknown) => {};
  public _onTouch = (value: unknown) => {};
  private _onValidationChange = () => {};
  /* eslint-enable */

  ngOnInit() {
    this.firstIdentityFormGroup.addControl(
      this.DOUBLE_ID_DROPDOWN_INPUT_KEY,
      new FormControl(null)
    );
    this.firstIdentityFormGroup.addControl(
      this.COUNTRY_DROPDOWN_INPUT_KEY,
      new FormControl(null, Validators.required)
    );

    this.secondIdentityFormGroup.addControl(
      this.DOUBLE_ID_DROPDOWN_INPUT_KEY,
      new FormControl(null)
    );
    this.secondIdentityFormGroup.addControl(
      this.COUNTRY_DROPDOWN_INPUT_KEY,
      new FormControl(null, Validators.required)
    );

    merge(
      this.firstIdentityFormGroup.valueChanges.pipe(
        distinctUntilChanged((a, b) => stringify(a) === stringify(b))
      ),
      this.secondIdentityFormGroup.valueChanges.pipe(
        distinctUntilChanged((a, b) => stringify(a) === stringify(b))
      )
    ).subscribe(() => {
      this.statusClass = undefined;
      this.checkDoubleIdDataAndTriggerApiFetchIfValid();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['statusClass']) {
      switch (changes['statusClass'].currentValue) {
        case IdInputStatusEnum.DANGER: {
          this.invalidInput = true;
          break;
        }
        case IdInputStatusEnum.SUCCESS: {
          this._onChange(this.doubleIdControlValue);
          this._onTouch(this.doubleIdControlValue);
          this.invalidInput = false;
          break;
        }
        default: {
          this.invalidInput = false;
        }
      }
      this._onValidationChange();
    }

    if (changes?.['allowedDocumentTypes']) {
      this.documentTypes = changes?.['allowedDocumentTypes'].currentValue;
      this.generateAllowedDocumentsTypes();
    }

    this.getDataByGetMethod();
    this.presetDefaultInputValues();
  }

  presetDefaultInputValues(): void {
    if (this.isRequired) {
      this.firstIdentityFormGroup
        ?.get(this.DOUBLE_ID_DROPDOWN_INPUT_KEY)
        ?.addValidators(Validators.required);
      this.secondIdentityFormGroup
        ?.get(this.DOUBLE_ID_DROPDOWN_INPUT_KEY)
        ?.addValidators(Validators.required);
    } else {
      this.firstIdentityFormGroup
        ?.get(this.DOUBLE_ID_DROPDOWN_INPUT_KEY)
        ?.clearValidators();
    }
  }

  getDataByGetMethod() {
    let countryDatasetUrl = '/admin/v1/dataset-items/by-dataset-code/COUNTRY';
    const portalApiGatewayBaseUrl: string | undefined =
      this.environment.apiGatewayBaseUrl;
    if (!portalApiGatewayBaseUrl) {
      throw new Error(
        'useBaseUrl: portal environment ApiGatewayBaseUrl property not found.'
      );
    } else {
      countryDatasetUrl = `${portalApiGatewayBaseUrl}${countryDatasetUrl}`;
    }

    const dataField = 'data';

    this.countryDataSetLoading = true;
    this.idInputSub.add(
      this.http
        .get<Record<string, unknown> | []>(`${countryDatasetUrl}`, {})
        .subscribe({
          next: res => {
            this.items = Array.isArray(res)
              ? res
              : getValueFromNestedObject(res, dataField);
            this.countryDataSetLoading = false;
            this.cd.detectChanges();
          },
          error: () => {
            this.countryDataSetLoading = false;
          },
        })
    );
  }

  setPresetIdentityCountry(
    itemOption: 'first' | 'second',
    country: string | undefined
  ): void {
    const countryValue = this.findPresetCountryValueInItems(country);
    if (itemOption === 'first') {
      this.firstIdentityFormGroup
        .get?.(this.COUNTRY_DROPDOWN_INPUT_KEY)
        ?.setValue?.(countryValue);
      this.firstIdentityPresetCountry = country;
    }
    if (itemOption === 'second') {
      this.secondIdentityFormGroup
        .get?.(this.COUNTRY_DROPDOWN_INPUT_KEY)
        ?.setValue?.(countryValue);
      this.secondIdentityPresetCountry = country;
    }
  }

  private findPresetCountryValueInItems(
    countryString: string | undefined
  ): Record<string, unknown> | null {
    if (!countryString) {
      return null;
    }
    let countryItemValue: Record<string, unknown> | null = null;
    this.items.some((item: Record<string, unknown>) => {
      if (
        (item['value'] as string).toLowerCase() === countryString.toLowerCase()
      ) {
        countryItemValue = item;
        return true;
      }
      return false;
    });
    return countryItemValue;
  }

  getPresetIdentityCountry(itemOption: 'first' | 'second'): string | undefined {
    if (itemOption === 'first') {
      return this.firstIdentityPresetCountry;
    }
    if (itemOption === 'second') {
      return this.secondIdentityPresetCountry;
    }
    return undefined;
  }

  generateAllowedDocumentsTypes() {
    if (!Array.isArray(this.documentTypes)) {
      this.documentTypes = [];
    }
    this.documentTypes.forEach((config: IDocumentTypeConfig) => {
      const validatorFns: ValidatorFn[] = this.getSharedValidatorFunctions(
        config.minLength,
        config.maxLength,
        config.pattern
      );
      this.firstIdentityFormGroup.addControl(
        config.value,
        new FormControl(null, [...validatorFns])
      );
      this.secondIdentityFormGroup.addControl(
        config.value,
        new FormControl(null, [...validatorFns])
      );
    });
  }

  private getSharedValidatorFunctions(
    minLength: number,
    maxLength: number,
    pattern: string
  ): ValidatorFn[] {
    return [
      Validators.required,
      Validators.minLength(minLength),
      Validators.maxLength(maxLength),
      Validators.pattern(pattern),
    ];
  }

  getErrorDisplayMessage(
    control: AbstractControl,
    label: string,
    param?: Record<string, unknown>
  ): string | undefined {
    if (!control.touched) {
      return;
    }
    if (control.hasError('required')) {
      return `${label} is required`;
    }
    if (control.hasError('pattern')) {
      return `Invalid entry for ${label}`;
    }
    if (control.hasError('minlength')) {
      return `Minimum length ${
        param?.['minLength'] ? 'of ' + param?.['minLength'] + ' ' : ''
      }not met for ${label}. `;
    }
    if (control.hasError('maxlength')) {
      return `Maximum length ${
        param?.['maxLength'] ? 'of ' + param?.['maxLength'] + ' ' : ''
      }exceeded for ${label}. `;
    }
    return undefined;
  }

  writeValue(obj: Record<string, unknown>): void {
    const valueForIdentity1: IIdentityFieldValue | undefined = <
      IIdentityFieldValue
    >obj?.[this.firstIdentityValueKey];
    const valueForIdentity2: IIdentityFieldValue | undefined = <
      IIdentityFieldValue
    >obj?.[this.secondIdentityValueKey];

    this.writeValueIntoIdentityFormGroup(
      this.firstIdentityFormGroup,
      valueForIdentity1
    );

    this.writeValueIntoIdentityFormGroup(
      this.secondIdentityFormGroup,
      valueForIdentity2
    );
  }

  private writeValueIntoIdentityFormGroup(
    identityFormGroup: FormGroup,
    value: IIdentityFieldValue | undefined
  ) {
    identityFormGroup
      .get?.(this.DOUBLE_ID_DROPDOWN_INPUT_KEY)
      ?.setValue(value?.idType, { emitEvent: false });

    identityFormGroup
      .get?.(this.COUNTRY_DROPDOWN_INPUT_KEY)
      ?.setValue(value?.idCountry, { emitEvent: false });

    if (value?.idType) {
      identityFormGroup
        .get?.(value.idType)
        ?.setValue(value?.idValue, { emitEvent: false });
    }
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.firstIdentityFormGroup.disable();
      this.secondIdentityFormGroup.disable();
    } else {
      this.firstIdentityFormGroup.enable();
      this.secondIdentityFormGroup.enable();
    }
  }

  validate(formControl: FormControl): ValidationErrors | null {
    const validationErrors: ValidationErrors = {};

    if (this.isRequired && !formControl.value) {
      validationErrors['required'] = true;
    }

    return Object.keys(validationErrors).length ? validationErrors : null;
  }

  private checkDoubleIdDataAndTriggerApiFetchIfValid() {
    const firstIdentityFormGroupValid: boolean =
      this.validateIdDataFromFormGroup(this.firstIdentityValueKey);
    const secondIdentityFormGroupValid: boolean =
      this.validateIdDataFromFormGroup(this.secondIdentityValueKey);

    if (
      (this.doubleIdControlValue &&
        Object.keys(this.doubleIdControlValue).length === 0) ||
      (this.doubleIdControlValue?.[this.firstIdentityValueKey] === undefined &&
        this.doubleIdControlValue?.[this.secondIdentityValueKey] === undefined)
    ) {
      this.doubleIdControlValue = null;
      this.invalidInput = !this.isRequired ? false : this.invalidInput;
    }

    if (firstIdentityFormGroupValid && secondIdentityFormGroupValid) {
      this.fetchDataFromApi.emit(this.doubleIdControlValue);
    }
  }

  private validateIdDataFromFormGroup(fieldValueKey: string): boolean {
    let identityFormGroup: FormGroup | undefined = undefined;
    let idLabel = '';

    switch (fieldValueKey) {
      case this.firstIdentityValueKey: {
        identityFormGroup = this.firstIdentityFormGroup;
        idLabel = this.firstIdentityLabel;
        break;
      }
      case this.secondIdentityValueKey: {
        identityFormGroup = this.secondIdentityFormGroup;
        idLabel = this.secondIdentityLabel;
        break;
      }
      default:
        return false;
    }

    const documentTypeControl: AbstractControl | null = identityFormGroup.get?.(
      this.DOUBLE_ID_DROPDOWN_INPUT_KEY
    );
    const valueControl: AbstractControl | null = documentTypeControl?.value
      ? identityFormGroup.get?.(documentTypeControl.value)
      : null;
    const countryControl: AbstractControl | null = identityFormGroup.get?.(
      this.COUNTRY_DROPDOWN_INPUT_KEY
    );

    let value: IIdentityFieldValue | undefined = undefined;
    if (documentTypeControl?.value) {
      value = {};
      value.idType = documentTypeControl?.value;
      value.idValue = valueControl?.value;
      value.idLabel = idLabel;
      value.idCountry = countryControl?.value;
    }

    if (!value) {
      delete this.doubleIdControlValue?.[fieldValueKey];
    }

    if (!this.doubleIdControlValue) {
      this.doubleIdControlValue = {};
    }

    this.doubleIdControlValue[fieldValueKey] = value;

    return (
      documentTypeControl?.valid &&
      documentTypeControl?.value &&
      valueControl?.valid &&
      valueControl?.value &&
      countryControl?.valid &&
      countryControl?.value
    );
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }

  ngOnDestroy(): void {
    this.idInputSub?.unsubscribe();
  }
}
