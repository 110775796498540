import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { ILocale } from 'src/app/models/locale/locale.model';
import { LocaleService } from 'src/app/services/locale.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'irembogov-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() styling: 'NAVBAR' | 'MOBILE' | 'NONAVBAR' = 'NAVBAR';
  @Output() localeChanged = new EventEmitter<string>();

  activeLocale: string;
  activeLocaleName: string;
  supportedLocales: ILocale[] = [];
  constructor(
    public translate: TranslateService,
    private localeService: LocaleService,
    private toastService: ToastService,
    private keycloakService: KeycloakService
  ) {
    this.activeLocale =
      localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
    this.activeLocaleName =
      localStorage.getItem('localeName') ?? environment.DEFAULT_LOCALE;
    translate.addLangs([this.activeLocale]);
    translate.setDefaultLang(this.activeLocale);
  }

  ngOnInit(): void {
    this.getSupportedLocales();
  }

  changeLocale(locale: ILocale) {
    this.translate.use(locale.locale);
    localStorage.setItem('locale', locale.locale);
    localStorage.setItem('localeName', locale.nativeName);
    this.activeLocale = locale.locale;
    this.activeLocaleName = locale.nativeName;

    this.localeChanged.emit();
  }

  getSupportedLocales() {
    this.localeService.getSupportedLocales().subscribe({
      next: response => {
        this.supportedLocales = response.data.content;
        this.translate.addLangs(this.supportedLocales.map(lang => lang.locale));
        this.getLocaleFromToken();
        if (
          this.supportedLocales.length > 0 &&
          !this.supportedLocales
            .map(lang => lang.locale)
            .includes(environment.DEFAULT_LOCALE)
        ) {
          this.getLocaleFromToken().then((tokenLocale: string) => {
            console.log('tokenLocale', tokenLocale);
            const filteredLocales = this.supportedLocales
              .map(lang => lang.locale)
              .filter(lang => lang.startsWith(tokenLocale));

            this.activeLocale =
              filteredLocales.length === 0
                ? environment.DEFAULT_LOCALE
                : filteredLocales[0];
            localStorage.setItem('locale', this.activeLocale);
            this.translate.setDefaultLang(this.activeLocale);
          });
        }
      },
      error: error => {
        this.toastService.show({
          body: error.error.responseMessage ?? error.error.message,
          type: 'error',
          delay: 5000,
        });
      },
    });
  }

  async getLocaleFromToken(): Promise<string> {
    const accessToken = await this.keycloakService.getToken();
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(accessToken);

    if (!decodedToken || decodedToken['locale'] === undefined) {
      return localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
    }

    if (
      this.supportedLocales
        .map(locale => locale.locale)
        .filter(element => element.includes(decodedToken['locale'])).length !==
      0
    ) {
      return this.supportedLocales
        .map(locale => locale.locale)
        .filter(element => element.includes(decodedToken['locale']))[0];
    }

    return localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
  }

  getStrippedLocale(locale: string): string {
    return locale === 'en' || locale === 'en-US'
      ? 'GB'
      : locale.indexOf('sw') >= 0
      ? 'TZ'
      : locale.includes('-')
      ? locale.split('-')[1]
      : locale;
  }
}
