<div class="row">
  <ng-container *ngFor="let group of data?.formGroup; trackBy: trackByFn">
    <div [class]="columnSize" *ngIf="isNotEmpty(group)">
      <irembogov-application-generic-form-field-card
        [group]="group"
        [applicationId]="applicationId"
        [baseUrl]="applicationBaseUrl"
        [applicationPath]="
          applicationPath
        "></irembogov-application-generic-form-field-card>
    </div>
  </ng-container>
</div>
