import { Component, ViewEncapsulation } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'irembogov-custom-label-value-repeater',
  templateUrl: './custom-label-value-repeater.component.html',
  styleUrls: ['./custom-label-value-repeater.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomLabelValueRepeaterComponent extends FieldArrayType {}
