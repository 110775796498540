<div class="row col-12">
  <ng-container *ngFor="let service of services">
    <div
      class="col-12 col-lg-4 col-md-6 text-start mt-2"
      role="button"
      (click)="redirectToService(service)">
      <p class="service-name">
        {{ service.serviceName | translate }}
      </p>
    </div>
  </ng-container>
</div>
