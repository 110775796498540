<div
  class="personal-applications"
  *ngIf="!isLoadingCertificates; else loadingState">
  <a
    class="back-button text-decoration-none d-flex flex-row align-items-center"
    [routerLink]="['/profile']">
    <i class="icon-chevron-left me-2"></i> {{ 'Back' | translate }}
  </a>

  <div class="divider"></div>

  <irembogov-documents-list
    *ngIf="filteredCertificates.length !== 0; else noDocuments"
    [filteredAttachments]="filteredAttachments"
    [filteredCertificates]="filteredCertificates"
    [categories]="categories"
    [selectedCategory]="selectedCategory"
    [showMobileApplicationList]="showMobileApplicationList"
    [isLoadingDocuments]="isLoadingDocuments"
    [documentMode]="documentMode"
    [languageSelector]="languageSelector"
    [certificate_content]="certificate_content"
    [attachment_content]="attachment_content"
    [certificateNames]="certificateNames"
    [allowedAttachmentPreviewTypes]="allowedAttachmentPreviewTypes"
    (DocumentsListFiltered)="filterDocuments()"
    (LanguageSelectorModalOpened)="
      openLanguageSelectorModal(
        languageSelector,
        $event.certificate,
        $event.documentMode,
        certificate_content
      )
    "
    (PreviewAttachment)="previewAttachment(attachment_content, $event)"
    (DownloadAttachment)="
      downloadAttachment($event)
    "></irembogov-documents-list>
</div>

<ng-template #loadingState>
  <div
    class="d-flex flex-column justify-content-center align-items-center w-100 vh-100">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #noDocuments>
  <div
    class="no-applications d-flex flex-column justify-content-center align-items-center w-100">
    <div class="icons-container">
      <i class="icon-file-06"></i>
    </div>
    <div class="title">{{ 'Your documents list is empty!' | translate }}</div>
    <div class="description col-10 col-lg-4 col-md-4">
      {{
        'Once you submit an application and it progresses, any documents issued by the service provider will be displayed here.'
          | translate
      }}
    </div>
  </div>
</ng-template>
<ng-template #languageSelector let-modal>
  <div class="modal-header d-flex">
    <div class="d-flex flex-column">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'Select certificate language' | translate }}
      </h4>
    </div>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div
    class="modal-body"
    *ngIf="
      selectedCertificate &&
      certificateNames[selectedCertificate.applicationNumber]
    ">
    <button
      class="d-flex justify-content-between align-items-center locale w-100"
      (click)="setSelectedLanguage(item['locale'])"
      type="button"
      [class.selected]="item['locale'] === selectedLanguage"
      *ngFor="
        let item of certificateNames[selectedCertificate.applicationNumber];
        index as i
      ">
      <div class="d-flex flex-row gap-2">
        <div class="locale-flag">{{ item['localeFlag'] }}</div>
        <div class="locale-name">{{ item['localeName'] }}</div>
      </div>
      <input
        type="radio"
        name="language"
        [id]="item['localeName']"
        [value]="item['locale']"
        [(ngModel)]="selectedLanguage" />
    </button>
    <div
      class="modal-footer mt-3 d-flex flex-column flex-lg-row-reverse gap-lg-2">
      <button
        *ngIf="languageMode === documentMode.download"
        type="submit"
        class="btn btn-primary fw-semibold col-12 col-lg-5 flex-lg-fill"
        (click)="
          downloadCertificate(selectedCertificate, selectedLanguage || '')
        "
        [disabled]="!selectedLanguage || isDownloadingCertificate">
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          *ngIf="isDownloadingCertificate">
          <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
        </div>
        {{ 'Download' | translate }}
      </button>
      <button
        *ngIf="languageMode === documentMode.preview"
        type="submit"
        class="btn btn-primary fw-semibold col-12 col-lg-5 flex-lg-fill"
        (click)="modal.close(selectedLanguage)"
        [disabled]="!selectedLanguage">
        {{ 'Preview' | translate }}
      </button>
      <button
        type="button"
        class="btn cancel-btn col-12 col-lg-5 flex-lg-fill"
        (click)="selectedLanguage = null">
        {{ 'Cancel' | translate }}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #attachment_content let-modal>
  <div class="modal-body certificate-preview">
    <div class="modal-header-preview">
      <p class="fw-normal preview-header">
        <i class="fa fa-file-text"></i>
        {{ selectedAttachment?.serviceName }} -
        {{ selectedAttachment?.applicationNumber }}
      </p>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div
      *ngIf="isPreviewingCertificate"
      class="d-flex justify-content-center mt-5">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="
        downloadedAttachment?.contentType === 'application/pdf' && base64Data;
        else imagePreview
      ">
      <pdf-viewer
        [src]="base64Data"
        [render-text]="true"
        [original-size]="true"
        style="width: 100%; height: 80vh; margin-top: 15px"></pdf-viewer>
    </ng-container>
  </div>
</ng-template>

<ng-template #imagePreview>
  <img
    *ngIf="filePath"
    [src]="filePath"
    alt="Image preview"
    class="w-100 h-100 mt-3" />
</ng-template>

<!-- Certficate preview -->
<ng-template #certificate_content let-modal>
  <div class="modal-body certificate-preview">
    <div class="modal-header-preview">
      <p class="tt_norms500 preview-header">
        <i class="fa fa-file-text"></i> {{ selectedCertificate?.serviceName }} -
        {{ selectedCertificate?.applicationNumber }}
      </p>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div
      *ngIf="isPreviewingCertificate"
      class="d-flex justify-content-center mt-5">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="
        downloadedCertificate?.contentType === 'application/pdf' && base64Data;
        else imagePreview
      ">
      <pdf-viewer
        [src]="base64Data"
        [render-text]="true"
        [original-size]="true"
        style="width: 100%; height: 80vh; margin-top: 15px"></pdf-viewer>
    </ng-container>
  </div>
</ng-template>
