import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'irembogov-inline-toggle',
  templateUrl: './inline-toggle.component.html',
  styleUrls: ['./inline-toggle.component.scss'],
})
export class InlineToggleComponent {
  @Input() activeTab = 'personal';
  @Output() tabChanged = new EventEmitter<string>();

  setTab(tab: string) {
    this.activeTab = tab;
    this.tabChanged.emit(this.activeTab);
  }
}
