import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormlyUtilsService } from '../../../services/formly/formly-utils.service';

@Component({
  selector: 'irembogov-custom-phone-number',
  templateUrl: './custom-phone-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPhoneNumberComponent extends FieldType<FieldTypeConfig> {
  constructor(private formlyUtilsService: FormlyUtilsService) {
    super();
  }

  intelInputInitialized(status: 'success' | 'failed'): void {
    const initializedStatus = status === 'success' ? true : false;
    this.formlyUtilsService.onPhoneNumberFieldInitialized({
      key: <string>this.field.key,
      success: initializedStatus,
    });
  }
}
