import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'irembogov-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit, OnDestroy {
  constructor(private router: Router) {}

  title = '';
  body = '';

  ngOnInit(): void {
    const page = window.localStorage.getItem('flow');
    const regType = window.localStorage.getItem('regType');

    if (page === 'registration') {
      this.title =
        regType === 'email' ? 'Email verified!' : 'Phone number verified!';
      this.body =
        (regType === 'email' ? 'Your email' : 'Your phone number') +
        ' has been verified. Click below to login';
    }
    if (page === 'reset') {
      this.title = 'Password reset';
      this.body = `Your password has been successfully reset. Click below to login.`;
    }
  }

  ngOnDestroy() {
    window.localStorage.setItem('page', '');
    window.localStorage.setItem('regType', '');
  }

  goToDashboard() {
    this.router.navigate(['/profile']);
  }
}
