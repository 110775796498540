import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'irembogov-activation-account-activated',
  templateUrl: './activation-account-activated.component.html',
  styleUrls: ['./activation-account-activated.component.scss'],
})
export class ActivationAccountActivatedComponent {
  @Input() title = 'Account already activated';
  @Input() description =
    'The activation link you are trying to access is from an account that is already activated.<br /> Click the button below to login';
  @Input() buttonActionLabel = 'Continue to login';
  @Input() showLogo = true;
  @Output() goToLogin = new EventEmitter();
}
