<ng-container *ngIf="accountStatus === ''">
  <div class="ontainer d-flex align-items-center justify-content-center vh-100">
    <div class="spinner-grow text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-container>

<ng-template [ngIf]="accountStatus === 'failure'">
  <irembogov-activation-link-expired
    [title]="failureTitle | translate"
    [description]="failureMessage | translate"
    [showResendLink]="false"
    [showNavigation]="true"
    [navigationText]="'Return to log in' | translate"
    [showImage]="false"
    (goBack)="onGoToLogin()"
    (resendLinkClick)="resendLinkClicked()"></irembogov-activation-link-expired>
</ng-template>

<ng-template [ngIf]="accountStatus === 'resend'">
  <irembogov-resend-auth-otp
    (OnCancelClicked)="onGoToLogin()"
    (OnGoToActionClicked)="goToOtp()"
    (OnResendToEmailClicked)="resendToEmailClicked($event)"
    (OnResendToPhoneClicked)="resendToPhoneClicked($event)"
    [isLoading]="isLoading"></irembogov-resend-auth-otp>
</ng-template>

<ng-template [ngIf]="accountStatus === 'success'">
  <irembogov-token-sent
    (goBack)="onGoToLogin()"
    (resend)="resendLinkClicked()"
    [recipient]="recipient"
    [title]="successTitle | translate"
    [noReceiveQuestion]="successQuestion | translate">
  </irembogov-token-sent>
</ng-template>
