import { Injectable } from '@angular/core';
import { TDateFormatTypes } from '../../models/date-picker-date-format.types';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class CustomDateParserFormatterService extends NgbDateParserFormatter {
  parserDelimeter = '/';
  parserDateFormat: TDateFormatTypes = 'DD MM YYYY';

  setDelimeter(delimeter: string | undefined): void {
    if (!delimeter) return;
    this.parserDelimeter = delimeter;
  }

  setDateFormatType(dateFormatType: TDateFormatTypes | undefined): void {
    if (!dateFormatType) return;
    this.parserDateFormat = dateFormatType;
  }

  parse(value: string): NgbDateStruct | null {
    if (!value) return null;
    const date = value.split(this.parserDelimeter);

    if (this.parserDateFormat === 'MM DD YYYY') {
      return {
        day: parseInt(date[1], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10),
      };
    }

    return {
      day: parseInt(date[0], 10),
      month: parseInt(date[1], 10),
      year: parseInt(date[2], 10),
    };
  }

  format(date: NgbDateStruct | null): string {
    if (this.parserDateFormat === 'MM DD YYYY') {
      return date
        ? date.month.toString().padStart(2, '0') +
            this.parserDelimeter +
            date.day.toString().padStart(2, '0') +
            this.parserDelimeter +
            date.year
        : '';
    }
    return date
      ? date.day.toString().padStart(2, '0') +
          this.parserDelimeter +
          date.month.toString().padStart(2, '0') +
          this.parserDelimeter +
          date.year
      : '';
  }
}
