<ng-select
  appendTo="body"
  [items]="timeSlots"
  [virtualScroll]="true"
  [placeholder]="placeholder"
  [searchable]="false"
  [formControl]="customFormControl"
  class="custom irembo-time-picker"
  [labelForId]="labelForId"
  bindLabel="label"
  bindValue="value"
  (change)="change($event)">
</ng-select>
