<div class="row section-content" style="line-height: 28px">
  <div class="col">
    <p class="text-color" style="font-size: 18px">
      {{
        'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Platform and tells You about Your privacy rights and how the law protects You.'
          | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'We use Your Personal data to provide You with the Service You apply for and/or those applied on your behalf. By using the Platform, You agree to the collection and use of information in accordance with this Privacy Policy.'
          | translate
      }}
    </p>
    <h3 style="padding: 32px 0 16px">
      {{ 'Interpretation and Definitions' | translate }}
    </h3>
    <h4 style="padding: 0 0 16px">
      {{ 'Interpretation' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.'
          | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">
      {{ 'Definitions' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{ 'For the purposes of this Privacy Policy' | translate }}:
    </p>
    <ul class="text-color" style="font-size: 18px">
      <li
        [innerHTML]="
          '<b>Account</b> means a unique account created for You to access our Service or parts of our Service.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Company</b> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot;, or &quot;Our&quot; in this Agreement) refers to Irembo Ltd, Irembo Campus KG 9 Ave, Nyarutarama, Kigali, Rwanda.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Cookies</b> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.'
            | translate
        "></li>
      <li [innerHTML]="'<b>Country</b> refers to Rwanda.' | translate"></li>
      <li
        [innerHTML]="
          '<b>Device</b> means any device that can access the Platform and Services, such as a computer, a cellphone or a digital tablet.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Personal Data</b> is any information that relates to an identified or identifiable individual.'
            | translate
        "></li>
      <li
        [innerHTML]="'<b>Platform</b> refers to the Website.' | translate"></li>
      <li
        [innerHTML]="
          '<b>Service</b> means the Company services available on the Website.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Service Provider</b> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service, or to assist the Company in analysing how the Service is used.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Usage Data</b> refers to data collected automatically, either generated by the use of the Platform or from the Platform infrastructure itself (for example, the duration of a page visit).'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Website</b> refers to IremboGov, accessible from <a href=&quot;https://irembo.gov.rw&quot;>https://irembo.gov.rw</a>'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>You</b> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Agent</b> refers to an authorized person who has the right to act on behalf of someone else.'
            | translate
        "></li>
    </ul>
    <h3 style="padding: 32px 0 16px">
      {{ 'Collecting and Using Your Personal Data' | translate }}
    </h3>
    <h4 style="padding: 0 0 16px">
      {{ 'Types of Data Collected' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'Irembo collects personal data and sensitive personal data. This data may include, but is not limited to:'
          | translate
      }}
    </p>
    <ul class="text-color" style="font-size: 18px">
      <li>{{ 'Email address' | translate }}</li>
      <li>{{ 'Other names and Name' | translate }}</li>
      <li>{{ 'Phone number' | translate }}</li>
      <li>{{ 'National Identity Numbers' | translate }}</li>
      <li>{{ 'Passport Numbers' | translate }}</li>
      <li>{{ 'Address, State, Province, City' | translate }}</li>
      <li>{{ 'Usage Data' | translate }}</li>
    </ul>
    <h4 style="padding: 32px 0 16px">
      {{ 'How do we collect your data?' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'You directly provide Irembo with most of the data through online forms. We collect data and process data when you:'
          | translate
      }}
    </p>
    <ul class="text-color" style="font-size: 18px">
      <li>{{ 'Create an account.' | translate }}</li>
      <li>{{ 'When you apply for a Service.' | translate }}</li>
      <li>
        {{ 'When you contact our customer support.' | translate }}
      </li>
      <li>
        {{
          'We also collect your data indirectly from other Government bodies only when one component of the data is provided.'
            | translate
        }}
      </li>
    </ul>
    <h4 style="padding: 32px 0 16px">
      {{ 'Why do we collect your data?' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'The Company may use Personal Data for the following purposes:'
          | translate
      }}
    </p>
    <ul class="text-color" style="font-size: 18px">
      <li
        [innerHTML]="
          '<b>To provide and maintain our service</b>, including monitoring the usage of our Service.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>To manage your account:</b> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>For the performance of a contract:</b> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>To contact you:</b> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application\'s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>To manage your requests:</b> To attend to and manage Your requests to Us.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>For other purposes:</b> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and evaluating and improving our Platform, products, services, marketing and your experience.'
            | translate
        "></li>
    </ul>
    <p class="text-color" style="font-size: 18px">
      {{
        'We may share Your personal information in the following situations:'
          | translate
      }}
    </p>
    <ul class="text-color" style="font-size: 18px">
      <li
        [innerHTML]="
          '<b>With service providers:</b> We may share Your personal information with Service Providers to monitor and analyse the use of our Service, to contact You.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>With affiliates:</b> We may share Your information with Our affiliates, in which case we will require those affiliates to honour this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>With business partners:</b> We may share Your information with Our business partners to offer You certain products, services or promotions.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>With other users:</b> when You share personal information or otherwise interact in public areas with other users, such information may be viewed by all users and may be publicly distributed outside.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>With your consent:</b> We may disclose Your personal information for any other purpose with Your consent.'
            | translate
        "></li>
    </ul>
    <h4 style="padding: 32px 0 16px">
      {{ 'Retention of Your Personal Data' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.'
          | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.'
          | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">
      {{ 'Transfer of Your Personal Data' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        "Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction."
          | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.'
          | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.'
          | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">
      {{ 'Cookies' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information when using Our Platform.'
          | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'When you visit Our Platform, we may collect information from you automatically through cookies such as Google analytics, Mixpanel and Fresh Desk to:'
          | translate
      }}
    </p>
    <ul class="text-color" style="font-size: 18px">
      <li>
        {{
          'Give us an understanding of how your interact with our platform.'
            | translate
        }}
      </li>
      <li>
        {{
          'Give us a visibility on Which country Our Platform Users are based.'
            | translate
        }}
      </li>
      <li>{{ 'Fetch Our Platform uptime status.' | translate }}</li>
      <li>
        {{ 'Activate a chat session for feedback collection.' | translate }}
      </li>
    </ul>
    <h3 style="padding: 32px 0 16px">
      {{ 'Disclosure of Your Personal Data' | translate }}
    </h3>
    <h4 style="padding: 0 0 16px">
      {{ 'Law enforcement' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).'
          | translate
      }}
    </p>
    <h4 style="padding: 0 0 16px">
      {{ 'Other legal requirements' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'The Company may disclose Your Personal Data in the good faith that such action is necessary to:'
          | translate
      }}
    </p>
    <ul class="text-color" style="font-size: 18px">
      <li>{{ 'Comply with a legal obligation.' | translate }}</li>
      <li>
        {{
          'Protect and defend the rights or property of the Company.'
            | translate
        }}
      </li>
      <li>
        {{
          'Prevent or investigate possible wrongdoing in connection with the Service.'
            | translate
        }}
      </li>
      <li>
        {{
          'Protect the personal safety of Users of the Service or the public.'
            | translate
        }}
      </li>
      <li>{{ 'Protect against legal liability.' | translate }}</li>
    </ul>
    <h3 style="padding: 32px 0 16px">
      {{ 'Security of Your Personal Data' | translate }}
    </h3>
    <p class="text-color" style="font-size: 18px">
      {{
        'The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.'
          | translate
      }}
    </p>
    <h4 style="padding: 0 0 16px">
      {{ 'What are your data protection rights?' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'As written in the law No 058/21021 of 13/10/2021, every user\n is entitled to the following:'
          | translate
      }}
    </p>
    <ol class="text-color" style="font-size: 18px">
      <li
        [innerHTML]="
          '<b>The right to personal data:</b> The data belongs to You.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Right to object:</b> You have the right to request us to stop processing your personal data.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Right to personal data portability:</b> You have the right to request Us to send Your personal data to a different organization where technically feasible or directly to you.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Right not to be subject to a decision based on automated data processing:</b> You have the right not to be subject to a decision based solely on automated personal data processing under certain conditions.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Right to restriction of processing of personal data:</b> You have the right to restrict the data controller from processing your personal data under certain conditions. You can exercise this right by not submitting an application or by not making payment.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Right to erasure of personal data:</b> You have the right to request us to erase your data under certain conditions.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Right to rectification:</b> You have the right to complete incomplete data and to rectify information that may be inaccurate, under certain conditions.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Right to designate an heir to personal data:</b> You have the right to select an heir, under certain conditions.'
            | translate
        "></li>
      <li
        [innerHTML]="
          '<b>Right to representation:</b> You have the right to be represented, under certain conditions. Agents must comply to the following while representing You:'
            | translate
        "></li>
      <ol type="a">
        <li
          [innerHTML]="
            '<b>Account Login:</b> Agents should not ask for You for your Irembo account credentials (username, password). The agent should use their own account to apply for the user.'
              | translate
          "></li>
        <li
          [innerHTML]="
            '<b>Consent:</b> You should be informed and voluntarily consent before Your information is collected, processed, or shared. Agents must also enter Your phone number and email so the You can track their application. Agents must agree and comply to this note before applying on your behalf.'
              | translate
          "></li>
        <li
          [innerHTML]="
            '<b>Purpose Limitation:</b> Agents should only use the information provided for the specific application for which it was requested, to prevent the data from being used for other purposes.'
              | translate
          "></li>
        <li
          [innerHTML]="
            '<b>Data Minimization:</b> Agents should collect only the information needed for the application and only inquire about information that is relevant to the process.'
              | translate
          "></li>
        <li
          [innerHTML]="
            '<b>Transparency:</b> Users have the right to know how their information is collected, processed, and used. Agents should notify and display the information entered by the user before applying. Agents will ensure that they have a notice displayed in the shop informing You about this before hand.'
              | translate
          "></li>
        <li
          [innerHTML]="
            '<b>Data Sharing:</b> Agents should only share needed information during the escalation process with the appropriate channel (Irembo support team or Territory Coordinators). Agents are reminded that Your information is confidential.'
              | translate
          "></li>
        <li
          [innerHTML]="
            '<b>Information Collection and Deletion:</b> Agents should delete the information and the scanned documents from their computers and phones, immediately after using them in an application.'
              | translate
          "></li>
        <li
          [innerHTML]="
            '<b>Payment:</b> When You decide to pay for Irembo services using the various payment methods, Agents should not request Your sensitive information which includes but is not limited to Card information, and mobile money passwords.'
              | translate
          "></li>
      </ol>
    </ol>
    <p class="text-color" style="font-size: 18px">
      {{
        "If you would like to exercise any of these rights, please reach out to Us at our email, dpo@irembo.com, and we'll respond in not more than 30 days."
          | translate
      }}
    </p>
    <h3 style="padding: 32px 0 16px">{{ "Children's Privacy" | translate }}</h3>
    <p class="text-color" style="font-size: 18px">
      {{
        'To provide services to a child under the age of sixteen (16) years, consent from anyone who has parental or legal guardians responsibilities over the child in accordance to with the relevant Law is needed.'
          | translate
      }}
    </p>
    <h3 style="padding: 32px 0 16px">
      {{ 'Links to Other Websites' | translate }}
    </h3>
    <p class="text-color" style="font-size: 18px">
      {{
        "Our Platform may contain links to other websites that we do not operate. If You click on a third-party link, You will be directed to that third-party's site. We strongly advise You to review the Privacy Policy of every site You visit."
          | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        "We have no control over and assume no responsibility for any third-party sites or services' content, privacy policies, or"
          | translate
      }}
    </p>
    <h3 style="padding: 32px 0 16px">
      {{ 'Changes to this Privacy Policy' | translate }}
    </h3>
    <p class="text-color" style="font-size: 18px">
      {{
        'We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.'
          | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'We will let You know via email and/or a prominent notice on Our Platform, prior to the change becoming effective, and update the "Last updated" date at the top of this Privacy Policy.'
          | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.'
          | translate
      }}
    </p>
    <h3 style="padding: 32px 0 16px">
      {{ 'Contact Us' | translate }}
    </h3>
    <p class="text-color" style="font-size: 18px; margin: 0">
      {{
        'If you have any questions about this Privacy Policy, You can contact us at:'
          | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px; margin: 0">
      Irembo Ltd. Kigali, Gasabo.
    </p>
    <p class="text-color" style="font-size: 18px; margin: 0">
      {{ 'Email:' | translate }}&nbsp;
      <a href="mailto:support@irembo.com">support@irembo.com</a>
    </p>
    <p class="text-color" style="font-size: 18px; margin: 0">
      {{ 'Call Center Hotline:' | translate }}&nbsp; 9099
    </p>
    <p class="text-color" style="font-size: 18px; margin: 0">
      {{
        'Monday through Friday from 06:00 a.m. to 01:00 p.m., Central Africa Time.'
          | translate
      }}
    </p>
    <p
      class="text-color"
      style="font-size: 18px; margin: 0; padding-bottom: 8rem">
      {{
        'Saturdays and Sundays from 09:00 a.m. to 05:00 p.m, Central Africa Time.'
          | translate
      }}
    </p>
  </div>
</div>
