import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { IFeatureService } from '../../models/featured-services-list.model';
import { IService } from '../../models/services-list.model';
import { PublicServicesService } from '../../services/public-services.service';

import { map } from 'rxjs';

@Component({
  selector: 'irembogov-service-not-found',
  templateUrl: './service-not-found.component.html',
  styleUrls: ['./service-not-found.component.scss'],
})
export class ServiceNotFoundComponent implements OnInit {
  @ViewChild('search') searchEl: ElementRef | undefined;

  userProfile: KeycloakProfile | undefined;
  isLoggedIn = false;

  featuredServicesList: IFeatureService[] = [];
  selectedApplication!: IService;
  modalID!: ElementRef;
  keyword = '';

  constructor(
    private keycloak: KeycloakService,
    private modalService: NgbModal,
    private router: Router,
    private publicServicesService: PublicServicesService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    this.route.queryParams.subscribe(res => {
      this.keyword = res['keyword'];
    });

    if (this.isLoggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();
    }

    this.publicServicesService
      .getFeaturedServices()
      .pipe(map(res => res.data.content))
      .subscribe({
        next: res => {
          this.featuredServicesList = res;
        },
      });
  }

  openModal(modal: ElementRef, selectedService: IService) {
    this.selectedApplication = selectedService;
    this.modalService.open(modal, {
      size: 'lg',
      centered: true,
      animation: true,
    });
  }

  handleOnClicked(selectedItem: IService) {
    const navigationExtras: NavigationExtras = {
      queryParams: { keyword: selectedItem.serviceName, id: selectedItem.id },
    };
    this.router.navigate(['/service/details'], navigationExtras);
  }
  handleOnEnterHit(selectedItem: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: { keyword: selectedItem },
    };
    this.router.navigate(['/advance-search'], navigationExtras);
  }
}
