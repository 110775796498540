import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Subscription, distinctUntilChanged, filter } from 'rxjs';
import { stringify } from 'flatted';

@Component({
  selector: 'irembogov-hide-field-wrapper',
  templateUrl: './hide-field-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HideFieldWrapperComponent
  extends FieldWrapper
  implements AfterViewInit, OnDestroy
{
  private fieldChangesSub: Subscription | undefined;

  ngAfterViewInit(): void {
    if (this.field.props?.['hideField']) {
      this.field.className = 'irembo-formly-hidden-field';
    }

    this.fieldChangesSub = this.field.options?.fieldChanges
      ?.pipe(
        filter(e => {
          return (
            e.type === 'expressionChanges' &&
            e['property'] === 'props.hideField' &&
            e.field.key === this.field.key
          );
        }),
        distinctUntilChanged((a, b) => stringify(a) === stringify(b))
      )
      .subscribe({
        next: () => {
          let cssClasses = '';
          const classesArr: string[] = [];

          if (this.field.className) {
            this.field.className
              .split(' ')
              .forEach(ele => classesArr.push(ele));
          }

          if (this.field.props?.['hideField']) {
            classesArr?.push('irembo-formly-hidden-field');
            cssClasses = classesArr?.join(' ');
          } else {
            cssClasses = classesArr
              ?.filter(x => x !== 'irembo-formly-hidden-field')
              .join(' ');
          }
          this.field.className = cssClasses;
        },
      });
  }

  ngOnDestroy(): void {
    this.fieldChangesSub?.unsubscribe();
  }
}
