import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomDropDownComponent } from '../custom-drop-down/custom-drop-down.component';

@Component({
  selector: 'irembogov-custom-dropdown-paginated',
  templateUrl: './custom-dropdown-paginated.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDropdownPaginatedComponent extends CustomDropDownComponent {
  getMoreDataMethod(event: boolean) {
    if (!event) return;
    const { dataset } = this.field.props;
    const httpMethod = dataset['httpMethod'];
    const url = this.field.props['useBaseUrl']
      ? this.updateUrlWithApiGatewayBaseUrl(dataset['url'])
      : dataset['url'];
    const params = dataset['params'];
    const headers = dataset['headers'];
    const dataField = dataset['dataField'];
    const currentPage = params.page ?? 0;
    const currentSize = params.size ?? 10;
    if ((currentPage + 1) * currentSize < this.items.length) {
      params.page++;
    }
    params.size = params.size + this.items.length;

    if (httpMethod === 'POST') {
      return this.getDataByPostMethod(url, dataField, params, headers);
    } else {
      return this.getDataByGetMethod(url, dataField, params, headers);
    }
  }

  searchData(value: any) {
    const { dataset } = this.field.props;
    if (!dataset['searching']) return;
    const { bindLabel } = this.field.props;
    const httpMethod = dataset['httpMethod'];
    const url = this.field.props['useBaseUrl']
      ? this.updateUrlWithApiGatewayBaseUrl(dataset['url'])
      : dataset['url'];
    const params = dataset['params'];
    const headers = dataset['headers'];
    const dataField = dataset['dataField'];
    if (dataset['searchField']) {
      params[dataset['searchField']] = value;
    } else {
      params[bindLabel] = value;
    }

    if (httpMethod === 'POST') {
      return this.getDataByPostMethod(url, dataField, params, headers);
    } else {
      return this.getDataByGetMethod(url, dataField, params, headers);
    }
  }
}
