<irembogov-phone-number
  [formControl]="formControl"
  ngDefaultControl
  [id]="field.props['id']"
  [countryNoValidation]="
    field.props['countryNoValidation'] === undefined
      ? true
      : field.props['countryNoValidation']
  "
  [preferredCountries]="field.props['preferredCountries']"
  [allowDropdown]="field.props['allowDropdown']"
  [onlyCountries]="field.props['onlyCountries']"
  [initialCountry]="field.props['initialCountry']"
  [placeholder]="field.props['placeholder'] ? field.props['placeholder'] : ''"
  (intelInputInitialized)="
    intelInputInitialized($event)
  "></irembogov-phone-number>
