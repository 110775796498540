<div
  class="irembo-token-sent d-flex flex-column align-items-center"
  style="min-height: 100%">
  <div
    class="col-md-5 col-sm-12 col-xs-12 d-flex flex-column align-items-center justify-content-center">
    <div *ngIf="showLogo" class="col-12 text-center px-5 logo-container">
      <img class="img logo" src="assets/images/logo.png" alt="irembo logo" />
    </div>
    <div class="col-12 text-center check text-center image-contianer">
      <img
        class="icon-img"
        src="assets/images/irembo_img_set_password.svg"
        alt="" />
    </div>
    <h2 class="mb-2">{{ title }}</h2>

    <!-- Email sent confirmation -->

    <div class="align-self-stretch d-flex flex-column align-items-center mt-2">
      <div class="align-self-center col-md-7 col-sm-12">
        <p class="text-center mb-3">
          {{ recipientMessage }} <br />
          {{ recipient }}
        </p>
        <p class="text-center">
          <small>
            {{ noReceiveQuestion }}
            <a class="resend-a" (click)="resend.emit()">
              {{ resendLinkText }}</a
            >
          </small>
        </p>
      </div>
    </div>

    <div
      *ngIf="showBackToLogin"
      class="align-self-stretch d-flex flex-column align-items-center mt-2">
      <div class="align-self-center col-md-7 col-sm-12 login-button">
        <button
          name="login"
          id="login"
          class="btn btn-block col-12 mt-3"
          type="button"
          (click)="goBack.emit()">
          <i aria-hidden="true" class="fa fa-arrow-left pe-2"></i>
          Back to log in
        </button>
      </div>
    </div>
  </div>
</div>
