import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../models/response.model';
import { apis } from '../app-apis';
import { map, Observable } from 'rxjs';
import { FileDownloadResponseModel } from '../models/file-download-response.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private baseUrl = environment.apiGatewayBaseUrl + '/application';
  private paymentAPIs = apis.payment;

  constructor(private http: HttpClient) {}

  getPaymentReceipt(
    applicationNumber: string
  ): Observable<FileDownloadResponseModel> {
    return this.http
      .get<IResponse<FileDownloadResponseModel>>(
        `${this.baseUrl}${this.paymentAPIs.paymentReceipt}`,
        {
          headers: { applicationNumber },
        }
      )
      .pipe(map(res => res.data));
  }
}
