<div class="legal-header">
  <div class="dated">{{ 'Current as of 01 Mar 2024' | translate }}</div>
  <div class="legal-title">{{ 'Legal information' | translate }}</div>
  <div class="legal-description">
    {{
      'Irembo is committed to your privacy and the security of your data, We have developed a privacy policy & terms of use\n' +
        '    of our services that suit our users.' | translate
    }}
  </div>
</div>
<div class="legal-tabs">
  <div class="tab-nav">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          class="nav-link tt_norms500 active d-flex flex-column flex-sm-row justify-content-center align-items-center"
          [ngClass]="{ active: selectedTab === 'privacy' }"
          id="nav-cat-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-privacy"
          type="button"
          role="tab"
          aria-controls="nav-cat"
          aria-selected="true">
          <span>{{ 'Privacy Policy' | translate }}</span>
        </button>
        <button
          [ngClass]="{ active: selectedTab === 'terms' }"
          class="nav-link tt_norms500 d-flex flex-column flex-sm-row justify-content-center align-items-center"
          id="nav-inst-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-terms"
          type="button"
          role="tab"
          aria-controls="nav-inst"
          aria-selected="false">
          <span>{{ 'Terms of Use' | translate }}</span>
        </button>
        <button
          [ngClass]="{ active: selectedTab === 'disc' }"
          class="nav-link tt_norms500 d-flex flex-column flex-sm-row justify-content-center align-items-center"
          id="nav-doc-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-disc"
          type="button"
          role="tab"
          aria-controls="nav-inst"
          aria-selected="false">
          <span>{{ 'Disclaimer' | translate }}</span>
        </button>
      </div>
    </nav>
  </div>

  <div class="container tab-container">
    <div class="row">
      <div class="col">
        <div class="tab-content" id="nav-tabContent">
          <div
            [ngClass]="{ 'show active': selectedTab === 'privacy' }"
            class="tab-pane fade privacy"
            id="nav-privacy"
            role="tabpanel"
            aria-labelledby="nav-privacy-tab">
            <div class="row section-header">
              <div class="col">
                <h2 class="text-center" style="padding: 64px 0">
                  {{ 'Privacy Policy' | translate }}
                </h2>
              </div>
            </div>
            <irembogov-privacy-policy></irembogov-privacy-policy>
          </div>
          <div
            class="tab-pane fade documents"
            [ngClass]="{ 'show active': selectedTab === 'terms' }"
            id="nav-terms"
            role="tabpanel"
            aria-labelledby="nav-terms-tab">
            <div class="row section-header">
              <div class="col">
                <h2 class="text-center" style="padding: 64px 0">
                  {{ 'Terms of Use' | translate }}
                </h2>
              </div>
            </div>
            <irembogov-terms-use></irembogov-terms-use>
          </div>
          <div
            class="tab-pane fade"
            [ngClass]="{ 'show active': selectedTab === 'disc' }"
            id="nav-disc"
            role="tabpanel"
            aria-labelledby="nav-disc-tab">
            <div class="row section-header">
              <div class="col">
                <h2 class="text-center" style="padding: 64px 0">
                  {{ 'Disclaimer' | translate }}
                </h2>
              </div>
            </div>
            <irembogov-disclaimer></irembogov-disclaimer>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
