import { NgModule } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import {
  MissingTranslationHandler,
  TranslateModule,
} from '@ngx-translate/core';
import { PortalFooterComponent } from './portal-footer/portal-footer.component';
import { RouterLinkWithHref } from '@angular/router';
import {
  IremboMissingTranslationHandler,
  UiModule,
} from '@irembo-andela/irembogov3-common';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { CitizenToastComponent } from './citizen-toast/citizen-toast.component';

@NgModule({
  declarations: [
    PortalFooterComponent,
    LanguageSelectorComponent,
    CitizenToastComponent,
  ],
  imports: [
    CommonModule,
    RouterLinkWithHref,
    TranslateModule.forChild({
      useDefaultLang: true,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: IremboMissingTranslationHandler,
      },
    }),
    UiModule,
    NgbModule,
    NgIf,
  ],
  providers: [
    {
      provide: 'environment',
      useValue: environment,
    },
  ],
  exports: [
    TranslateModule,
    PortalFooterComponent,
    LanguageSelectorComponent,
    CitizenToastComponent,
  ],
})
export class SharedModule {}
