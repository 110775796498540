import { CdkStepper } from '@angular/cdk/stepper';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AbstractControl } from '@angular/forms';
import { VerticalStepperService } from '../../../services/vertical-stepper/vertical-stepper.service';
import { Directionality } from '@angular/cdk/bidi';
import { Router } from '@angular/router';

@Component({
  selector: 'irembogov-custom-vertical-stepper',
  templateUrl: './custom-vertical-stepper.component.html',
  styleUrls: ['./custom-vertical-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: CdkStepper, useExisting: CustomVerticalStepperComponent },
  ],
})
export class CustomVerticalStepperComponent extends CdkStepper {
  @Input() fields: FormlyFieldConfig[] | undefined;
  draftIsSaving = false;

  constructor(
    dir: Directionality,
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
    private verticalStepperService: VerticalStepperService,
    private router: Router
  ) {
    super(dir, changeDetectorRef, elementRef);
    this.verticalStepperService.draftIsSavingObservable$.subscribe({
      next: (toggle: boolean) => {
        this.draftIsSaving = toggle;
      },
    });
  }

  onNext(field: FormlyFieldConfig) {
    if (!this.isValid(field)) {
      return;
    }
    this.next();
    this.verticalStepperService.setSelectedIndex(this.selectedIndex);
  }

  goBack(index: number) {
    if (this.selectedIndex > index) {
      this.previous();
      this.verticalStepperService.setSelectedIndex(this.selectedIndex);
    }
  }

  cancel() {
    this.verticalStepperService.triggerDraftSavingBoolean(false);
    this.router.navigate(['/']);
  }

  isValid(field: FormlyFieldConfig): boolean {
    if (field?.formControl) {
      if (field.formControl.invalid) {
        field.formControl.markAllAsTouched();
      }
      return field.formControl.valid;
    }
    return field.fieldGroup
      ? field.fieldGroup.every(f => this.isValid(f))
      : true;
  }

  isValidForDraft(field: FormlyFieldConfig): boolean {
    let valid = true;
    /* Collecting all form controls on the current section */
    const sectionFormControls: AbstractControl[] = [];
    field.fieldGroup?.forEach(section => {
      section.fieldGroup?.forEach(block => {
        if (block.formControl instanceof AbstractControl) {
          sectionFormControls.push(block.formControl);
        }
      });
    });
    sectionFormControls.forEach(control => {
      if (control.touched && control.invalid) {
        valid = false;
        return;
      }
    });
    return valid && field.model;
  }

  handleDraft(field: FormlyFieldConfig) {
    this.verticalStepperService.triggerDraftSaving(field.model);
    this.verticalStepperService.triggerDraftSavingBoolean(true);
  }
}
