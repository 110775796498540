import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'irembogov-portal-footer',
  templateUrl: './portal-footer.component.html',
  styleUrls: ['./portal-footer.component.scss'],
})
export class PortalFooterComponent {
  env = environment;
  currentYear = new Date().getFullYear();
}
