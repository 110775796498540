export const ERROR_MAP: Record<string, string> = {
  EMAIL_ALREADY_EXIST: 'This account already exists. Please login',
  PHONE_ALREADY_EXIST: 'This account already exists. Please login',
  INVALID_EMAIL_ADDRESS:
    'The email provided is invalid, please provide a correct email to proceed',
  INVALID_PHONE_NUMBER: 'Phone Number must be 10 digits',
  EMAIL_ALREADY_EXIST_DEACTIVATED: 'EMAIL_ALREADY_EXIST_DEACTIVATED',
  PHONE_NUMBER_ALREADY_EXIST_DEACTIVATED:
    'PHONE_NUMBER_ALREADY_EXIST_DEACTIVATED',
  OTP_EXPIRED: 'The provided code is expired',
  ACCOUNT_USER_ALREADY_EXIST_NOT_ACTIVATED:
    'ACCOUNT_USER_ALREADY_EXIST_NOT_ACTIVATED',
  ACCOUNT_NOT_FOUND: 'Account not found',
};
