import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { ICascadingConfig } from '../../models/irembo-cascading-config';

@Component({
  selector: 'irembogov-cascading-drop-downs',
  templateUrl: './irembo-cascading-drop-downs.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./irembo-cascading-drop-downs.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboCascadingDropDownsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IremboCascadingDropDownsComponent,
      multi: true,
    },
  ],
})
export class IremboCascadingDropDownsComponent
  implements ControlValueAccessor, Validator, OnInit, OnChanges
{
  @Input() loading: Record<string, boolean> = {};
  @Input() configs: ICascadingConfig[] = [];
  @Input() items: Record<string, Record<string, unknown>[] | null> | undefined;
  @Input() isTouched = false;
  @Input() linear = false;
  @Output() activeDropDown = new EventEmitter<ICascadingConfig>();

  form: FormGroup | undefined;

  ngOnInit(): void {
    const group: Record<string, FormControl> = {};
    this.configs.forEach(field => {
      group[field.key] = field.required
        ? new FormControl(null, Validators.required)
        : new FormControl(null);
    });
    this.form = new FormGroup(group);
    this.activeDropDown.emit(this.configs[0]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['isTouched'] && this.form?.invalid) {
      this.form.markAllAsTouched();
    }
    if (changes?.['configs']) {
      this.updateValidationRulesForConfigsChange(
        changes?.['configs'].currentValue
      );
    }
  }

  /**
   * Update validation rules in response to changes in the 'configs' input.
   * @param newConfigs - An object containing changes in component configs.
   */
  updateValidationRulesForConfigsChange(newConfigs: ICascadingConfig[]): void {
    newConfigs.forEach(field => {
      if (this.form?.get(field.key)) {
        // Update the FormControl's validation rules based on the 'required' property
        if (field.required) {
          this.form.get(field.key)?.setValidators(Validators.required);
        } else {
          this.form.get(field.key)?.clearValidators();
        }
        // Reset the control's value if needed (e.g., set it to null)
        this.form.get(field.key)?.setValue(null);
      }
    });
    this.form?.updateValueAndValidity();
    this.form?.markAllAsTouched();
  }

  resetDropDowns(_: string, index: number) {
    this.configs.slice(index).forEach(child => {
      this.form?.controls[child.key].setValue(undefined);
    });
  }

  onSelectChange(value: Record<string, unknown>, key: string, index: number) {
    const nextItemIndex = index + 1;
    this.resetDropDowns(key, nextItemIndex);
    this._onChange(this.form?.value);
    this._onTouch(this.form?.value);
    if (this.configs.length - 1 === index || !value) return;
    this.activeDropDown.emit({ ...this.configs[nextItemIndex], value });
  }

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onChange = (value: unknown) => {};
  private _onTouch = (value: unknown) => {};
  /* eslint-enable */

  writeValue(obj: { [key: string]: any }): void {
    if (obj) {
      this.form?.setValue(obj);
    }
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  validate(): ValidationErrors | null {
    return this.form?.invalid ? { invalid: true } : null;
  }
}
