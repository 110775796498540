<div class="container-fluid bg-white min-vh-100">
  <div class="row min-vh-100">
    <!--Login Form-->
    <div class="col-md-6 col-sm-12 position-relative">
      <div
        class="logo d-flex justify-content-between align-items-center position-absolute top-0 left-0 py-3 px-3 z-top w-100 pe-md-4">
        <a href="/" class="link-no-deco">
          <img
            src="../../../../assets/img/logo.png"
            width="109.37"
            height="20"
            alt="IremboGov Logo"
            class="float-start" />
        </a>
        <irembogov-language-selector
          styling="NONAVBAR"
          (localeChanged)="onLocaleChanged()"></irembogov-language-selector>
      </div>
      <div
        class="main-container d-flex flex-column justify-content-center align-content-center align-items-center p-3 position-relative">
        <div
          class="d-flex flex-column justify-content-center text-start create-container">
          <div class="login-details mb-3 text-start">
            <h1>{{ 'Create an account' | translate }}</h1>
          </div>
          <div
            id="loginOptions"
            class="p-1 rounded-3 mb-3 hstack gap-2 text-center">
            <button
              id="togglePhone"
              class="btn option-btn w-50 text-center"
              [class.active]="activeToggle === 'phone'"
              (click)="toggleActive('phone')">
              {{ 'Use phone number' | translate }}
            </button>
            <button
              id="toggleEmail"
              class="btn option-btn w-50 text-center"
              [class.active]="activeToggle === 'email'"
              (click)="toggleActive('email')">
              {{ 'Use email' | translate }}
            </button>
          </div>
          <form
            class="form-group"
            autocomplete="off"
            [formGroup]="registrationForm"
            (ngSubmit)="onFormSubmit()">
            <div *ngIf="activeToggle === 'phone'; else email">
              <div class="form-group mb-3" id="phoneInput">
                <label for="phone_number">{{
                  'Phone number' | translate
                }}</label>
                <input
                  formControlName="phone_number"
                  id="phone_number"
                  class="mt-2 form-control text-input"
                  type="tel"
                  autocomplete="off"
                  placeholder="078 000 000"
                  pattern="[0-9]{10}"
                  [ngClass]="{
                    'is-invalid has-error':
                      (errorMessage && accountAlreadyExist) ||
                      (PhoneNumber?.touched && PhoneNumber?.invalid)
                  }" />
                <small
                  class="text-muted mt-1"
                  *ngIf="
                    !errorMessage &&
                    !(PhoneNumber?.touched && PhoneNumber?.invalid)
                  "
                  >{{
                    '10 digit Rwandan phone number required' | translate
                  }}</small
                >
                <small
                  class="error mt-1"
                  *ngIf="errorMessage && accountAlreadyExist"
                  >{{ errorMessage | translate }}</small
                >
                <small
                  class="error mt-1"
                  *ngIf="PhoneNumber?.touched && PhoneNumber?.invalid"
                  >{{
                    'Must be a 10 digit Rwandan phone number. Please double-check and try again.'
                      | translate
                  }}</small
                >
              </div>
            </div>
            <ng-template #email>
              <div class="form-group mb-3" id="emailInput">
                <label for="email">{{ 'Email' | translate }}</label>
                <input
                  formControlName="email"
                  id="email"
                  class="mt-2 form-control text-input"
                  name="email"
                  type="email"
                  autocomplete="off"
                  aut
                  [placeholder]="'Enter your email address' | translate"
                  [ngClass]="{
                    'is-invalid has-error': errorMessage && accountAlreadyExist
                  }" />
                <small
                  class="error mt-1"
                  *ngIf="errorMessage && accountAlreadyExist"
                  >{{ errorMessage | translate }}</small
                >
              </div>
            </ng-template>

            <div class="form-group mb-3">
              <label for="password">{{ 'Password' | translate }}</label>
              <div class="input-group">
                <input
                  formControlName="password"
                  id="password"
                  class="form-control text-input"
                  type="{{ showPassword ? 'text' : 'password' }}"
                  [ngClass]="{
                    'has-error':
                      Password?.errors && Password?.touched && Password?.invalid
                  }"
                  [placeholder]="'Create a password' | translate" />
                <span
                  class="input-group-text bg-transparent"
                  [ngClass]="{
                    'has-error':
                      Password?.errors && Password?.touched && Password?.invalid
                  }">
                  <i
                    class="fa-regular text-muted"
                    [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                    (click)="togglePassword()"></i>
                </span>
              </div>
              <password-strength-meter
                [password]="
                  registrationForm.get('password')?.value
                "></password-strength-meter>
              <small
                [ngClass]="
                  Password?.errors && Password?.touched && Password?.invalid
                    ? 'error'
                    : 'text-muted'
                "
                >{{
                  'Must be at least 8 characters long and may contain letters and numbers'
                    | translate
                }}</small
              >
            </div>
            <div class="form-group mb-3">
              <label for="confirm_password">{{
                'Confirm password' | translate
              }}</label>
              <div class="input-group">
                <input
                  formControlName="confirm_password"
                  id="confirm_password"
                  class="form-control text-input"
                  type="{{ showConfirmPassword ? 'text' : 'password' }}"
                  [placeholder]="'Re-enter password' | translate"
                  [ngClass]="{
                    'has-error': confirmPassword?.errors
                  }" />
                <span
                  class="input-group-text bg-transparent"
                  [ngClass]="{
                    'has-error': confirmPassword?.errors
                  }">
                  <i
                    class="fa-regular text-muted"
                    [ngClass]="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"
                    (click)="toggleConfirmPassword()"></i>
                </span>
              </div>
              <password-strength-meter
                [password]="
                  registrationForm.get('confirm_password')?.value
                "></password-strength-meter>
              <small class="error mt-1" *ngIf="confirmPassword?.errors">
                {{
                  'Passwords do not match, please double-check and try again'
                    | translate
                }}.
              </small>
            </div>

            <div class="form-check form-check-inline">
              <input
                #agreeToTermsCheckbox
                type="checkbox"
                [checked]="termsAndConditions"
                (change)="onSaveAgreeTermsChanged(agreeToTermsCheckbox.checked)"
                id="terms"
                name="terms"
                value=""
                class="form-check-input" />
              <label
                class="form-check-label"
                for="terms"
                [innerHTML]="
                  'I agree to the IremboGov Terms and Conditions and Privacy Policy'
                    | translate
                ">
              </label>
            </div>
            <div class="mt-3">
              <div class="col-sm-12 controls">
                <button
                  type="submit"
                  class="btn btn-primary col-12 text-white bg-blue rounded-3 py-2"
                  [disabled]="
                    registrationForm.invalid || isLoading || !termsAndConditions
                  ">
                  <div
                    id="login-spinner"
                    *ngIf="isLoading"
                    class="spinner-border spinner-border-sm me-2"></div>
                  {{ 'Continue' | translate }}
                </button>
                <small class="error mt-1" *ngIf="httpError">{{
                  httpError | translate
                }}</small>
                <p class="mt-3">
                  <span class="sign-up-text"
                    >{{ 'Already have an account?' | translate }} </span
                  ><a
                    routerLink="/profile"
                    class="text-decoration-none text-blue fw-semibold"
                    [style.font-size.px]="14"
                    >{{ 'Log in' | translate }}
                  </a>
                </p>
              </div>
            </div>
          </form>
        </div>
        <div
          class="w-100 mt-auto footer position-absolute bottom-0 p-3 d-none d-md-block z-top">
          <div class="float-start">
            {{ '&copy; Irembo ' + year }}
          </div>
          <div class="float-end support">
            <a
              class="link-no-deco"
              [href]="env.knowledgeBaseUrl"
              target="_blank">
              <img
                src="../../../../assets/icons/message-question.svg"
                alt="question" />
              Support Center
            </a>
          </div>
        </div>
      </div>
    </div>

    <!--Login bg-->
    <div class="col-6 login-bg d-none d-md-block"></div>
  </div>
</div>
