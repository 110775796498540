<!-- Header -->
<div class="wrapper">
  <!-- Mobile design -->
  <div class="c-stepper d-lg-none d-md-none">
    <ng-container *ngFor="let step of steps; let i = index">
      <div
        *ngIf="selectedIndex === i"
        (click)="selectedIndex = i"
        class="c-stepper__item"
        [ngClass]="{
          completed__step: step.completed,
          active__step: selectedIndex === i,
          disabled__step: !enableButtonNavigation
        }">
        <div class="d-flex justify-content-center align-items-center">
          <ng-template #showLabelText>
            <h5 class="c-stepper__title">{{ i + 1 }}. {{ step.label }}</h5>
            <div class="done-icon-container" *ngIf="step.completed">
              <i class="done-icon fa fa-check"></i>
              <i class="fa-solid fa-check done-icon"></i>
            </div>
          </ng-template>
          <ng-container
            *ngIf="step.stepLabel; else showLabelText"
            [ngTemplateOutlet]="step.stepLabel.template">
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <!-- End mobile design -->

  <!-- Desktop view -->
  <div class="c-stepper d-none d-lg-flex d-md-flex">
    <ng-container *ngFor="let step of steps; let i = index">
      <div
        (click)="selectedIndex = i"
        class="c-stepper__item"
        [ngClass]="{
          completed__step: step.completed,
          active__step: selectedIndex === i,
          disabled__step: !enableButtonNavigation
        }">
        <div class="d-flex justify-content-center align-items-center">
          <ng-template #showLabelText>
            <h5 class="c-stepper__title">{{ i + 1 }}. {{ step.label }}</h5>
            <div class="done-icon-container" *ngIf="step.completed">
              <i class="done-icon fa fa-check"></i>
              <i class="fa-solid fa-check done-icon"></i>
            </div>
          </ng-template>
          <ng-container
            *ngIf="step.stepLabel; else showLabelText"
            [ngTemplateOutlet]="step.stepLabel.template">
          </ng-container>
        </div>
      </div>
      <div
        *ngIf="i !== steps.length - 1"
        class="h-line"
        [ngClass]="{
          completed: step.completed,
          active: selectedIndex === i
        }"></div>
    </ng-container>
  </div>
  <!-- End desktop view-->
</div>
<!-- Body -->
<div
  class="py-2 px-3 px-lg-5 px-md-5"
  [style.display]="selected ? 'block' : 'none'">
  <ng-container [ngTemplateOutlet]="selected ? selected.content : null">
  </ng-container>
</div>
