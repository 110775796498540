declare global {
  // eslint-disable-next-line no-var
  var IremboDefinitions: {
    daysBetweenDates(
      startDateStr: string,
      endDateStr: string | 'TODAY',
      format: 'MM-DD-YYYY' | 'MM/DD/YYYY' | 'DD-MM-YYYY' | 'DD/MM/YYYY'
    ): number | undefined;
  };
}

// Import this call on the main level of the application(main.ts)
export const setupDefinitions = () => {
  global.IremboDefinitions = {
    daysBetweenDates: (
      startDateStr: string,
      endDateStr: string | 'TODAY',
      format: 'MM-DD-YYYY' | 'MM/DD/YYYY' | 'DD-MM-YYYY' | 'DD/MM/YYYY'
    ): number | undefined => {
      if (!startDateStr) {
        return undefined;
      }

      let [day, month, year] = ['0', '0', '0'];

      switch (format) {
        case 'MM-DD-YYYY':
          [month, day, year] = startDateStr.split('-');
          break;
        case 'MM/DD/YYYY':
          [month, day, year] = startDateStr.split('/');
          break;
        case 'DD-MM-YYYY':
          [day, month, year] = startDateStr.split('-');
          break;
        case 'DD/MM/YYYY':
          [day, month, year] = startDateStr.split('/');
          break;
        default:
          return undefined;
      }

      const startDateFromReformatted = `${year}-${month}-${day}`;

      let endDateFromReformatted: string | null = null;

      if (endDateStr !== 'TODAY') {
        let [endDateDay, endDateMonth, endDateYear] = ['0', '0', '0'];

        switch (format) {
          case 'MM-DD-YYYY':
            [endDateMonth, endDateDay, endDateYear] = endDateStr.split('-');
            break;
          case 'MM/DD/YYYY':
            [endDateMonth, endDateDay, endDateYear] = endDateStr.split('/');
            break;
          case 'DD-MM-YYYY':
            [endDateDay, endDateMonth, endDateYear] = endDateStr.split('-');
            break;
          case 'DD/MM/YYYY':
            [endDateDay, endDateMonth, endDateYear] = endDateStr.split('/');
            break;
          default:
            return undefined;
        }

        endDateFromReformatted = `${endDateYear}-${endDateMonth}-${endDateDay}`;
      }

      const startDateParsed = new Date(startDateFromReformatted);

      const endDateParsed =
        endDateFromReformatted === null
          ? new Date()
          : new Date(endDateFromReformatted);

      if (endDateParsed < startDateParsed) {
        return undefined;
      }

      const elapsedDays =
        (endDateParsed.getTime() - startDateParsed.getTime()) /
        (1000 * 60 * 60 * 24);

      return Math.floor(elapsedDays);
    },
  };
};

export {};
