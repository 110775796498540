import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { VerticalStepperService } from '../../../services/vertical-stepper/vertical-stepper.service';

@Component({
  selector: 'irembogov-custom-field-stepper',
  templateUrl: './custom-field-stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldStepperComponent extends FieldType {
  constructor(public verticalStepperService: VerticalStepperService) {
    super();
  }

  filterFields() {
    return this.field.fieldGroup?.filter?.((x: any) => !x.hide) ?? [];
  }
}
