import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormlyValueChangeEvent } from '@ngx-formly/core/lib/models';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { ICascadingConfig } from '../../../models/irembo-cascading-config';
import { getValueFromNestedObject } from '../../../../utils/utils/nested-object-extractor.util';
import { IEnvironment } from '../../../models/environment.model';
import { ToastService } from '../../../services/toast/toast.service';

@Component({
  selector: 'irembogov-custom-cascading-drop-downs',
  templateUrl: './custom-cascading-drop-downs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCascadingDropDownsComponent
  extends FieldType<FieldTypeConfig>
  implements OnDestroy, OnInit
{
  private environment: IEnvironment;
  private subscriptions = new Subscription();
  configs: ICascadingConfig[] = [];
  items: Record<string, Record<string, unknown>[] | null> = {};
  loading: Record<string, boolean> = {};
  subscribeSubject: Subject<void> = new Subject();

  constructor(
    @Inject('environment') environment: IEnvironment,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private toastService: ToastService
  ) {
    super();
    this.environment = environment;
  }
  ngOnInit(): void {
    this.configs = this.field.props['configs'];
    this.watchForConfigChanges();
  }

  /**
   * Observes changes made through expressions in the `configs` of the Formly input field
   * and triggers change detection if such changes are detected.
   */
  watchForConfigChanges(): void {
    // detect changes made through expressions in the `configs` of the formly input
    this.field.options?.fieldChanges
      ?.pipe(
        filter(
          (e: FormlyValueChangeEvent) =>
            e.field.key === this.field.key && e.type === 'expressionChanges'
        ),
        takeUntil(this.subscribeSubject)
      )
      .subscribe(data => {
        // check if changes made affected the `configs` property of the field
        const configsPattern = /props\.configs\[(\d+)\]/;
        const match = data?.['property']?.match(configsPattern);
        if (match) {
          // use array spreading to allow change detection
          this.configs = [...this.configs];
          this.cd.detectChanges();
        }
      });
  }

  handleActiveDropDown(event: ICascadingConfig) {
    this.initializeDropDown(event);
  }

  initializeDropDown(activeControl: ICascadingConfig) {
    this.loading[activeControl.key] = true;
    let searchId: unknown = activeControl.value;
    if (
      typeof activeControl.value === 'object' &&
      activeControl.parentBindKey
    ) {
      searchId = activeControl.value[activeControl.parentBindKey];
    }
    let url = searchId
      ? `${activeControl.dataset.url}/${searchId}`
      : activeControl.dataset.url;

    if (activeControl.dataset.useBaseUrl) {
      url = this.prependUrlWithApiGatewayBaseUrl(url);
    }

    this.subscriptions.add(
      this.http.get<Record<string, unknown> | []>(url).subscribe({
        next: res => {
          const data = Array.isArray(res)
            ? res
            : getValueFromNestedObject(res, activeControl.dataset.dataField);
          this.items = { ...this.items, [activeControl.key]: data };
          this.loading = { ...this.loading, [activeControl.key]: false };
          this.cd.detectChanges();
        },
        error: () => {
          this.loading = { ...this.loading, [activeControl.key]: false };
          this.toastService.show({
            body: `Error loading ${activeControl.label}`,
            type: 'error',
          });
          this.cd.detectChanges();
        },
      })
    );
  }

  private prependUrlWithApiGatewayBaseUrl(url: string): string {
    const apiGatewayBaseUrl: string | undefined =
      this.environment.apiGatewayBaseUrl;
    if (!apiGatewayBaseUrl) {
      throw new Error(
        'useBaseUrl: main portal environment ApiGatewayBaseUrl property is required'
      );
    } else {
      url = `${apiGatewayBaseUrl}${url}`;
    }

    return url;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscribeSubject.unsubscribe();
  }
}
