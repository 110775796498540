import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { IdInputStatusEnum } from '../../../models/irembo-id-input-status.enum';
import { FormArray, FormGroup } from '@angular/forms';
import { IEnvironment } from '../../../models/environment.model';

import {
  checkForValidFields,
  configureFields,
  populateFormFields,
  updateUrlWithApiGatewayBaseUrl,
  getPopulateReferenceForm,
  subscribeToResetFieldFetchData,
} from '../../../../utils/utils/data-fetch-widget-utils';
import { FormStateService } from '../../../services/formly/form-state.service';

@Component({
  selector: 'irembogov-custom-tin-input',
  templateUrl: './custom-tin-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTinInputComponent
  extends FieldType<FieldTypeConfig>
  implements OnDestroy, AfterViewInit
{
  private environment: IEnvironment;
  private subscriptions = new Subscription();
  statusClass: string | undefined;

  fieldsToPopulate: Record<string, string>[] = [];

  tinLength = 9;
  useBaseUrl = true;

  constructor(
    @Inject('environment') environment: IEnvironment,
    private http: HttpClient,
    private formStateService: FormStateService,
    private cd: ChangeDetectorRef
  ) {
    super();
    this.environment = environment;
  }

  ngAfterViewInit(): void {
    if (this.field.props['tinLength'] !== undefined) {
      this.tinLength = this.field.props['tinLength'];
    }

    if (this.field.props['useBaseUrl'] !== undefined) {
      this.useBaseUrl = Boolean(this.field.props['useBaseUrl']);
    }

    this.subscriptions.add(
      subscribeToResetFieldFetchData(this.field, this.formState)
    );

    checkForValidFields(this.field);
    this.fieldsToPopulate = this.field.props?.['populates'];
    const formRef = getPopulateReferenceForm(this.field, this.form);

    if (this.fieldsToPopulate.length > 0) {
      this.subscriptions.add(
        configureFields(this.field, this.fieldsToPopulate, formRef)
      );
    }
  }

  onVerifyTin(tin: string) {
    let { url } = this.field.props;
    if (!url) {
      throw new Error('url property is required');
    }
    if (this.useBaseUrl) {
      url = updateUrlWithApiGatewayBaseUrl(url, this.environment);
    }

    this.statusClass = 'fetching';
    this.subscriptions.add(
      this.http.get<Record<string, unknown>>(`${url}/${tin}`).subscribe({
        next: (res: Record<string, unknown>) => {
          this.statusClass = IdInputStatusEnum.SUCCESS;
          const data = res['data'] as Record<string, unknown>;
          this.formStateService.saveFetchedDataKeyInFormState(
            this.field,
            data['ResponseObject']
          );
          if (this.fieldsToPopulate.length > 0) {
            const populateForm: FormGroup<any> | FormArray<any> =
              getPopulateReferenceForm(this.field, this.form);
            populateFormFields(
              data['ResponseObject'],
              this.field,
              populateForm
            );
          }
          this.cd.detectChanges();
        },
        error: () => {
          this.statusClass = IdInputStatusEnum.DANGER;
          this.cd.detectChanges();
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
