import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { GenericInputStatusEnum } from '../../../models/irembo-generic-input-status.enum';
import { IEnvironment } from '../../../models/environment.model';
import { v4 as uuidv4 } from 'uuid';
import {
  checkForValidFields,
  configureFields,
  populateFormFields,
  updateUrlWithApiGatewayBaseUrl,
  getPopulateReferenceForm,
  subscribeToResetFieldFetchData,
} from '../../../../utils/utils/data-fetch-widget-utils';
import { FormStateService } from '../../../services/formly/form-state.service';

@Component({
  selector: 'irembogov-custom-generic-data-fetch',
  templateUrl: './custom-generic-data-fetch.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomGenericDataFetchComponent
  extends FieldType<FieldTypeConfig>
  implements OnDestroy, AfterViewInit
{
  private subscriptions = new Subscription();
  statusClass: string | undefined;
  fieldsToPopulate: Record<string, string>[] = [];
  private environment: IEnvironment;

  constructor(
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private formStateService: FormStateService,
    @Inject('environment') environment: IEnvironment
  ) {
    super();
    this.environment = environment;
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      subscribeToResetFieldFetchData(this.field, this.formState)
    );

    checkForValidFields(this.field);

    this.fieldsToPopulate = this.field.props?.['populates'];
    if (this.fieldsToPopulate.length > 0) {
      const formRef = getPopulateReferenceForm(this.field, this.form);
      this.subscriptions.add(
        configureFields(this.field, this.fieldsToPopulate, formRef)
      );
    }
  }

  onDataFetch(inputValue: string) {
    let { url } = this.field.props;
    const { endpointCode, payloadKey } = this.field.props;
    if (!url || !endpointCode || !payloadKey) {
      throw new Error(
        'url, endpointCode and payloadKey properties are required'
      );
    }

    if (this.field.props['useBaseUrl']) {
      url = updateUrlWithApiGatewayBaseUrl(url, this.environment);
    }

    const params = {
      endpointCode,
      callerId: uuidv4(),
      payload: {
        [payloadKey]: inputValue,
      },
      requester: uuidv4(),
    };
    this.statusClass = GenericInputStatusEnum.FETCHING;
    this.subscriptions.add(
      this.http.post<Record<string, unknown>>(url, params).subscribe({
        next: (res: Record<string, unknown>) => {
          this.statusClass = GenericInputStatusEnum.SUCCESS;
          const data = res['data'] as Record<string, unknown>;
          const response = data['response'] as string;

          this.formStateService.saveFetchedDataKeyInFormState(
            this.field,
            response
          );

          if (this.fieldsToPopulate.length > 0) {
            const formRef = getPopulateReferenceForm(this.field, this.form);
            populateFormFields(response, this.field, formRef);
          }
          this.cd.detectChanges();
        },
        error: () => {
          this.statusClass = GenericInputStatusEnum.DANGER;
          this.cd.detectChanges();
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
