export const DOUBLE_ID_DROPDOWN_INPUT_KEY = 'documentTypeDropdown';
export const COUNTRY_DROPDOWN_INPUT_KEY = 'countryDropdown';

export type TDoubleIdentityFormControlValue = Record<
  string,
  IIdentityFieldValue | undefined
>;

export interface IIdentityFieldValue {
  idType?: string;
  idValue?: string;
  idLabel?: string;
  idCountry?: Record<string, unknown>;
}

export interface IDocumentTypeConfig {
  label: string;
  value: string;
  placeholder?: string;
  pattern: string;
  minLength: number;
  maxLength: number;
  presetCountry?: string;
}
