import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import {
  IFieldArrayData,
  IFieldData,
  IFieldGroupNameData,
} from '../../models/application-form-data.model';
import { IIremboFileUpload } from '../../models/irembo-file-upload-response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subscription, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '../../services/toast/toast.service';
import { IIrembogovBasicLabelKeyPair } from '../../models/irembogov-basic-label-keypair.model';

@Component({
  selector: 'irembogov-application-form-field-card',
  templateUrl: './application-form-field-card.component.html',
  styleUrls: ['./application-form-field-card.component.scss'],
})
export class ApplicationFormFieldCardComponent implements OnDestroy, OnInit {
  @Input() group: IFieldGroupNameData | undefined;
  @Input() applicationId: string | undefined;
  @Input() baseUrl?: any;
  @Input() applicationPath: string | undefined;
  selectedAttachment: IIremboFileUpload | undefined;
  filePath: string | undefined;
  private subscriptions = new Subscription();

  imagesSourceList: Array<string | null> = [];
  base64Data: string | undefined;
  loadingPreview = false;

  multiLineValueFieldTypes: string[] = ['customdoubleidinput'];

  constructor(
    public modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private toast: ToastService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.applicationId) {
      this.getRemoteImagePaths(this.applicationId);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackByFn(index: number, item: IFieldData | IFieldArrayData) {
    return index;
  }

  getSingleLineValue(field: IFieldData | undefined): string | null {
    if (!field || this.multiLineValueFieldTypes.indexOf(field.fieldType) > -1) {
      return null;
    }
    let valueString: string | null = '';
    switch (field.fieldType) {
      case 'customdropdown':
      case 'customdropdownpaginated': {
        valueString = this.getValueForCustomDropDowns(field);
        break;
      }
      case 'customdoubleidinput': {
        if (field.fieldValue) {
          valueString = <string>(
            (<Record<string, unknown>>field.fieldValue)['idValue']
          );
          break;
        }
        valueString = null;
        break;
      }
      default: {
        if (field.fieldValue) {
          valueString = <string>field.fieldValue;
          break;
        }
        valueString = null;
      }
    }

    return valueString;
  }

  getMultiLineValues(
    field: IFieldData | undefined
  ): IIrembogovBasicLabelKeyPair<string | null>[] {
    const values: IIrembogovBasicLabelKeyPair<string | null>[] = [];
    if (!field || this.multiLineValueFieldTypes.indexOf(field?.fieldType) < 0) {
      return values;
    }
    if (field.fieldType === 'customdoubleidinput') {
      if (field.fieldValue && typeof field.fieldValue === 'object') {
        const countryValue: unknown = (
          field.fieldValue as Record<string, unknown>
        )['idCountry'];
        values.push(
          {
            key: <string>(<Record<string, unknown>>field.fieldValue)['idValue'],
            label: `${field?.fieldLabel} ID Number`,
          },
          {
            key: <string>(<Record<string, unknown>>field.fieldValue)['idType'],
            label: `${field?.fieldLabel} ID Type`,
          },
          {
            key: (countryValue as Record<string, unknown>)['label'] as string,
            label: `${field?.fieldLabel} Country`,
          }
        );
      }
    }
    return values;
  }

  private getValueForCustomDropDowns(
    field: IFieldData | undefined
  ): string | null {
    if (!field) return null;
    let valueString: string | null = '';
    if (!Array.isArray(field.fieldValue)) {
      if (typeof field.fieldValue === 'object') {
        valueString = (<Record<string, string>>field.fieldValue)[
          <string>field.fieldBindLabel
        ];
      } else {
        valueString = <string>field.fieldValue;
      }
      return valueString;
    }

    if (Array.isArray(field.fieldValue)) {
      const arrObj: Record<string, unknown>[] = field.fieldValue;
      if (arrObj.every(elem => typeof elem === 'object')) {
        valueString = arrObj
          .map((ele: Record<string, unknown>) => {
            return ele[field.fieldBindLabel as string];
          })
          .join(', ');
      } else {
        valueString = arrObj.join(', ');
      }
      return valueString;
    }
    return valueString;
  }

  getAttachmentValue(dataValue: unknown): IIremboFileUpload {
    return dataValue as IIremboFileUpload;
  }

  isImage(mimeType: unknown) {
    return (mimeType as string).split('/')[0].toLowerCase() === 'image';
  }

  previewAttachment(
    content: TemplateRef<unknown>,
    file: unknown,
    index: number
  ) {
    this.selectedAttachment = file as IIremboFileUpload;
    this.filePath = '';
    this.loadingPreview = true;

    if (!this.selectedAttachment.fileDownloadName) {
      if (this.isImage(this.selectedAttachment.fileType)) {
        this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.selectedAttachment.content as string
        ) as string;
      } else {
        this.base64Data = this.selectedAttachment.content as string;
      }
      this.loadingPreview = false;
    }

    if (this.selectedAttachment.fileDownloadName && this.applicationId) {
      if (this.isImage(this.selectedAttachment.fileType)) {
        this.filePath = this.imagesSourceList[index] as string;
        this.loadingPreview = false;
      } else {
        this.subscriptions.add(
          this.getFileFromUrl(
            this.applicationId,
            this.selectedAttachment.fileDownloadName
          ).subscribe({
            next: res => {
              const reader = new FileReader();
              reader.readAsDataURL(res);
              reader.onload = () => {
                this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl(
                  reader.result as string
                ) as string;
                this.base64Data = reader.result as string;
                this.loadingPreview = false;
              };
            },
            error: () => {
              this.toast.show({ body: `Error loading preview`, type: 'error' });
              this.loadingPreview = false;
            },
          })
        );
      }
    }
    this.modalService.open(content, {
      ariaLabelledBy: 'preview-document',
      size: 'xl',
    });
  }

  getImagePath(file: unknown): string {
    const iremboFileUpload = file as IIremboFileUpload;
    return iremboFileUpload.content as string;
  }

  getRemoteImagePaths(applicationId: string) {
    this.group?.fieldGroup?.forEach(field => {
      if (field?.fieldType === 'customfileupload') {
        const value = field?.fieldValue as IIremboFileUpload;
        if (this.isImage(value.fileType) && value.fileDownloadName) {
          this.subscriptions.add(
            this.loadImageAsync(
              applicationId,
              value.fileDownloadName
            ).subscribe({
              next: res => {
                this.imagesSourceList.push(res);
              },
            })
          );
        } else {
          this.imagesSourceList.push(null);
        }
      } else {
        this.imagesSourceList.push(null);
      }
    });
  }

  getFileFromUrl(id: string, fileDownloadName: string) {
    const url = `${this.baseUrl}${this.applicationPath}?id=${id}&fileName=${fileDownloadName}`;
    return this.http.get(url, {
      responseType: 'blob',
    });
  }

  loadImageAsync(id: string, fileDownloadName: string) {
    return this.getFileFromUrl(id, fileDownloadName).pipe(
      switchMap(res => {
        return new Observable<string>(observer => {
          const reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onload = () => {
            observer.next(
              this.sanitizer.bypassSecurityTrustResourceUrl(
                reader.result as string
              ) as string
            );
            observer.complete();
          };
        });
      })
    );
  }

  downloadFile(base64String: string, fileName: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = base64String;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
