import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Subject, Subscription } from 'rxjs';
import { ToastService } from '../../../services/toast/toast.service';
import { IIremboFileUpload } from '../../../models/irembo-file-upload-response.model';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IPreviousAttachmentEvent } from '../../../models/previous-attachment-event';
import { FileUploadSource } from '../../../models/file-upload-source.enum';
import { ICertificateAttachmentEvent } from '../../../models/certificate-attachment-event';
import { IEnvironment } from '../../../models/environment.model';
import { UserDocumentType } from '../../../models/user-document-type.enum';
import { getCertificateNameByLocale } from '../../../../utils/utils/application-form.util';

const FIND_USER_CERTIFICATES_API =
  '/application/v1/application/find-user-certificates';
const FIND_USER_PREVIOUS_ATTACHMENTS_API =
  '/application/v1/application/find-user-previous-attachments';
const FILEMANAGER_URL = '/application/v1/file-manager/application/attachment';
const CERTIFICATE_URL = '/document-generation/v1/file-manager/certificate';
const PHOTO_VALIDATION_URL = '/photo-validation/api/photo/validate';

@Component({
  selector: 'irembogov-custom-file-upload',
  templateUrl: './custom-file-upload.component.html',
})
export class CustomFileUploadComponent
  extends FieldType<FieldTypeConfig>
  implements OnDestroy
{
  private subscriptions = new Subscription();

  totalPreviousAttachments = 0;
  totalCertificateAttachments = 0;
  previousAttachments: IPreviousAttachmentEvent[] = [];
  certificateAttachments: ICertificateAttachmentEvent[] = [];
  filePath: string | undefined;
  loadingFile = false;
  progress = new Subject<number>();
  allowUploadPreviousFiles = true;

  page = 0;
  size = 10;
  sort = 'dateCreated,asc';
  placeholder = 'Select File to upload';
  dataField = 'data.content';

  file: IIremboFileUpload | null | undefined;
  private environment: IEnvironment;
  photoValidationResponse: Record<string, unknown> | undefined;
  validatingPhoto = false;

  constructor(
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private toastService: ToastService,
    private sanitizer: DomSanitizer,
    public modalService: NgbModal,
    @Inject('environment') environment: IEnvironment
  ) {
    super();
    this.environment = environment;
    this.allowUploadPreviousFiles =
      this.environment.allowUploadPreviousFiles ?? true;
  }

  onFileUploadEvent(file: IIremboFileUpload) {
    const isImage = file['fileType'].includes('image');
    this.photoValidationResponse = {};
    if (this.field.props['validatePhoto'] && file['content'] && isImage) {
      const fileContent = file.content as string;
      const base64Str = fileContent.replace(/^data:image\/[a-z]+;base64,/, '');
      this.validatingPhoto = true;
      this.validatePhoto(base64Str).subscribe({
        next: res => {
          const checks = res['checks'] as Record<string, string>;
          this.photoValidationResponse = { ...checks, has_checks: true };
          this.validatingPhoto = false;
          this.cd.detectChanges();
        },
        error: () => {
          this.photoValidationResponse = { has_checks: false };
          this.validatingPhoto = false;
          this.cd.detectChanges();
        },
      });
    }
    this.cd.detectChanges();
  }

  loadUserDocuments(endpoint: string, type: UserDocumentType) {
    const url = `${this.environment.apiGatewayBaseUrl}${endpoint}`;
    const { dataset } = this.field.props;
    const params: Record<string, unknown> = {
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    const keyString = dataset?.['dataField']
      ? dataset['dataField']
      : this.dataField;
    if (keyString) {
      const queryParams = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

      this.subscriptions.add(
        this.http
          .get<Record<string, unknown>>(`${url}?${queryParams}`)
          .subscribe({
            next: (res: Record<string, unknown>) => {
              const data = res['data'] as Record<string, unknown>;
              const totalElements = data['totalElements'] as number;
              this.loadCertificates(type, res, keyString, totalElements);
              this.loadPreviousDocuments(type, res, keyString, totalElements);
              this.cd.detectChanges();
            },
            error: () => {
              this.toastService.show({
                body: `Error loading ${type}`,
                type: 'error',
              });
              this.cd.detectChanges();
            },
          })
      );
    }
  }

  private loadCertificates(
    type: UserDocumentType,
    res: Record<string, unknown>,
    keyString: string,
    totalElements: number
  ) {
    if (type === UserDocumentType.CERTIFICATES) {
      this.totalCertificateAttachments = totalElements;
      const certificateAttachmentsData = Array.isArray(res)
        ? (res as ICertificateAttachmentEvent[])
        : this.getValueFromNestedObject<ICertificateAttachmentEvent>(
            res,
            keyString
          );
      this.certificateAttachments.push(...certificateAttachmentsData);
    }
  }

  private loadPreviousDocuments(
    type: UserDocumentType,
    res: Record<string, unknown>,
    keyString: string,
    totalElements: number
  ) {
    if (type === UserDocumentType.PREVIOUS_ATTACHMENTS) {
      this.totalPreviousAttachments = totalElements;
      const previousAttachmentsData = Array.isArray(res)
        ? (res as IPreviousAttachmentEvent[])
        : this.getValueFromNestedObject<IPreviousAttachmentEvent>(
            res,
            keyString
          );
      this.previousAttachments.push(...previousAttachmentsData);
    }
  }

  onSelectFile() {
    if (this.previousAttachments.length === 0) {
      const { dataset } = this.field.props;
      if (dataset?.['params']) {
        const externalParams = dataset['params'] as Record<string, unknown>;
        if (
          externalParams['size'] &&
          typeof externalParams['size'] === 'number'
        ) {
          this.size = externalParams['size'];
        }
        if (
          externalParams['sort'] &&
          typeof externalParams['sort'] === 'string'
        ) {
          this.sort = externalParams['sort'];
        }
      }

      this.loadUserDocuments(
        FIND_USER_CERTIFICATES_API,
        UserDocumentType.CERTIFICATES
      );
      this.loadUserDocuments(
        FIND_USER_PREVIOUS_ATTACHMENTS_API,
        UserDocumentType.PREVIOUS_ATTACHMENTS
      );
    }
  }

  private getValueFromNestedObject<T>(
    obj: Record<string, unknown>,
    keyString: string
  ): T[] {
    const keys = keyString.split('.');
    let value = obj;
    for (const key of keys) {
      value = value[key] as Record<string, unknown>;
      if (value === undefined) {
        throw new Error(`Invalid key string: ${keyString}`);
      }
    }
    return value as unknown as T[];
  }

  handlePreviousAttachmentPagination(event: Record<string, number>) {
    this.handlePagination(event, UserDocumentType.PREVIOUS_ATTACHMENTS);
  }

  handleCertificateAttachmentPagination(event: Record<string, number>) {
    this.handlePagination(event, UserDocumentType.CERTIFICATES);
  }

  handlePagination(event: Record<string, number>, type: UserDocumentType) {
    const limit = event['scrollHeight'] - event['clientHeight'];
    const moreItems =
      type === UserDocumentType.CERTIFICATES
        ? this.totalCertificateAttachments !==
          this.certificateAttachments.length
        : this.totalPreviousAttachments !== this.previousAttachments.length;

    if (event['scrollPosition'] === limit && moreItems) {
      this.page++;
      if (type === UserDocumentType.PREVIOUS_ATTACHMENTS) {
        this.loadUserDocuments(
          FIND_USER_PREVIOUS_ATTACHMENTS_API,
          UserDocumentType.PREVIOUS_ATTACHMENTS
        );
      }
      if (type === UserDocumentType.CERTIFICATES) {
        this.loadUserDocuments(
          FIND_USER_CERTIFICATES_API,
          UserDocumentType.CERTIFICATES
        );
      }
    }
  }

  selectPreviousAttachmentEvent(event: IPreviousAttachmentEvent) {
    const file_manager_url = `${this.environment.apiGatewayBaseUrl}${FILEMANAGER_URL}`;

    this.modalService.dismissAll();
    this.loadingFile = true;
    this.cd.detectChanges();
    this.subscriptions.add(
      this.getBlob(
        { id: event.applicationId, fileName: event.fileName },
        file_manager_url
      ).subscribe({
        next: (httpEvent: HttpEvent<Blob>) => {
          this.updateControlValue(
            httpEvent,
            event.fileName,
            FileUploadSource.PREVIOUS_ATTACHMENT,
            this.field.props['attachmentCode']
          );
        },
        error: () => {
          this.loadingFile = false;
          this.toastService.show({
            body: `Error selecting previous attachments`,
            type: 'error',
          });
          this.cd.detectChanges();
        },
      })
    );
  }

  selectCertificateAttachmentEvent(event: ICertificateAttachmentEvent) {
    const file_manager_url = `${this.environment.apiGatewayBaseUrl}${CERTIFICATE_URL}`;

    this.modalService.dismissAll();
    this.loadingFile = true;
    this.cd.detectChanges();
    this.subscriptions.add(
      this.getBlob(
        { certificateName: getCertificateNameByLocale(event.certificateNames) },
        file_manager_url
      ).subscribe({
        next: (httpEvent: HttpEvent<Blob>) => {
          this.updateControlValue(
            httpEvent,
            getCertificateNameByLocale(event.certificateNames),
            FileUploadSource.CERTIFICATE,
            this.field.props['attachmentCode']
          );
        },
        error: () => {
          this.loadingFile = false;
          this.toastService.show({
            body: `Error selecting certificate attachments`,
            type: 'error',
          });
          this.cd.detectChanges();
        },
      })
    );
  }

  private updateControlValue(
    httpEvent: HttpEvent<Blob>,
    fileName: string,
    source: FileUploadSource,
    attachmentCode: string | undefined
  ) {
    if (httpEvent.type === HttpEventType.DownloadProgress) {
      if (httpEvent.total !== undefined) {
        const progressPercentage = Math.round(
          (100 * httpEvent.loaded) / httpEvent.total
        );
        this.progress.next(progressPercentage);
      }
    } else if (httpEvent.type === HttpEventType.Response) {
      const reader = new FileReader();
      const blob = httpEvent.body;
      if (blob) {
        reader.readAsDataURL(blob);
        reader.onload = () => {
          this.loadingFile = false;
          this.cd.detectChanges();
          this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl(
            reader.result as string
          ) as string;
          this.field.formControl.setValue({
            fileName,
            fileSize: blob.size,
            fileType: blob.type,
            fileDownloadName: '',
            content: reader.result,
            source,
            attachmentCode,
          });
        };
      }
    }
  }

  private getBlob(
    queryParams: Record<string, unknown>,
    fileManagerUrl: string
  ) {
    const params = Object.keys(queryParams)
      .map(key => `${key}=${encodeURIComponent(queryParams[key] as string)}`)
      .join('&');
    return this.http.get(`${fileManagerUrl}?${params}`, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events',
    });
  }

  private validatePhoto(data: string) {
    const url = `${this.environment.apiGatewayBaseUrl}${PHOTO_VALIDATION_URL}`;
    return this.http.post<Record<string, unknown>>(url, { data });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
