<div>
  <form class="auth-form" (submit)="submit()">
    <div class="otp-code-frame-inner mb-3">
      <div class="otp-code-frame-enter">
        <p class="enter-code tt_norms400 text-center">
          {{ 'Enter Code' | translate }}
        </p>
        <ng-otp-input
          class="w-100 text-center"
          [formCtrl]="otp"
          [config]="otpInputConfig"></ng-otp-input>
      </div>
      <div class="text-center counter-down-frame">
        <span class="opt-expire-secondary tt_norms400">
          {{ 'OTP expires in' | translate }}</span
        >
        <span>
          <countdown
            id="cd"
            #cd
            [config]="config"
            [style.backgroundColor]="isLower ? 'pink' : 'dark-grey'"
            [style.color]="isLower ? 'red' : 'black'"
            (event)="handleEvent($event)"></countdown>
        </span>
      </div>
    </div>

    <div class="d-grid gap-2 col-12 mx-auto">
      <button class="btn btn-primary" type="submit" [disabled]="isLoading">
        <div
          *ngIf="isLoading"
          class="spinner-border spinner-border-sm mr-2"
          role="status"></div>
        &nbsp;
        <strong>{{ 'Verify' | translate }}</strong>
      </button>
    </div>
  </form>
</div>
