<div class="bg-white">
  <div
    class="container-fluid d-flex flex-column justify-content-center align-items-center align-content-center pt-5 min-vh-100">
    <div class="mb-3">
      <img
        src="../../../../assets/img/logo.png"
        alt="Irembo logo"
        height="20"
        width="109" />
    </div>
    <div class="text-center">
      <h1>
        {{ 'Verify phone number' | translate }}
      </h1>
      <p class="sub-heading">
        {{ 'Enter the five-digit code sent to' | translate }}
      </p>
      <p class="mb-3 ms-1 receiver">
        {{ phoneNumber }}
      </p>

      <irembogov-new-otp-input
        [size]="5"
        [isSuccess]="false"
        [isLoading]="isLoading"
        [countdownTime]="300"
        [showCountdown]="true"
        [navigationText]="'Go back' | translate"
        [navigationLink]="'/verify'"
        [buttonText]="'Verify' | translate"
        [resendText]="'Click to resend' | translate"
        [redirectionStyle]="'step'"
        [errorMessage]="errorMessage"
        (goBack)="onGoBack()"
        (OnFinish)="onFormSubmit($event)"
        (OnResend)="resendLink()"></irembogov-new-otp-input>
    </div>
  </div>
</div>
