<div
  class="min-vh-100 d-flex flex-column justify-content-start align-items-center px-3">
  <div class="logo logo-container auth-components-margin-top" *ngIf="showLogo">
    <img class="img logo" src="assets/images/logo.png" alt="irembo logo" />
  </div>
  <div class="image-contianer" *ngIf="showImage">
    <img class="icon-img" src="assets/images/link_broken_blue.svg" alt="" />
  </div>
  <div class="text-center">
    <h2 class="mb-4">{{ title }}</h2>

    <p class="m-0 py-2" [innerHTML]="description"></p>
    <p class="mt-2" *ngIf="showResendLink">
      <small>
        {{ resendDescription }}
        <span
          *ngIf="resendLinkType === 'link'"
          (click)="resendLinkClick.emit()"
          class="resendLink"
          >{{ resendLinkText }}</span
        >
      </small>
    </p>
    <button
      *ngIf="resendLinkType === 'button'"
      (click)="resendLinkClick.emit()"
      class="btn btn-primary mt-2 col-12"
      style="font-weight: 600"
      type="submit">
      {{ resendLinkText }}
    </button>
  </div>
  <div class="col-12 text-center mt-4" *ngIf="showNavigation">
    <button class="back-btn btn btn-default w-auto" (click)="goBack.emit()">
      <i aria-hidden="true" class="fa fa-arrow-left pe-2"></i>
      Back to log in
    </button>
  </div>
</div>
