import { Injectable } from '@angular/core';

export interface ToastInfo {
  body: string;
  delay?: number;
  title?: string;
  type: 'success' | 'error' | 'warning' | 'info';
}

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: ToastInfo[] = [];

  show(toast: ToastInfo) {
    this.toasts = [...this.toasts, toast];
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
