export enum EApplicationStatus {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_EXPIRED = 'PAYMENT_EXPIRED',
  PAID = 'PAID',
  PENDING_OFFICER_PROCESSING = 'PENDING_OFFICER_PROCESSING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING_PUSH_TO_PARTNER = 'PENDING_PUSH_TO_PARTNER',
  PUSHED_TO_PARTNER = 'PUSHED_TO_PARTNER',
  PENDING_RESUBMISSION = 'PENDING_RESUBMISSION',
  PENDING_RESUBMISSION_LEVEL_1 = 'PENDING_RESUBMISSION_LEVEL_1',
  PENDING_RESUBMISSION_LEVEL_2 = 'PENDING_RESUBMISSION_LEVEL_2',
  PENDING_RESUBMISSION_LEVEL_3 = 'PENDING_RESUBMISSION_LEVEL_3',
  PENDING_RESUBMISSION_LEVEL_4 = 'PENDING_RESUBMISSION_LEVEL_4',
  PENDING_RESUBMISSION_LEVEL_5 = 'PENDING_RESUBMISSION_LEVEL_5',
  PENDING_APPOINTMENT = 'PENDING_APPOINTMENT',
  PENDING_ASSIGN_OFFICE = 'PENDING_ASSIGN_OFFICE',
  RESUBMITTED = 'RESUBMITTED',
  ESCALATED = 'ESCALATED',
  PENDING_OFFICER_PROCESSING_LEVEL_1 = 'PENDING_OFFICER_PROCESSING_LEVEL_1',
  PENDING_OFFICER_PROCESSING_LEVEL_2 = 'PENDING_OFFICER_PROCESSING_LEVEL_2',
  PENDING_OFFICER_PROCESSING_LEVEL_3 = 'PENDING_OFFICER_PROCESSING_LEVEL_3',
  PENDING_OFFICER_PROCESSING_LEVEL_4 = 'PENDING_OFFICER_PROCESSING_LEVEL_4',
  PENDING_OFFICER_PROCESSING_LEVEL_5 = 'PENDING_OFFICER_PROCESSING_LEVEL_5',
  RESUBMITTED_LEVEL_1 = 'RESUBMITTED_LEVEL_1',
  RESUBMITTED_LEVEL_2 = 'RESUBMITTED_LEVEL_2',
  RESUBMITTED_LEVEL_3 = 'RESUBMITTED_LEVEL_3',
  RESUBMITTED_LEVEL_4 = 'RESUBMITTED_LEVEL_4',
  RESUBMITTED_LEVEL_5 = 'RESUBMITTED_LEVEL_5',
}

export function applicationStatusValueOf(str: string) {
  return EApplicationStatus[str as keyof typeof EApplicationStatus];
}

export const applicationStatusEnumToLabelUtil = (
  applicationStatus: EApplicationStatus
): string => {
  switch (applicationStatus) {
    case EApplicationStatus.NEW:
      return 'New';

    case EApplicationStatus.SUBMITTED:
      return 'Submitted';

    case EApplicationStatus.PAYMENT_PENDING:
      return 'Payment Pending';

    case EApplicationStatus.PAYMENT_EXPIRED:
      return 'Payment Expired';

    case EApplicationStatus.PAID:
      return 'Paid';

    case EApplicationStatus.PENDING_OFFICER_PROCESSING:
    case EApplicationStatus.PENDING_OFFICER_PROCESSING_LEVEL_1:
    case EApplicationStatus.PENDING_OFFICER_PROCESSING_LEVEL_2:
    case EApplicationStatus.PENDING_OFFICER_PROCESSING_LEVEL_3:
    case EApplicationStatus.PENDING_OFFICER_PROCESSING_LEVEL_4:
    case EApplicationStatus.PENDING_OFFICER_PROCESSING_LEVEL_5:
      return 'Pending Officer Processing';

    case EApplicationStatus.APPROVED:
      return 'Approved';

    case EApplicationStatus.REJECTED:
      return 'Rejected';

    case EApplicationStatus.PENDING_PUSH_TO_PARTNER:
      return 'Pending Push to Partner';

    case EApplicationStatus.PUSHED_TO_PARTNER:
      return 'Pushed to Partner';

    case EApplicationStatus.PENDING_RESUBMISSION:
    case EApplicationStatus.PENDING_RESUBMISSION_LEVEL_1:
    case EApplicationStatus.PENDING_RESUBMISSION_LEVEL_2:
    case EApplicationStatus.PENDING_RESUBMISSION_LEVEL_3:
    case EApplicationStatus.PENDING_RESUBMISSION_LEVEL_4:
    case EApplicationStatus.PENDING_RESUBMISSION_LEVEL_5:
      return 'Pending Resubmission';

    case EApplicationStatus.PENDING_APPOINTMENT:
      return 'Pending Appointment';

    case EApplicationStatus.PENDING_ASSIGN_OFFICE:
      return 'Pending Assign Office';

    case EApplicationStatus.RESUBMITTED:
    case EApplicationStatus.RESUBMITTED_LEVEL_1:
    case EApplicationStatus.RESUBMITTED_LEVEL_2:
    case EApplicationStatus.RESUBMITTED_LEVEL_3:
    case EApplicationStatus.RESUBMITTED_LEVEL_4:
    case EApplicationStatus.RESUBMITTED_LEVEL_5:
      return 'Resubmitted';

    case EApplicationStatus.ESCALATED:
      return 'Escalated';

    default:
      return applicationStatus;
  }
};

export const applicationStatusEnumToColorUtil = (
  applicationStatus: EApplicationStatus
): string => {
  switch (applicationStatus) {
    case EApplicationStatus.NEW:
    case EApplicationStatus.SUBMITTED:
      return 'primary';

    case EApplicationStatus.PAYMENT_PENDING:
      return 'yellow';

    case EApplicationStatus.PAYMENT_EXPIRED:
      return 'danger';

    case EApplicationStatus.PAID:
      return 'primary';

    case EApplicationStatus.PENDING_OFFICER_PROCESSING:
    case EApplicationStatus.PENDING_OFFICER_PROCESSING_LEVEL_1:
    case EApplicationStatus.PENDING_OFFICER_PROCESSING_LEVEL_2:
    case EApplicationStatus.PENDING_OFFICER_PROCESSING_LEVEL_3:
    case EApplicationStatus.PENDING_OFFICER_PROCESSING_LEVEL_4:
    case EApplicationStatus.PENDING_OFFICER_PROCESSING_LEVEL_5:
      return 'yellow';

    case EApplicationStatus.APPROVED:
      return 'success';

    case EApplicationStatus.REJECTED:
      return 'danger';

    case EApplicationStatus.PENDING_PUSH_TO_PARTNER:
      return 'yellow';

    case EApplicationStatus.PUSHED_TO_PARTNER:
      return 'success';

    case EApplicationStatus.PENDING_RESUBMISSION:
    case EApplicationStatus.PENDING_RESUBMISSION_LEVEL_1:
    case EApplicationStatus.PENDING_RESUBMISSION_LEVEL_2:
    case EApplicationStatus.PENDING_RESUBMISSION_LEVEL_3:
    case EApplicationStatus.PENDING_RESUBMISSION_LEVEL_4:
    case EApplicationStatus.PENDING_RESUBMISSION_LEVEL_5:
      return 'yellow';

    case EApplicationStatus.PENDING_APPOINTMENT:
      return 'yellow';

    case EApplicationStatus.PENDING_ASSIGN_OFFICE:
      return 'yellow';

    case EApplicationStatus.RESUBMITTED:
    case EApplicationStatus.RESUBMITTED_LEVEL_1:
    case EApplicationStatus.RESUBMITTED_LEVEL_2:
    case EApplicationStatus.RESUBMITTED_LEVEL_3:
    case EApplicationStatus.RESUBMITTED_LEVEL_4:
    case EApplicationStatus.RESUBMITTED_LEVEL_5:
      return 'yellow';

    default:
      return 'primary';
  }
};
