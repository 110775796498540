import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Subscription, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { getValidDataFetchInputValue } from '../../../utils/utils/data-fetch-widget-utils';
import { BaseControlValueAccessor } from '../../models/BaseControlValueAccessor';

@Component({
  selector: 'irembogov-tin-input',
  templateUrl: './irembo-tin-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboTinInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IremboTinInputComponent,
      multi: true,
    },
  ],
})
export class IremboTinInputComponent
  extends BaseControlValueAccessor<string>
  implements
    ControlValueAccessor,
    Validator,
    OnChanges,
    AfterViewInit,
    OnDestroy
{
  @ViewChild('tin') tinEl: ElementRef | undefined;
  @Input() placeholder: unknown;
  @Input() id: unknown;
  @Input() tinLength = 0;
  @Output() verifyTin = new EventEmitter<string>();
  @Input() statusClass: string | undefined;
  isInvalidTIN = false;
  tinInputSub: Subscription | undefined;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onValidationChange = () => {};
  /* eslint-enable */

  ngAfterViewInit(): void {
    this.tinInputSub = fromEvent(this.tinEl?.nativeElement, 'keyup')
      .pipe(
        map((event: unknown) => {
          let tin = '';
          if (event instanceof Event) {
            tin = (event.target as HTMLInputElement).value;
          }
          return tin;
        }),
        distinctUntilChanged()
      )
      .subscribe({
        next: (value: string) => {
          this.statusClass = '';
          this.onChange(value);
          this.onTouched(value);
          const validTin = getValidDataFetchInputValue(value, this.tinLength);
          if (validTin) {
            this.verifyTin.emit(validTin);
          }
          this.cd.detectChanges();
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['statusClass']) {
      this.isInvalidTIN = changes['statusClass'].currentValue === 'danger';
      this._onValidationChange();
    }
  }

  validate(formControl: FormControl): ValidationErrors | null {
    const value = formControl.value?.trim();
    const validationErrors: ValidationErrors = {};
    if (!value) {
      return null;
    }
    const validInput = getValidDataFetchInputValue(value, this.tinLength);
    if (!validInput) {
      validationErrors['invalidInput'] = true;
    }
    if (this.isInvalidTIN) {
      validationErrors['invalidTIN'] = true;
    }
    return Object.keys(validationErrors).length ? validationErrors : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }

  ngOnDestroy(): void {
    this.tinInputSub?.unsubscribe();
  }
}
