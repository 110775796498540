import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Business } from '../models/business.model';

@Injectable({
  providedIn: 'root',
})
export class UserBusinessService {
  private userBusinesses$: BehaviorSubject<Business[] | undefined> =
    new BehaviorSubject<Business[] | undefined>(undefined);

  private userPermissions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );

  constructor(private http: HttpClient) {}

  setUserBusinesses(businesses: Business[]) {
    this.userBusinesses$.next(businesses);
  }

  getUserBusinesses(): Business[] | undefined {
    return this.userBusinesses$.getValue();
  }

  setPermissions(permissions: any[]) {
    this.userPermissions$.next([...permissions]);
  }

  getPermissions(): any[] {
    return this.userPermissions$.getValue();
  }

  getUserBusinessesSubject(): BehaviorSubject<any[] | undefined> {
    return this.userBusinesses$;
  }

  getUserPermissionsSubject(): BehaviorSubject<any[]> {
    return this.userPermissions$;
  }
}
