<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [autohide]="autohide"
  (mouseenter)="autohide = false"
  (mouseleave)="autohide = true"
  [delay]="toast.delay || 2500"
  [class]="toast.type"
  (hidden)="toastService.remove(toast)">
  <div
    class="d-flex"
    [ngClass]="title === '' ? 'align-items-start' : 'align-items-center'">
    <ng-template [ngIf]="toast.type === 'success'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none">
        <g clip-path="url(#clip0_5597_62965)">
          <path
            d="M6.2513 9.99996L8.7513 12.5L13.7513 7.49996M18.3346 9.99996C18.3346 14.6023 14.6037 18.3333 10.0013 18.3333C5.39893 18.3333 1.66797 14.6023 1.66797 9.99996C1.66797 5.39759 5.39893 1.66663 10.0013 1.66663C14.6037 1.66663 18.3346 5.39759 18.3346 9.99996Z"
            stroke="#039855"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_5597_62965">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </ng-template>
    <ng-template [ngIf]="toast.type === 'error'">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0V0ZM10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84286 14.1566 2 12.1217 2 10C1.9978 8.22334 2.59302 6.49755 3.69 5.1L14.9 16.31C13.5025 17.407 11.7767 18.0022 10 18V18ZM16.31 14.9L5.1 3.69C6.49755 2.59302 8.22335 1.99779 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1572 5.84344 18 7.87827 18 10C18.0022 11.7767 17.407 13.5025 16.31 14.9Z"
          fill="#DE1C3F" />
      </svg>
    </ng-template>
    <ng-template [ngIf]="toast.type === 'info'">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 9C9.73479 9 9.48043 9.10536 9.2929 9.29289C9.10536 9.48043 9 9.73478 9 10V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V10C11 9.73478 10.8946 9.48043 10.7071 9.29289C10.5196 9.10536 10.2652 9 10 9ZM10.38 5.08C10.1365 4.97998 9.86347 4.97998 9.62 5.08C9.49725 5.12759 9.38511 5.19896 9.29 5.29C9.20167 5.3872 9.13065 5.49882 9.08 5.62C9.02402 5.73868 8.99662 5.86882 9 6C8.99924 6.13161 9.02447 6.26207 9.07423 6.38391C9.124 6.50574 9.19732 6.61656 9.29 6.71C9.38721 6.79833 9.49882 6.86936 9.62 6.92C9.7715 6.98224 9.93597 7.00632 10.099 6.99011C10.2619 6.97391 10.4184 6.91792 10.5547 6.82707C10.691 6.73622 10.8029 6.61328 10.8805 6.46907C10.9582 6.32486 10.9992 6.16378 11 6C10.9963 5.73523 10.8927 5.48163 10.71 5.29C10.6149 5.19896 10.5028 5.12759 10.38 5.08ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z"
          fill="#0063CF" />
      </svg>
    </ng-template>
    <ng-template [ngIf]="toast.type === 'warning'">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 5C9.73478 5 9.48043 5.10536 9.29289 5.29289C9.10536 5.48043 9 5.73478 9 6V10C9 10.2652 9.10536 10.5196 9.29289 10.7071C9.48043 10.8946 9.73478 11 10 11C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5ZM10 13C9.80222 13 9.60888 13.0586 9.44443 13.1685C9.27998 13.2784 9.15181 13.4346 9.07612 13.6173C9.00043 13.8 8.98063 14.0011 9.01921 14.1951C9.0578 14.3891 9.15304 14.5673 9.29289 14.7071C9.43275 14.847 9.61093 14.9422 9.80491 14.9808C9.99889 15.0194 10.2 14.9996 10.3827 14.9239C10.5654 14.8482 10.7216 14.72 10.8315 14.5556C10.9414 14.3911 11 14.1978 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13ZM19.71 5.56L14.44 0.29C14.2484 0.107266 13.9948 0.00368349 13.73 0H6.27C6.00523 0.00368349 5.75163 0.107266 5.56 0.29L0.29 5.56C0.107266 5.75163 0.00368349 6.00523 0 6.27V13.73C0.00368349 13.9948 0.107266 14.2484 0.29 14.44L5.56 19.71C5.75163 19.8927 6.00523 19.9963 6.27 20H13.73C13.9948 19.9963 14.2484 19.8927 14.44 19.71L19.71 14.44C19.8927 14.2484 19.9963 13.9948 20 13.73V6.27C19.9963 6.00523 19.8927 5.75163 19.71 5.56ZM18 13.31L13.31 18H6.69L2 13.31V6.69L6.69 2H13.31L18 6.69V13.31Z"
          fill="#000000" />
      </svg>
    </ng-template>
    <div class="{{ toast.title ? 'ms-2' : 'ms-1' }} me-auto flex-column">
      <div class="title">{{ toast.title }}</div>
      <div>{{ toast.body }}</div>
    </div>
    <button
      type="button"
      aria-label="Close"
      class="btn-close"
      [ngClass]="btnclass(toast.type)"
      (click)="handleClose(toast)"></button>
  </div>
</ngb-toast>
