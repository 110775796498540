<div
  class="row border border-primary bg-light-blue rounded-1 bg-light p-1"
  style="width: 180px">
  <button
    *ngFor="let option of options; index as i"
    (click)="onSelectOption(option.value)"
    class="col-6 btn"
    [class.btn-primary]="selectedOption === option.value"
    [class.btn-transparent]="selectedOption !== option.value">
    {{ option.label }}
  </button>
</div>
