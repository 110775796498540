<div class="bg-white">
  <div
    class="container-fluid d-flex flex-column justify-content-lg-center justify-content-md-center justify-content-sm-start align-items-lg-center align-items-md-center align-items-sm-start align-content-center pt-5 min-vh-100">
    <div class="mb-3">
      <img
        src="../../../../assets/img/logo.png"
        alt="Irembo logo"
        height="20"
        width="109" />
    </div>
    <div class="text-lg-center text-md-center text-sm-start">
      <h1>
        {{ "Confirm it's you" | translate }}
      </h1>
      <p class="sub-heading">
        {{ 'Enter the five-digit code sent to' | translate }}
      </p>
      <p class="mb-3 ms-1 receiver">
        {{ verificationType === 'email' ? email : phoneNumber }}
      </p>

      <irembogov-new-otp-input
        [size]="5"
        [isSuccess]="false"
        [isLoading]="isLoading"
        [countdownTime]="300"
        [navigationText]="'Return to login' | translate"
        [navigationLink]="'/profile'"
        [buttonText]="'Continue' | translate"
        [errorMessage]="errorMessage"
        (OnFinish)="onFormSubmit($event)"
        (OnResend)="resendLink()"></irembogov-new-otp-input>
    </div>
  </div>
</div>
