<div class="modal-header">
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div *ngIf="!base64Data" class="d-flex justify-content-center mt-5">
    <div class="d-flex justify-content-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
      </div>
    </div>
  </div>
  <ng-container>
    <pdf-viewer
      [src]="base64Data ?? ''"
      [render-text]="true"
      [original-size]="false"
      [fit-to-page]="true"
      [autoresize]="true"></pdf-viewer>
  </ng-container>
</div>
