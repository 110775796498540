import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IOrganisation } from '../../models/organisations-list.model';
import { IService } from '../../models/services-list.model';
import { PublicServicesService } from '../../services/public-services.service';

@Component({
  selector: 'irembogov-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.scss'],
})
export class AdvanceSearchComponent implements OnInit {
  totalItems = 0;
  page = 0;
  size = 50;
  searchResults: IService[];
  institutions: IOrganisation[];
  keyword = '';
  activeFilter = '';
  browseBy = '';
  modalID!: ElementRef;

  selectedApplication: IService;
  selectedApplicationIcon = '';
  isSearching = false;

  @Output() applyClicked = new EventEmitter<string>();

  constructor(
    private publicServicesService: PublicServicesService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this.searchResults = [];
    this.institutions = [];
    this.selectedApplication = {
      id: '',
      serviceName: '',
      serviceSummary: '',
      category: {
        id: '',
        name: '',
      },
      organization: {
        id: '',
        acronym: '',
        name: '',
      },
      priceType: '',
      price: 0,
      currency: '',
      expectedProcessingTime: 0,
      applicantType: '',
    };
  }

  ngOnInit() {
    const keyword = this.route.snapshot.queryParamMap.get('keyword') ?? '';
    this.searchByQuery(keyword);
  }

  searchItemSelect(queryParams: IService) {
    const navigationExtras: NavigationExtras = {
      queryParams: { keyword: queryParams.serviceName, id: queryParams.id },
    };
    this.router.navigate(['/service/details'], navigationExtras);
  }

  searchItemPressed(searchTerm: string) {
    this.searchByQuery(searchTerm);
  }

  searchByQuery(query: string) {
    this.isSearching = true;
    this.publicServicesService
      .getServices({ page: 0, size: this.size, query })
      .subscribe({
        next: res => {
          this.totalItems = res.data.totalPages;
          this.searchResults = res.data.content;
          this.size = res.data.size;
          this.isSearching = false;

          if (this.totalItems) {
            this.institutions = this.getInstitutionsFromServices(
              this.searchResults
            );
            this.selectedApplication = this.searchResults[0];
            this.selectedApplicationIcon =
              'fa-' + this.selectedApplication.category.name.toLowerCase();
          }
        },
        error: () => {
          this.isSearching = false;
        },
      });
  }

  paginateHandler(event: { pageNumber: number; pageSize: number }) {
    this.page = event.pageNumber - 1;
    this.searchByQuery(this.keyword);
  }

  handleApply(modal: ElementRef, service: IService) {
    this.selectedApplication = service;
    this.openModal(modal);
  }

  openModal(modal: ElementRef) {
    this.modalService.open(modal, {
      size: 'lg',
      centered: true,
      animation: true,
    });
  }

  searchByCategoryId(categoryId: string) {
    this.isSearching = true;
    this.publicServicesService
      .getServices({ page: 0, size: 10, categoryId })
      .subscribe({
        next: res => {
          this.isSearching = false;
          this.totalItems = res.data.totalPages;
          this.searchResults = res.data.content;
        },
        error: () => {
          this.isSearching = false;
        },
      });
  }

  searchByOrgId(organizationId: string) {
    this.isSearching = true;
    this.publicServicesService
      .getServices({ page: 0, size: 10, organizationId })
      .subscribe({
        next: res => {
          this.isSearching = false;
          this.totalItems = res.data.totalPages;
          this.searchResults = res.data.content;
        },
        error: () => {
          this.isSearching = false;
        },
      });
  }
  searchByCatOrgId(categoryId: string, organizationId: string) {
    this.isSearching = true;
    this.publicServicesService
      .getServices({ page: 0, size: 10, categoryId, organizationId })
      .subscribe({
        next: res => {
          this.isSearching = false;
          this.totalItems = res.data.totalPages;
          this.searchResults = res.data.content;
        },
        error: () => {
          this.isSearching = false;
        },
      });
  }

  getInstitutions() {
    this.publicServicesService
      .getOrganisations({ page: 0, size: this.size })
      .subscribe({
        next: res => {
          this.institutions = res.data.content;
        },
      });
  }

  getInstitutionsFromServices(services: IService[]) {
    const result = [];
    const index: Record<string, unknown> = {};
    for (const service of services) {
      const key = service.organization.acronym as string;
      if (key in index) continue;
      index[key] = service.organization.acronym;
      result.push(service.organization);
    }

    return result;
  }
}
