import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'irembogov-token-sent',
  templateUrl: './token-sent.component.html',
  styleUrls: ['./token-sent.component.scss'],
})
export class TokenSentComponent {
  @Input() title = 'Check your email';
  @Input() recipient: string | undefined;
  @Input() recipientMessage = 'We sent password reset link to';
  @Input() noReceiveQuestion = "Didn't receive the email?";
  @Input() resendLinkText = 'Click to resend';
  @Input() showBackToLogin = true;
  @Input() showLogo = true;
  @Output() resend: EventEmitter<undefined> = new EventEmitter<undefined>();
  @Output() goBack: EventEmitter<undefined> = new EventEmitter<undefined>();
}
