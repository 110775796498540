<div class="input-widget-container" [ngClass]="statusClass">
  <input
    #tin
    [disabled]="disabled"
    [(ngModel)]="value"
    type="text"
    class="form-control"
    [id]="id"
    [placeholder]="placeholder" />
  <span class="thumb-icon">
    <span class="icon-success">
      <i class="fa fa-thumbs-up"></i>
    </span>
    <span class="icon-danger">
      <i class="fa fa-thumbs-down"></i>
    </span>
    <div class="spinner-border text-primary spinner-border-sm" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </span>
</div>
