import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, map } from 'rxjs';
import { ILocale } from 'src/app/models/locale/locale.model';
import { LocaleService } from 'src/app/services/locale.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GenericContainerResolver implements Resolve<void> {
  activeLocale: string;
  passedLocale: string | null | undefined;

  constructor(
    public translate: TranslateService,
    private localeService: LocaleService,
    private toastService: ToastService
  ) {
    this.activeLocale =
      localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
    translate.addLangs([this.activeLocale]);
    translate.setDefaultLang(this.activeLocale);
  }

  async resolve(route: ActivatedRouteSnapshot): Promise<void> {
    this.passedLocale = route.queryParamMap.get('locale');
    const supportedLocales = await this.getSupportedLocales();
    this.setQueryLocale(supportedLocales);
  }

  async getSupportedLocales(): Promise<ILocale[]> {
    try {
      const response = await firstValueFrom(
        this.localeService
          .getSupportedLocales()
          .pipe(map(res => res.data.content))
      );
      return response;
    } catch (error: any) {
      this.toastService.show({
        body: error.error.responseMessage ?? error.error.message,
        type: 'error',
        delay: 5000,
      });
      return [];
    }
  }

  setQueryLocale(supportedLocales: ILocale[]) {
    if (this.passedLocale) {
      const filteredLocales = supportedLocales.filter(
        lang =>
          lang.name === this.passedLocale ||
          lang.nativeName === this.passedLocale
      );

      if (filteredLocales.length > 0) {
        this.activeLocale = filteredLocales[0].locale;
        this.translate.setDefaultLang(this.activeLocale);
        localStorage.setItem('locale', this.activeLocale);
      }
    }
  }
}
