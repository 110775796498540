<div
  class="bg-white d-flex flex-column justify-content-lg-center justify-content-sm-start align-items-lg-center align-items-sm-start">
  <div
    class="d-flex flex-column justify-content-lg-center justify-content-sm-start align-items-lg-center align-items-sm-start pt-5 min-vh-100 col-lg-3 col-md-4 col-sm-12 px-3">
    <div class="mb-5">
      <img
        src="../../../../assets/img/logo.png"
        alt="Irembo logo"
        height="20"
        width="109" />
    </div>
    <div class="text-lg-center text-md-center text-sm-start">
      <h1>
        {{ 'Reset account password' | translate }}
      </h1>
      <p
        class="sub-heading text-break"
        [innerHTML]="
          'Your new password must be different from <br /> previously used passwords.'
            | translate
        "></p>
    </div>
    <form class="form" [formGroup]="resetForm">
      <div
        *ngIf="errorMessage"
        class="error-container rounded-3 p-3 border border-1 border-danger my-3 d-flex justify-content-start align-content-center align-items-start text-start">
        <div class="d-flex justify-content-start align-items-start pt-1">
          <i class="fa-solid fa-circle-info me-2" style="color: #b42318"></i>
        </div>
        <div
          class="d-flex justify-content-start align-items-start text-error text-start">
          {{ errorMessage | translate }}
        </div>
      </div>
      <div class="form-group mb-3">
        <label for="password">{{ 'Password' | translate }}</label>
        <div class="input-group">
          <input
            formControlName="password"
            id="password"
            class="form-control text-input"
            type="{{ showPassword ? 'text' : 'password' }}"
            [placeholder]="'Create a password' | translate" />
          <span class="input-group-text bg-transparent">
            <i
              class="fa-regular text-muted"
              [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"
              (click)="togglePassword()"></i>
          </span>
        </div>
        <password-strength-meter
          [password]="
            resetForm.get('password')?.value
          "></password-strength-meter>
        <small class="text-muted">{{
          'Must be at least 8 characters long and may contain letters and numbers'
            | translate
        }}</small>
      </div>
      <div class="form-group mb-3">
        <label for="confirm_password">{{
          'Confirm password' | translate
        }}</label>
        <div class="input-group">
          <input
            formControlName="confirm_password"
            id="confirm_password"
            class="form-control text-input"
            type="{{ showConfirmPassword ? 'text' : 'password' }}"
            [ngClass]="{
              'error':
                resetForm.get('confirm_password')?.errors?.['passwordNotMatch']
            }"
            [placeholder]="'Re-enter password' | translate" />
          <span
            class="input-group-text bg-transparent error"
            *ngIf="
          resetForm.get('confirm_password')?.errors?.['passwordNotMatch']">
            <i
              class="fa-solid fa-circle-exclamation text-danger"
              (click)="toggleConfirmPassword()"></i>
          </span>
          <span
            class="input-group-text bg-transparent"
            *ngIf="!
          resetForm.get('confirm_password')?.errors?.['passwordNotMatch']">
            <i
              class="fa-regular text-muted"
              [ngClass]="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"
              (click)="toggleConfirmPassword()"></i>
          </span>
        </div>
        <password-strength-meter
          [password]="
            resetForm.get('confirm_password')?.value
          "></password-strength-meter>
        <p
          *ngIf="resetForm.get('confirm_password')?.touched &&
            resetForm.get('confirm_password')?.errors?.['passwordNotMatch']"
          class="text-danger">
          {{ 'Password and Confirm Password do not match' | translate }}
        </p>
      </div>

      <div class="d-grid gap-2 col-12 mx-auto">
        <button
          (click)="onFormSubmit()"
          class="btn btn-primary"
          type="submit"
          [disabled]="isLoading || !resetForm.valid">
          <div
            *ngIf="isLoading"
            class="spinner-border spinner-border-sm mr-2"
            role="status"></div>
          &nbsp;
          {{ 'Reset Password' | translate }}
        </button>
      </div>
    </form>
    <a
      class="text-lg-center text-md-center text-sm-start mt-3 fw-semibold text-decoration-none"
      routerLink="/profile"
      ><i class="fa-solid fa-angle-left me-2"></i
      >{{ 'Return to log in' | translate }}</a
    >
  </div>
</div>
