<div class="irembo-otp-entry">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 text-center mb-3 px-5">
            <img class="img" src="assets/images/logo.svg" alt="" />
          </div>
          <div class="col-12 text-center mb-3 check text-center">
            <img
              class="icon-img"
              src="assets/images/irembo_img-key.svg"
              alt="" />
          </div>
          <div class="col-12">
            <h2 class="text-center">{{ title }}</h2>
            <p class="text-center mt-4 desc">
              {{ description }}
            </p>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <div class="mt-2 activation-reset-form">
              <div
                *ngIf="this.mainErrorMessage"
                class="small text-danger text-center mb-2">
                {{ mainErrorMessage }}
              </div>
              <div class="form-group">
                <div class="input-group mb-3">
                  <ng-otp-input
                    class="w-100 text-center"
                    [formCtrl]="otp"
                    [config]="otpInputConfig"></ng-otp-input>
                </div>
              </div>
              <button
                (click)="onOtpSubmit()"
                class="btn btn-primary mt-1 mx-auto"
                type="submit"
                [disabled]="isLoading">
                <div
                  *ngIf="isLoading"
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"></div>
                <b> {{ submitLabel }} </b>
              </button>

              <div class="mt-3 text-center" *ngIf="showResendButton">
                <p class="">
                  {{ resendLinkQuestion }}
                  <span
                    (click)="OnResendClicked.emit()"
                    class="no-dec resendLink pointerlink active">
                    {{ resendLinkLabel }}</span
                  >
                </p>
              </div>
              <div class="text-center mt-3" *ngIf="showCancelButton">
                <button
                  class="back-btn btn btn-default w-auto pointerlink"
                  (click)="OnCancelClicked.emit()">
                  <i aria-hidden="true" class="fa fa-arrow-left pe-2"></i>
                  {{ cancelLinkLabel }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
