export interface NotificationType {
  PHONE_NUMBER: boolean;
  EMAIL: boolean;
}

export enum IdentificationType {
  NID = 'NID',
  NIN = 'NIN',
  PASSPORT_NUMBER = 'PASSPORT_NUMBER',
  CITIZEN_APPLICATION_NUMBER = 'CITIZEN_APPLICATION_NUMBER',
}

export interface IUserProfile {
  userId: string;
  identificationType: IdentificationType;
  identificationNumber: string;
  accountVerified: boolean;
  notificationPhoneNumber: string;
  notificationEmail: string;
  notificationType: NotificationType;
  firstLogin?: boolean;
}
