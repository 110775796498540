<div class="irembo-double-id-input" [ngClass]="statusClass">
  <div class="mb-3" [formGroup]="firstIdentityFormGroup">
    <label class="form-label identity-title"
      >{{ firstIdentityPlaceholder }}
    </label>
    <ng-container
      [ngTemplateOutlet]="identityFields"
      [ngTemplateOutletContext]="{
        form: firstIdentityFormGroup,
        idKey: firstIdentityValueKey,
        itemOption: 'first'
      }"></ng-container>
  </div>

  <div class="mb-3" [formGroup]="secondIdentityFormGroup">
    <label class="form-label identity-title"
      >{{ secondIdentityPlaceholder }}
    </label>
    <ng-container
      [ngTemplateOutlet]="identityFields"
      [ngTemplateOutletContext]="{
        form: secondIdentityFormGroup,
        idKey: secondIdentityValueKey,
        itemOption: 'second'
      }"></ng-container>
  </div>
  <small class="w-100 text-danger text-center" *ngIf="invalidInput">
    No matching record found for {{ firstIdentityLabel }} and
    {{ secondIdentityLabel }}
  </small>
</div>

<ng-template
  let-form="form"
  let-idKey="idKey"
  let-itemOption="itemOption"
  #identityFields>
  <div>
    <div class="my-1">
      <label for="docType" class="form-label tt_norms400"
        >Select Document Type<span *ngIf="isRequired">*</span>
      </label>
      <ng-select
        appendTo="body"
        id="{{ idKey }}_docType"
        labelForId="{{ idKey }}_docType"
        placeholder="Select Document Type"
        [formControl]="form.get(DOUBLE_ID_DROPDOWN_INPUT_KEY)"
        class="custom"
        [searchable]="false"
        bindValue="value"
        bindLabel="label"
        (change)="setPresetIdentityCountry(itemOption, $event?.presetCountry)"
        [virtualScroll]="true"
        [items]="documentTypes">
        <ng-template ng-label-tmp let-item="item">
          {{ item.label }}
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{ item.label }}
        </ng-template>
      </ng-select>
      <div class="small text-danger">
        {{
          getErrorDisplayMessage(
            form.get(DOUBLE_ID_DROPDOWN_INPUT_KEY),
            'Document Type'
          )
        }}
      </div>
    </div>

    <div *ngFor="let config of documentTypes">
      <div
        class="my-1 double-id-input-type-value"
        *ngIf="form.get(DOUBLE_ID_DROPDOWN_INPUT_KEY)?.value === config.value">
        <label [for]="config.value" class="form-label tt_norms400"
          >{{ config.label }} *
        </label>
        <input
          [formControl]="form.get(config.value)"
          [hidden]="
            form.get(DOUBLE_ID_DROPDOWN_INPUT_KEY)?.value !== config.value
          "
          type="text"
          class="form-control"
          id="{{ idKey }}_{{ config.value }}"
          [placeholder]="config.placeholder ?? 'Enter ' + config.label" />
        <span class="thumb-icon">
          <span class="icon-success">
            <i class="fa fa-thumbs-up"></i>
          </span>
          <span class="icon-danger">
            <i class="fa fa-thumbs-down"></i>
          </span>
          <div
            class="spinner-border text-primary spinner-border-sm"
            role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </span>
        <div class="small text-danger">
          {{
            getErrorDisplayMessage(form.get(config.value), config.label, {
              minLength: config.minLength,
              maxLength: config.maxLength
            })
          }}
        </div>
      </div>
    </div>

    <div
      *ngIf="
        form.get(DOUBLE_ID_DROPDOWN_INPUT_KEY)?.value &&
        !getPresetIdentityCountry(itemOption)
      ">
      <label
        [for]="itemOption + 'Identity_country'"
        class="form-label tt_norms400"
        >Country *
      </label>
      <irembogov-select
        [loading]="countryDataSetLoading"
        [id]="itemOption + 'Identity_country'"
        [customFormControl]="form.get(COUNTRY_DROPDOWN_INPUT_KEY)"
        [items]="items"
        [bindLabel]="'label'"
        ngDefaultControl
        [bindValue]="''"
        placeholder="Select Nationality"
        labelForId="Nationality"
        [multiple]="false"></irembogov-select>
    </div>
  </div>
</ng-template>
