import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '../../models/environment.model';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private environment: IEnvironment;

  constructor(
    private http: HttpClient,
    @Inject('environment') environment: IEnvironment
  ) {
    this.environment = environment;
    if (!this.environment.apiGatewayBaseUrl) {
      throw new Error(
        'useBaseUrl: portal environment ApiGatewayBaseUrl property is required'
      );
    }
  }

  getEventFutureDates(
    eventCode: string,
    startDate: string,
    maxDataSetSize: string
  ) {
    const url = `${this.environment.apiGatewayBaseUrl}/admin/v1/schedule-operations/future-dates`;
    const params = new HttpParams()
      .set('scheduleCode', eventCode)
      .set('startDate', startDate)
      .set('maxDataSetSize', maxDataSetSize);
    return this.http.get<Record<string, unknown>>(url, { params });
  }

  getBookingsSummary(eventCode: string, date: string) {
    const url = `${this.environment.apiGatewayBaseUrl}/admin/v1/bookings-summary/by-schedule-code/${eventCode}/by-date/${date}`;
    return this.http.get<Record<string, unknown>>(url);
  }
}
