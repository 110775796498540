<ng-container
  *ngTemplateOutlet="!multiple ? singleSelect : multiSelect"></ng-container>

<ng-template #commonTemplate let-item="item">
  <ng-container *ngIf="item['icon_image_link'] !== undefined">
    <img
      class="org-img"
      src="{{ item['icon_image_link'] }}"
      alt="icon image link" />
  </ng-container>
  {{ item[bindLabel] }}
</ng-template>

<ng-template #singleSelect>
  <ng-select
    [loading]="loading"
    [appendTo]="appendTo"
    [dropdownPosition]="dropdownPosition"
    [virtualScroll]="true"
    [placeholder]="placeholder"
    (change)="change()"
    [formControl]="customFormControl"
    class="custom"
    [labelForId]="labelForId"
    [items]="items"
    (scrollToEnd)="fetchMoreItems()"
    [typeahead]="searchInput$"
    [searchFn]="search"
    [searchable]="searching"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue">
    <ng-template ng-label-tmp let-item="item">
      <ng-container
        *ngTemplateOutlet="
          commonTemplate;
          context: { item: item }
        "></ng-container>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <ng-container
        *ngTemplateOutlet="
          commonTemplate;
          context: { item: item }
        "></ng-container>
    </ng-template>
  </ng-select>
</ng-template>

<ng-template #multiSelect>
  <ng-select
    [loading]="loading"
    [appendTo]="appendTo"
    [dropdownPosition]="dropdownPosition"
    [virtualScroll]="true"
    [placeholder]="placeholder"
    [items]="items"
    [multiple]="true"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [closeOnSelect]="false"
    [formControl]="customFormControl"
    (change)="change()"
    (scrollToEnd)="fetchMoreItems()"
    [typeahead]="searchInput$"
    [labelForId]="labelForId"
    class="custom">
    <ng-template
      ng-option-tmp
      let-item="item"
      let-item$="item$"
      let-index="index">
      <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
      <ng-container
        *ngTemplateOutlet="
          commonTemplate;
          context: { item: item }
        "></ng-container>
    </ng-template>
  </ng-select>
</ng-template>
