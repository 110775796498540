import { IService } from './../../models/services-list.model';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'irembogov-service-modal',
  templateUrl: './service-modal.component.html',
  styleUrls: ['./service-modal.component.scss'],
})
export class ServiceModalComponent implements AfterViewInit, OnChanges {
  @Input() data!: IService;
  @Output() modalID: EventEmitter<ElementRef> = new EventEmitter();
  @ViewChild('content') _modalID: ElementRef | undefined;

  constructor(private router: Router) {}

  selectedService: IService | undefined;

  ngAfterViewInit() {
    this.modalID.emit(this._modalID);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'].currentValue) {
      this.selectedService = changes['data'].currentValue;
    }
  }

  closeModal(modal: NgbActiveModal, reason?: string) {
    modal.dismiss(reason ?? null);
  }

  apply(modal: NgbActiveModal) {
    const id = this.selectedService?.id ?? '';
    const keyword = this.selectedService?.serviceName ?? '';

    modal.dismiss(null);
    this.router.navigate(['service/details'], { queryParams: { id, keyword } });
  }
}
