<div class="irembo-otp-resend">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 text-center mb-3 px-5">
            <img class="img" src="assets/images/logo.svg" alt="" />
          </div>
          <div class="col-12 text-center mb-3 check text-center">
            <img
              class="icon-img"
              src="assets/images/irembo_img-key.svg"
              alt="" />
          </div>
          <div class="col-12">
            <h2 class="text-center">{{ title | titlecase }}</h2>
            <p class="text-center mt-4 desc d-flex flex-column">
              {{
                (inputMode === 'email'
                  ? descriptionForEmail
                  : descriptionForPhone
                ) | titlecase
              }}
              <small
                (click)="toggleInputMode()"
                class="w-100 pointerlink active">
                {{ getToggleSwitchLabel() }}
              </small>
            </p>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <div class="mt-2 activation-reset-form">
              <div
                *ngIf="this.mainErrorMessage"
                class="small text-danger text-center mb-2">
                {{ mainErrorMessage }}
              </div>

              <ng-container *ngIf="inputMode === 'email'">
                <div class="form-group">
                  <div class="input-group mb-3">
                    <input
                      [formControl]="email"
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="Email Address"
                      #password
                      autocomplete />
                  </div>
                </div>
                <button
                  (click)="onSubmit()"
                  class="btn btn-primary mt-1 mx-auto"
                  type="submit"
                  [disabled]="isLoading">
                  <div
                    *ngIf="isLoading"
                    class="spinner-border spinner-border-sm mr-2"
                    role="status"></div>
                  <b> {{ ResendButtonLabel }} </b>
                </button>
              </ng-container>

              <ng-container *ngIf="inputMode === 'phone'">
                <div class="form-group">
                  <div class="input-group mb-3">
                    <input
                      [formControl]="phone"
                      type="tel"
                      class="form-control"
                      id="telephone"
                      placeholder="Telephone Number"
                      #password
                      autocomplete />
                  </div>
                </div>
                <button
                  (click)="onSubmit()"
                  class="btn btn-primary mt-1 mx-auto"
                  type="submit"
                  [disabled]="isLoading">
                  <div
                    *ngIf="isLoading"
                    class="spinner-border spinner-border-sm mr-2"
                    role="status"></div>
                  <b> {{ ResendButtonLabel }} </b>
                </button>
              </ng-container>

              <div class="mt-3 text-center" *ngIf="showGoToActionButton">
                <p class="">
                  {{ goToActionQuestion }}
                  <span
                    (click)="goToActionClicked()"
                    class="no-dec"
                    [ngClass]="
                      inputMode === 'email' ? '' : 'active pointerlink'
                    ">
                    {{
                      inputMode === 'email'
                        ? 'Follow link sent in email'
                        : goToActionLabel
                    }}</span
                  >
                </p>
              </div>
              <div class="text-center mt-3" *ngIf="showCancelButton">
                <button
                  class="back-btn btn btn-default w-auto pointerlink"
                  (click)="OnCancelClicked.emit()">
                  <i aria-hidden="true" class="fa fa-arrow-left pe-2"></i>
                  {{ cancelLinkLabel }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
