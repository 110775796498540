import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'irembogov-activation-link-expired',
  templateUrl: './activation-link-expired.component.html',
  styleUrls: ['./activation-link-expired.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivationLinkExpiredComponent {
  @Input() title = 'Link Expired';
  @Input() description? =
    "The activation link you're trying to access has expired.";
  @Input() showResendLink = false;
  @Input() resendDescription = 'Do you want another link? ';
  @Input() resendLinkText = 'Click to resend';
  @Input() showImage = true;
  @Input() showLogo = true;
  @Input() showNavigation = true;
  @Input() navigationText = 'Back to login';
  @Input() resendLinkType: 'link' | 'button' = 'link';
  @Output() resendLinkClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() goBack: EventEmitter<string> = new EventEmitter<string>();
}
