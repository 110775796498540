<div class="row section-content" style="line-height: 28px">
  <div class="col">
    <h4 style="padding: 32px 0 16px">
      {{ 'Notice to Public Users' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'All users are requested to read the following notices before\n' +
          '                  using this Portal for proper understanding of the limitations\n' +
          "                  of the contents and scope of Irembo's responsibilities\n" +
          '                  pertaining to all provisions stated hereunder.'
          | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">{{ 'General Disclaimer' | translate }}</h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'While Irembo has made every reasonable effort to ensure that\n' +
          '                  the contents of this Portal have been obtained from reliable\n' +
          '                  sources, Irembo is not responsible for any errors or\n' +
          '                  omissions, or for the results obtained or consequences arising\n' +
          '                  from the use of any of the Contents.' | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">{{ 'Links Disclaimer' | translate }}</h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'The Portal links to backend systems that are managed by other\n' +
          '                  Government of Rwanda departments and agencies that are not\n' +
          '                  under the control of Irembo. We have no control over the\n' +
          '                  nature, content and availability of those systems.'
          | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'Links to other systems are inserted for accessibility only and\n' +
          '                  do not constitute endorsement of materials on the linked\n' +
          '                  websites.' | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">{{ 'Payment Disclaimer' | translate }}</h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'Bill payments by you to the services may initially be made\n' +
          '                  through different payment modes such as mobile payment\n' +
          '                  services, credit & debit cards and with mVisa hosted by our\n' +
          '                  designated banks. The Payment Gateway/Internet Banking service\n' +
          '                  is provided to you in order to facilitate access to pay your\n' +
          '                  services remotely. Irembo makes no representation of any kind,\n' +
          '                  express or implied, as to the operation of the Payment\n' +
          '                  Gateway. You expressly agree that your use of this online\n' +
          '                  payment service is entirely at your own responsibility\n' +
          '                  together with your banker.' | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'The debit/credit card banking details provided by you for use\n' +
          '                  of the Payment Gateway/ Internet Banking will be correct and\n' +
          '                  accurate and you shall not use a Debit /credit card/Net\n' +
          '                  Banking which is not lawfully owned by you.'
          | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'If you use our Services for paying services, the Portal will\n' +
          '                  retain log files in relation to a service of who applied,\n' +
          '                  when, how much you paid and how you paid but will not retain\n' +
          '                  personal identification number (PINs) or passwords used by\n' +
          '                  payment processor and other personal data used for\n' +
          '                  identification verification with a payment processor on the\n' +
          '                  platform, any Relevant Database, and related servers or\n' +
          '                  storage devices, without the express written permission of the\n' +
          '                  End-User.' | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">{{ 'Refund' | translate }}</h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'If Irembo is notified for a valid refund request from an End\n' +
          '                  User in regarding the service (or a refund is owed to an End\n' +
          '                  User in accordance with Applicable Laws), Irembo will promptly\n' +
          '                  proceed with reimbursing such fee.' | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'To the extent permitted by law, the total liability of Irembo,\n' +
          '                  and its subcontractors, for any claims under these terms,\n' +
          '                  including for any implied warranties, is limited to the amount\n' +
          '                  you paid to get the service on the Portal (or, if we choose,\n' +
          '                  to provide you the Services again).' | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">
      {{ 'Third Parties and Affiliate' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'Irembo uses a variety of third-party service providers to help\n' +
          '                  in providing services, such as agents, Internet providers, and\n' +
          '                  bankers. There is no extra cost to an End-User to request for\n' +
          '                  any service available on this Portal apart from the fee fixed\n' +
          '                  displayed on the Portal and Irembo will not be liable for any\n' +
          '                  misuse, loss or damage, special, consequential, exemplary, or\n' +
          '                  punitive damages arising out of or in connection with our\n' +
          '                  third parties. This is a comprehensive limitation of liability\n' +
          '                  that applies to all damages of any kind, including (without\n' +
          '                  limitation) compensatory, direct, indirect or consequential\n' +
          '                  damages, loss of data, income or profit, loss of or damage to\n' +
          '                  property and claims of third parties.' | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">{{ 'Virus Protection' | translate }}</h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'Irembo makes every effort to check and test material at all\n' +
          '                  stages of the building of the Government Online Portal. It is\n' +
          '                  always wise for users to run an antivirus program on all\n' +
          '                  material downloaded from the Internet.' | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'Irembo cannot accept any responsibility for any loss,\n' +
          '                  disruption or damage to any of your data or computer system\n' +
          '                  which may occur whilst using material from this website and\n' +
          '                  the other government websites.' | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'We take precautions to maintain the security, confidentiality,\n' +
          '                  and integrity of the information we collect at this site. Such\n' +
          '                  measures include access controls designed to limit access to\n' +
          '                  information to the extent necessary to accomplish our mission.'
          | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'We also employ various security technologies to protect the\n' +
          '                  information stored on our systems' | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">{{ 'Governing Law' | translate }}</h4>
    <p class="text-color" style="font-size: 18px; padding-bottom: 8rem">
      {{
        'These Terms and any non-contractual obligations arising out of\n' +
          '                  or in connection with them are governed by and shall be\n' +
          '                  construed in accordance with Rwandan law. Any dispute relating\n' +
          '                  to or arising out of these Terms and any non-contractual\n' +
          '                  obligations arising out of or in connection with them shall be\n' +
          '                  subject to the exclusive jurisdiction of Rwandan courts.'
          | translate
      }}
    </p>
  </div>
</div>
