<irembogov-double-id-input
  [formControl]="formControl"
  [id]="field.key"
  [statusClass]="statusClass"
  [isRequired]="field.props?.required"
  [firstIdentityLabel]="firstIdentityLabel"
  [firstIdentityPlaceholder]="firstIdentityPlaceholder"
  [firstIdentityDocumentTypeKey]="firstIdentityDocumentTypeKey"
  [firstIdentityValueKey]="firstIdentityValueKey"
  [secondIdentityLabel]="secondIdentityLabel"
  [secondIdentityPlaceholder]="secondIdentityPlaceholder"
  [secondIdentityDocumentTypeKey]="secondIdentityDocumentTypeKey"
  [secondIdentityValueKey]="secondIdentityValueKey"
  [allowedDocumentTypes]="this.field.props?.['allowedDocumentTypes'] ?? []"
  (fetchDataFromApi)="onFetchDataFromApi($event)"></irembogov-double-id-input>
