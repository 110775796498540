<ng-template #content let-modal>
  <div class="service-modal-container">
    <div class="modal-header">
      <h4 class="modal-title text-irembo-blue tt_norms500">
        {{ data.serviceName }}
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="closeModal(modal, 'x')"></button>
    </div>
    <div class="modal-body modal-dialog">
      <h5 class="text-irembo-blue tt_norms400 modal-sub-title">
        {{ 'About' | translate }} {{ data.serviceName }}
      </h5>
      <div class="service-details">
        <p>{{ data.serviceSummary }}</p>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary ms-2" (click)="apply(modal)">
        {{ 'Apply' | translate }}
      </button>
    </div>
  </div>
</ng-template>
