import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Business } from '../../../../../app/models/business.model';
import { UserBusinessService } from '../../../../../app/services/user-business.service';

@Component({
  selector: 'irembogov-business-list',
  templateUrl: './business-list.component.html',
  styleUrls: ['./business-list.component.scss'],
})
export class BusinessListComponent {
  businesses?: Business[] = [];

  constructor(
    private router: Router,
    private userBusinessService: UserBusinessService
  ) {
    this.businesses = this.userBusinessService.getUserBusinesses();
  }

  viewApplications(businessId: string) {
    this.router.navigate(['profile', 'applications', 'business', businessId]);
  }

  viewCertificates(businessId: string) {
    console.log('No Certificates for ' + businessId);
    //this.router.navigate(['profile', 'certificates', 'business', businessId]);
  }
}
