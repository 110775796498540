<div
  class="featured-services d-flex flex-column justify-content-lg-center justify-content-md-center justify-content-sm-start align-content-center">
  <div
    class="d-flex justify-content-lg-center justify-content-md-center justify-content-sm-center">
    <h3 class="text-lg-center text-md-center text-sm-start">
      {{ 'Top picks for you' | translate }}
    </h3>
  </div>
  <div class="item-slider mt-3">
    <div
      class="float-start ms-2 d-none d-lg-inline-block d-md-inline-block"
      *ngIf="scrollX > 0">
      <button class="prev-button" (click)="scroll(-150)">
        <i class="fa-solid fa-angle-left"></i>
      </button>
    </div>
    <div #scrl class="item-container" (scroll)="scrollCheck()">
      <button
        *ngFor="let categoryService of services"
        class="service-widget btn btn-link text-decoration-none ps-0"
        (click)="selectService(categoryService)">
        {{ categoryService.service.serviceName | translate }}
      </button>
    </div>
    <div
      class="float-end d-none d-lg-inline-block d-md-inline-block"
      *ngIf="!scrollEnd">
      <button class="next-button" (click)="scroll(150)">
        <i class="fa-solid fa-angle-right"></i>
      </button>
    </div>
  </div>
</div>
