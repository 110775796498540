<div class="searchbox">
  <div
    class="px-2 py-2 bg-white rounded-3 border border-1 border-secondary align-items-center">
    <div class="hstack gap-1">
      <div
        class="position-relative py-2 object-fit-contain d-flex justify-content-between align-items-center">
        <img src="../../../assets/img/search-lg.svg" alt="" class="ms-3" />
      </div>
      <div class="text-start w-100">
        <input
          #search
          type="text"
          class="text-dark fs-6 fw-normal py-2 border-0 form-control search-input"
          placeholder="{{
            'Search by service name, category, or institution (e.g., certificate, visas, DGIE)'
              | translate
          }}"
          (keyup)="onKeyUp()"
          (focus)="setFocus(1)"
          (blur)="setFocus(0)"
          [value]="value"
          (keydown.enter)="onEnter()" />
        <div class="col-1">
          <span
            *ngIf="search.value"
            class="clear-search"
            (click)="search.value = ''">
            {{ 'Clear' | translate }}
          </span>
          <span class="searching" *ngIf="isSearching">
            <div
              class="spinner-border text-bg-irembo-blue spinner-border-sm"
              role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </span>
        </div>
      </div>
      <ul
        class="predictions position-absolute py-2 px-0 list-unstyled"
        *ngIf="search.value && focused">
        <li
          class="px-2"
          *ngFor="let result of searchResults"
          (mousedown)="handleSelect(result)">
          <span class="service-name">
            {{ result.serviceName | translate }}
          </span>
          <small class="ms-2 text-uppercase" [style.color]="'#475467'">{{
            result.organization.name | translate
          }}</small>
        </li>
      </ul>
    </div>
  </div>
</div>
