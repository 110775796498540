import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CERTIFICATESTATUS } from '../../models/certificate-status.enum';
import { ApplicationsService } from '../../services/applications.service';
import { Subscription, finalize } from 'rxjs';
import { ApplicationCertificateValidity } from '../../models/application-certificate-response-model';
import { NgOtpInputComponent } from 'ng-otp-input';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { PreviewCertificateComponent } from '../../component/preview-certificate/preview-certificate.component';

@Component({
  selector: 'irembogov-verify-certificate-qr-scan',
  templateUrl: './verify-certificate-qr-scan.component.html',
  styleUrls: ['./verify-certificate-qr-scan.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerifyCertificateQrScanComponent implements OnInit, OnDestroy {
  applicationNumber!: string;

  token: string | undefined;
  invalidToken = false;
  isPreviewingCertificate = false;
  certificateStatusOptions = CERTIFICATESTATUS;

  isCertificateLoading = false;
  certificateStatus?: ApplicationCertificateValidity;

  @ViewChild('viewCertificateModalContent')
  viewCertificateModalContent!: TemplateRef<unknown>;

  private subscriptions = new Subscription();

  constructor(
    public route: ActivatedRoute,
    private modalService: NgbModal,
    private applicationService: ApplicationsService,
    private toastService: ToastService
  ) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const paramApplicationNumber: string | null =
        params.get('applicationNumber');
      if (paramApplicationNumber) {
        this.applicationNumber = paramApplicationNumber;
      }
    });
  }

  ngOnInit(): void {
    this.checkCertificateValidity();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  checkCertificateValidity() {
    this.isCertificateLoading = true;
    this.applicationService
      .checkApplicationCertificateValidity(this.applicationNumber)
      .pipe(finalize(() => (this.isCertificateLoading = false)))
      .subscribe({
        next: res => {
          this.certificateStatus = res.data;
        },
        error: () => {
          this.certificateStatus = {
            validityStatus: CERTIFICATESTATUS.INVALID,
          };
          this.toastService.show({
            body: 'Error checking certificate',
            type: 'error',
          });
        },
      });
  }

  openModel(
    content: unknown,
    windowClass: string,
    size?: string,
    backdrop?: string
  ) {
    let options: Record<string, unknown> = {
      ariaLabelledBy: 'verify-certificate',
      windowClass,
      centered: true,
      size,
    };
    if (backdrop) {
      options = { ...options, backdrop };
    }
    return this.modalService.open(content, options);
  }

  openVerifyModal(content: TemplateRef<unknown>) {
    this.openModel(content, 'verify-certificate-modal');
  }

  openTokenModal(content: TemplateRef<unknown>) {
    this.modalService.dismissAll();
    this.openModel(content, 'scan-token-input-modal');
  }

  getApplicationStatusIcon(status: string): string {
    const icons: Record<string, string> = {
      INVALID: 'modal_icon_close.svg',
      VALID: 'modal_icon_success.svg',
      EXPIRED: 'modal_icon_warning.svg',
    };
    return icons[status];
  }

  onOtpChange(event: string, ngOtpInputRef: NgOtpInputComponent) {
    this.token = event;

    if (ngOtpInputRef) {
      const controls = ngOtpInputRef.otpForm.controls;
      for (const key in controls) {
        if (Object.prototype.hasOwnProperty.call(controls, key)) {
          const element = controls[key];
          if (!element.value) {
            element.reset();
          }
        }
      }
    }
  }

  confirmCertificate(ngOtpInputRef: NgOtpInputComponent) {
    if (!this.token) {
      return;
    }

    this.isPreviewingCertificate = true;

    this.subscriptions.add(
      this.applicationService
        .verifyApplicationToken(this.applicationNumber, this.token)
        .subscribe({
          next: res => {
            this.isPreviewingCertificate = false;
            const base64Data = `data:${res.data.contentType};base64,${res.data.file}`;
            this.modalService.dismissAll();
            const modalRef = this.openModel(
              PreviewCertificateComponent,
              'certificate-preview',
              'xl',
              'static'
            );
            modalRef.componentInstance.base64Data = base64Data;
          },
          error: () => {
            this.markControlsAsInValid(ngOtpInputRef);
            this.isPreviewingCertificate = false;
          },
        })
    );
  }

  private markControlsAsInValid(ngOtpInputRef: NgOtpInputComponent) {
    if (ngOtpInputRef) {
      this.invalidToken = true;
      const controls = ngOtpInputRef.otpForm.controls;
      for (const key in controls) {
        if (Object.prototype.hasOwnProperty.call(controls, key)) {
          const element = controls[key];
          element.setErrors({ invalidToken: true });
        }
      }
    }
  }

  onClosePreviewModal() {
    this.modalService.dismissAll();
  }
}
