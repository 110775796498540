<form
  *ngIf="form"
  [formGroup]="form"
  [ngClass]="{ 'linear-cascading-drop-downs': linear }">
  <ng-container *ngFor="let field of configs; let index = index">
    <div
      *ngIf="!field.parent || form.controls[field.parent].value"
      class="row mb-3">
      <div class="col">
        <label [for]="field.key" class="form-label tt_norms400"
          >{{ field.label }} <span *ngIf="field.required">*</span>
        </label>
        <irembogov-select
          [loading]="loading[field.key]"
          [formControlName]="field.key"
          [items]="items && items[field.key] ? items[field.key] : null"
          [bindLabel]="field.bindlabel"
          ngDefaultControl
          [bindValue]="field.bindvalue"
          [placeholder]="field.placeholder"
          [labelForId]="field.key"
          [multiple]="false"
          [virtualScroll]="true"
          (itemSelected)="
            onSelectChange($event, field.key, index)
          "></irembogov-select>

        <div
          class="invalid-feedback"
          style="display: block"
          *ngIf="
            form.controls[field.key].invalid &&
            (form.controls[field.key].dirty || form.controls[field.key].touched)
          ">
          <ng-container *ngIf="form.controls[field.key].errors">
            {{ field.label }} is required
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</form>
