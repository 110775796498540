<div class="container-fluid bg-white min-vh-100">
  <div
    class="container-fluid d-flex flex-column justify-content-lg-center justify-content-md-center justify-content-sm-start align-items-lg-center align-items-md-center align-items-sm-start pt-5 min-vh-100">
    <div class="mb-5">
      <img
        src="../../../../assets/img/logo.png"
        alt="Irembo logo"
        height="20"
        width="109" />
    </div>
    <div class="text-lg-center text-md-center text-sm-start">
      <h1 class="mb-2">
        {{ 'Check your email' | translate }}
      </h1>
      <div class="sub-heading">{{ message | translate }}</div>
      <div class="receiver mb-3">{{ email }}</div>
      <a
        class="text-lg-center text-md-center text-sm-start mt-3 fw-bold text-decoration-none"
        routerLink="/profile"
        ><i class="fa-solid fa-angle-left me-2"></i
        >{{ 'Return to log in' | translate }}</a
      >
    </div>
  </div>
</div>
