<ngb-alert #alert [type]="type" [dismissible]="dismissible" *ngIf="openAlert">
  <strong class="title d-block text-capitalize">{{ title }}</strong>
  <div class="message my-2">{{ message }}</div>
  <ng-container *ngIf="extraMessage">
    <div class="small message my-2" *ngIf="!extraMessageAsHtml">
      {{ extraMessage }}
    </div>
    <div
      class="small message my-2"
      *ngIf="extraMessageAsHtml"
      [innerHtml]="extraMessage"></div>
  </ng-container>
  <div
    *ngIf="actionText"
    class="action-link text-capitalize"
    (click)="onActionClicked()">
    {{ actionText }}
  </div>
</ngb-alert>
