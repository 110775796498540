import { Injectable } from '@angular/core';
import { TDateFormatTypes } from '../../models/date-picker-date-format.types';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class CustomDateAdapterService extends NgbDateAdapter<string> {
  adapterDelimeter = '/';
  adapterDateFormat: TDateFormatTypes = 'DD MM YYYY';

  setDelimeter(delimeter: string | undefined): void {
    if (!delimeter) return;
    this.adapterDelimeter = delimeter;
  }

  setDateFormatType(dateFormatType: TDateFormatTypes | undefined): void {
    if (!dateFormatType) return;
    this.adapterDateFormat = dateFormatType;
  }

  fromModel(value: string | null): NgbDateStruct | null {
    if (!(value && typeof value === 'string')) return null;
    const date = value.split(this.adapterDelimeter);

    if (this.adapterDateFormat === 'MM DD YYYY') {
      return {
        day: parseInt(date[1], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10),
      };
    }

    return {
      day: parseInt(date[0], 10),
      month: parseInt(date[1], 10),
      year: parseInt(date[2], 10),
    };
  }

  toModel(date: NgbDateStruct | null): string | null {
    if (this.adapterDateFormat === 'MM DD YYYY') {
      return date
        ? date.month.toString().padStart(2, '0') +
            this.adapterDelimeter +
            date.day.toString().padStart(2, '0') +
            this.adapterDelimeter +
            date.year
        : null;
    }

    return date
      ? date.day.toString().padStart(2, '0') +
          this.adapterDelimeter +
          date.month.toString().padStart(2, '0') +
          this.adapterDelimeter +
          date.year
      : null;
  }
}
