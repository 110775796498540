import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { protectedEndpoints } from '../constants';
import { _ } from '../utils/sec.util';

@Injectable()
export class SatHeaderInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const isURLProtected: boolean = protectedEndpoints.some(
      (endpoint: string) => {
        return request.url.includes(endpoint);
      }
    );
    if (isURLProtected) {
      request = request.clone({
        setHeaders: {
          SAT: _(),
        },
      });
    }
    return next.handle(request);
  }
}
