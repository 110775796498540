import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivationActivateAccountComponent } from './components/activation-activate-account/activation-activate-account.component';
import { ActivationLinkExpiredComponent } from './components/activation-link-expired/activation-link-expired.component';
import { AuthActionSuccessfulComponent } from './components/auth-action-successful/auth-action-successful.component';
import { ActivationAccountActivatedComponent } from './components/activation-account-activated/activation-account-activated.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { TokenSentComponent } from './components/token-sent/token-sent.component';
import { ConfirmLogoutComponent } from './components/confirm-logout/confirm-logout.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { OtpEntryComponent } from './components/otp-entry/otp-entry.component';
import { ResendAuthOtpComponent } from './components/resend-auth-otp/resend-auth-otp.component';
import { NonCitizenAccountActivationComponent } from './components/non-citizen-auth-components/non-citizen-account-activation/non-citizen-account-activation.component';
import { NonCitizenResendActivationOtpComponent } from './components/non-citizen-auth-components/non-citizen-resend-activation-otp/non-citizen-resend-activation-otp.component';
import { NonCitizenResetPasswordComponent } from './components/non-citizen-auth-components/non-citizen-reset-password/non-citizen-reset-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UtilsModule } from '../utils/utils.module';
import {
  MissingTranslationHandler,
  TranslateModule,
} from '@ngx-translate/core';
import { PasswordStrengthMeterComponent } from './components/password-strength-meter/password-strength-meter.component';
import { IremboMissingTranslationHandler } from '../utils/utils/missing-translations-handler';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    UtilsModule,
    TranslateModule.forChild({
      useDefaultLang: true,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: IremboMissingTranslationHandler,
      },
    }),
  ],
  declarations: [
    ActivationActivateAccountComponent,
    ActivationLinkExpiredComponent,
    AuthActionSuccessfulComponent,
    ActivationAccountActivatedComponent,
    OtpEntryComponent,
    ResendAuthOtpComponent,
    ForgotPasswordComponent,
    PasswordResetComponent,
    TokenSentComponent,
    ConfirmLogoutComponent,
    NonCitizenAccountActivationComponent,
    NonCitizenResendActivationOtpComponent,
    NonCitizenResetPasswordComponent,
    ChangePasswordComponent,
    PasswordStrengthMeterComponent,
  ],
  exports: [
    ActivationActivateAccountComponent,
    ActivationLinkExpiredComponent,
    AuthActionSuccessfulComponent,
    ActivationAccountActivatedComponent,
    OtpEntryComponent,
    ResendAuthOtpComponent,
    ForgotPasswordComponent,
    PasswordResetComponent,
    TokenSentComponent,
    ConfirmLogoutComponent,
    NonCitizenAccountActivationComponent,
    NonCitizenResendActivationOtpComponent,
    NonCitizenResetPasswordComponent,
    ChangePasswordComponent,
  ],
})
export class AuthModule {}
