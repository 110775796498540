import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { TUiAlertTypes } from '../../models/IUiAlertTypes';

@Component({
  selector: 'irembogov-irembo-section-message',
  templateUrl: './irembo-section-message.component.html',
  styleUrls: ['./irembo-section-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IremboSectionMessageComponent {
  @Input() openAlert: boolean;
  @Input() dismissible = false;
  @Input() title = 'Title Here';
  @Input() message = 'Message Here';
  @Input() extraMessage?: string;
  @Input() extraMessageAsHtml = false;
  @Input() type: TUiAlertTypes = 'warning';
  @Input() actionText = '';
  @Output() actionClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('alert') alertElement!: NgbAlert;

  constructor() {
    this.openAlert = true;
  }

  onActionClicked() {
    this.actionClicked.emit(true);
  }
}
