<div class="search-header">
  <div class="search-container">
    <irembogov-searchbox
      (resultSelected)="onResultSelected($event)"
      (keyEnterEvent)="onKeyPresedSelected($event)"
      [value]="keyword"></irembogov-searchbox>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col">
      <div class="list-container">
        <ng-container *ngIf="searchResults?.length !== 0">
          <ul class="search-results-filter">
            <li
              class="tt_norms400"
              [ngClass]="{ active: 'all' === selectedOrgFilter }"
              (click)="resetFilter()">
              <i class="fa-regular fa-list-alt"></i>
              {{ 'All Results' | translate }}
            </li>
            <li
              class="tt_norms400"
              [ngClass]="{ active: filter === selectedOrgFilter }"
              *ngFor="let filter of orgFilters"
              (click)="filterByOrg(filter)">
              {{ filter }}
            </li>
          </ul>
          <div class="pages">
            1 - 4 <span>of 4 {{ 'Results' | translate }}</span>
          </div>

          <div class="row">
            <div class="col-md-9">
              <ng-container *ngIf="filteredSearchResults.length === 0">
                <irembogov-service-search-result
                  *ngFor="let result of searchResults"
                  [service]="result"
                  (applyClicked)="
                    handleApply(modalID, result)
                  "></irembogov-service-search-result>
              </ng-container>
              <ng-container *ngIf="filteredSearchResults.length !== 0">
                <irembogov-service-search-result
                  *ngFor="let result of filteredSearchResults"
                  [service]="result"
                  (applyClicked)="
                    handleApply(modalID, result)
                  "></irembogov-service-search-result>
              </ng-container>
              <div
                *ngIf="totalItems"
                class="d-flex align-items-start flex-column mt-4">
                <irembogov-pagination
                  [collectionSize]="totalItems"
                  (paginatedValues)="
                    handlePaginate($event)
                  "></irembogov-pagination>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="searchResults?.length === 0">
          <div class="row">
            <div class="col">
              <h1 class="no-results-title tt_norms500">
                {{ 'No Results Found for' | translate }} "<span>{{
                  keyword
                }}</span
                >"
              </h1>
              <div class="no-results-links">
                <div class="image">
                  <img
                    src="/assets/img/no-results.svg"
                    alt="No Results Found" />
                </div>
                <div class="links">
                  <span class="text me-3"
                    >{{ 'browse services by' | translate }}
                  </span>
                  <a
                    [routerLink]="['/services/browse-by']"
                    [queryParams]="{ type: 'institution' }"
                    class="btn btn-outline-primary me-3">
                    <strong> {{ 'Institutions' | translate }} </strong>
                    <i aria-hidden="true" class="fa fa-arrow-right ms-2"></i>
                  </a>
                  <span class="text me-3"> {{ 'or' | translate }} </span>
                  <a
                    [routerLink]="['/services/browse-by']"
                    [queryParams]="{ type: 'category' }"
                    class="btn btn-outline-primary me-3">
                    <strong> {{ 'Categories' | translate }} </strong>
                    <i aria-hidden="true" class="fa fa-arrow-right ms-2"></i>
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h5 class="fw-bold mb-4 mt-5 text-irembo-blue">
                    {{ 'Featured Services' | translate }}
                  </h5>
                  <div class="row">
                    <div
                      class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4 position-relative"
                      *ngFor="let service of featuredServices; index as i">
                      <div class="card no-results-card">
                        <div
                          class="card-body"
                          (click)="handleApply(modalID, service)">
                          <div class="row">
                            <div class="col-10 mb-1">
                              <h5 class="card-title text-irembo-blue p-0 m-0">
                                {{ service.serviceName | translate }}
                              </h5>
                            </div>
                            <div
                              class="col-2 px-0 d-flex align-items-center justify-content-end">
                              <i
                                aria-hidden="true"
                                class="fa fa-arrow-right"></i>
                            </div>
                            <small class="col-12 text-uppercase mb-3 org-name">
                              {{ service.organization.name }}
                            </small>
                          </div>
                          <small class="card-text">
                            {{ service.serviceSummary | translate }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<irembogov-service-modal
  *ngIf="selectedApplication"
  (modalID)="modalID = $event"
  [data]="selectedApplication"></irembogov-service-modal>
