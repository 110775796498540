import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgOtpInputConfig } from 'ng-otp-input';
import {
  CountdownComponent,
  CountdownConfig,
  CountdownEvent,
} from 'ngx-countdown';

@Component({
  selector: 'irembogov-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
})
export class OtpInputComponent implements OnChanges {
  @Input() isLoading = false;
  @Input() restartTimer = false;
  @Output() formSubmit: EventEmitter<string> = new EventEmitter();
  @Output() formError: EventEmitter<string> = new EventEmitter();

  isLower = false;

  config: CountdownConfig = {
    leftTime: 300,
    notify: [60],
    format: 'mm:ss',
  };

  otp: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
  ]);

  otpInputConfig: NgOtpInputConfig = {
    length: 5,
    allowNumbersOnly: true,
    placeholder: '-',
    inputClass: 'enter-code-frame-input',
    containerClass: 'enter-code-frame',
  };

  @ViewChild('cd', { static: false }) private countdown:
    | CountdownComponent
    | undefined;

  ngOnChanges(changes: SimpleChanges) {
    const restartTimerValue = changes['restartTimer'];
    if (restartTimerValue?.currentValue === true && this.countdown) {
      this.countdown.restart();
      this.restartTimer = false;
    }
  }

  handleEvent(e: CountdownEvent) {
    if (e.action === 'notify') {
      // add next theme class
      this.isLower = true;
    }
  }

  submit() {
    if (this.otp.invalid) {
      this.formError.emit('Otp must be 5 digits long');
      return;
    }

    this.formSubmit.emit(this.otp.value);
  }
}
