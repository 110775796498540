<irembogov-irembo-date-picker
  [formControl]="formControl"
  ngDefaultControl
  [maxDate]="field.props['maxDate']"
  [addRemoveTimeMinDate]="field.props['addRemoveTimeMinDate']"
  [addRemoveTimeMaxDate]="field.props['addRemoveTimeMaxDate']"
  [allowedDaysOfTheWeek]="field.props['allowedDaysOfTheWeek']"
  [minDate]="field.props['minDate']"
  [placeholder]="field.props['placeholder']"
  [dateFormat]="field.props['dateFormat']"
  [eventCode]="field.props['eventCode']"
  [eventDatesMaxSize]="field.props['eventDatesMaxSize']"
  [delimeter]="field.props['delimeter']"></irembogov-irembo-date-picker>
