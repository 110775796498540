<ng-container
  *ngTemplateOutlet="
    loadingFile ? loading : !file ? noFile : hasFile
  "></ng-container>

<ng-template #noFile>
  <div
    [ngClass]="{ 'cta-drag-enter': addDragClass }"
    class="file-upload-container noFile"
    (drop)="toogledragClass($event, false)"
    (dragenter)="toogledragClass($event, true)"
    (dragleave)="toogledragClass($event, false)">
    <input
      (click)="onClickFileUpload($event)"
      [accept]="acceptList()"
      type="file"
      (change)="change($event)"
      class="uploader file-upload-input"
      [disabled]="disabled" />
    <div class="uploader-container">
      <i class="fa-solid fa-file-circle-plus"></i>
      <button
        type="button"
        class="btn btn-primary btn-sm cta-btn"
        (click)="selectFile(selectfile)">
        {{ placeholder }}
      </button>
      <p>{{ 'Or drag and drop it here' | translate }}</p>
    </div>
  </div>
</ng-template>

<ng-template #hasFile>
  <div class="file-upload-container" [ngClass]="{ loading: validatingPhoto }">
    <div class="preview">
      <div class="preview-info">
        <div class="file-icon">
          <i class="fa fa-file-text"></i>
        </div>
        <div class="file-details">
          <h1 class="tt_norms500" [ngClass]="{ hasErros: hasErrors }">
            {{ file?.fileName }}
            <span class="success" *ngIf="!hasErrors && !loadingFile">
              <i class="fa fa-check-circle"></i>
            </span>
            <span class="error" *ngIf="hasErrors && !loadingFile">
              <i class="fa fa-times-circle"></i>
            </span>
          </h1>
          <div class="uploader-group" *ngIf="!loadingFile">
            <label [for]="id + '_cd'" class="link-label tt_norms400">{{
              'Choose a different document' | translate
            }}</label>
            <input
              [accept]="acceptList()"
              [id]="id + '_cd'"
              (change)="change($event)"
              type="file"
              [disabled]="disabled"
              class="file-upload-input" />
            <button
              type="button"
              *ngIf="file"
              [disabled]="!canPreview"
              (click)="preview(content)"
              class="btn btn-sm btn-outline-primary">
              {{ 'Preview' | translate }}
              <i class="fa-regular fa-eye" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="remove-icon">
        <button type="button" class="btn btn-link p-0" (click)="removeFile()">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>

  <div
    *ngIf="validatePhoto && failedChecks.length > 0"
    class="invalid-feedback photo-validation-errors"
    style="display: block">
    <div>
      <span style="font-size: 24px; font-weight: bold"
        >Sorry, your photo is not a compliant passport photo.</span
      ><br />
      Make sure that:
      <ul>
        <li>The passport photo is on a white background</li>
        <li>
          The face is positioned in the center for both vertical and horizontal
          directions
        </li>
        <li>Eyes are on the same position and looking straight forward</li>
      </ul>
      Please check this article on our knowledge base for more details:
      <a
        href="https://support.irembo.gov.rw/en/support/solutions/articles/47001165759-how-to-apply-for-an-e-passport-application"
        >click here</a
      >
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="file-upload-container">
    <div class="preview">
      <div class="preview-info">
        <div class="file-icon">
          <i class="fa fa-file-text"></i>
        </div>
        <div class="file-details">
          <h1 class="tt_norms500">
            {{ loadingFileName }}
          </h1>
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              [attr.aria-valuenow]="progress | async"
              [style.width]="(progress | async) + '%'"
              aria-valuemin="0"
              aria-valuemax="100"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-body">
    <div class="modal-header-preview">
      <h1 class="tt_norms500">
        <i class="fa fa-file-text"></i> {{ file?.fileName }}
      </h1>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <ng-container
      *ngIf="
        file?.fileType === 'application/pdf' && base64Data;
        else imagePreview
      ">
      <pdf-viewer
        [src]="base64Data"
        [render-text]="true"
        [original-size]="true"
        style="width: 100%; height: 80vh; margin-top: 15px"></pdf-viewer>
    </ng-container>
  </div>
</ng-template>

<ng-template #selectfile let-modal>
  <div class="modal-header">
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <button
        type="button"
        class="nav-link tt_norms500 active"
        id="nav-certificates"
        data-bs-toggle="tab"
        data-bs-target="#certificates"
        type="button"
        role="tab"
        aria-controls="certificates"
        aria-selected="true">
        {{ 'My Certificates' | translate }}
      </button>
      <button
        type="button"
        class="nav-link tt_norms500"
        id="nav-attachments"
        data-bs-toggle="tab"
        data-bs-target="#attachments"
        type="button"
        role="tab"
        aria-controls="attachments"
        aria-selected="false">
        {{ 'Previous Attachment' | translate }}
      </button>

      <div class="nav-link tt_norms500 tab-file-upload" role="button">
        <label [for]="id">{{ 'Upload from device' | translate }}</label>
        <input
          class="file-upload-input"
          [accept]="acceptList()"
          [id]="id"
          (change)="change($event)"
          type="file"
          [disabled]="disabled" />
      </div>
    </div>
    <button
      type="button"
      class="btn-close d-none d-sm-block"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="tab-content" id="nav-tabContent">
      <div
        class="tab-pane fade show active"
        id="certificates"
        role="tabpanel"
        aria-labelledby="nav-certificates-tab">
        <p>
          {{
            'Choose a document from the selection below, or alternatively, you may upload a document directly from your computer'
              | translate
          }}
        </p>

        <div
          #certificateAttachmentsContainer
          class="table-responsive table-container custom-table certificateAttachmentsContainer"
          (scroll)="onCertificateAttachmentScroll()">
          <table
            class="table table-bordered table-hover"
            aria-describedby="list of user applications">
            <thead>
              <tr>
                <th class="icon-column" scope="col"></th>
                <th scope="col">{{ 'Name' | translate }}</th>
                <th scope="col">{{ 'Issued' | translate }}</th>
                <th scope="col">{{ 'Expiration' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let cert of certificateAttachments"
                (click)="handleSelectCertificateAttachment(cert)">
                <td class="icon-color">
                  <i
                    class="icon-style"
                    [ngClass]="getIcon(cert['certificateName'])"></i>
                </td>
                <td class="tt_norms500">{{ cert.certificateName }}</td>
                <td>
                  {{ castToString(cert.dateCreated) | date : 'dd-MM-yyyy' }}
                </td>
                <td>
                  {{
                    castToString(cert.certificateExpiration)
                      | date : 'dd-MM-yyyy'
                  }}
                </td>
              </tr>
              <tr *ngIf="certificateAttachments.length === 0">
                <td colspan="3">{{ 'No certificates' | translate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="attachments"
        role="tabpanel"
        aria-labelledby="nav-attachments-tab">
        <p>
          {{
            'Choose a document from the selection below, or alternatively, you may upload a document directly from your computer'
              | translate
          }}
        </p>
        <div
          #previousAttachmentsContainer
          class="table-responsive table-container custom-table previousAttachmentsContainer"
          (scroll)="onPreviousAttachmentScroll()">
          <table
            class="table table-bordered table-hover"
            aria-describedby="list of user applications attachments">
            <thead>
              <tr>
                <th class="icon-column" scope="col"></th>
                <th scope="col">{{ 'Name' | translate }}</th>
                <th scope="col">{{ 'Date Uploaded' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let attachment of previousAttachments"
                (click)="handleSelectPreviousAttachment(attachment)">
                <td class="icon-color">
                  <i
                    class="icon-style"
                    [ngClass]="getIcon(attachment['fileName'])"></i>
                </td>
                <td class="tt_norms500">{{ attachment['fileName'] }}</td>
                <td>
                  {{
                    castToString(attachment['dateCreated'])
                      | date : 'dd-MM-yyyy'
                  }}
                </td>
              </tr>
              <tr *ngIf="previousAttachments.length === 0">
                <td colspan="2">{{ 'No previous attachments' | translate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      aria-label="Close"
      (click)="modal.close('Cross click')">
      {{ 'Close' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #imagePreview>
  <img
    *ngIf="filePath"
    [src]="filePath"
    alt="File upload preview"
    class="w-100 h-100 mt-3 resize" />
</ng-template>
