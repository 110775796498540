import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'irembogov-verify-identity-banner',
  templateUrl: './verify-identity-banner.component.html',
  styleUrls: ['./verify-identity-banner.component.scss'],
})
export class VerifyIdentityBannerComponent {
  @Output() cancelClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() verifyUser: EventEmitter<boolean> = new EventEmitter<boolean>();

  onCancel() {
    this.cancelClicked.emit(true);
  }

  onVerify() {
    this.verifyUser.emit(true);
  }
}
