<div class="search-header">
  <div class="search-container">
    <irembogov-searchbox
      [smShadow]="true"
      (resultSelected)="searchItemSelect($event)"
      (keyEnterEvent)="searchItemPressed($event)"
      [value]="keyword"></irembogov-searchbox>
  </div>

  <div class="search-container mt-4 mt-sm-5">
    <a
      (click)="searchByQuery('')"
      class="btn bg-irembo-skyblue text-irembo-blue me-3 mb-3">
      <i aria-hidden="true" class="fa-solid fa-list ms-2"></i>
      <strong> {{ 'All Results' | translate }} </strong>
    </a>

    <ng-container *ngFor="let organization of institutions">
      <a
        (click)="searchByOrgId(organization.id)"
        class="btn bg-irembo-grey me-3 mb-3">
        <i
          aria-hidden="true"
          class="fa-solid ms-2"
          [ngClass]="selectedApplicationIcon"></i>
        <strong> {{ organization.acronym ?? '' | translate }} </strong>
      </a>
    </ng-container>
  </div>

  <div *ngIf="!isSearching" class="search-container mt-2">
    <ng-container *ngFor="let result of searchResults">
      <irembogov-service-search-result
        [service]="result"
        (applyClicked)="
          handleApply(modalID, result)
        "></irembogov-service-search-result>
    </ng-container>
    <div *ngIf="totalItems" class="d-flex align-items-start flex-column mt-4">
      <irembogov-citizen-pagination
        [collectionSize]="totalItems"
        [pageSize]="size"
        (paginatedValues)="
          paginateHandler($event)
        "></irembogov-citizen-pagination>
    </div>
  </div>

  <div
    *ngIf="isSearching"
    class="w-99 d-flex align-items-center justify-content-center text-center mt-5">
    <div
      class="spinner-grow text-primary"
      style="width: 3rem; height: 3rem"
      role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>

<irembogov-service-modal
  *ngIf="selectedApplication"
  (modalID)="modalID = $event"
  [data]="selectedApplication"></irembogov-service-modal>
