import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { ERROR_MAP } from '../../../data/errorMap';
import {
  RecipientType,
  UserType,
} from '../../../models/citizenCreationRequest.model';
import { ResendOtpDto } from '../../../models/common.model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivationRequestDto } from '../../../models/activationRequest.model';

@Component({
  selector: 'irembogov-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}
  phoneNumber = '';
  email = '';
  userFeedback = '';
  errorMessage = '';
  isLoading = false;
  restartTimer = false;
  verificationType = '';
  showCountDown = true;
  locale = '';

  ngOnInit(): void {
    this.email = this.route.snapshot.queryParamMap.get('email') ?? '';
    this.phoneNumber = this.route.snapshot.queryParamMap.get('phone') ?? '';

    if (this.email) {
      this.verificationType = 'email';
    } else {
      this.verificationType = 'phone';
    }

    this.locale = localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
  }

  onError(errorMessage: string): void {
    this.isLoading = false;
    this.errorMessage = errorMessage;
  }

  onFormSubmit(otp: string): void {
    this.isLoading = true;
    this.userFeedback = '';
    this.errorMessage = '';

    const activateAccountRequest: ActivationRequestDto = {
      clientId: environment.authClientId,
      username:
        this.verificationType === 'email' ? this.email : this.phoneNumber,
      userType: UserType.CITIZEN,
      oneTimePasswordToken: otp,
      password: null,
      locale: this.locale,
    };

    this.authService
      .activateAccount(activateAccountRequest)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.router.navigate(['auth/success'], {
            queryParams: { type: this.phoneNumber ? 'phone' : 'email' },
          });
        },
        error: (err: HttpErrorResponse) => {
          const errorCode = err.error.responseCode as string;
          if (errorCode === 'OTP_EXPIRED') {
            this.errorMessage = ERROR_MAP[errorCode] ?? err?.error?.message;
            this.showCountDown = false;
            this.restartTimer = false;
          }
          this.errorMessage =
            ERROR_MAP[errorCode] ??
            err?.error?.message ??
            'Failed to verify account';
          this.isLoading = false;
        },
      });
  }

  resendLink(): void {
    this.userFeedback = '';
    this.errorMessage = '';

    const resendOtpDto: ResendOtpDto = {
      username:
        this.verificationType === 'phone' ? this.phoneNumber : this.email,
      clientId: environment.authClientId,
      usernameType:
        this.verificationType === 'phone'
          ? RecipientType.PHONE_NUMBER
          : RecipientType.EMAIL_ADDRESS,
      userType: UserType.CITIZEN,
      locale: this.locale,
    };
    this.authService
      .resendAccountLink(resendOtpDto)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.showCountDown = true;
          this.userFeedback = 'We successfully sent you an otp';
        },
        error: (err: HttpErrorResponse) => {
          this.errorMessage = err.error.message ?? 'Fail to resend an otp';
        },
      });
  }

  goBack() {
    this.router.navigate(['/profile']);
  }

  getEncodedString(str: string): string {
    let encoded = '';
    try {
      encoded = btoa(str ?? '');
    } catch (error) {
      console.log(error);
    }

    return encoded;
  }
}
