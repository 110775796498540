import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AccountResendOtpRequestDto } from '../../../models/account-resend-otp-request.model';
import {
  RecipientType,
  UserType,
} from '../../../models/citizenCreationRequest.model';
import { AuthService } from '../service/auth.service';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs';
import { ResendTokenOtpDto } from '../../../models/common.model';

@Component({
  selector: 'irembogov-reset-link',
  templateUrl: './reset-link.component.html',
})
export class ResetLinkComponent implements OnInit {
  recipient = '';
  successTitle = '';
  successQuestion = '';
  accountStatus = '';
  isLoading = false;
  token = '';
  username = '';
  failureMessage =
    'The link to reset your password has expired.<br /> If you already reset your password, please login.';
  errorMessage = '';
  failureTitle = 'Reset password link has expired';
  locale = '';

  constructor(
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get('token') ?? '';
    this.username = this.route.snapshot.queryParamMap.get('username') ?? '';
    const linkParam = this.route.snapshot.queryParamMap.get('link');
    this.locale = localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;

    if (this.token == '' && linkParam == 'link') {
      this.accountStatus = 'resend';
      return;
    }

    // Means user is coming from login page. Route them to forget password page for email/phone input
    if (this.token == '') {
      this.router.navigate(['auth/reset']);
      return;
    }

    this.verifyLink(this.token, this.username);
  }

  verifyLink(token: string, username: string) {
    this.authService.verifyResetLink(token, username).subscribe({
      next: () => {
        this.router.navigate(['auth/reset-password'], {
          queryParams: { token: this.token, username: this.username },
        });
      },
      error: () => {
        this.accountStatus = 'failure';
      },
    });
  }

  resendLinkClicked() {
    this.errorMessage = '';

    const resendTokenOtpDto: ResendTokenOtpDto = {
      token: this.token,
      clientId: environment.authClientId,
      userType: UserType.CITIZEN,
      username: this.username,
    };

    this.authService.resendResetTokenExpired(resendTokenOtpDto).subscribe({
      next: () => {
        this.toastService.show({
          type: 'success',
          body: `Email sent, check your email`,
        });
      },
      error: (err: HttpErrorResponse) => {
        this.toastService.show({ type: 'error', body: 'Fail to resend link' });
        this.errorMessage = err.error.message ?? 'Fail to resend link';
        this.accountStatus = 'failure';
      },
    });
  }

  onGoToLogin() {
    this.router.navigate(['/profile']);
  }

  goToOtp() {
    this.router.navigate(['auth/otp']);
  }

  getDecodedToken(oneTimePasswordToken: string) {
    return atob(oneTimePasswordToken ?? '');
  }

  resendToPhoneClicked(phone: string) {
    this.recipient = phone;
    this.successTitle = 'Check your phone';
    this.successQuestion = 'Did not receive the message?';
    this.resendResetPasswordLink(phone, RecipientType.PHONE_NUMBER);
  }

  resendToEmailClicked(email: string) {
    console.log(email);

    this.recipient = email;
    this.successTitle = 'Check your email';
    this.successQuestion = 'Did not receive the email?';
    this.resendResetPasswordLink(email, RecipientType.EMAIL_ADDRESS);
  }

  resendResetPasswordLink(recipient: string, recipientType: RecipientType) {
    this.isLoading = true;
    const accountResendOtpRequestDto: AccountResendOtpRequestDto = {
      clientId: environment.authClientId,
      username: recipient,
      usernameType: recipientType,
      userType: UserType.CITIZEN,
      locale: this.locale,
    };

    this.authService
      .initiatePasswordReset(accountResendOtpRequestDto)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.accountStatus = 'success';
        },
        error: (err: HttpErrorResponse) => {
          const errMsg =
            err.error.message ?? 'Fail to send reset password link';
          this.toastService.show({ type: 'error', body: errMsg });
          console.log(err);
        },
      });
  }
}
