<ng-container *ngIf="accountStatus === ''">
  <div class="ontainer d-flex align-items-center justify-content-center vh-100">
    <div class="spinner-grow text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-container>

<ng-template [ngIf]="accountStatus === 'account-activated'">
  <irembogov-activation-account-activated
    [title]="defaultActivatedErrorTitle | translate"
    [description]="activatedErrorMessage | translate"
    (goToLogin)="onGoToLogin()">
  </irembogov-activation-account-activated>
</ng-template>

<ng-template [ngIf]="accountStatus === 'expired-account'">
  <irembogov-citizen-link [token]="token" [username]="username">
  </irembogov-citizen-link>
</ng-template>
