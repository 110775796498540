import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {
  RecipientType,
  UserType,
} from '../../../models/citizenCreationRequest.model';
import { CountdownComponent } from 'ngx-countdown';
import { ERROR_MAP } from '../../../data/errorMap';
import { AccountResendOtpRequestDto } from '../../../models/account-resend-otp-request.model';

@Component({
  selector: 'irembogov-reset-password-otp',
  templateUrl: './reset-password-otp.component.html',
  styleUrls: ['./reset-password-otp.component.scss'],
})
export class ResetPasswordOtpComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  @ViewChild('cd', { static: false }) private countdown:
    | CountdownComponent
    | undefined;

  maskNumber = '';
  phoneNumber = '';
  userFeedback = '';
  errorMessage = '';
  isLoading = false;
  restartTimer = false;
  state = 'default';
  email = '';
  verificationType = '';
  locale = '';

  ngOnInit(): void {
    this.email = this.route.snapshot.queryParamMap.get('email') ?? '';
    this.phoneNumber = this.route.snapshot.queryParamMap.get('phone') ?? '';

    if (this.email) {
      this.verificationType = 'email';
    } else {
      this.verificationType = 'phone';
    }

    this.locale = localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
  }

  onError(errorMessage: string): void {
    this.isLoading = false;
    this.errorMessage = errorMessage;
  }

  onFormSubmit(otp: any): void {
    this.isLoading = true;
    this.userFeedback = '';
    this.errorMessage = '';

    const encodedOtp = this.getEncodedString(otp + '');
    const encodedPhoneNumber = this.getEncodedString(this.phoneNumber);

    this.performHttpRequest(
      this.authService.verifyResetLink(encodedOtp, encodedPhoneNumber),
      () =>
        this.router.navigate(['auth/reset-password'], {
          queryParams: { token: encodedOtp, username: encodedPhoneNumber },
        })
    );
  }

  resendLink(): void {
    this.userFeedback = '';
    this.errorMessage = '';

    const accountResendOtpRequestDto: AccountResendOtpRequestDto = {
      clientId: environment.authClientId,
      username: this.phoneNumber,
      usernameType: RecipientType.PHONE_NUMBER,
      userType: UserType.CITIZEN,
      locale: this.locale,
    };

    this.performHttpRequest(
      this.authService.initiatePasswordReset(accountResendOtpRequestDto),
      () => {
        this.restartTimer = true;
        this.userFeedback = 'We successfully resent you an otp';
      }
    );
  }

  getEncodedString(str: string): string {
    let encoded = '';
    try {
      encoded = btoa(str ?? '');
    } catch (error) {
      console.log(error);
    }

    return encoded;
  }

  private performHttpRequest(request: any, successCallback: () => void): void {
    request.pipe(finalize(() => (this.isLoading = false))).subscribe({
      next: () => {
        successCallback();
      },
      error: (err: HttpErrorResponse) => {
        const errorCode = err.error.responseCode as string;
        this.errorMessage =
          ERROR_MAP[errorCode] ?? err?.error?.message ?? 'Fail to verify otp';
      },
    });
  }
}
