<div class="service-not-found-container">
  <div class="container-fluid search-header">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 mx-auto">
        <irembogov-searchbox
          [smShadow]="true"
          (resultSelected)="handleOnClicked($event)"
          (keyEnterEvent)="handleOnEnterHit($event)"></irembogov-searchbox>
      </div>
    </div>
  </div>

  <section class="container">
    <div class="row">
      <div
        class="col-12 d-flex align-items-center justify-content-center mt-4 mt-sm-5">
        <div class="search-logo">
          <img src="assets/img/irem-search.svg" alt="" />
        </div>
      </div>
      <div class="col-12 d-flex align-items-center justify-content-center mt-4">
        <p class="text-center not-found-text">
          {{ "We're sorry, but we couldn't find any results for" | translate }}
          "<strong>{{ keyword }}</strong
          >". <br />
          {{
            "We're constantly working to improve our search results"
              | translate
          }}.
        </p>
      </div>
      <div class="col-12 d-flex align-items-center justify-content-center mt-3">
        <p class="text-center not-found-text">
          {{ 'In the meantime, Try to browse services by' | translate }}
        </p>
      </div>

      <div class="col-12 text-center mx-auto alt-search">
        <a
          [routerLink]="['/services/browse-by']"
          [queryParams]="{ type: 'institution' }"
          class="btn btn-outline-primary bg-irembo-blue find-button text-light me-3">
          <i aria-hidden="true" class="fa-solid fa-building-columns ms-2"></i>
          {{ 'Institutions' | translate }}
          <i aria-hidden="true" class="fa fa-arrow-right ms-2"></i>
        </a>

        <a
          [routerLink]="['/services/browse-by']"
          [queryParams]="{ type: 'category' }"
          class="btn btn-outline-primary bg-irembo-blue find-button text-light">
          <i aria-hidden="true" class="fa-regular fa-folder ms-2"></i>
          {{ 'Categories' | translate }}
          <i aria-hidden="true" class="fa fa-arrow-right ms-2"></i>
        </a>
      </div>
    </div>
  </section>

  <section class="featured-sec container text-bg-irembo-blue">
    <div class="row">
      <div class="col-12 mt-3">
        <h5 class="fw-bold mb-3 text-sm-start text-center">
          {{ 'Featured services' | translate }}
        </h5>
      </div>
    </div>

    <div *ngIf="featuredServicesList.length > 0">
      <div class="row">
        <div
          class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
          *ngFor="let featureService of featuredServicesList; index as i">
          <div class="card text-start featured-service">
            <div
              class="card-body"
              (click)="handleOnClicked(featureService.service)">
              <div class="row">
                <div class="col-10 mb-1">
                  <h5 class="card-title tt_norms500 text- p-0 m-0">
                    {{ featureService.service.serviceName | translate }}
                  </h5>
                </div>
                <div
                  class="col-2 px-0 d-flex align-items-center justify-content-end">
                  <i aria-hidden="true" class="fa fa-arrow-right"></i>
                </div>
                <small
                  class="col-12 text-uppercase mb-3 tt_norms500 text-feature-secondary">
                  {{
                    featureService.service.organization.acronym ?? ''
                      | translate
                  }}
                </small>
              </div>

              <small class="card-text text-feature-tertiary tt_norms400">
                {{ featureService.reasonForBeingFeatured | translate }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <irembogov-service-modal
    (modalID)="modalID = $event"
    [data]="selectedApplication"></irembogov-service-modal>
</div>
