<div class="nav-bar-placeholder d-none d-sm-block"></div>
<div class="register-container">
  <img
    src="assets/img/O.svg"
    alt="irembo logo"
    class="irembo-logo d-none d-sm-block" />
  <div class="message-container d-none d-sm-block">
    <h1 class="welcome-h1">{{ 'Welcome' | translate }}</h1>
    <p class="welcome-p">
      {{
        'We have been working to bring you a new IremboGov experience'
          | translate
      }}, <br />
      {{
        'easier to use, has more & more e-certificates and less attachments'
          | translate
      }}. <br /><br />
      {{
        'We have been working to bring you a new IremboGov experience'
          | translate
      }},<br />
      {{ 'easier to use, has more & mo' | translate }}
    </p>
  </div>
  <div class="authentication-container">
    <div class="row">
      <div class="col-12">
        <div
          *ngIf="errorMessage.length > 0"
          class="text-center px-5 mt-4 alert alert-danger">
          {{ errorMessage | translate }}
        </div>

        <div class="logo text-center px-5">
          <img class="img" src="assets/images/logo.svg" alt="" />
        </div>

        <div class="text-center px-5 mt-3">
          <img
            class="img"
            src="assets/img/key.png"
            class="irembo-responsive"
            alt="" />
        </div>
      </div>

      <h2 class="mb-4 text-center tt_norms500">
        {{ 'Set Password' | translate }}
      </h2>
      <p class="set-password-secondary tt_norms400">
        {{
          'Your new password must be 8 characters and contain a number, an uppercase letter and a lowercase letter and a special character'
            | translate
        }}.
      </p>

      <form class="auth-form" (submit)="onFormSubmit()">
        <div class="mb-3">
          <label for="password" class="form-label">{{
            'Password' | translate
          }}</label>
          <input
            name="password"
            [(ngModel)]="password"
            type="{{ showPassword ? 'text' : 'password' }}"
            class="form-control password-input"
            id="password"
            placeholder="{{ 'Enter your password' | translate }}" />
          <div
            *ngIf="errorPassMsg.length > 0"
            class="invalid-feedback d-block stretch tt_norms400">
            {{ errorPassMsg | translate }}
          </div>
          <a (click)="togglePassword()" class="eye-b">
            <i
              class="{{
                showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'
              }}"></i>
          </a>
          <password-strength-meter
            class=""
            [password]="password"
            [minPasswordLength]="8"></password-strength-meter>
        </div>

        <div class="mb-3">
          <label for="cpassword" class="form-label">{{
            'Confirm Password' | translate
          }}</label>
          <input
            name="cpassword"
            [(ngModel)]="cpassword"
            type="{{ cShowPassword ? 'text' : 'password' }}"
            class="form-control password-input"
            id="cpassword"
            placeholder="Confirm your password" />
          <div
            class=""
            *ngIf="errorCPassMsg.length > 0"
            class="invalid-feedback d-block">
            {{ errorCPassMsg | translate }}
          </div>
          <a (click)="cTogglePassword()" class="eye-b-c">
            <i
              class="{{
                cShowPassword ? 'fas fa-eye-slash' : 'fas fa-eye'
              }}"></i>
          </a>
        </div>

        <div class="d-grid gap-2 col-12 mx-auto">
          <button class="btn btn-primary" type="submit" [disabled]="isLoading">
            <div
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm mr-2"
              role="status"></div>
            &nbsp;
            <strong>{{ 'Set Password' | translate }}</strong>
          </button>
        </div>
      </form>

      <div class="text-center p-2">
        <p class="mt-2 otp-code-secondary">
          {{ 'Didn’t receive the email' | translate }}?
          <span (click)="resendLink()" class="resendLink">{{
            'Click to resend' | translate
          }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
