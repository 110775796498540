import { NotificationType } from './../models/user-profile.model';
import { IResponse } from './../models/response.model';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { apis } from '../app-apis';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IUserProfile } from '../models/user-profile.model';
import { map, Observable, Subject } from 'rxjs';
import { IChangePasswordRequest } from '../models/changePassword/change-password-request';
import { IChangePasswordResponse } from '../models/changePassword/change-password-response';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  private baseUrl = environment.apiGatewayBaseUrl + '/application/v1';
  private userProfileAPIs = apis.userProfile;
  private userProfile$ = new Subject<IUserProfile>();
  userProfileObservable$ = this.userProfile$.asObservable();

  constructor(private http: HttpClient) {}

  getUserProfile(): Observable<IUserProfile> {
    return this.http
      .get<IResponse<IUserProfile>>(
        `${this.baseUrl}${this.userProfileAPIs.getUserProfile}`
      )
      .pipe(map(res => res.data));
  }

  setDefaultChannel(notificationType: NotificationType) {
    let defaultChannel = '';
    if (notificationType.PHONE_NUMBER) {
      defaultChannel = 'PHONE_NUMBER';
    } else if (notificationType.EMAIL) {
      defaultChannel = 'EMAIL';
    }
    return this.http.patch<IResponse<IUserProfile>>(
      `${this.baseUrl}${this.userProfileAPIs.setDefaultChannel}`,
      {
        notificationType: defaultChannel,
      }
    );
  }

  addUpdateNotificationChannel(params: {
    notificationPhoneNumber?: string;
    notificationEmail?: string;
  }) {
    return this.http.patch<IResponse<IUserProfile>>(
      `${this.baseUrl}${this.userProfileAPIs.addUpdateNotificationChannel}`,
      params
    );
  }

  updateUserProfile(userProfile: IUserProfile) {
    this.userProfile$.next(userProfile);
  }

  // auth update user password
  changePassword(
    updatePasswordRequest: IChangePasswordRequest
  ): Observable<IChangePasswordResponse> {
    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}${this.userProfileAPIs.changePassword}`;
    return this.http.put<IChangePasswordResponse>(url, updatePasswordRequest, {
      headers,
    });
  }

  updateFirstLogin() {
    const headers = new HttpHeaders({});
    const url = `${this.baseUrl}${this.userProfileAPIs.changeFirstLogin}`;
    return this.http.patch(url, {
      headers,
    });
  }
}
