const errorMessages: Record<string, string> = {
  face_detected:
    'We could not identify your face in the uploaded photo. Try choosing a more clear photo and upload again.',
  ratio_correct: "The uploaded photo doesn't have correct ration.",
  face_in_center_horizontal:
    'The face is not properly aligned horizontally in the uploaded photo. Please choose a well-aligned photo and upload again.',
  face_in_center_vertical:
    'The face is not properly aligned vertically in the uploaded photo. Please choose a well-aligned photo and upload again.',
  head_width_correct:
    'The width of the detected face in the photo does not match with correct size of a good profile photo.',
  lighter_and_unique_background:
    'The uploaded photo must have a unique and lighter background.',
  midpoint_in_upper_half:
    'The orientation of the face detected in the photo is not correct.',
  midpoint_in_vertical_center:
    'The orientation of the face detected in the photo is not correct.',
  single_face: 'The photo must contain one single face.',
  v_line_almost_vertical:
    'The orientation of the face detected in the photo is not correct.',
  h_line_almost_vertical:
    'The orientation of the face detected in the photo is not correct.',
  looking_straight_forward:
    'The face detected in the picture is not looking straight forward.',
  has_checks:
    'Invalid passport photo uploaded, allowed format are jpg, jpeg, and png.',
};
export default errorMessages;
