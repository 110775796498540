<div class="section">
  <div class="container">
    <div
      class="row d-flex justify-content-center align-items-start certificate-status-box pt-5">
      <div class="col-12 col-md-4">
        <div class="d-flex flex-column gap-4">
          <div class="">
            <img
              *ngIf="certificateStatus"
              src="/assets/icons/{{
                getApplicationStatusIcon(certificateStatus.validityStatus)
              }}"
              alt="modal-icon" />
          </div>
          <div class="modal-body">
            <ng-container
              *ngIf="
                certificateStatus?.validityStatus ===
                certificateStatusOptions.VALID
              ">
              <h4 class="modal-title" id="modal-basic-title">
                {{ 'Certificate is valid' | translate }}
              </h4>
              <p>
                {{
                  'This document was generated by Irembo and is valid as of today'
                    | translate
                }}.
              </p>
            </ng-container>
            <ng-container
              *ngIf="
                certificateStatus?.validityStatus ===
                certificateStatusOptions.INVALID
              ">
              <h4 class="modal-title" id="modal-basic-title">
                {{ 'Certificate is invalid' | translate }}
              </h4>
              <p>
                {{
                  'Certificate not found on IremboGov. Please verify the documents source and either try again or apply for a new certificate'
                    | translate
                }}.
              </p>
            </ng-container>
            <ng-container
              *ngIf="
                certificateStatus?.validityStatus ===
                certificateStatusOptions.EXPIRED
              ">
              <h4 class="modal-title" id="modal-basic-title">
                {{ 'Certificate is expired' | translate }}
              </h4>
              <p>
                {{
                  'This certificate has expired and is no longer valid. Please apply for a new certificate'
                    | translate
                }}.
              </p>
            </ng-container>
          </div>
          <div
            class="d-flex flex-column gap-4"
            *ngIf="
              certificateStatus?.validityStatus ===
              certificateStatusOptions.VALID
            ">
            <button
              type="button"
              class="btn w-100 btn-primary"
              (click)="openTokenModal(token)">
              {{ 'View Certificate' | translate }}
            </button>

            <button
              routerLink="/"
              type="button"
              class="btn w-100 btn-outline-dark cancel-btn">
              {{ 'Cancel' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #token let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'Enter token provided by certificate owner' | translate }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body responsive-otp-input">
    <ng-otp-input
      #ngOtpInputRef
      (onInputChange)="onOtpChange($event, ngOtpInputRef)"
      [config]="{
        length: 5,
        placeholder: '0',
        inputClass: 'formControl',
        containerClass: 'custom-token-input'
      }"></ng-otp-input>
    <p *ngIf="invalidToken" class="invalidTokenMsg mt-2">
      {{
        'The token you entered is invalid. Please verify the token and try again'
          | translate
      }}.
    </p>
  </div>
  <div class="modal-footer d-flex">
    <button
      type="button"
      (click)="confirmCertificate(ngOtpInputRef)"
      class="btn btn-primary">
      {{ 'Confirm' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline-dark cancel-btn"
      (click)="modal.dismiss('Cancel')">
      {{ 'Cancel' | translate }}
    </button>
  </div>
</ng-template>
