import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IFeatureService, IService } from '@irembo-andela/irembogov3-common';

@Component({
  selector: 'irembogov-featured-services',
  templateUrl: './featured-services.component.html',
  styleUrls: ['./featured-services.component.scss'],
})
export class FeaturedServicesComponent {
  @Input() services: IFeatureService[] = [];
  @Output() selectedService: EventEmitter<IService> =
    new EventEmitter<IService>();

  @ViewChild('scrl', { read: ElementRef }) scrl!: ElementRef<any>;

  scrollX = 0;
  scrollEnd = false;

  selectService(service: IFeatureService) {
    this.selectedService.emit(service.service);
  }

  scroll(shift: number) {
    if (this.scrl) {
      this.scrl.nativeElement.scrollTo({
        left: shift,
        behavior: 'smooth',
      });

      this.scrl.nativeElement.scrollLeft += shift;
      this.scrollX += shift;

      this.scrollCheck();
    }
  }

  scrollCheck() {
    if (this.scrl) {
      this.scrollX = this.scrl.nativeElement.scrollLeft;
      this.scrollEnd =
        Math.floor(
          this.scrl.nativeElement.scrollWidth -
            this.scrl.nativeElement.scrollLeft
        ) <= this.scrl.nativeElement.offsetWidth;
    }
  }
}
