import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivationRequestDto } from '../../../models/activationRequest.model';
import { AuthService } from '../service/auth.service';
import { zxcvbn, ZxcvbnResult } from '@zxcvbn-ts/core';
import { finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ResendTokenOtpDto } from '../../../models/common.model';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { environment } from '../../../../environments/environment';
import { UserType } from '../../../models/citizenCreationRequest.model';

@Component({
  selector: 'irembogov-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  password = '';
  cpassword = '';
  isLoading = false;
  errorPassMsg = '';
  errorMessage = '';
  errorCPassMsg = '';
  showPassword = false;
  cShowPassword = false;
  locale = '';

  private token = '';
  private username = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.token =
      this.route.snapshot.queryParamMap.get('token') ??
      window.localStorage.getItem('phoneceasar') ??
      '';
    this.username = this.route.snapshot.queryParamMap.get('username') ?? '';
    this.locale = localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
  }

  onFormSubmit(): void {
    this.isLoading = true;
    this.reinitialize();

    // Validate Password
    const result: ZxcvbnResult = zxcvbn(this.password);

    if (result.score < 3) {
      this.errorPassMsg =
        'At least 8 characters and contain a number, an uppercase letter and a lowercase letter and a special character.';
      this.isLoading = false;

      return;
    }

    if (this.password !== this.cpassword) {
      this.errorCPassMsg = 'Password must match.';
      this.isLoading = false;
      return;
    }

    // Component is shared between Account Creation flow and Password reset flow.

    // Create account
    const activationRequestDto: ActivationRequestDto = {
      oneTimePasswordToken: this.token,
      password: this.password,
      clientId: environment.authClientId,
      userType: UserType.CITIZEN,
      username: this.username,
      locale: this.locale,
    };

    this.authService
      .activateAccount(activationRequestDto)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.router.navigate(['auth/success']);
        },
        error: (err: HttpErrorResponse) => {
          this.errorMessage = err.error.message ?? 'Fail to reset password';
          console.log(err);
        },
      });
  }

  resendLink() {
    this.reinitialize();

    const resendTokenOtpDto: ResendTokenOtpDto = {
      token: this.token,
      clientId: environment.authClientId,
      userType: UserType.CITIZEN,
      username: this.username,
    };

    this.authService.resendTokenLink(resendTokenOtpDto).subscribe({
      next: () => {
        this.toastService.show({
          type: 'success',
          body: `Email sent, check your email`,
        });
      },
      error: (err: HttpErrorResponse) => {
        this.errorMessage = err.error.message ?? 'Fail to resend link';
        console.log(err);
      },
    });
  }

  reinitialize() {
    this.errorMessage = '';
    this.errorPassMsg = '';
    this.errorCPassMsg = '';
  }

  public togglePassword() {
    this.showPassword = !this.showPassword;
  }

  public cTogglePassword() {
    this.cShowPassword = !this.cShowPassword;
  }
}
