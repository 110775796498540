<irembogov-select
  [loading]="loading"
  [customFormControl]="formControl"
  [items]="items"
  [bindLabel]="field.props['bindLabel']"
  ngDefaultControl
  [bindValue]="field.props['bindValue']"
  [placeholder]="field.props.placeholder ? field.props.placeholder : ''"
  [labelForId]="field.key"
  [multiple]="field.props['multiple']"></irembogov-select>
