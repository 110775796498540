import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IremboMissingTranslationHandler,
  AuthModule as LibAuthModule,
  UiModule,
} from '@irembo-andela/irembogov3-common';
import { AuthRoutingModule } from './auth-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordComponent } from './password/password.component';
import { SuccessComponent } from './success/success.component';
import { OtpComponent } from './otp/otp.component';
import { ResetComponent } from './reset/reset.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { NgOtpInputModule } from 'ng-otp-input';
import { ActivationComponent } from './activation/activation.component';
import { LinkComponent } from './link/link.component';
import { CitizenLinkComponent } from './citizen-link/citizen-link.component';
import { ResetLinkComponent } from './reset-link/reset-link.component';
import { CountdownModule } from 'ngx-countdown';
import { ResetActivationComponent } from './reset-activation/reset-activation.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordOtpComponent } from './reset-password-otp/reset-password-otp.component';
import { OtpInputComponent } from './otp-input/otp-input.component';
import { SharedModule } from '../shared/shared.module';
import { GenericContainerComponent } from './generic-container/generic-container.component';
import { NewOtpInputComponent } from './new-otp-input/new-otp-input.component';
import { VerifyComponent } from './verify/verify.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { LocaleService } from 'src/app/services/locale.service';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    PasswordComponent,
    SuccessComponent,
    OtpComponent,
    ResetComponent,
    ActivationComponent,
    LinkComponent,
    CitizenLinkComponent,
    ResetLinkComponent,
    ResetActivationComponent,
    ResetPasswordComponent,
    ResetPasswordOtpComponent,
    OtpInputComponent,
    GenericContainerComponent,
    NewOtpInputComponent,
    VerifyComponent,
    CreateAccountComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    AuthRoutingModule,
    LibAuthModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordStrengthMeterModule.forRoot(),
    NgOtpInputModule,
    CountdownModule,
    SharedModule,
    TranslateModule.forChild({
      defaultLanguage:
        localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE,
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, LocaleService],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: IremboMissingTranslationHandler,
      },
    }),
  ],
  exports: [NewOtpInputComponent],
})
export class AuthModule {}
