<div class="bg-white min-vh-100 w-100">
  <div
    class="container-fluid d-flex flex-column justify-content-lg-center justify-content-md-center justify-content-sm-start align-items-lg-center align-items-sm-center align-content-lg-center lign-content-md-center align-content-sm-start pt-5 min-vh-100 col-lg-3 col-sm-12">
    <div class="mb-5">
      <img
        src="../../../../assets/img/logo.png"
        alt="Irembo logo"
        height="20"
        width="109" />
    </div>
    <div class="mb-3">
      <img
        src="../../../../assets/img/success.png"
        alt="Success"
        height="56"
        width="56" />
    </div>
    <div class="text-lg-center text-md-center text-sm-start">
      <h1 class="mb-3">
        {{ title | translate }}
      </h1>
      <p class="sub-heading">{{ body | translate }}</p>

      <button
        class="btn btn-primary text-white w-100"
        (click)="goToDashboard()"
        type="button">
        {{ 'Log in' | translate }}
      </button>
    </div>
  </div>
</div>
