import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { GenericInputStatusEnum } from '../../../models/irembo-generic-input-status.enum';
import { IEnvironment } from '../../../models/environment.model';
import { v4 as uuidv4 } from 'uuid';
import {
  checkForValidFields,
  configureFields,
  populateFormFields,
  resetFieldsToPopulate,
  updateUrlWithApiGatewayBaseUrl,
  getPopulateReferenceForm,
  subscribeToResetFieldFetchData,
} from '../../../../utils/utils/data-fetch-widget-utils';
import { FormArray, FormGroup } from '@angular/forms';
import { FormStateService } from '../../../services/formly/form-state.service';

const INTEGRATION_URL = '/integration/v1/fetch/sync';

@Component({
  selector: 'irembogov-custom-crvs-id-input-fetch-widget',
  templateUrl: './custom-crvs-id-input-fetch-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCrvsIdInputFetchWidgetComponent
  extends FieldType<FieldTypeConfig>
  implements OnDestroy, AfterViewInit
{
  private subscriptions = new Subscription();
  statusClass: string | undefined;
  fieldsToPopulate: Record<string, string>[] = [];
  private environment: IEnvironment;
  placeholder: string | undefined;
  regExp: string | undefined;
  documentTypeValue: string | undefined;

  constructor(
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private formStateService: FormStateService,
    @Inject('environment') environment: IEnvironment
  ) {
    super();
    this.environment = environment;
  }
  ngAfterViewInit(): void {
    checkForValidFields(this.field);
    this.fieldsToPopulate = this.field.props?.['populates'];
    const formRef = getPopulateReferenceForm(this.field, this.form);

    this.subscriptions.add(
      subscribeToResetFieldFetchData(this.field, this.formState)
    );

    if (this.fieldsToPopulate.length > 0) {
      this.subscriptions.add(
        configureFields(this.field, this.fieldsToPopulate, formRef)
      );
    }

    this.configureDocumentTypeDropDown(formRef);
  }

  configureDocumentTypeDropDown(formRef: FormGroup<any> | FormArray<any>) {
    const { documentTypeKey, allowedDocumentTypes } = this.field.props;

    this.validateRequiredProps(
      documentTypeKey as string,
      allowedDocumentTypes as string[]
    );

    const documentTypeField = this.form.get(documentTypeKey);

    if (documentTypeField !== null) {
      this.subscriptions.add(
        documentTypeField.valueChanges.subscribe({
          next: value => {
            if (documentTypeField.valid) {
              this.documentTypeValue = value;
              this.statusClass = '';
              const docType = allowedDocumentTypes.find(
                (x: Record<string, unknown>) => x['value'] === value
              );
              if (docType) {
                const { pattern } = docType;
                this.regExp = pattern;
              }
              const resetFields = this.field.props['resetFields'] ?? true;

              if (resetFields) {
                this.field.formControl.setValue(null);
                resetFieldsToPopulate(this.fieldsToPopulate, formRef);
              }
              this.cd.detectChanges();
            }
          },
        })
      );
    }
  }

  validateRequiredProps(
    documentTypeKey: string,
    allowedDocumentTypes: string[]
  ) {
    if (!documentTypeKey || !allowedDocumentTypes) {
      throw new Error(
        'allowedDocumentTypes and documentTypeKey are required properties'
      );
    }

    if (!Array.isArray(allowedDocumentTypes)) {
      throw new Error('allowedDocumentTypes should be an array');
    }
  }

  onDataFetch(inputValue: string) {
    let { url } = this.field.props;
    const { endpointCode, payload } = this.field.props;
    if (!endpointCode) {
      throw new Error('endpointCode and documentType are required properties');
    }

    if (this.documentTypeValue === undefined) {
      throw new Error('Document type value is required');
    }

    if (this.field.props['useBaseUrl']) {
      url = updateUrlWithApiGatewayBaseUrl(INTEGRATION_URL, this.environment);
    }

    const params = {
      endpointCode,
      callerId: uuidv4(),
      payload: {
        ...payload,
        documentType: this.documentTypeValue,
        documentNumber: inputValue,
      },
      requester: uuidv4(),
    };
    this.statusClass = GenericInputStatusEnum.FETCHING;
    this.subscriptions.add(
      this.http.post<Record<string, unknown>>(url, params).subscribe({
        next: (res: Record<string, unknown>) => {
          this.statusClass = GenericInputStatusEnum.SUCCESS;
          const data = res['data'] as Record<string, unknown>;
          const response = data['response'] as string;
          if (this.fieldsToPopulate.length > 0) {
            const formRef: FormGroup<any> | FormArray<any> =
              getPopulateReferenceForm(this.field, this.form);
            populateFormFields(response, this.field, formRef);
          }
          this.formStateService.saveFetchedDataKeyInFormState(
            this.field,
            response
          );
          this.cd.detectChanges();
        },
        error: () => {
          this.statusClass = GenericInputStatusEnum.DANGER;
          this.cd.detectChanges();
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
