import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CitizenCreationRequestDto,
  ICheckTokenResponse,
  IKeycloakTokenResponse,
  IProfileResponse,
} from '../../../models/citizenCreationRequest.model';
import { ActivationRequestDto } from '../../../models/activationRequest.model';
import { AccountResendOtpRequestDto } from '../../../models/account-resend-otp-request.model';
import { ResetPasswordDto } from '../../../models/resetPassword.model';
import { BaseResponsePageOrganizationResponseDto } from '../../../types/organisation.types';
import { BaseResponseCustomPageServiceDto } from '../../../types/services.types';
import {
  IVerifyIdentityOtpResponse,
  OneTimePasswordDto,
  ResendOtpDto,
  ResendTokenOtpDto,
  VerifyIdentityOtp,
} from '../../../models/common.model';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { EUserType } from '../../../types/user-types.enum';
import { environment } from '../../../../environments/environment';
import {
  IVerifyAccountResponse,
  VerifyAccountDto,
} from '../../../models/verify-account.model';
import { IResponse } from '../../../models/response.model';
import { AccountExistResponseDto } from '../../../models/account-exist-response-model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private pendingProcessForLogout = false;
  private _showLogOutWarningModal: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public showLogOutWarningModal$: Observable<boolean> =
    this._showLogOutWarningModal.asObservable();
  private cancelSubscription$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private keyCloakService: KeycloakService,
    private offCanvasService: NgbOffcanvas
  ) {
    this.redirectUserOnTokenExpired();
  }

  checkIfAccountExist(username: string) {
    const url =
      environment.apiGatewayBaseUrl + '/auth/v1/user/user-exist-status';
    const userType = EUserType.CITIZEN;
    const headers = new HttpHeaders({ username, userType });
    return this.httpClient.get<AccountExistResponseDto>(url, { headers });
  }

  createCitizen(citizenCreationRequestDto: CitizenCreationRequestDto) {
    const url =
      environment.apiGatewayBaseUrl + '/application/v1/create-citizen';
    return this.httpClient.post<IKeycloakTokenResponse>(
      url,
      citizenCreationRequestDto
    );
  }

  activateAccount(activationRequestDto: ActivationRequestDto) {
    const url =
      environment.apiGatewayBaseUrl + '/auth/v1/user/activate-account';
    return this.httpClient.post<IProfileResponse>(url, activationRequestDto);
  }

  resendAccountLink(resendOtpDto: ResendOtpDto) {
    const url =
      environment.apiGatewayBaseUrl + '/auth/v1/resend-activation-token';
    return this.httpClient.post<IKeycloakTokenResponse>(url, resendOtpDto);
  }

  resendTokenLink(resendTokenOtpDto: ResendTokenOtpDto) {
    // Resend expired token
    const url =
      environment.apiGatewayBaseUrl +
      '/auth/v1/account-activation/resend-token';
    return this.httpClient.post<IKeycloakTokenResponse>(url, resendTokenOtpDto);
  }

  verifyLink(oneTimePassword: string, username: string) {
    const userType = EUserType.CITIZEN;
    const headers = new HttpHeaders({ oneTimePassword, username, userType });
    const url =
      environment.apiGatewayBaseUrl +
      '/auth/v1/account-activation/check-token-validity';
    return this.httpClient.get<ICheckTokenResponse>(url, { headers });
  }

  initiatePasswordReset(
    accountResendOtpRequestDto: AccountResendOtpRequestDto
  ) {
    const url =
      environment.apiGatewayBaseUrl + '/auth/v1/reset-password/send-token';
    return this.httpClient.post<IKeycloakTokenResponse>(
      url,
      accountResendOtpRequestDto
    );
  }

  resendResetTokenExpired(resendTokenOtpDto: ResendTokenOtpDto) {
    // Resend expired token
    const url =
      environment.apiGatewayBaseUrl + '/auth/v1/reset-password/resend-token';
    return this.httpClient.post<IKeycloakTokenResponse>(url, resendTokenOtpDto);
  }

  resetPassword(resetPasswordDto: ResetPasswordDto) {
    const url = environment.apiGatewayBaseUrl + '/auth/v1/reset-password';
    return this.httpClient.put<IProfileResponse>(url, resetPasswordDto);
  }

  sendOneTimePassword(oneTimePasswordDto: OneTimePasswordDto) {
    const url =
      environment.apiGatewayBaseUrl + '/auth/v1/send-one-time-password';
    return this.httpClient.post<IProfileResponse>(url, oneTimePasswordDto);
  }

  verifyOneTimePassword(oneTimePassword: string, username: string) {
    const headers = new HttpHeaders({ oneTimePassword, username });
    const url =
      environment.apiGatewayBaseUrl + '/auth/v1/verify-one-time-password';
    return this.httpClient.get<IProfileResponse>(url, { headers });
  }

  verifyResetLink(oneTimePassword: string, username: string) {
    const userType = EUserType.CITIZEN;
    const headers = new HttpHeaders({ oneTimePassword, username, userType });
    const url =
      environment.apiGatewayBaseUrl +
      '/auth/v1/reset-password/check-token-validity';
    return this.httpClient.get<ICheckTokenResponse>(url, { headers });
  }

  getOrganizations() {
    const url =
      environment.apiGatewayBaseUrl + '/organization/api/v1/organizations';
    return this.httpClient.get<BaseResponsePageOrganizationResponseDto>(url);
  }

  getServicesByOrganId(organizationId: string) {
    const url =
      environment.apiGatewayBaseUrl +
      '/service/api/v1/organization/' +
      organizationId;
    return this.httpClient.get<BaseResponseCustomPageServiceDto>(url);
  }

  redirectUserOnTokenExpired() {
    this.keyCloakService.keycloakEvents$
      .pipe(takeUntil(this.cancelSubscription$))
      .subscribe(event => {
        if (event.type === KeycloakEventType.OnAuthRefreshError) {
          this.keyCloakService.login({
            prompt: 'login',
          });
        }
      });
  }

  async logOutUser(): Promise<void> {
    const hasOpenOffCanvas: boolean = this.offCanvasService.hasOpenOffcanvas();
    if (this.pendingProcessForLogout || hasOpenOffCanvas) {
      this._showLogOutWarningModal.next(true);
      return;
    }

    if (await this.keyCloakService.isLoggedIn()) {
      this._showLogOutWarningModal.next(false);
      this.keyCloakService
        .logout(environment.authSuccessRedirectUrl)
        .catch(err => {
          console.error('Error during logout:', err);
        });
      this.keyCloakService.clearToken();
    }
  }

  updateIncompleteProcessState(state: boolean): void {
    this.pendingProcessForLogout = state;
  }

  initiateVerifyAccount(verifyAccountDto: VerifyAccountDto) {
    const url =
      environment.apiGatewayBaseUrl +
      '/application/v1/initiate-user-profile-verification';

    return this.httpClient.post<IResponse<IVerifyAccountResponse>>(
      url,
      verifyAccountDto
    );
  }

  validateIdentiyOtp(verifyIdentityOtp: VerifyIdentityOtp) {
    const url =
      environment.apiGatewayBaseUrl + '/application/v1/verify-user-profile';

    return this.httpClient.post<IResponse<IVerifyIdentityOtpResponse>>(
      url,
      verifyIdentityOtp
    );
  }
}
