import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Input,
} from '@angular/core';
import { IOrganisation } from 'src/app/models/organisations-list.model';
import { Subscription, catchError, map, of } from 'rxjs';
import { PublicServicesService } from '../../services/public-services.service';
import { ICategory } from 'src/app/models/categories-list.model';
import { IService } from '../../models/services-list.model';
import { NavigationStart, Router } from '@angular/router';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'irembogov-app-find-offcanvas',
  templateUrl: './find-offcanvas.component.html',
  styleUrls: ['./find-offcanvas.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FindOffcanvasComponent implements OnInit, OnDestroy {
  @Input() setActive: any;

  subscriptions = new Subscription();
  categories: ICategory[] = [];
  institutions: IOrganisation[] = [];
  size = 50;
  page = 0;
  displayStatus = false;
  filterByFirstItem: Record<string, string> = {
    category: '',
    institution: '',
  };
  browseby = '';
  activeFilter = '';
  isSearching = false;
  searchResults: IService[] | undefined;
  totalItems = 0;
  navigationSubscription: Subscription | undefined;

  constructor(
    public publicServicesService: PublicServicesService,
    private activeOffcanvas: NgbActiveOffcanvas,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getCategories();
    this.getInstitutions();
    this.subscriptions.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.activeOffcanvas.close();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleTabClick(activeTab: string) {
    this.setActive = activeTab;
  }

  getInstitutions() {
    this.subscriptions.add(
      this.publicServicesService
        .getOrganisations({ page: 0, size: this.size })
        .pipe(
          map(res => res.data),
          catchError(error => {
            console.error('Error fetching institutions:', error);
            return of(null);
          })
        )
        .subscribe({
          next: res => {
            if (!res) {
              return;
            }
            this.institutions = res.content;

            this.filterByFirstItem['institution'] = res.content[0].id;
            if (this.browseby === 'institution') {
              this.activeFilter = res.content[0].id;
              this.searchByType('institution', res.content[0].id);
            }
          },
        })
    );
  }

  getCategories() {
    this.subscriptions.add(
      this.publicServicesService
        .getCategories()
        .pipe(map(res => res.data))
        .subscribe({
          next: res => {
            this.categories = res.content;
            this.filterByFirstItem['category'] = res.content[0].id;
            if (this.browseby === 'category') {
              this.activeFilter = res.content[0].id;
              this.searchByType('category', res.content[0].id);
            }
          },
        })
    );
  }

  searchByType(type: string, id: string) {
    const filters: Record<string, unknown> = {};
    this.isSearching = true;
    if (type === 'category') {
      filters['categoryId'] = id;
    }

    if (type === 'institution') {
      filters['organizationId'] = id;
    }

    this.subscriptions.add(
      this.publicServicesService
        .getServices({ page: this.page, size: this.size, ...filters })
        .subscribe({
          next: res => {
            this.isSearching = false;
            this.totalItems = res.data.totalPages;
            this.searchResults = res.data.content;
            this.size = res.data.size;
          },
          error: () => {
            this.isSearching = false;
          },
        })
    );
  }
}
