import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {
  RecipientType,
  UserType,
} from '../../../models/citizenCreationRequest.model';
import { ResendOtpDto } from '../../../models/common.model';
import { ERROR_MAP } from '../../../data/errorMap';

@Component({
  selector: 'irembogov-otp',
  templateUrl: './otp.component.html',
})
export class OtpComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  maskNumber = '';
  phoneNumber = '';
  userFeedback = '';
  errorMessage = '';
  isLoading = false;
  restartTimer = false;
  locale = '';

  ngOnInit(): void {
    this.phoneNumber = this.route.snapshot.queryParamMap.get('phone') ?? '';

    const part1 = this.phoneNumber.substring(0, 4);
    const part2 = this.phoneNumber.substring(10);

    this.maskNumber = part1 + 'XXXXX' + part2;
    this.locale = localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
  }

  onError(errorMessage: string): void {
    this.isLoading = false;
    this.errorMessage = errorMessage;
  }

  onFormSubmit(otp: string): void {
    this.isLoading = true;
    this.userFeedback = '';
    this.errorMessage = '';

    const encodedOtp = this.getEncodedString(otp + '');
    const encodedPhoneNumber = this.getEncodedString(this.phoneNumber);

    this.authService
      .verifyLink(encodedOtp, encodedPhoneNumber)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.router.navigate(['auth/password'], {
            queryParams: { token: encodedOtp, username: encodedPhoneNumber },
          });
        },
        error: (err: HttpErrorResponse) => {
          const errorCode = err.error.responseCode as string;
          this.errorMessage =
            ERROR_MAP[errorCode] ?? err?.error?.message ?? 'Fail to verify otp';

          this.isLoading = false;
        },
      });
  }

  resendLink(): void {
    this.userFeedback = '';
    this.errorMessage = '';

    const resendOtpDto: ResendOtpDto = {
      username: this.phoneNumber,
      clientId: environment.authClientId,
      usernameType: RecipientType.PHONE_NUMBER,
      userType: UserType.CITIZEN,
      locale: this.locale,
    };
    this.authService
      .resendAccountLink(resendOtpDto)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.restartTimer = true;
          this.userFeedback = 'We successfully sent you an otp';
        },
        error: (err: HttpErrorResponse) => {
          this.errorMessage = err.error.message ?? 'Fail to resend an otp';
          console.log(err);
        },
      });
  }

  goBack() {
    this.router.navigate(['/profile']);
  }

  getEncodedString(str: string): string {
    let encoded = '';
    try {
      encoded = btoa(str ?? '');
    } catch (error) {
      console.log(error);
    }

    return encoded;
  }
}
