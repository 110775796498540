import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormlyValueChangeEvent } from '@ngx-formly/core/lib/models';
import { Subscription, distinctUntilChanged, filter } from 'rxjs';
import { IEnvironment } from '../../../models/environment.model';
import {
  IIntegrationFetchSyncRequest,
  IIntegrationFetchSyncRequestResponse,
} from '../../../models/integration-fetch-sync.model';
import { alphaNanoIdUtil } from '../../../../utils/utils/alpha-nano-id.util';
@Component({
  selector: 'irembogov-custom-drop-down',
  templateUrl: './custom-drop-down.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDropDownComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy, AfterViewInit
{
  items: Record<string, unknown>[] = [];
  private subscriptions = new Subscription();
  private environment: IEnvironment;
  loading = false;
  dataFetchFields: string[] = ['CUSTOM_DROPDOWN_DATAFETCH'];

  constructor(
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    @Inject('environment') environment: IEnvironment
  ) {
    super();
    this.environment = environment;
  }

  ngOnInit(): void {
    this.getDataSet();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.field.formControl.valueChanges
        .pipe(
          distinctUntilChanged(
            (a, b) => JSON.stringify(a) === JSON.stringify(b)
          )
        )
        .subscribe({
          next: (value: unknown) => {
            if (value && typeof value !== 'object') {
              if (!this.field.props['bindValue'] && Array.isArray(this.items)) {
                const objValue = this.findBindValue(this.items, value);
                this.field.formControl.setValue(objValue);
              }
            }
          },
        })
    );

    this.subscriptions.add(
      this.field.options?.fieldChanges
        ?.pipe(
          filter((e: FormlyValueChangeEvent) => {
            return (
              e.field.key === this.field.key &&
              e.type === 'expressionChanges' &&
              e['property'] === 'props.options' &&
              e.value
            );
          })
        )
        .subscribe((e: FormlyValueChangeEvent) => {
          this.field.props.options = e.field.props?.options ?? [];
          this.items = <Record<string, unknown>[]>(
            (e.field.props?.options ?? [])
          );
        })
    );
  }

  findBindValue(options: Record<string, unknown>[], value: unknown) {
    const lowercaseValue =
      typeof value === 'string' ? value.toLowerCase() : value;
    const result = options.find(obj =>
      Object.values(obj).some(val => {
        if (typeof val === 'string') {
          return val.toLowerCase() === lowercaseValue;
        }
        return val === lowercaseValue;
      })
    );
    return result ?? null;
  }

  updateUrlWithApiGatewayBaseUrl(url: string) {
    const portalApiGatewayBaseUrl: string | undefined =
      this.environment.apiGatewayBaseUrl;
    if (!portalApiGatewayBaseUrl) {
      throw new Error(
        'useBaseUrl: portal environment ApiGatewayBaseUrl property is required'
      );
    } else {
      url = `${portalApiGatewayBaseUrl}${url}`;
    }

    return url;
  }

  getDataSet() {
    if (this.field?.props['options']) {
      this.items = this.field.props['options'] as Record<string, unknown>[];
    }

    const { dataset } = this.field?.props || {};
    if (dataset) {
      if (!dataset['url']) {
        throw new Error('Invalid dataset property');
      }

      const httpMethod = dataset?.['httpMethod'];
      let url = dataset['url'];
      const params = dataset['params'];
      const headers = dataset['headers'];
      const dataField = dataset['dataField'];

      if (this.field.props['useBaseUrl']) {
        url = this.updateUrlWithApiGatewayBaseUrl(url);
      }

      if (httpMethod === 'POST') {
        this.getDataByPostMethod(url, dataField, params, headers);
      } else {
        this.getDataByGetMethod(url, dataField, params, headers);
      }
    }
  }

  getDataByPostMethod(
    url: string,
    dataField: string,
    params: Record<string, unknown> = {},
    headers: Record<string, string> = {}
  ) {
    this.loading = true;
    const fieldSubType = this.field?.props['subType'];
    if (fieldSubType && this.dataFetchFields.includes(fieldSubType)) {
      const endpointCode = this.field?.props['code'];
      this.getDataByIntegrationFetchSync(endpointCode, url, dataField);
    } else {
      this.subscriptions.add(
        this.http
          .post<Record<string, unknown> | []>(url, params, { headers })
          .subscribe({
            next: res => {
              this.items = Array.isArray(res)
                ? res
                : (res[dataField] as Record<string, unknown>[]);
              this.loading = false;
              this.cd.detectChanges();
            },
            error: () => {
              this.loading = false;
            },
          })
      );
    }
  }

  getDataByGetMethod(
    url: string,
    dataField: string,
    params: Record<string, unknown> = {},
    headers: Record<string, string> = {}
  ) {
    const queryParams = Object.keys(params)
      .map(key => `${key}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    this.loading = true;
    this.subscriptions.add(
      this.http
        .get<Record<string, unknown> | []>(`${url}?${queryParams}`, { headers })
        .subscribe({
          next: res => {
            this.items = Array.isArray(res)
              ? res
              : this.getValueFromNestedObject(res, dataField);
            this.loading = false;
            this.cd.detectChanges();
          },
          error: () => {
            this.loading = false;
          },
        })
    );
  }

  getDataByIntegrationFetchSync(
    endpointCode: string,
    url: string,
    dataField: string
  ): void {
    this.loading = true;
    const req: IIntegrationFetchSyncRequest = {
      callerId: alphaNanoIdUtil(50),
      endpointCode: endpointCode,
      requester: alphaNanoIdUtil(50),
      payload: {
        ServiceName: endpointCode,
      },
    };

    this.subscriptions.add(
      this.http
        .post<IIntegrationFetchSyncRequestResponse>(`${url}`, req)
        .subscribe({
          next: res => {
            if (res.status) {
              const responseObject = JSON.parse(res.data.response);
              if (dataField.length === 0) {
                this.items = responseObject;
              } else {
                this.items = this.getValueFromNestedObject(
                  responseObject,
                  dataField
                );
              }
            }
            this.loading = false;
            this.cd.detectChanges();
          },
          error: () => {
            this.loading = false;
          },
        })
    );
  }

  private getValueFromNestedObject(
    obj: Record<string, unknown>,
    keyString: string
  ) {
    const keys = keyString.split('.');
    let value = obj;
    for (const key of keys) {
      value = value[key] as Record<string, unknown>;
      if (value === undefined) {
        throw new Error(`Invalid key string: ${keyString}`);
      }
    }
    return value as unknown as Record<string, unknown>[];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
