import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
} from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IremboSelectComponent } from '../irembo-select/irembo-select.component';

@Component({
  selector: 'irembogov-select-paginated',
  templateUrl: './irembo-select-paginated.component.html',
  styleUrls: ['./irembo-select-paginated.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboSelectPaginatedComponent),
      multi: true,
    },
  ],
})
export class IremboSelectPaginatedComponent
  extends IremboSelectComponent
  implements OnDestroy, OnInit
{
  @Input() pagination = true;
  @Input() searching = true;
  @Output() loadMoreItems: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searchItem: EventEmitter<any> = new EventEmitter<any>();

  searchInput$ = new Subject<any>();
  private ngUnsubscribe = new Subject<void>();

  ngOnInit(): void {
    this.search();
  }

  fetchMoreItems(): void {
    if (this.pagination) {
      this.loadMoreItems.emit(true);
    }
  }

  search(): void {
    if (this.searching) {
      this.searchInput$
        .pipe(
          debounceTime(200),
          distinctUntilChanged(),
          switchMap(async (term: any) => this.searchItem.emit(term))
        )
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe();
    }
  }

  override ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
