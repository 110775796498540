<ng-container
  *ngTemplateOutlet="!multiple ? singleSelect : multiSelect"></ng-container>

<ng-template #singleSelect>
  <ng-select
    [loading]="loading"
    [appendTo]="appendTo"
    [dropdownPosition]="dropdownPosition"
    [placeholder]="placeholder"
    (change)="change()"
    (open)="onDropDownOpen()"
    [formControl]="customFormControl"
    class="custom"
    [labelForId]="labelForId"
    [items]="items"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue">
    <ng-template ng-label-tmp let-item="item">
      <ng-container *ngIf="item['icon_image_link'] !== undefined && !hideIcon">
        <img
          class="org-img"
          src="{{ item['icon_image_link'] }}"
          alt="icon image link" />
      </ng-container>
      {{ item[bindLabel] }}
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      <ng-container *ngIf="item['icon_image_link'] !== undefined && !hideIcon">
        <img
          class="org-img"
          src="{{ item['icon_image_link'] }}"
          alt="icon image link" />
      </ng-container>
      {{ item[bindLabel] }}
    </ng-template>
  </ng-select>
</ng-template>

<ng-template #multiSelect>
  <ng-select
    [loading]="loading"
    [appendTo]="appendTo"
    [dropdownPosition]="dropdownPosition"
    [placeholder]="placeholder"
    [items]="items"
    [multiple]="true"
    (open)="onDropDownOpen()"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [closeOnSelect]="false"
    [formControl]="customFormControl"
    (change)="change()"
    [labelForId]="labelForId"
    class="custom">
    <ng-template
      ng-option-tmp
      let-item="item"
      let-item$="item$"
      let-index="index">
      <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
      {{ item[bindLabel] }}
    </ng-template>
  </ng-select>
</ng-template>
