<div class="business-list d-flex flex-column flex-lg-row flex-wrap gap-3">
  <div class="business-card" *ngFor="let business of businesses">
    <div class="business-list-item d-flex flex-column gap-2 align-items-start">
      <div class="business-list-item-title">
        {{ business.name }}
        <div class="business-list-item-tin">
          {{ business.tinNumber }}
        </div>
      </div>

      <a class="btn btn-link text-decoration-none text-start">
        {{ 'View details' | translate }}
      </a>

      <div class="business-list-item-user-role">
        {{ 'Administrator' | translate }}
      </div>
    </div>
    <div class="divider"></div>
    <div
      class="business-list-item-buttons d-flex flex-row justify-content-between gap-2">
      <button
        class="btn btn-link text-decoration-none"
        (click)="viewApplications(business.guid)">
        {{ 'View applications' | translate }}
      </button>
      <button
        class="btn btn-link text-decoration-none"
        (click)="viewCertificates(business.guid)">
        {{ 'View certificates' | translate }}
      </button>
    </div>
  </div>
</div>
