<div *ngIf="service" class="service">
  <span class="orgcode instituion-badge">{{
    service.organization.acronym ?? '' | translate
  }}</span>
  <h1>{{ service.serviceName | translate }}</h1>

  <div class="summary">
    <p
      class="excerpt"
      *ngIf="service.serviceSummary.length > 200"
      [innerHTML]="
        (service.serviceSummary | translate | slice : 0 : 200) + '...'
      "></p>
    <p
      class="excerpt"
      *ngIf="service.serviceSummary.length < 200"
      [innerHTML]="service.serviceSummary | translate"></p>

    <div *ngIf="!showMore" class="price tt_norms600">
      {{ service | servicePrice | translate }}
    </div>
  </div>
  <button class="btn btn-link apply-link" (click)="openDetails(service.id)">
    {{ showMoreText | translate }} <i class="fa fa-angle-down"></i>
  </button>

  <ng-container *ngIf="showMore">
    <ul class="service-header-meta-box">
      <li>
        <p class="tt_norms400">
          <i
            aria-hidden="true"
            class="fa fa-tag me-2 d-none d-lg-inline-block"></i
          >{{ 'Price' | translate }}:
          <span class="tt_norms500">{{
            service | servicePrice | translate
          }}</span>
        </p>
      </li>

      <li>
        <p class="tt_norms400">
          <i
            aria-hidden="true"
            class="fa fa-building-flag me-2 d-none d-lg-inline-block"></i
          >{{ 'Institution' | translate }}:
          <span class="instituion-badge-details">{{
            service.organization.acronym ?? '' | translate | uppercase
          }}</span>
        </p>
      </li>

      <li>
        <p class="tt_norms400">
          <i
            aria-hidden="true"
            class="fa-regular fa-clock me-2 d-none d-lg-inline-block"></i
          >{{ 'Expected Processing Time' | translate }}:
          <span class="tt_norms500"
            >{{ service.expectedProcessingTime }}
            {{ 'Day(s)' | translate }}</span
          >
        </p>
      </li>
    </ul>

    <div class="attachments" *ngIf="requiredAttachments.length > 0">
      <div class="row">
        <div class="col">
          <p class="attachments-title tt_norms500">
            {{ 'Attachments' | translate }}
          </p>
          <ol class="attachments-list">
            <li *ngFor="let attachment of requiredAttachments">
              {{ attachment.attachmentName }}
            </li>
          </ol>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="apply-btn mt-2">
    <a
      class="btn btn-outline-primary mr-15"
      href="{{ knowledgeBaseUrl }}"
      rel="noopener"
      target="_blank"
      >{{ 'How to Apply?' | translate }}</a
    >
    <button
      (click)="navigateToApply(service.serviceName, service.id)"
      class="btn btn-primary">
      {{ 'Apply' | translate }} <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>
