<div class="apply-stepper-body">
  <div class="apply-stepper-form">
    <ng-container
      *ngFor="let step of fields; let index = index; let last = last">
      <ng-container
        *ngTemplateOutlet="
          selectedIndex !== index ? headerCollapsed : headerExpanded
        "></ng-container>

      <ng-template #headerCollapsed>
        <div class="card-header collapse">
          <h5>
            {{ index + 1 + '. ' + step.props?.label }}
          </h5>
          <div class="icon" *ngIf="!(selectedIndex > index)">
            <i class="fa-solid fa-angle-down"></i>
          </div>
          <div
            class="d-flex justify-content-between align-items-center w-auto gap-3"
            *ngIf="selectedIndex > index">
            <div
              role="button"
              (click)="goBack(index)"
              class="icon rounded-3 py px-3 w-auto"
              [ngStyle]="{ height: 'unset', width: 'unset' }">
              {{ 'Edit' | translate }}
            </div>
            <div
              class="bg-success text-white rounded-circle text-center"
              [style.width.px]="28"
              [style.height.px]="28">
              <i class="fa fa-check pt-2"></i>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #headerExpanded>
        <div class="card-header">
          <h5>
            {{ index + 1 + '.' + step.props?.label }}
          </h5>
          <span
            >{{ 'Fields marked with' }} <span class="text-danger">*</span>
            {{ 'are mandatory.' | translate }}</span
          >
        </div>
      </ng-template>
      <div
        class="card"
        [ngClass]="{
          completed__step: selectedIndex > index,
          active__step: selectedIndex === index,
          hidden__step: selectedIndex !== index
        }">
        <div
          [ngbCollapse]="selectedIndex !== index"
          class="{{ 'step_' + index }}">
          <div class="card-body px-0 py-0">
            <div class="px-4 formly-field-preview">
              <formly-field [field]="step" class="px-3"></formly-field>
            </div>
            <div
              class="d-flex gap-2 d-md-flex justify-content-end action-buttons px-4 py-3 w-100">
              <button
                *ngIf="!last"
                type="button"
                class="btn btn-primary next"
                (click)="onNext(step)">
                {{ 'Continue' | translate }}
              </button>
              <button *ngIf="last" type="submit" class="btn btn-primary next">
                {{ 'Continue' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
