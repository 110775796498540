<div class="business-certificates">
  <div class="inner-container mb-4">
    <div class="row mt-4">
      <div class="col-md-12">
        <a
          class="back-button text-decoration-none d-flex flex-row align-items-center mb-2"
          href="/profile?section=business">
          <i class="icon-chevron-left me-2"></i>
          {{ 'Back to profile' | translate }}
        </a>

        <h3>{{ business?.name }}</h3>
      </div>
    </div>
    <div class="divider"></div>
    <div class="row profile">
      <div class="col-md-12">
        <!-- Tab Content -->
        <div class="results-view col-12">
          <!-- Certificates -->
          <div class="results col-12">
            <!-- Search Certificates -->
            <div class="row">
              <div class="mb-4 col-12 col-md-6 col-lg-4">
                <!-- Search Documents -->
                <div class="input-group mb-4">
                  <span class="input-group-text bg-white search-input">
                    <i class="icon-search-lg"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control search-input"
                    placeholder="Search by document name or service..."
                    [(ngModel)]="searchDocTerm"
                    (keyup.enter)="onEnterInSearchDocInput()" />
                </div>
              </div>
            </div>
            <!-- Results View -->
            <div class="content-list">
              <div
                class="custom-gap d-flex flex-column"
                *ngIf="showMobileApplicationList; else tableDocumentList">
                <!-- Loading Spinner -->
                <div
                  *ngIf="isLoadingDocuments"
                  class="d-flex align-items-center justify-content-center text-center py-5">
                  <div
                    class="spinner-grow text-primary"
                    style="width: 3rem; height: 3rem"
                    role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <!-- Document List -->
                <ng-container *ngIf="!isLoadingDocuments && certificatesList">
                  <ng-container
                    *ngIf="
                      filteredCertificates.length > 0 && certificateNames;
                      else noDocuments
                    ">
                    <div
                      class="document"
                      *ngFor="
                        let singleCert of filteredCertificates;
                        index as i
                      ">
                      <div class="document-details">
                        <div class="document-name">
                          {{ singleCert.certificateNames['en-US'] }}
                        </div>
                        <span class="service-name">{{
                          singleCert.serviceName
                        }}</span>
                      </div>
                      <div class="document-expiry">
                        <div
                          class="d-flex align-items-center justify-content-center"
                          *ngIf="
                            singleCert.nonExpiringCertificate;
                            else expiry
                          ">
                          {{ 'Expires on' | translate }} :
                          <i class="icon-infinity ms-2"></i>
                        </div>
                      </div>

                      <ng-template #expiry>
                        <div
                          class="d-flex align-items-center justify-content-center">
                          {{ 'Expires on' | translate }}:{{
                            singleCert.expirationDate | date : 'dd/MM/yyyy'
                          }}
                        </div>
                      </ng-template>
                      <div class="divider"></div>
                      <div class="document-action d-flex flex-row col-12">
                        <button
                          class="preview btn btn-link text-decoration-none"
                          *ngIf="!singleCert.expired"
                          (click)="
                            openLanguageSelectorModal(
                              languageSelector,
                              singleCert,
                              documentMode.preview,
                              certificate_content
                            )
                          ">
                          {{ 'Preview' | translate }}
                        </button>
                        <button
                          class="btn download ms-auto btn-link text-decoration-none"
                          (click)="
                            openLanguageSelectorModal(
                              languageSelector,
                              singleCert,
                              documentMode.download
                            )
                          ">
                          {{ 'Download' | translate }}
                        </button>
                      </div>
                    </div>

                    <!-- Attachments -->
                    <div
                      class="document"
                      *ngFor="
                        let attachment of filteredAttachments;
                        index as i
                      ">
                      <div class="document-details">
                        <div class="document-name">
                          {{ attachment.serviceName }} -
                          {{ attachment.applicationNumber }}
                        </div>
                        <span class="service-name">{{
                          attachment.serviceName
                        }}</span>
                      </div>
                      <div class="divider"></div>
                      <div
                        class="document-action d-flex flex-row col-12"
                        *ngIf="
                          allowedAttachmentPreviewTypes.includes(
                            attachment.fileName.split('.').pop() || ''
                          )
                        ">
                        <button
                          class="preview btn btn-link text-decoration-none"
                          (click)="
                            previewAttachment(attachment_content, attachment)
                          ">
                          {{ 'Preview' | translate }}
                        </button>
                        <button
                          class="btn download ms-auto btn-link text-decoration-none"
                          (click)="downloadAttachment(attachment)">
                          {{ 'Download' | translate }}
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- No Documents Template -->
<ng-template #noDocuments>
  <div
    class="no-applications d-flex flex-column justify-content-center align-items-center w-100">
    <div class="icons-container">
      <i class="icon-file-06"></i>
    </div>
    <div class="title">{{ 'Your documents list is empty!' | translate }}</div>
    <div class="description col-10 col-lg-4 col-md-4">
      {{
        'Once you submit an application and it progresses, any documents issued by the service provider will be displayed here.'
          | translate
      }}
    </div>
  </div>
</ng-template>
<!-- Certficate preview -->
<ng-template #certificate_content let-modal>
  <div class="modal-body certificate-preview">
    <div class="modal-header-preview">
      <p class="tt_norms500 preview-header">
        <i class="fa fa-file-text"></i> {{ selectedCertificate?.serviceName }} -
        {{ selectedCertificate?.applicationNumber }}
      </p>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div
      *ngIf="isPreviewingCertificate"
      class="d-flex justify-content-center mt-5">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="
        downloadedCertificate?.contentType === 'application/pdf' && base64Data;
        else imagePreview
      ">
      <pdf-viewer
        [src]="base64Data"
        [render-text]="true"
        [original-size]="true"
        style="width: 100%; height: 80vh; margin-top: 15px"></pdf-viewer>
    </ng-container>
  </div>
</ng-template>
<ng-template #imagePreview>
  <img
    *ngIf="filePath"
    [src]="filePath"
    alt="Image preview"
    class="w-100 h-100 mt-3" />
</ng-template>
<ng-template #languageSelector let-modal>
  <div class="modal-header d-flex">
    <div class="d-flex flex-column">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'Select certificate language' | translate }}
      </h4>
    </div>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div
    class="modal-body"
    *ngIf="
      selectedCertificate &&
      certificateNames[selectedCertificate.applicationNumber]
    ">
    <button
      class="d-flex justify-content-between align-items-center locale w-100"
      (click)="setSelectedLanguage(item['locale'])"
      type="button"
      [class.selected]="item['locale'] === selectedLanguage"
      *ngFor="
        let item of certificateNames[selectedCertificate.applicationNumber];
        index as i
      ">
      <div class="d-flex flex-row gap-2">
        <div class="locale-flag">{{ item['localeFlag'] }}</div>
        <div class="locale-name">{{ item['localeName'] }}</div>
      </div>
      <input
        type="radio"
        name="language"
        [id]="item['localeName']"
        [value]="item['locale']"
        [(ngModel)]="selectedLanguage" />
    </button>
    <div
      class="modal-footer mt-3 d-flex flex-column flex-lg-row-reverse gap-lg-2">
      <button
        *ngIf="languageMode === documentMode.download"
        type="submit"
        class="btn btn-primary fw-semibold col-12 col-lg-5 flex-lg-fill"
        (click)="
          downloadCertificate(selectedCertificate, selectedLanguage || '')
        "
        [disabled]="!selectedLanguage || isDownloadingCertificate">
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          *ngIf="isDownloadingCertificate">
          <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
        </div>
        {{ 'Download' | translate }}
      </button>
      <button
        *ngIf="languageMode === documentMode.preview"
        type="submit"
        class="btn btn-primary fw-semibold col-12 col-lg-5 flex-lg-fill"
        (click)="modal.close(selectedLanguage)"
        [disabled]="!selectedLanguage">
        {{ 'Preview' | translate }}
      </button>
      <button
        type="button"
        class="btn cancel-btn col-12 col-lg-5 flex-lg-fill"
        (click)="selectedLanguage = null">
        {{ 'Cancel' | translate }}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #attachment_content let-modal>
  <div class="modal-body certificate-preview">
    <div class="modal-header-preview">
      <p class="fw-normal preview-header">
        <i class="fa fa-file-text"></i>
        {{ selectedAttachment?.serviceName }} -
        {{ selectedAttachment?.applicationNumber }}
      </p>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div
      *ngIf="isPreviewingCertificate"
      class="d-flex justify-content-center mt-5">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="
        downloadedAttachment?.contentType === 'application/pdf' && base64Data;
        else imagePreview
      ">
      <pdf-viewer
        [src]="base64Data"
        [render-text]="true"
        [original-size]="true"
        style="width: 100%; height: 80vh; margin-top: 15px"></pdf-viewer>
    </ng-container>
  </div>
</ng-template>

<ng-template #tableDocumentList>
  <div class="table-responsive">
    <table class="table table-sm">
      <thead>
        <tr>
          <th>Document Name</th>
          <th>Service</th>
          <th>Expires on</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let singleCert of filteredCertificates; index as i">
          <td>{{ singleCert.certificateNames['en-US'] }}</td>
          <td>{{ singleCert.serviceName }}</td>
          <td>
            <div
              class="d-flex align-items-center justify-content-center"
              *ngIf="singleCert.nonExpiringCertificate; else expiry">
              <i class="icon-infinity ms-2"></i>
            </div>
            <ng-template #expiry>
              <div class="d-flex align-items-center justify-content-center">
                {{ singleCert.expirationDate | date : 'dd/MM/yyyy' }}
              </div>
            </ng-template>
          </td>
          <td>
            <div class="application-view fw-semibold">
              <!-- <a
                  type="button"
                  (click)="openApplicationDetails(applicationData, application)"
                  class="text-decoration-none"
                  >{{ 'View' | translate }}</a
                > -->
              <div class="document-action d-flex flex-row col-12">
                <button
                  class="preview btn btn-link text-decoration-none"
                  *ngIf="!singleCert.expired"
                  (click)="
                    openLanguageSelectorModal(
                      languageSelector,
                      singleCert,
                      documentMode.preview,
                      certificate_content
                    )
                  ">
                  {{ 'Preview' | translate }}
                </button>
                <button
                  class="btn download ms-auto btn-link text-decoration-none"
                  (click)="
                    openLanguageSelectorModal(
                      languageSelector,
                      singleCert,
                      documentMode.download
                    )
                  ">
                  {{ 'Download' | translate }}
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="w-100 mt-3 px-4">
      <irembogov-citizen-pagination
        [minimalPagination]="false"
        [showDirectionText]="true"
        [collectionSize]="totalDocuments"
        (paginatedValues)="
          handlePaginate($event)
        "></irembogov-citizen-pagination>
    </div>
  </div>
</ng-template>
