<ngb-pagination
  [collectionSize]="collectionSize"
  [(page)]="page"
  (pageChange)="pageChange($event)"
  [maxSize]="maxSize"
  [size]="paginationDisplaySize"
  [rotate]="false"
  [pageSize]="pagination.pageSize"
  [ellipses]="!minimalPagination"
  class="citizen-pagination d-flex flex-row justify-content-between align-items-center w-100"
  aria-label="Custom pagination">
  <ng-container *ngIf="minimalPagination">
    <ng-template ngbPaginationPrevious>
      <i class="icon-arrow-left"></i>
      <span *ngIf="showDirectionText">{{ 'Previous' | translate }}</span>
    </ng-template>
    <ng-template ngbPaginationNext>
      <i class="icon-arrow-right"></i>
      <span *ngIf="showDirectionText">{{ 'Next' | translate }}</span>
    </ng-template>
    <ng-template ngbPaginationPages let-page>
      <div class="d-flex flex-nowrap px-2">
        <span class="pagination-text text-nowrap px-2">
          {{ 'Page' | translate }} {{ page }} of {{ getTotalPages() }}</span
        >
      </div>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="!minimalPagination">
    <ng-template ngbPaginationPrevious>
      <i class="icon-arrow-left"></i>
      <span>{{ 'Previous' | translate }}</span>
    </ng-template>
    <ng-template ngbPaginationNext>
      <span>{{ 'Next' | translate }}</span>
      <i class="icon-arrow-right"></i>
    </ng-template>
    <ng-template ngbPaginationNumber let-p>{{ getPageSymbol(p) }}</ng-template>
    <ng-template ngbPaginationEllipsis>...</ng-template>
  </ng-container>
</ngb-pagination>
