import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { KeycloakService } from 'keycloak-angular';
import { IService } from '../../models/services-list.model';
import { PublicServicesService } from '../../services/public-services.service';
import {
  EServicePriceType,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { IHttpPagedResponse } from '../../models/http/paginated-response.model';
import { Business } from '../../models/business.model';
import { UserBusinessService } from '../../services/user-business.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  EApplicationApplicantType,
  EServiceApplicantType,
} from '../../models/applicant-type.enum';
import { IApplicationApplicantTypeAndBusinessInfo } from '../../models/applicant-type-and-business-id.model';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BusinessService } from '../../services/business.service';
import { TranslateService } from '@ngx-translate/core';

interface IBothApplicationtTypeOption {
  key: EApplicationApplicantType;
  label: string;
  description: string;
}
@Component({
  selector: 'irembogov-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ServiceDetailsComponent implements OnInit, OnDestroy {
  keyword = '';
  serviceId = '';
  selectedService: IService | undefined;
  isLoggedIn?: boolean;
  isLoading = false;
  priceType = EServicePriceType;
  EApplicationApplicantType = EApplicationApplicantType;
  applicationApplicantTypeData?: IApplicationApplicantTypeAndBusinessInfo =
    undefined;

  navigatingToApplicationPage = false;

  userBusinesses?: Business[] = undefined;

  @ViewChild('noBusinessModal')
  noBusinessModal!: TemplateRef<unknown>;

  @ViewChild('selectApplicantForBusinessModal')
  selectApplicantForBusinessModal!: TemplateRef<unknown>;

  @ViewChild('selectApplicantForBothModal')
  selectApplicantForBothModal!: TemplateRef<unknown>;

  applicationApplicantTypeFormControl = new FormControl('');
  applicationBusinessIdFormControl = new FormControl('');
  applicationBusinessNameFormControl = new FormControl('');

  bothApplicationtTypeOptions: IBothApplicationtTypeOption[] = [
    {
      key: EApplicationApplicantType.INDIVIDUAL,
      label: 'Individual',
      description: 'Apply as a private citizen',
    },
    {
      key: EApplicationApplicantType.BUSINESS,
      label: 'Business',
      description: 'Apply on behalf of a company or organization',
    },
  ];

  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private keycloak: KeycloakService,
    private businessService: BusinessService,
    private publicServicesService: PublicServicesService,
    private router: Router,
    private userBusinessService: UserBusinessService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {
    this.applicationApplicantTypeData = undefined;
  }

  async ngOnInit() {
    this.isLoading = true;
    this.keyword = this.route.snapshot.queryParamMap.get('keyword') ?? '';
    this.serviceId = this.route.snapshot.queryParamMap.get('id') ?? '';
    this.isLoggedIn = await this.keycloak.isLoggedIn();

    this.subscriptions.add(
      this.businessService.getBusinesses().subscribe({
        next: (response: IHttpPagedResponse<Business>) => {
          if (response.data.content) {
            this.userBusinessService.setUserBusinesses(response.data.content);
          }
        },
        error: () => {
          this.toastService.show({
            body: this.translateService.instant(
              'User businesses could not be retrieved.'
            ),
            type: 'error',
          });
        },
      })
    );
    this.subscriptions.add(
      this.publicServicesService
        .getServiceById(this.serviceId)
        .subscribe(res => {
          this.selectedService = res;
          this.isLoading = false;
        })
    );
  }

  setSelectedBusiness(businessId: string, businessName: string): void {
    this.applicationBusinessIdFormControl.setValue(businessId);
    this.applicationBusinessNameFormControl.setValue(businessName);
  }

  openModal(
    content: unknown,
    windowClass: string,
    container?: string,
    backdrop?: boolean | 'static',
    size?: string
  ) {
    const options: NgbModalOptions = {
      ariaLabelledBy: 'verify-token',
      windowClass,
      centered: true,
      size,
      container,
      backdrop: backdrop ?? 'static',
    };
    return this.modalService.open(content, options);
  }

  onApplyServiceClicked() {
    if (!this.selectedService) return;

    this.applicationApplicantTypeData = undefined;
    this.navigatingToApplicationPage = false;
    this.applicationApplicantTypeFormControl.setValue(null);
    this.applicationBusinessIdFormControl.setValue(null);
    this.applicationBusinessNameFormControl.setValue(null);

    if (this.selectedService?.applicantType === EServiceApplicantType.BOTH) {
      this.initSelectApplicantForBoth();
      return;
    }

    if (
      this.selectedService?.applicantType === EServiceApplicantType.BUSINESS
    ) {
      this.initSelectApplicantForBusiness();
      return;
    }

    this.applicationApplicantTypeData = {
      applicationApplicantType: EApplicationApplicantType.INDIVIDUAL,
    };

    //FOR SERVICE WITH APPLICANT TYPE INDIVIDUAL, ALWAYS OPEN APPLICATION PAGE
    this.navigateToApplicationPage();
  }

  initSelectApplicantForBoth(): void {
    this.openModal(
      this.selectApplicantForBothModal,
      'select-applicant-for-both-modal',
      '.irembogov-service-details'
    );
    return;
  }

  handleSelectedApplicantForBoth(): void {
    if (!this.applicationApplicantTypeFormControl.value) {
      return;
    }
    this.applicationApplicantTypeData = {
      applicationApplicantType: <EApplicationApplicantType>(
        this.applicationApplicantTypeFormControl.value
      ),
    };

    if (
      this.applicationApplicantTypeData.applicationApplicantType ===
      EApplicationApplicantType.INDIVIDUAL
    ) {
      this.navigateToApplicationPage();
      return;
    }
    this.modalService.dismissAll();
    this.initSelectApplicantForBusiness();
  }

  initSelectApplicantForBusiness(): void {
    this.userBusinesses = this.userBusinessService.getUserBusinesses();

    if (
      this.userBusinesses === undefined ||
      this.userBusinesses?.length === 0
    ) {
      //NO BUSINESSES OPEN ADD BUSINESS MODAL
      this.modalService.dismissAll();
      this.openModal(
        this.noBusinessModal,
        'no-business-modal',
        '.irembogov-service-details'
      );

      return;
    }

    this.applicationApplicantTypeData = {
      applicationApplicantType: EApplicationApplicantType.BUSINESS,
    };
    this.openModal(
      this.selectApplicantForBusinessModal,
      'select-applicant-for-business-modal',
      '.irembogov-service-details'
    );
    return;
  }

  handleSelectedApplicantForBusiness(): void {
    if (
      !(
        this.applicationBusinessIdFormControl.value &&
        this.applicationBusinessNameFormControl.value
      )
    ) {
      this.toastService.show({
        body: 'Please select or add a business or organization.',
        type: 'error',
      });
      return;
    }
    this.applicationApplicantTypeData = {
      applicationApplicantType: EApplicationApplicantType.BUSINESS,
      businessInfo: {
        businessId: this.applicationBusinessIdFormControl.value,
        businessName: this.applicationBusinessNameFormControl.value,
      },
    };
    this.navigateToApplicationPage();
  }

  navigateToApplicationPage() {
    this.navigatingToApplicationPage = true;
    const navigationExtras: NavigationExtras = {
      queryParams: {
        keyword: this.selectedService?.serviceName,
        id: this.selectedService?.id,
      },
      state: {
        applicantData: {
          applicantType:
            this.applicationApplicantTypeData?.applicationApplicantType,
          businessId:
            this.applicationApplicantTypeData?.businessInfo?.businessId,
          businessName:
            this.applicationApplicantTypeData?.businessInfo?.businessName,
        },
      },
    };
    this.router.navigate(['/application'], navigationExtras);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
