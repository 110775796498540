import { Component, OnDestroy } from '@angular/core';
import { ToastInfo, ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'irembogov-toast',
  templateUrl: './irembo-toast.component.html',
  styleUrls: ['./irembo-toast.component.scss'],
})
export class IremboToastComponent implements OnDestroy {
  autohide = true;
  constructor(public toastService: ToastService) {}

  btnclass(type: string): string {
    return `btn-close-${type}`;
  }

  handleClose(toast: ToastInfo) {
    this.autohide = true;
    this.toastService.remove(toast);
  }

  ngOnDestroy(): void {
    this.toastService.clear();
  }
}
