<div class="search-container mt-5">
  <a
    (click)="searchByQuery.emit(keyword)"
    class="btn bg-irembo-skyblue text-irembo-blue me-3">
    <i aria-hidden="true" class="fa-solid fa-list ms-2"></i>
    <strong> All Results </strong>
  </a>

  <ng-container *ngFor="let institution of institutions">
    <a
      (click)="searchByOrgId.emit(institution.id)"
      class="btn bg-irembo-grey me-3">
      <i
        aria-hidden="true"
        class="fa-solid ms-2"
        [ngClass]="selectedApplicationIcon"></i>
      <strong> {{ institution.acronym }} </strong>
    </a>
  </ng-container>
</div>
