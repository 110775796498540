/**
 * Returns object defining the details of the web client
 * @returns web client's details
 */
export const getBrowserDetails = () => ({
  channel: 'web',
  application_origin: window.location.hostname,
  language: 'fr', // TODO: set language from user preference
  appVersion: '1.0.0', // TODO: set app version
  os: 'android', // TODO: set os & app version
  osVersion: '30',
  browser: null,
});
