<div class="identity-verification">
  <div
    class="inner-container d-flex flex-column flex-lg-row justify-content-between align-items-center">
    <div
      class="d-flex flex-row justify-content-between align-items-start align-items-lg-center align-items-md-center">
      <img
        src="../../../assets/icons/notification.png"
        alt="notification icon"
        class="notification-icon d-none d-lg-flex me-3" />
      <div class="notification-message mt-0 mb-2 mb-lg-0">
        {{
          'Verify your identity to access all IremboGov’s services' | translate
        }}
      </div>
      <i
        class="fa-solid fa-xmark ms-auto d-lg-none d-md-none"
        style="color: #136fd3"
        (click)="onCancel()"></i>
    </div>

    <button
      class="btn text-white fw-semibold ms-auto col-12 col-lg-auto col-md-auto"
      (click)="onVerify()">
      {{ 'Verify now' | translate }}
    </button>
    <i
      class="fa-solid fa-xmark d-none d-lg-flex d-md-flex ms-3"
      style="color: #136fd3"
      (click)="onCancel()"></i>
  </div>
</div>
