<div class="offcanvas-header py-1">
  <small class="offcanvas-title py-md-1 py-sm-1" id="offcanvasLabel"
    >{{ 'browse services by' | translate }}:</small
  >
  <ul class="nav nav-tabs mt-0 px-sm-1 px-md-1">
    <li class="nav-item">
      <button
        class="nav-link"
        [class.active]="setActive === 'category'"
        (click)="handleTabClick('category')">
        {{ 'Categories' | translate }}
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        [class.active]="setActive === 'institution'"
        (click)="handleTabClick('institution')">
        {{ 'Institutions' | translate }}
      </button>
    </li>
  </ul>
</div>
<div class="offcanvas-body mt-0 py-1 px-0">
  <!-- Category Content -->
  <div *ngIf="setActive === 'category'">
    <div *ngFor="let category of categories" class="list-group borderless">
      <p class="borderless">
        <a
          class="widthfiller tab-content itemContent borderless"
          [routerLink]="['/services/browse-by']"
          [queryParams]="{ type: 'category', id: category.id }"
          >{{ category.name }}</a
        >
      </p>
    </div>
  </div>

  <!-- Institution Content -->
  <div *ngIf="setActive === 'institution'">
    <div *ngFor="let institution of institutions" class="list-group borderless">
      <p class="borderless">
        <a
          class="widthfiller tab-content itemContent borderless"
          [routerLink]="['/services/browse-by']"
          [queryParams]="{ type: 'institution', id: institution.id }"
          >{{ institution.acronym ?? '' | translate }}</a
        >
      </p>
    </div>
  </div>
</div>
