import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../service/auth.service';
import { finalize } from 'rxjs';
import { ResetPasswordDto } from '../../../models/resetPassword.model';
import { UserType } from '../../../models/citizenCreationRequest.model';
import { ResendTokenOtpDto } from '../../../models/common.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'irembogov-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  password = '';
  cpassword = '';
  isLoading = false;
  errorPassMsg = '';
  errorMessage = '';
  errorCPassMsg = '';
  userFeedback = '';
  showPassword = false;
  showConfirmPassword = false;
  showCurrentPassword = false;
  locale = '';

  private token = '';
  private username = '';

  resetForm!: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService
  ) {}

  private entryPath = '';

  ngOnInit(): void {
    this.token =
      this.route.snapshot.queryParamMap.get('token') ??
      window.localStorage.getItem('phoneceasar') ??
      '';
    this.username = this.route.snapshot.queryParamMap.get('username') ?? '';
    this.entryPath = this.router.url;

    this.resetForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      confirm_password: new FormControl('', [
        Validators.required,
        this.passwordMatchValidator.bind(this),
      ]),
    });

    this.locale = localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
  }

  passwordMatchValidator(): { [s: string]: boolean } | null {
    if (this.resetForm) {
      const password = this.resetForm.get('password')?.value;
      const confirmPassword = this.resetForm.get('confirm_password')?.value;
      return confirmPassword === password ? null : { passwordNotMatch: true };
    }
    return null;
  }

  onFormSubmit(): void {
    this.isLoading = true;
    this.errorMessage = '';
    if (this.resetForm.invalid) {
      this.isLoading = false;
      return;
    }

    if (
      this.resetForm.get('password')?.value !==
      this.resetForm.get('confirm_password')?.value
    ) {
      this.resetForm
        .get('confirm_password')
        ?.setErrors({ passwordNotMatch: true });
      this.isLoading = false;
      return;
    }

    const resetPasswordDto: ResetPasswordDto = {
      clientId: environment.authClientId,
      oneTimePasswordToken: this.token,
      newPassword: this.resetForm.get('password')?.value,
      userType: UserType.CITIZEN,
      username: this.username,
      locale: this.locale,
    };

    this.authService
      .resetPassword(resetPasswordDto)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.router.navigate(['auth/success']);
        },
        error: (err: HttpErrorResponse) => {
          this.errorMessage = err?.error?.message ?? 'Fail to reset password';
          this.isLoading = false;
          this.resetForm.reset();
        },
      });
  }

  resendLink() {
    this.userFeedback = '';
    this.errorMessage = '';
    const resendTokenOtpDto: ResendTokenOtpDto = {
      token: this.token,
      clientId: environment.authClientId,
      userType: UserType.CITIZEN,
      username: this.username,
    };

    this.authService.resendResetTokenExpired(resendTokenOtpDto).subscribe({
      next: () => {
        this.toastService.show({
          type: 'success',
          body: `Email sent, check your email`,
        });
      },
      error: (err: HttpErrorResponse) => {
        this.errorMessage = err.error.message ?? 'Fail to resend link';
      },
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  toggleCurrentPassword() {
    this.showCurrentPassword = !this.showCurrentPassword;
  }
}
