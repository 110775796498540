<div
  class="irembogov-irembo-loading-circle-spinner d-block"
  [style]="{ marginTop: marginTop }">
  <div
    class="w-99 d-flex align-items-center justify-content-center text-center h-100">
    <div class="spinner-grow text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>
