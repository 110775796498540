import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AccountResendOtpRequestDto } from '../../../models/account-resend-otp-request.model';
import {
  RecipientType,
  UserType,
} from '../../../models/citizenCreationRequest.model';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'irembogov-reset-activation',
  templateUrl: './reset-activation.component.html',
  styleUrls: ['./reset-activation.component.scss'],
})
export class ResetActivationComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}
  email = '';
  message = 'We sent a password reset link to';
  userFeedback = '';
  errorMessage = '';
  locale = '';

  ngOnInit(): void {
    this.email = this.route.snapshot.queryParamMap.get('email') ?? '';
    this.locale = localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
  }

  resendResetLink() {
    this.userFeedback = '';
    this.errorMessage = '';
    const accountResendOtpRequestDto: AccountResendOtpRequestDto = {
      clientId: environment.authClientId,
      usernameType: RecipientType.EMAIL_ADDRESS,
      username: this.email,
      userType: UserType.CITIZEN,
      locale: this.locale,
    };

    this.authService
      .initiatePasswordReset(accountResendOtpRequestDto)
      .subscribe({
        next: () => {
          this.userFeedback = 'Email sent, check your email';
        },
        error: (err: HttpErrorResponse) => {
          this.errorMessage = err.error.message ?? 'Fail to resend link';
          console.log(err);
        },
      });
  }
}
