<div class="business-applications">
  <div class="inner-container mb-4">
    <div class="row mt-4">
      <div class="col-md-12">
        <a
          class="back-button text-decoration-none d-flex flex-row align-items-center mb-2"
          href="/profile?section=business">
          <i class="icon-chevron-left me-2"></i>
          {{ 'Back to profile' | translate }}
        </a>

        <h3>{{ business?.name }}</h3>
      </div>
    </div>
    <div class="divider"></div>
    <div class="row profile">
      <div class="col-md-12">
        <!-- Tab Content -->
        <div class="results-view col-12">
          <!-- Applications -->
          <div class="results col-12">
            <!-- Search Applications -->
            <div class="row" *ngIf="showMobileApplicationList">
              <div class="mb-4 col-12 col-md-6 col-lg-4">
                <div
                  class="input-group"
                  *ngIf="
                    !isLoadingApplications && applicationsList.length !== 0
                  ">
                  <span class="input-group-text bg-white search-input"
                    ><i class="icon-search-lg"></i
                  ></span>
                  <input
                    type="text"
                    class="form-control search-input col-12 col-md-6 col-lg-4"
                    [placeholder]="
                      'Search by application name or bill ID' | translate
                    "
                    [(ngModel)]="searchTerm"
                    (keyup.enter)="onEnterInSearchInput()" />
                </div>
              </div>
            </div>
            <!-- Results View -->
            <div class="content-list">
              <!-- Application List -->
              <div class="d-flex flex-column custom-gap">
                <ng-container
                  *ngIf="applicationsList.length > 0; else noApplications">
                  <ng-container
                    *ngIf="
                      showMobileApplicationList;
                      else tableApplicationList
                    ">
                    <div
                      class="application"
                      *ngFor="
                        let application of applicationsList;
                        index as index
                      ">
                      <div class="application-details">
                        <div class="application-number">
                          {{ application.applicationNumber }}
                        </div>
                        <span class="service-name">{{
                          application.serviceName
                        }}</span>
                      </div>
                      <div
                        class="application-status irembo-badge"
                        [ngClass]="
                          getApplicationStatusToColor(
                            application.applicationStatus
                          )
                        ">
                        <div
                          class="pulse"
                          [ngClass]="
                            getApplicationStatusPulseToBackgroundColor(
                              application.applicationStatus
                            )
                          "></div>
                        {{
                          getApplicationStatusToLabel(
                            application.applicationStatus
                          )
                        }}
                      </div>
                      <div class="divider"></div>
                      <div class="application-view">
                        <a
                          type="button"
                          (click)="
                            openApplicationDetails(applicationData, application)
                          "
                          class="text-decoration-none"
                          >{{ 'View details' | translate }}</a
                        >
                      </div>
                    </div>
                    <div class="divider"></div>
                    <!-- Pagination -->
                    <irembogov-citizen-pagination
                      [minimalPagination]="true"
                      [collectionSize]="totalApplications"
                      (paginatedValues)="handlePaginate($event)"
                      [showDirectionText]="
                        false
                      "></irembogov-citizen-pagination>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
          <!-- End applications  -->
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #applicationData let-offcanvas>
  <div class="offcanvas-header">
    <div class="d-flex flex-column align-items-start">
      <h2 class="title">{{ 'Application details' | translate }}</h2>
      <small>{{
        'View your application details and current status' | translate
      }}</small>
    </div>
    <div class="fs-1 d-flex align-items-start">
      <button class="btn btn-close" (click)="offcanvas.dismiss()"></button>
    </div>
  </div>
  <div class="offcanvas-body" *ngIf="applicationDetails">
    <div class="hstack gap-2 w-100">
      <button
        *ngIf="
          applicationDetails !== undefined &&
          selectedApplication?.priceType !== 'FREE' &&
          applicationDetails?.applicationStatus !== 'PAYMENT_PENDING'
        "
        (click)="getPaymentReceipt(selectedApplication?.applicationNumber)"
        [disabled]="downloadingReceipt"
        [ngClass]="{ disabled: downloadingReceipt }"
        class="btn btn-download d-flex flex-row gap-2 align-items-center">
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          *ngIf="downloadingReceipt">
          <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
        </div>
        <i class="icon-file-download-02" *ngIf="!downloadingReceipt"></i>
        {{ 'Download receipt' | translate }}
      </button>
      <button
        *ngIf="
          applicationDetails !== undefined &&
          applicationDetails?.applicationStatus === 'PAYMENT_PENDING'
        "
        (click)="makePayment()"
        class="btn btn-primary d-flex flex-row gap-2 align-items-center text-white">
        <i class="icon-credit-card-01"></i>{{ 'Pay' | translate }}
      </button>
      <button
        *ngIf="
          applicationDetails !== undefined &&
          applicationDetails?.applicationStatus?.includes(
            'PENDING_RESUBMISSION'
          )
        "
        (click)="
          editApplication(
            selectedApplication?.applicationId ?? '',
            applicationDetails.applicationStatus,
            selectedApplication?.applicationNumber ?? '',
            selectedApplication?.serviceId ?? '',
            selectedApplication?.serviceName ?? ''
          )
        "
        class="btn btn-primary d-flex flex-row gap-2 align-items-center text-white">
        <i class="icon-edit-05"></i>{{ 'Edit' | translate }}
      </button>
    </div>

    <div class="app-status" *ngIf="applicationDetails">
      <h3 class="title">Status</h3>
      <div *ngIf="applicationDetails.applicationStateTrackers.length > 0">
        <ol class="timeline">
          <li
            *ngFor="
              let step of applicationDetails?.applicationStateTrackers;
              let index = index
            "
            class="step-item">
            <div class="step-icon-container">
              <div class="step-icon">
                <img src="assets/icons/status-check.svg" alt="" />
              </div>
            </div>
            <ng-template #otherStatus>
              <div class="step-content">
                <div class="title">
                  {{
                    getApplicationStatusToLabel(step.applicationState)
                      | translate
                  }}
                </div>
                <div class="item_text">
                  {{ step.stateTimestamp | date : 'medium' }}
                </div>
              </div>
            </ng-template>
            <ng-container
              *ngIf="
                step.applicationState.includes('PENDING_RESUBMISSION');
                else otherStatus
              ">
              <div class="step-content">
                <div class="title">
                  {{ 'Returned for my action' | translate }}
                </div>
                <div class="item_text">
                  {{
                    'Additional information required. Please review and update your application'
                      | translate
                  }}
                </div>
                <div
                  class="feedback-container"
                  *ngIf="
                    applicationDetails?.applicationFeedbacks
                      ?.feedbackResponseDetails &&
                    applicationDetails.applicationFeedbacks
                      .feedbackResponseDetails.length
                  ">
                  <irembogov-application-feedback
                    [applicationFeedBack]="
                      getActiveFeedback(
                        applicationDetails.applicationFeedbacks
                          .feedbackResponseDetails
                      )
                    "></irembogov-application-feedback>
                </div>
              </div>
            </ng-container>
          </li>
        </ol>
      </div>
    </div>
    <button
      class="toggle-button btn"
      (click)="viewSummaryToggle()"
      type="button">
      <i [ngClass]="!viewSummary ? 'icon-eye' : 'icon-eye-off'"></i>
      {{ viewSummaryText | translate }}
    </button>

    <ng-container *ngIf="applicationDetails && viewSummary">
      <div class="section">
        <irembogov-application-summary
          [showTitle]="false"
          [application]="selectedApplication"
          [applicationSummary]="applicationSummary"
          [applicationBaseUrl]="applicationBaseUrl"
          [applicationPath]="applicationPath"></irembogov-application-summary>
      </div>
    </ng-container>
  </div>
  <irembogov-irembo-loading-circle-spinner
    *ngIf="!applicationDetails"></irembogov-irembo-loading-circle-spinner>
</ng-template>

<ng-template #loadingState>
  <div
    class="d-flex flex-column justify-content-center align-items-center w-100 vh-100">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>

<!-- No Applications Template -->
<ng-template #noApplications>
  <div
    class="no-applications d-flex flex-column justify-content-center align-items-center w-100 h-100">
    <div class="icons-container">
      <i class="icon-layers-three-01"></i>
    </div>
    <div class="title">{{ 'Your application list is empty!' | translate }}</div>
    <div class="description col-10 col-lg-4 col-md-4">
      {{
        "You haven't submitted any applications yet. Browse available services and get started!"
          | translate
      }}
    </div>
  </div>
</ng-template>

<ng-template #tableApplicationList>
  <irembogov-application-list
    *ngIf="totalApplications !== 0; else noApplications"
    [isLoadingApplications]="isLoadingApplications"
    [applicationsList]="applicationsList"
    [totalApplications]="totalApplications"
    [viewType]="'business'"
    (getApplications)="
      getApplications(
        $event.pageNumber,
        $event.pageSize,
        $event.sort,
        $event.searchTerm
      )
    "
    (viewApplicationDetails)="
      openApplicationDetails(applicationData, $event)
    "></irembogov-application-list>
</ng-template>
